import React from "react";
import { Avatar, Flex, FlexItem, Badge } from "orcs-design-system";
import { get } from "lodash";
import { useGlobalConfig } from "src/contexts/global/GlobalContext";
import GroupPropType from "src/custom-prop-types/group";
import GroupTypeBadge from "src/components/GroupTypeBadge";
import HighlightedGroupTags from "src/components/HighlightedGroupTags";
import GroupMemberCountBadge from "src/components/GroupMemberCountBadge";
import isSupplyDemandGroupType from "src/util/isSupplyDemandGroupType";

import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { getBgImage, attributeTypes } from "src/util/group";

import { getTeamGradient, AvatarWithGradient } from "src/util/gradient";
import { getGroupExternalId } from "src/util/groupAttributes";
import TeamHierarchyBreadcrumb from "../TeamHierarchyBreadcrumb";
import TeamName from "../TeamName";
import GroupDeletedBadge from "./GroupDeletedBadge";
import FteQueryBadgeContainer from "./FteQueryBadgeContainer";

const Heading = ({ team }) => {
  const workspace = useWorkspace();
  const globalConfig = useGlobalConfig();
  const [isSupply, isDemand] = isSupplyDemandGroupType(team, workspace);
  const { isDeleted, isHidden, deletedAt, hiddenAt } = team;
  const displayFteBadge = !isDeleted && !isHidden && (isSupply || isDemand);
  const bgImg = getBgImage(get(team, "attributes"), attributeTypes.IMAGE);
  const bgGradient = getTeamGradient(globalConfig);

  const AvatarComponent = bgImg ? Avatar : AvatarWithGradient;
  const memberCount = team?.memberCount;
  const workspaceFeatureFlags = get(workspace, "config.featureFlags", {});
  const externalId = getGroupExternalId(team);
  const showExternalIdBadge =
    workspaceFeatureFlags?.showTeamExternalIdAsBadge && externalId;

  return (
    <Flex alignItems="center" width="100%">
      <AvatarComponent
        mr="r"
        sizing="large"
        shape="square"
        image={bgImg}
        imageAlt={`Avatar of ${team.name}`}
        bgGradient={bgImg ? undefined : bgGradient}
      />
      <FlexItem>
        <TeamHierarchyBreadcrumb team={team} />
        <Flex alignItems="center" flexWrap="wrap">
          <TeamName
            team={team}
            className="guided-tour-team-details-team-name"
          />
          {(isDeleted || isHidden) && (
            <FlexItem flex="0 0 auto" ml="xs">
              <GroupDeletedBadge removedAt={deletedAt || hiddenAt} />
            </FlexItem>
          )}
          <FlexItem flex="0 0 auto" ml="xs">
            <GroupTypeBadge
              group={team}
              showToolTip
              className="guided-tour-team-details-team-type"
            />
          </FlexItem>
          {showExternalIdBadge && (
            <FlexItem flex="0 0 auto" ml="xs">
              <Badge role="listitem" display="block" noWrap>
                {externalId}
              </Badge>
            </FlexItem>
          )}
          <FlexItem flex="0 0 auto" ml="xs">
            {!isNaN(memberCount) && (
              <GroupMemberCountBadge
                count={team.memberCount}
                isTotalMemberCount
                hideWhenZero
                showToolTip
                className="guided-tour-team-details-member-count"
              />
            )}
          </FlexItem>
          {displayFteBadge && (
            <FlexItem flex="0 0 auto" ml="xs">
              <FteQueryBadgeContainer team={team} workspace={workspace} />
            </FlexItem>
          )}
        </Flex>
        <HighlightedGroupTags team={team} small />
      </FlexItem>
    </Flex>
  );
};

Heading.propTypes = {
  team: GroupPropType.isRequired,
};

export default Heading;
