const createFileProcessWorker = () => {
  const workerScript = `
  postMessage({ status: "ready" });
  onmessage = function(evt) {
    importScripts(evt.data.url + "/libs/xlsx.full.min-0.17.4.js");
    var data;
    try {
      var workbook = XLSX.read(evt.data.fileContent, evt.data.options);
      postMessage({ status: 'processing', data: "read file done" });
      if (workbook.SSF) {
        XLSX.SSF.load_table(workbook.SSF);
      }
      var wsname = workbook.SheetNames[0];
      var ws = workbook.Sheets[wsname];
      postMessage({ status: 'processing', data: "converting to json" });
      data = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        sheetRows: 10,
        raw: false
      });  
    } catch(e) {
      postMessage({ status:"error", data: e.stack });
    }
    postMessage({ status: "done", data: data});
  }
`;

  const workerBlob = new Blob([workerScript], {
    type: "text/javascript",
  });

  const worker = new Worker(window.URL.createObjectURL(workerBlob));

  return worker;
};

export default createFileProcessWorker;
