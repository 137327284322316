import React from "react";
import { isEmpty, map } from "lodash";

import CirclePackCard from "./CirclePackCard";
import PieChartCard from "./PieChartCard";
import HorizontalBarCard from "./HorizontalBarCard";
import PeopleInsightCard from "./PeopleCountInsightCard";
import GenderInsightCard from "./GenderInsightCard";
import SingleStatCard from "./SingleStatCard";
import StatListCard from "./StatListCard";
import HelpContent from "./HelpContent";

const getHelpContent = (text) => <HelpContent text={text} />;

export const ALL_CARDS = {
  person: ({ insights, loading, comparisonMode }) => {
    return (
      <div data-testid="cp-insights-people" key="person">
        <PeopleInsightCard
          insights={insights}
          loading={loading}
          icon={["far", "user"]}
          title="People"
          compare={comparisonMode}
          helpContent={getHelpContent(
            "Shows the total number of people matching the current filter"
          )}
        />
      </div>
    );
  },
  gender: ({ insights, loading, comparisonMode }) => {
    return (
      <div data-testid="cp-insights-gender" key="gender">
        <GenderInsightCard
          insights={insights}
          loading={loading}
          dataKey="gender"
          icon={["far", "transgender-alt"]}
          title="Gender"
          compare={comparisonMode}
          helpContent={getHelpContent(
            "Shows the gender ratio of the people matching the current filter"
          )}
        />
      </div>
    );
  },
  company: ({ insights, comparisonMode, loading, fteMode, updateCardSize }) => {
    return (
      <div data-testid="cp-insights-company" key="company">
        <CirclePackCard
          insights={insights}
          loading={loading}
          filterKey="company"
          dataKey="company"
          layoutKey="company"
          icon={["far", "building"]}
          title="Companies"
          labelAsPercentage
          compare={comparisonMode}
          fteMode={fteMode}
          helpContent={getHelpContent(
            "Shows the organisation employed by the people matching the current filter"
          )}
          updateCardSize={updateCardSize}
        />
      </div>
    );
  },
  country: ({ insights, comparisonMode, loading, fteMode, updateCardSize }) => {
    return (
      <div data-testid="cp-insights-country" key="country">
        <CirclePackCard
          insights={insights}
          loading={loading}
          filterKey="country"
          dataKey="country"
          layoutKey="country"
          icon={["far", "globe-asia"]}
          title="Countries"
          labelAsPercentage
          compare={comparisonMode}
          fteMode={fteMode}
          helpContent={getHelpContent(
            "Shows the country's population of the people matching the current filter"
          )}
          updateCardSize={updateCardSize}
        />
      </div>
    );
  },
  allocation: ({ insights, comparisonMode, fteMode, updateCardSize }) => {
    return (
      <div data-testid="cp-insights-allocation-summary" key="allocation">
        <PieChartCard
          insights={insights}
          loading={false}
          filterKey="allocation"
          dataKey="allocationDistribution"
          layoutKey="allocation"
          icon={["far", "user"]}
          title="Allocation Distribution"
          labelAsPercentage
          compare={comparisonMode}
          fteMode={fteMode}
          helpContent={getHelpContent(
            "Shows the allocation distribution for a single supply team"
          )}
          updateCardSize={updateCardSize}
        />
      </div>
    );
  },
  location: ({
    insights,
    comparisonMode,
    loading,
    fteMode,
    updateCardSize,
  }) => {
    return (
      <div data-testid="cp-insights-location" key="location">
        <CirclePackCard
          insights={insights}
          loading={loading}
          filterKey="location"
          dataKey="location"
          layoutKey="location"
          icon={["far", "map-marker-alt"]}
          title="Locations"
          labelAsPercentage
          compare={comparisonMode}
          fteMode={fteMode}
          helpContent={getHelpContent(
            "Shows the population working at the same address for the people matching the current filter"
          )}
          updateCardSize={updateCardSize}
        />
      </div>
    );
  },
  role: ({ insights, comparisonMode, loading, fteMode, updateCardSize }) => {
    return (
      <div data-testid="cp-insights-role" key="role">
        <CirclePackCard
          insights={insights}
          loading={loading}
          filterKey="jobTitle"
          dataKey="role"
          layoutKey="role"
          icon={["far", "address-card"]}
          title="Roles"
          labelAsPercentage
          compare={comparisonMode}
          fteMode={fteMode}
          helpContent={getHelpContent(
            "Shows the roles of the people matching the current filter"
          )}
          updateCardSize={updateCardSize}
        />
      </div>
    );
  },
  layers: ({ insights, loading, comparisonMode }) => {
    return (
      <div data-testid="cp-insights-layers" key="layers">
        <SingleStatCard
          icon={["far", "layer-group"]}
          title="Layers"
          insights={insights}
          loading={loading}
          dataKey="layers"
          compare={comparisonMode}
          helpContent={getHelpContent(
            "Shows the min, max and average (mean) of the number of management layers upto CEO for people matching the current filter"
          )}
        />
      </div>
    );
  },
  spans: ({ insights, loading, comparisonMode }) => {
    return (
      <div data-testid="cp-insights-spans" key="spans">
        <SingleStatCard
          icon={["far", "sitemap"]}
          title="Spans"
          insights={insights}
          loading={loading}
          dataKey="span"
          compare={comparisonMode}
          helpContent={getHelpContent(
            "Shows the min, max and average (mean) of the number of employee's a manager supervises for the people matching the current filter"
          )}
        />
      </div>
    );
  },
  teamFilterMatch: ({
    insights,
    comparisonMode,
    loading,
    fteMode,
    updateCardSize,
    includeSubTeams,
  }) => {
    return (
      <div key="teamFilterMatch">
        <HorizontalBarCard
          insights={insights}
          loading={loading}
          filterKey="teams"
          dataKey="childTeamsInsight"
          layoutKey="teamFilterMatch"
          icon={["far", "users"]}
          title={includeSubTeams ? "Descendant Teams" : "Child Teams"}
          labelAsPercentage={false}
          compare={comparisonMode}
          fteMode={fteMode}
          helpContent={getHelpContent(
            `Shows the descendant teams of a team, along with their direct member counts (excludes indirect members).\n
      1. If 'Include sub-teams' is selected, show all descendant teams of the selected team.\n
      2. If 'Include sub-teams' is not selected, only show the direct child teams of the selected team.`
          )}
          updateCardSize={updateCardSize}
        />
      </div>
    );
  },
  teamFilterMatchStat: ({ insights, loading, comparisonMode }) => {
    return (
      <div key="teamFilterMatchStat">
        <SingleStatCard
          icon={["far", "sitemap"]}
          title="Team Size"
          insights={insights}
          loading={loading}
          dataKey="teamFilterMatch"
          compare={comparisonMode}
          helpContent={getHelpContent(`Calculates team size statistics (see points 1 and 2 below) for the people in teams matching the current filter.\n
      1. If a team is selected, only calculate the selected team and all sub-teams (if 'Include sub-teams' is selected).\n
      2. If a team type is selected, only calculate teams matching that type (while still applying (1)).\n
      To understand what teams are included see 'Teams' card`)}
        />
      </div>
    );
  },
  teamTypeStats: ({ insights, loading, comparisonMode }) => {
    return (
      <div key="teamTypeStats">
        <StatListCard
          icon={["far", "users-class"]}
          title="Team Statistics"
          insights={insights}
          loading={loading}
          dataKey="teamTypeStatistics"
          compare={comparisonMode}
          emtpyContent="To show team statistics select one, or multiple, teams or team types from filters"
          helpContent={getHelpContent(
            "Team membership statistics across various team types"
          )}
        />
      </div>
    );
  },
  division: ({
    displaySubDivisions,
    insights,
    comparisonMode,
    loading,
    fteMode,
    updateCardSize,
  }) => {
    if (!displaySubDivisions) {
      return null;
    }

    return (
      <div data-testid="cp-insights-division" key="division">
        <CirclePackCard
          insights={insights}
          loading={loading}
          filterKey="divisions"
          dataKey="division"
          layoutKey="division"
          icon={["far", "chart-pie"]}
          title="Sub-divisions"
          labelAsPercentage
          compare={comparisonMode}
          fteMode={fteMode}
          helpContent={getHelpContent(
            'Shows the divisions of the people matching the current filter.\n\n If no divisions are shown, check the "include sub-divisions" option in the filter panel'
          )}
          updateCardSize={updateCardSize}
        />
      </div>
    );
  },
};

const cards = ({ features, ...cardProps }) => {
  let cardKeys = features;

  if (isEmpty(cardKeys)) {
    cardKeys = Object.keys(ALL_CARDS);
  }

  return map(cardKeys, (cardType) => {
    const card = ALL_CARDS[cardType];

    if (!card) {
      return null;
    }

    return card(cardProps);
  });
};

export default cards;
