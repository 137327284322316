import { NODE_TYPES } from "src/components/NodeVisualizer/consts";
import { createEdge, createNode } from "./shared";
import {
  getMembersCollectionId,
  createMembersCollectionNode,
} from "./createMembersCollectionNode";

export const initializeNodesAndEdges = ({ contextualData, state }) => {
  const allMembers = Object.values(state.members);
  const allTeams = Object.values(state.teams);

  const nodes = [];
  const edges = [];

  const collectionNodes = {};

  // Build the nodes and edges for the team member collections
  allMembers.forEach((member) => {
    const membersOfTeams = allTeams.filter((t) =>
      member.memberOf.find((mo) => mo.id === t.id)
    );

    membersOfTeams.forEach((memberOfTeam) => {
      const isExpanded = state.expandedNodes.includes(memberOfTeam.id);

      const collectionId = getMembersCollectionId(memberOfTeam);

      if (!isExpanded) {
        return;
      }

      if (memberOfTeam && !collectionNodes[collectionId]) {
        collectionNodes[collectionId] = createMembersCollectionNode({
          members: [member],
          group: memberOfTeam,
          contextualData,
          state,
        });

        edges.push(
          createEdge(memberOfTeam.id, collectionNodes[collectionId].id)
        );
      } else if (collectionNodes[collectionId]) {
        collectionNodes[collectionId].data.items.push(
          createNode(member.aggregateId, member, NODE_TYPES.PERSON_NODE)
        );
      }
    });
  });

  // Add empty collection nodes for teams that have no members
  allTeams.forEach((team) => {
    const isExpanded = state.expandedNodes.includes(team.id);
    const collectionNodeId = getMembersCollectionId(team);

    if (isExpanded && !collectionNodes[collectionNodeId]) {
      collectionNodes[collectionNodeId] = createMembersCollectionNode({
        members: [],
        group: team,
        contextualData,
        state,
      });

      edges.push(createEdge(team.id, collectionNodes[collectionNodeId].id));
    }
  });

  Object.values(collectionNodes).forEach((collectionNode) => {
    nodes.push(collectionNode);
  });

  return {
    nodes,
    edges,
  };
};
