import React from "react";
import { Box, H1 } from "orcs-design-system";

const Heading = () => {
  return (
    <Box
      bg="white"
      boxBorder="default"
      borderRadius={2}
      borderBottomLeftRadius={0}
      borderBottomRightRadius={0}
      p="r"
    >
      <H1>Tenant Settings</H1>
    </Box>
  );
};

export default Heading;
