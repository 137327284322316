import { map, isEmpty, find, forEach, filter } from "lodash";
import { Box, H5 } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback, useRef } from "react";

import useLazyLoad from "src/hooks/useLazyLoad";
import { getGroupChildTeamsQuery } from "src/queries/group.graphql";
import TreeView from "src/shared/TreeView";
import {
  addChildTeamsToNode,
  convertChildrenToArray,
  createTreeNode,
} from "src/shared/TreeView/TreeView.util";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";
import { filterOutNonVisibleGroups } from "./index.util";

import useAutoExpandTree from "./useAutoExpandTree";
import useOnNodeSelected from "./useOnNodeSelected";

const getNodesToBeLoaded = ({ node }) => {
  const { isParentType, loading, children } = node;

  let targetNodes = null;
  if (isParentType && loading) {
    targetNodes = [node];
  } else if (isParentType) {
    targetNodes = children;
  }

  return filter(targetNodes, "loading");
};

const GroupsNavigation = ({
  root,
  treeData,
  hierarchyIds,
  navigateTo,
  refreshTree,
  groupTypes,
  targetGroupTypes,
  sortOption,
  customizeTreeHeader,
  loadChildNodes,
  createTreeNodeImpl = createTreeNode,
  convertChildrenToArrayImpl = convertChildrenToArray,
  shouldIgnoreOnToggle,
  addGroupsToRootsImpl,
  additionalSearchVariables,
}) => {
  const loadGroupChildTeams = useLazyLoad({
    query: getGroupChildTeamsQuery,
    additionalVariables: additionalSearchVariables,
  });

  const loadChildTeams = loadChildNodes || loadGroupChildTeams;

  const toggleCountRef = useRef(0);

  useAutoExpandTree({
    root,
    hierarchyIds,
    groupTypes,
    loadChildTeams,
    refreshTree,
    sortOption,
    createTreeNodeImpl,
    convertChildrenToArrayImpl,
  });

  const onNodeSelected = useOnNodeSelected({ navigateTo });

  const onNodeToggled = useCallback(
    (node) => {
      const notLoadedNodes = getNodesToBeLoaded({ node });
      const groupIds = map(notLoadedNodes, "id");
      toggleCountRef.current += 1;
      trackEvent(EVENT_TRACKING.TEAM_CLICKED, {
        action: "team_tree_navigation_expanded",
        totalToggles: toggleCountRef.current,
      });

      if (isEmpty(groupIds)) {
        return;
      }

      // lazy load child teams of the group
      loadChildTeams({
        groupIds,
        ...(loadChildNodes ? { node } : {}),
      }).then(({ groups }) => {
        const filteredGroups = filterOutNonVisibleGroups(groups);
        forEach(notLoadedNodes, (childNode) => {
          const targetGroup = find(
            filteredGroups,
            (g) => g.id === childNode.id
          );
          addChildTeamsToNode(
            targetGroup,
            childNode,
            groupTypes,
            sortOption,
            targetGroupTypes,
            createTreeNodeImpl,
            convertChildrenToArrayImpl,
            addGroupsToRootsImpl
          );
        });
        refreshTree();
      });
    },
    [
      loadChildTeams,
      loadChildNodes,
      refreshTree,
      groupTypes,
      sortOption,
      targetGroupTypes,
      createTreeNodeImpl,
      convertChildrenToArrayImpl,
      addGroupsToRootsImpl,
    ]
  );

  return (
    <Box>
      {isEmpty(treeData) && <H5>No teams data available</H5>}
      <TreeView
        data={treeData}
        onNodeSelected={onNodeSelected}
        onNodeToggled={onNodeToggled}
        withGroupTypeBadge
        noTreeHeaderIcon
        groupTypes={groupTypes}
        customizeTreeHeader={customizeTreeHeader}
        shouldIgnoreOnToggle={shouldIgnoreOnToggle}
      />
    </Box>
  );
};

GroupsNavigation.propTypes = {
  root: PropTypes.object,
  treeData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hierarchyIds: PropTypes.array,
  navigateTo: PropTypes.func,
  refreshTree: PropTypes.func,
  groupTypes: PropTypes.object,
  targetGroupTypes: PropTypes.object,
  sortOption: PropTypes.string,
  customizeTreeHeader: PropTypes.func,
  loadChildNodes: PropTypes.func,
  createTreeNodeImpl: PropTypes.func,
  convertChildrenToArrayImpl: PropTypes.func,
  shouldIgnoreOnToggle: PropTypes.func,
  addGroupsToRootsImpl: PropTypes.func,
  additionalSearchVariables: PropTypes.object,
};

export default React.memo(GroupsNavigation);
