import { useApolloClient } from "@apollo/client";
import { get, map } from "lodash";
import { useCallback } from "react";
import { updateTeamAttributes as updateTeamAttributesQuery } from "src/queries/mutations/updateTeamAttributes.graphql";
import { getCanUpdateGroupAttributes, isGroupActive } from "src/util/group";

export const useUpdateTeamAttributes = ({ team }) => {
  const client = useApolloClient();

  const canUpdateGroupAttributes =
    getCanUpdateGroupAttributes(team) && isGroupActive(team);

  const updateTeamAttributes = useCallback(
    async (attrs) => {
      // strip __typename from attributes
      const attributes = map(attrs, (attr) => {
        const { __typename, ...keep } = attr;
        return keep;
      });

      if (get(team, "id")) {
        const response = await client.mutate({
          mutation: updateTeamAttributesQuery,
          variables: {
            groupId: team.id,
            attributes,
          },
        });

        return response.data.group;
      }

      return team;
    },
    [client, team]
  );

  return {
    updateTeamAttributes,
    canUpdateGroupAttributes,
  };
};
