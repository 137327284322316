// @ts-check

const _ = require("lodash");

/**
 * @param {string} string
 * @param {string[]} phrases
 */
export default function phrasesAreIncluded(string, phrases) {
  const lowerCaseString = string?.toLowerCase();
  return _(phrases)
    .map((phrase) => lowerCaseString?.includes(phrase.toLowerCase()))
    .every();
}
