import React from "react";
import PropTypes from "prop-types";
import { Avatar, Box, Flex } from "orcs-design-system";

import Auth0UserProps from "src/custom-prop-types/auth0user";
import { displayInitials } from "src/util/personName";

const Heading = ({ user, tenantId, preferredUsername }) => {
  return (
    <Box
      bg="white"
      p="r"
      boxBorder="default"
      borderRadius={2}
      borderBottomLeftRadius={0}
      borderBottomRightRadius={0}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex>
          <Avatar
            image={user.picture}
            title={preferredUsername}
            initials={displayInitials(preferredUsername)}
            subtitle={tenantId}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

Heading.propTypes = {
  tenantId: PropTypes.string,
  preferredUsername: PropTypes.string,
  user: Auth0UserProps,
};

export default Heading;
