import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";

import { StyledTable } from "./MappingTable.styles";
import MappingTableRow from "./MappingTableRow";

const MappingTable = ({
  headerFields,
  fieldOptions,
  fieldsMap,
  updateField,
  clearField,
}) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          <th>Source fields</th>
          <th>Mapping</th>
          <th>Target fields</th>
        </tr>
      </thead>
      <tbody>
        {map(headerFields, (h, index) => {
          return (
            <MappingTableRow
              key={`${h}-${index}`}
              header={h}
              fieldsMap={fieldsMap}
              targets={fieldsMap[h]}
              fieldOptions={fieldOptions}
              updateField={updateField}
              clearField={clearField}
            />
          );
        })}
      </tbody>
    </StyledTable>
  );
};

MappingTable.propTypes = {
  headerFields: PropTypes.array,
  fieldOptions: PropTypes.array,
  fieldsMap: PropTypes.object,
  updateField: PropTypes.func,
  clearField: PropTypes.func,
};

export default MappingTable;
