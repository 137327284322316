import React from "react";
import { Box, Notification, P } from "orcs-design-system";

const NotFoundNotification = () => (
  <Box paddingDouble>
    <Notification
      top="20px"
      centered
      colour="warning"
      icon={["far", "exclamation-triangle"]}
    >
      <P color="white">This person or team does not exist.</P>
      <P color="white">
        Please try searching for another person or team to visualise them here.
      </P>
    </Notification>
  </Box>
);

export default NotFoundNotification;
