import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { map, orderBy } from "lodash";
import {
  H1,
  Spacer,
  Button,
  Box,
  Icon,
  Flex,
  FlexItem,
  Notification,
} from "orcs-design-system";

import urlPaths, { url } from "../../consts/urlPaths";
import Workspace from "./Workspace";
import MarkActiveConfirmationDialog from "./MarkActiveConfirmationDialog";

const Workspaces = ({
  workspaces = [],
  selectedWorkspaceId,
  setWorkspace,
  unsetWorkspace,
  currentActiveWorkspace,
  history,
  onDeleteWorkspace,
  onCloneWorkspace,
  workspaceChanged,
  setWorkspaceChanged,
  markWorkspaceAsActive,
}) => {
  const [markAsActiveWorkspace, setMarkAsActiveWorkspace] = useState(undefined);

  const orderedWorkspaces = orderBy(workspaces, "name");

  const onWarningDismiss = useCallback(() => {
    setWorkspaceChanged(false);
  }, [setWorkspaceChanged]);

  const onMarkAsActive = useCallback(
    (workspace) => setMarkAsActiveWorkspace(workspace),
    [setMarkAsActiveWorkspace]
  );

  const onConfirm = useCallback(() => {
    markWorkspaceAsActive({ variables: { id: markAsActiveWorkspace.id } });
    setMarkAsActiveWorkspace(undefined);
  }, [markAsActiveWorkspace, setMarkAsActiveWorkspace, markWorkspaceAsActive]);

  const onClose = useCallback(() => {
    setMarkAsActiveWorkspace(undefined);
  }, [setMarkAsActiveWorkspace]);

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      width="100%"
      height="100%"
      overflow="hidden"
    >
      <FlexItem width="100%" flex="0 0 auto" mb="r">
        <Box bg="white" boxBorder="default" borderRadius={2} p="r">
          <Flex alignItems="center" justifyContent="space-between">
            <H1>Workspaces</H1>
            <Flex alignItems="center">
              <Button
                iconLeft
                onClick={() => {
                  history.push(url(urlPaths.CREATE_WORKSPACE));
                }}
              >
                <Icon icon={["fas", "plus"]} />
                Create new workspace
              </Button>
            </Flex>
          </Flex>
        </Box>
      </FlexItem>
      {workspaceChanged && (
        <FlexItem
          data-testid="workspace-switched-notification"
          width="100%"
          flex="1 1 auto"
          overflow="hidden"
        >
          <Box height="100%" p="r" overflow="auto">
            <Notification
              centered
              colour="warning"
              onDismiss={onWarningDismiss}
            >
              You have changed your workspace, if you have any other open
              TeamForm tabs they will automatically be updated to this new
              workspace
            </Notification>
          </Box>
        </FlexItem>
      )}
      {markAsActiveWorkspace && (
        <MarkActiveConfirmationDialog
          onConfirm={onConfirm}
          onClose={onClose}
          currentActiveWorkspace={currentActiveWorkspace}
          chosenActiveWorkspace={markAsActiveWorkspace}
        />
      )}
      <FlexItem width="100%" height="100%" flex="1 1 auto" overflow="hidden">
        <Box height="100%" overflow="auto">
          <Spacer mb="r">
            {map(orderedWorkspaces, (workspace) => (
              <Workspace
                key={workspace.id}
                workspace={workspace}
                selectedWorkspaceId={selectedWorkspaceId}
                currentActiveWorkspace={currentActiveWorkspace}
                onMarkAsActive={onMarkAsActive}
                onSelect={(ws) => {
                  setWorkspace(ws);
                }}
                onUnSelect={(ws) => {
                  unsetWorkspace(ws);
                }}
                onDeleteWorkspace={onDeleteWorkspace}
                onCloneWorkspace={onCloneWorkspace}
              />
            ))}
          </Spacer>
        </Box>
      </FlexItem>
    </Flex>
  );
};

Workspaces.propTypes = {
  workspaces: PropTypes.arrayOf(Object),
  selectedWorkspaceId: PropTypes.string,
  history: PropTypes.object,
  setWorkspace: PropTypes.func.isRequired,
  unsetWorkspace: PropTypes.func.isRequired,
  onDeleteWorkspace: PropTypes.func.isRequired,
  onCloneWorkspace: PropTypes.func.isRequired,
  currentActiveWorkspace: PropTypes.object,
  workspaceChanged: PropTypes.bool,
  setWorkspaceChanged: PropTypes.func,
  markWorkspaceAsActive: PropTypes.func,
};

export default Workspaces;
