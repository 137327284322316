import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ReactFlowProvider,
  Controls,
  useNodesState,
  useEdgesState,
  MiniMap,
  Panel,
  useReactFlow,
} from "reactflow";
import PropTypes from "prop-types";
import styled, { useTheme } from "styled-components";
import { isEqual, chain, every, merge, keyBy } from "lodash";
import usePrevious from "src/util/usePrevious";

import GroupPropType from "src/custom-prop-types/group";
import {
  toFlatArray,
  toNode,
  NODE_TYPES,
  toEdgeFromNode,
} from "../../util/objectives";
import LoadingNode from "./LoadingNode";
import ObjectiveNode from "./ObjectiveNode";
import TeamNode from "./TeamNode";

import "reactflow/dist/style.css";
import "./style.css";
import { StyledReactFlow } from "./node.styled";
import {
  collectFadedNodes,
  styleSelectedNodesWithEdges,
  collectSelectedNodes,
} from "./utils";
import getLayoutWithElk from "./getLayoutWithElk";
import loadingState from "./loadingState.json";

const StyledControls = styled(Controls)`
  &.react-flow__controls {
    bottom: 35px !important;
    left: -12px !important;
  }
`;

const proOptions = {
  account: "paid-pro",
  hideAttribution: true,
};

const nodeTypes = {
  [NODE_TYPES.NO_OBJECTIVE]: ObjectiveNode,
  [NODE_TYPES.OBJECTIVE]: ObjectiveNode,
  [NODE_TYPES.LOADING]: LoadingNode,
  [NODE_TYPES.TEAM]: TeamNode,
};

const defaultEdgeOptions = {
  animated: true,
};

const minimapStyle = {
  height: 120,
};

const layoutMode = {
  label: "ElkJS",
  value: "elkjs",
  getLayout: getLayoutWithElk,
};

const layoutOptions = { offsetWidth: 100, offsetHeight: 20, direction: "RL" };

function ReactFlowLayout(props) {
  const { loading, objectives, onNodeClick, selectedNode, team } = props;

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [moveStartCoords, setMoveStartCoords] = useState();

  const theme = useTheme();

  const { fitView } = useReactFlow();

  const readyForLayout = useMemo(
    () =>
      every(
        nodes,
        (node) => node.width !== undefined && node.height !== undefined
      ),
    [nodes]
  );

  const calculateLayout = useCallback(async () => {
    if (!nodes.length || !edges.length || !readyForLayout) {
      return;
    }

    const filteredEdges = edges.filter((edge) => {
      const sourceNode = nodes.find((node) => node.id === edge.source);
      const targetNode = nodes.find((node) => node.id === edge.target);

      return sourceNode && targetNode;
    });

    const [newNodes, newEdges] = await layoutMode.getLayout(
      nodes,
      filteredEdges,
      layoutOptions
    );

    if (!isEqual(newNodes, nodes)) {
      setEdges(newEdges);
      /**
       * There is a race condition that happens occasionally between layout calculated for loading state and the final result after loading more nodes.
       * The following logic remedies this to make sure that the loaded nodes are replaced by the actual data if the response is faster than the loading node
       * layout calculation.
       *  */
      setNodes((nds) => {
        return newNodes.map((node) => {
          const prevNode = nds.find((n) => n.id === node.id);
          if (
            node.type === NODE_TYPES.LOADING &&
            prevNode?.type !== NODE_TYPES.LOADING
          ) {
            return prevNode;
          }

          return node;
        });
      });
    }
  }, [nodes, edges, readyForLayout, setEdges, setNodes]);

  useEffect(() => {
    calculateLayout();
  }, [calculateLayout]);

  const previousObjectives = usePrevious(objectives);

  useEffect(() => {
    const newNodes = chain(toFlatArray(objectives, team?.id))
      .map(toNode)
      .value();

    const newEdges = chain(newNodes)
      .map((node) => toEdgeFromNode(node))
      .flatMap()
      .uniqBy("source_target")
      .value();

    if (objectives.length) {
      setNodes((nds) => {
        const existingNodesMap = keyBy(nds, "id");

        const updatedNodes = newNodes.map((node) => {
          const existingNode = existingNodesMap[node.id] || {};
          const mergedNode = {
            ...merge({ ...existingNode, data: {} }, node),
            position: { x: existingNode.x || 0, y: existingNode.y || 0 },
          };

          return mergedNode;
        });

        return updatedNodes;
      });
      setEdges(newEdges);
    }
  }, [
    previousObjectives,
    objectives,
    setNodes,
    setEdges,
    team?.id,
    objectives.length,
  ]);

  const [visibleNodesWithFaded, styledEdgesWithFaded] = useMemo(() => {
    return [
      collectFadedNodes(nodes, selectedNode?.id),
      styleSelectedNodesWithEdges({
        nodes,
        edges,
        selectedNodeId: selectedNode?.id,
        theme,
      }),
    ];
  }, [nodes, selectedNode?.id, edges, theme]);

  useEffect(() => {
    const selectedNodes = collectSelectedNodes(nodes, selectedNode?.id);
    const isLoadingNewNodes = nodes.find(
      (node) => node.type === NODE_TYPES.LOADING
    );
    if (selectedNodes.length && !isLoadingNewNodes) {
      fitView({
        maxZoom: 1,
        nodes: nodes.filter((node) => selectedNodes.includes(node.id)),
        duration: 1500,
      });
    }
  }, [nodes, fitView, selectedNode]);

  return (
    <StyledReactFlow
      autoPanOnNodeDrag={false}
      panOnDrag={2}
      onMoveStart={(_, coords) => setMoveStartCoords(coords)}
      onMoveEnd={(e, coords) => {
        const dx = coords.x - moveStartCoords.x;
        const dy = coords.y - moveStartCoords.y;

        const distance = Math.sqrt(dx * dx + dy * dy);

        // Sometimes a click is mistaken for a drag/pan movement by react-flow.
        // This method checks if the movement traveled is small enough to be considered a click and triggers the node expand method.
        if (distance <= 1) {
          nodes.forEach((node) => {
            const nodeEl = document.getElementById(`node-${node.id}`);

            if (nodeEl?.contains(e.target)) {
              onNodeClick(node);
            }
          });
        }
      }}
      nodesDraggable={false}
      nodesConnectable={false}
      zoomOnDoubleClick={false}
      elementsSelectable={false}
      nodes={loading ? loadingState.nodes : visibleNodesWithFaded}
      edges={loading ? loadingState.edges : styledEdgesWithFaded}
      defaultEdgeOptions={defaultEdgeOptions}
      proOptions={proOptions}
      nodeTypes={nodeTypes}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onNodeClick={onNodeClick}
      nodeDragThreshold={100}
      fitView={true}
    >
      <Panel position="bottom-left">
        <StyledControls showInteractive={false} className="controls" />
      </Panel>

      <MiniMap style={minimapStyle} zoomable pannable />
    </StyledReactFlow>
  );
}

ReactFlowLayout.propTypes = {
  objectives: PropTypes.array,
  onNodeClick: PropTypes.func,
  loading: PropTypes.bool,
  selectedNode: PropTypes.object,
  team: GroupPropType,
};

const ObjectivesFlow = (props) => {
  return (
    <ReactFlowProvider>
      <ReactFlowLayout {...props} />
    </ReactFlowProvider>
  );
};

export default ObjectivesFlow;
