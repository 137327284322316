import { H1 } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const TeamName = ({ name, isEditMode = false }) => {
  if (isEditMode) {
    return null;
  }

  return <H1 className="guided-tour-team-details-team-name">{name}</H1>;
};

TeamName.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

export default React.memo(TeamName);
