import { isEmpty } from "lodash";
import { Box } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import GroupPropType from "src/custom-prop-types/group";
import WorkspacePropType from "src/custom-prop-types/workspace";

import ExplorePanelList from "./sub-components/ExplorePanelList";
import Header from "./sub-components/Header";

const Explore = ({
  loading,
  childGroups,
  heading,
  navigateUpLabel,
  isDisplayNavigateUp,
  onNavigateUp,
  workspace,
  showAddNewTeam = false,
}) => {
  const isChildGroupsEmpty = !loading && isEmpty(childGroups);

  // When childGroups is empty, only display this panel when
  // this type can create child
  if (isChildGroupsEmpty && !showAddNewTeam) {
    return null;
  }

  return (
    <Box
      data-testid="cp-explore-container"
      borderRadius={2}
      boxBorder="default"
      py="r"
      px="r"
      width="100%"
      bg="white"
    >
      <Header
        isDisplayNavigateUp={isDisplayNavigateUp}
        loading={loading}
        heading={heading}
        navigateUpLabel={navigateUpLabel}
        onNavigateUp={onNavigateUp}
      />
      <ExplorePanelList
        loading={loading}
        childGroups={childGroups}
        workspace={workspace}
      />
    </Box>
  );
};

Explore.propTypes = {
  childGroups: PropTypes.oneOfType([
    PropTypes.arrayOf(GroupPropType),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  loading: PropTypes.bool,
  heading: PropTypes.string,
  navigateUpLabel: PropTypes.string,
  isDisplayNavigateUp: PropTypes.bool,
  onNavigateUp: PropTypes.func,
  workspace: WorkspacePropType,
  showAddNewTeam: PropTypes.bool,
};

export default Explore;
