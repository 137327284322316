import React from "react";
import { startCase, get } from "lodash";
import PropTypes from "prop-types";

import {
  useWorkspaces,
  useWorkspace,
} from "src/contexts/global/WorkspaceContext";
import { useActiveTenant } from "src/contexts/global/ApolloSettingsContext";
import {
  useAuth,
  useUserPerson,
  useGlobalConfig,
} from "src/contexts/global/GlobalContext";
import { useAuth0 } from "src/contexts/auth0Provider";
import { useFeatureFlags } from "src/contexts/global/FeatureFlagsContext";
import useTagsVisibleInFilter from "src/app/Restricted/useTagsVisibleInFilter";

import EnvInformationBar from "src/components/EnvInformationBar";
import GlobalNotification from "src/components/GlobalNotification";
import Search from "src/components/Search/SearchContainer";
import { isInternalSupportUser } from "src/services/auth";
import { isLocalEnvironment } from "src/services/localSettings";
import useDefaultRoutePath from "src/app/Restricted/useDefaultRoutePath";
import Cosmos from "src/app/Cosmos";
import { displayInitials } from "src/util/personName";
import EmptyAppNav from "./EmptyAppNav";
import UserMenuContent from "./UserMenuContent";

const FullAppNav = ({ themeSwitcher }) => {
  const defaultRoutePath = useDefaultRoutePath();
  const { user, logout } = useAuth0();
  const globalConfig = useGlobalConfig();
  const activeTenant = useActiveTenant();
  const auth = useAuth();
  const userPerson = useUserPerson();
  const workspaces = useWorkspaces();
  const workspace = useWorkspace();

  const featureFlags = useFeatureFlags();
  const tagsVisibleIn = useTagsVisibleInFilter();

  const { preferredUsername } = auth;

  const avatarSource = get(userPerson, "avatar");

  const isDemo = !!window?.location?.search?.includes("demo=true");

  const isSupportUser = isInternalSupportUser(user) || isLocalEnvironment();

  const showLogoSeparator =
    globalConfig?.config?.logoImage &&
    (workspaces.length > 1 || globalConfig?.config?.appName);

  return (
    <>
      {activeTenant && (
        <EnvInformationBar
          user={user}
          overrideTenant={activeTenant}
          isDemo={isDemo}
        />
      )}
      <GlobalNotification />
      <Cosmos
        mod="Header"
        variant="search"
        logo={<Cosmos mod="Logo" workspace={workspace} />}
        appName={
          <Cosmos
            mod="AppName"
            defaultRoutePath={defaultRoutePath}
            globalConfig={globalConfig}
          />
        }
        currentWorkspace={
          workspaces.length > 1 && (
            <Cosmos
              mod="WorkspaceSwitcher"
              workspaces={workspaces}
              isSupportUser={isSupportUser}
              withCustomStyles
            />
          )
        }
        userName={
          <Cosmos mod="UserName" defaultRoutePath={defaultRoutePath}>
            {startCase(preferredUsername)}
          </Cosmos>
        }
        avatarSource={avatarSource}
        avatarAlt={`Avatar for ${startCase(preferredUsername)}`}
        avatarInitials={userPerson && displayInitials(userPerson)}
        searchComponent={
          <Search
            workspace={workspace}
            featureFlags={featureFlags}
            tagsVisibleIn={tagsVisibleIn}
            dropdownOptionsDisplayLimit={3}
            Search={(searchProps) => <Cosmos mod="Search" {...searchProps} />}
          />
        }
        userMenuContent={
          <UserMenuContent themeSwitcher={themeSwitcher} logout={logout} />
        }
        showLogoSeparator={showLogoSeparator}
      />
    </>
  );
};

FullAppNav.propTypes = {
  themeSwitcher: PropTypes.func,
};

// TODO: Duplication with code in the ErrorPageWrapper and AppNav
// because for some reason adding AppNav to ErrorPageWrapper causes circular
// dependency that was not quick to figure out.
const EmptyAppNavWithAuth = () => {
  const { logout, user } = useAuth0();
  return <EmptyAppNav user={user} logout={logout} />;
};

const AppNav = (props) => {
  const { emptyMode = false } = props;

  if (emptyMode) {
    return <EmptyAppNavWithAuth {...props} />;
  }

  return <FullAppNav {...props} />;
};

AppNav.propTypes = {
  emptyMode: PropTypes.bool,
};

export default AppNav;
