import PropTypes from "prop-types";
import React from "react";

import { TAG_ATTRIBUTE_TYPES, ASSOCIATED_VALUE } from "src/consts/tags";

import getInputLabel from "src/util/getInputLabel";

import TagSearchSelect from "src/components/TagSearchDropdown";
import TagAttributeDateInput from "./TagAttributeDateInput";
import TagAttributeGroupSelect from "./TagAttributeGroupSelect";
import TagAttributePersonSelect from "./TagAttributePersonSelect";

// TODO: Consolidate with src/pages/Workspaces/components/TagTypeConfig/TagAttributeInput.js

const TagAttributeInput = ({
  id,
  type,
  name,
  onSelectAttrValue,
  InputComponent,
  disabled = false,
  featureFlags,
  ...others
}) => {
  if (
    type === TAG_ATTRIBUTE_TYPES.TEXT ||
    type === TAG_ATTRIBUTE_TYPES.ENTITY_ID
  ) {
    return (
      <InputComponent
        id={id}
        name={name}
        type="text"
        disabled={disabled}
        {...others}
        label={getInputLabel(others.label, others.invalid)}
      />
    );
  }

  if (type === TAG_ATTRIBUTE_TYPES.NUMBER) {
    return (
      <InputComponent
        id={id}
        name={name}
        type="number"
        disabled={disabled}
        {...others}
        label={getInputLabel(others.label, others.invalid)}
      />
    );
  }

  if (type === TAG_ATTRIBUTE_TYPES.INTEGER) {
    return (
      <InputComponent
        id={id}
        name={name}
        type="number"
        disabled={disabled}
        numberProps={{
          decimalScale: 0,
          onKeyDown: (evt) => {
            if (evt.key === ".") {
              evt.preventDefault();
            }
          },
        }}
        {...others}
        label={getInputLabel(others.label, others.invalid)}
      />
    );
  }

  if (type === TAG_ATTRIBUTE_TYPES.DATE) {
    if (name === ASSOCIATED_VALUE || disabled) {
      return (
        <InputComponent
          id={id}
          name={name}
          disabled={disabled}
          type="text"
          {...others}
        />
      );
    }
    return (
      <TagAttributeDateInput id={id} type={type} name={name} {...others} />
    );
  }

  if (type === TAG_ATTRIBUTE_TYPES.GROUPTYPE) {
    return (
      <TagAttributeGroupSelect
        id={id}
        onSelectGroup={onSelectAttrValue}
        disabled={disabled}
        {...others}
      />
    );
  }

  if (type === TAG_ATTRIBUTE_TYPES.PERSON) {
    return (
      <TagAttributePersonSelect
        id={id}
        onSelectPerson={onSelectAttrValue}
        disabled={disabled}
        featureFlags={featureFlags}
        {...others}
      />
    );
  }

  if (type === TAG_ATTRIBUTE_TYPES.TAGTYPE) {
    return (
      <TagSearchSelect
        label={others.label}
        tagTypes={[others.associatedValue]}
        value={others.value}
        onSelectTag={onSelectAttrValue}
        disabled={others.disabled}
        invalid={others.invalid}
        mandatory={others.mandatory}
        focus={others.focus}
      />
    );
  }

  return null;
};

TagAttributeInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  InputComponent: PropTypes.elementType,
  onSelectAttrValue: PropTypes.func,
  disabled: PropTypes.bool,
  featureFlags: PropTypes.object,
};

export default TagAttributeInput;
