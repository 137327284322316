import React from "react";
import PropTypes from "prop-types";

import { map, isEmpty } from "lodash";
import { Box, Toggle } from "orcs-design-system";

const FieldsMapFlagEditor = ({ fieldsMap, updateFieldsMap, importFlags }) => {
  if (isEmpty(importFlags) || !fieldsMap) {
    return null;
  }

  const handleToggleChange = (fieldName) => () => {
    updateFieldsMap({
      ...fieldsMap,
      [fieldName]: !fieldsMap[fieldName],
    });
  };

  return (
    <Box my="r">
      {map(importFlags, (label, fieldName) => {
        return (
          <Box mb="r">
            <Toggle
              id={`${fieldName}-toggle`}
              label={label}
              checked={!!fieldsMap[fieldName]}
              onChange={handleToggleChange(fieldName)}
              disabled={!updateFieldsMap}
            />
          </Box>
        );
      })}
    </Box>
  );
};

FieldsMapFlagEditor.propTypes = {
  importFlags: PropTypes.object,
  fieldsMap: PropTypes.object,
  updateFieldsMap: PropTypes.func,
};

export default FieldsMapFlagEditor;
