import { getGroupType } from "src/util/groupType";

export const getTargetGroupAndFilter = ({
  state,
  collectionGroup,
  groupTypes,
}) => {
  const { currentViewTeam: viewGroup } = state;
  const { groupMembersBySupplyOrDemand } = state.viewOptions;
  const viewGroupType = getGroupType(viewGroup, groupTypes);

  let filter = {};
  let targetGroup = collectionGroup;
  let filterGroup = null;

  if (groupMembersBySupplyOrDemand && viewGroupType.isDemand) {
    filter = { teams: { teams: [collectionGroup.id] } };
    filterGroup = collectionGroup;
    targetGroup = viewGroup;
  } else if (groupMembersBySupplyOrDemand && viewGroupType.isSupply) {
    filter = { teams: { teams: [viewGroup.id] } };
    filterGroup = viewGroup;
  }

  return { filter, targetGroup, filterGroup };
};
