import { useQuery } from "@apollo/client";
import { find } from "lodash";
import { getAllocationProjects as getAllocationProjectsQuery } from "src/allocation/allocation.graphql";
import useRestricted from "src/app/Restricted/useRestricted";

const useGetActiveAllocationProjectForOrgChart = () => {
  const [isDirectoryUser] = useRestricted();

  const { data } = useQuery(getAllocationProjectsQuery, {
    skip: isDirectoryUser,
  });
  const activeAllocationProject = find(data?.allocationProjects, "isActive");

  return activeAllocationProject;
};

export default useGetActiveAllocationProjectForOrgChart;
