import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Popover } from "orcs-design-system";
import themeGet from "@styled-system/theme-get";
import styled from "styled-components";

export const StyledRoundButton = styled(Button)`
  border-radius: 50%;
  height: ${(props) => (props.large ? "30px" : "20px")};
  width: ${(props) => (props.large ? "30px" : "20px")};
  border: none;
  cursor: pointer;
  svg {
    transform-origin: center;
    transition: all 0.5s;
  }

  &.size-xl {
    height: 40px;
    width: 40px;
  }
  &.size-lg {
    height: 35px;
    width: 35px;
  }

  &.expanded svg {
    transform: rotate(180deg) translateY(1px);
  }

  &:focus {
    box-shadow: none;
  }

  &.pinned {
    background: ${themeGet(`colors.primary`)};
    & span {
      color: white;
    }
  }

  &.unpinned {
    background: white;

    & span {
      color: ${themeGet("colors.greyLight")};
    }

    &:hover {
      background: ${themeGet(`colors.primary`)};
      border-color: ${themeGet(`colors.primary`)};
      & span {
        color: white;
      }
    }
  }

  &.teamsExpander {
    background: ${themeGet("colors.primary")};
    &:hover {
      border-color: ${themeGet("colors.primaryDarker")};
    }
  }

  &.measuresExpander {
    background: ${themeGet("colors.successLighter")};
    &:hover {
      border-color: ${themeGet("colors.success")};
    }
  }

  &.tree-navigator {
    background: black;
    &:hover {
      background: black;
      border-color: white;
    }

    &.light {
      background: white;
      border: 1px solid ${themeGet("colors.greyLight")};
      & svg {
        color: ${themeGet("colors.greyLight")};
      }
      &:hover {
        background: white;
        border-color: ${themeGet("colors.greyDark")};
        & svg {
          color: ${themeGet("colors.greyDark")};
        }
      }
    }
  }
`;

export const RoundIconButton = (props) => {
  const { icon, className, onClick, size = "r", tooltip, ...rest } = props;

  const buttonToIconsizeMap = {
    xl: "xl",
    lg: "lg",
    r: "sm",
  };

  const buttonMarkup = (
    <StyledRoundButton
      className={`${className} size-${size}`}
      iconOnly
      p="s"
      ariaLabel="Button"
      onClick={onClick}
      {...rest}
    >
      <Icon icon={icon} color="white" size={buttonToIconsizeMap[size]} />
    </StyledRoundButton>
  );

  if (tooltip) {
    return (
      <Popover width="fit-content" direction="top" text={tooltip}>
        {buttonMarkup}
      </Popover>
    );
  }

  return buttonMarkup;
};

RoundIconButton.propTypes = {
  icon: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
