import { useApolloClient } from "@apollo/client";
import debounce from "debounce-promise";
import { get, split, without } from "lodash";
import {
  Button,
  Flex,
  Icon,
  Divider,
  Notification,
  // Popover,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";

import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import { listTeamsInAllocation as searchTeams } from "src/allocation/team.graphql";
import TeamDropdown from "src/components/TeamDropdown";
import { createTeamSelectOptions } from "src/util/group";
import ErrorNotification from "src/components/ErrorNotification";
import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";

const TeamSearch = ({
  existingGroupIds = [],
  isProcessing,
  onProcess,
  onTeamSelected,
  typeFilters,
  kindFilters,
  showButton = true,
  buttonLabel = "Add",
  buttonIcon = ["fas", "plus"],
  preselectedGroup,
  divider = <Divider light />,
  isClearable = true,
  groupTypes,
  label,
  remainingFte,
  placeholder,
  limit = 10,
}) => {
  const client = useApolloClient();
  const [searchString, setSearchString] = useState(
    preselectedGroup ? createTeamSelectOptions([preselectedGroup], [])[0] : ""
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // const [goFetchTeams, { loading, error }] = useLazyQuery(searchTeams);

  // there is a problem with lodash debounce with react async select
  // using debounce-promise, see:
  // https://github.com/JedWatson/react-select/issues/3145#issuecomment-450373701
  // https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
  const search = useCallback(
    async (input, callback) => {
      try {
        setLoading(true);
        const result = await client.query({
          query: searchTeams,
          variables: {
            search: without(split(input, " "), "") || [input],
            typeFilters,
            kindFilters,
            limit,
          },
        });
        setLoading(false);

        callback(
          createTeamSelectOptions(
            get(result, "data.result.groups", []),
            existingGroupIds,
            preselectedGroup
          )
        );
      } catch (e) {
        setLoading(false);
        setError(e);
      }

      // const result = await goFetchTeams({
      //   variables: {
      //     search: without(split(input, " "), "") || [input],
      //     typeFilters,
      //     kindFilters,
      //     limit: 10,
      //   },
      // });
    },
    [
      client,
      typeFilters,
      kindFilters,
      existingGroupIds,
      preselectedGroup,
      limit,
    ]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(search, DEBOUNCE_SEARCH_TIME, { leading: true }),
    [search]
  );

  const onSelectTeam = useCallback(
    (option) => {
      if (onTeamSelected) {
        onTeamSelected(option);
      }
      setSearchString(option || "");
    },
    [onTeamSelected]
  );

  const onButtonClick = useCallback(async () => {
    setSearchString("");
    if (onProcess) {
      await onProcess();
    }
  }, [onProcess]);

  const isDisabled = !searchString ? "disabled" : "success";

  let addButton = null;
  if (showButton) {
    addButton = (
      <Button
        iconLeft
        variant={isDisabled}
        large
        ml="r"
        height="54px"
        onClick={onButtonClick}
        isLoading={isProcessing}
        data-testid="cp-allocate-team-add-btn"
      >
        <Icon icon={buttonIcon} />
        {buttonLabel}
      </Button>
    );
  }

  return (
    <>
      <Flex alignItems="flex-end" pb="r" pr="s">
        <TeamDropdown
          placeholder={placeholder || "Search and select team"}
          isSearchable={true}
          isLoading={loading}
          options={true}
          loadOptions={debouncedSearch}
          onSelectTeam={onSelectTeam}
          value={searchString}
          isDisabled={isProcessing}
          isClearable={isClearable}
          groupTypes={groupTypes}
          height="54px"
          label={label}
        />
        {addButton}
      </Flex>
      {remainingFte === 0 && (
        <Notification
          colour="success"
          icon={["fas", "exclamation-circle"]}
          closable={false}
        >
          This team member&apos;s FTE capacity has been reached.
        </Notification>
      )}
      {error && (
        <ErrorNotification
          message="Sorry, an error occurred, please try searching again."
          error={error}
        />
      )}
      {divider}
    </>
  );
};

TeamSearch.propTypes = {
  existingGroupIds: PropTypes.arrayOf(PropTypes.string),
  isProcessing: PropTypes.bool,
  onProcess: PropTypes.func,
  onTeamSelected: PropTypes.func,
  typeFilters: PropTypes.arrayOf(PropTypes.string),
  kindFilters: PropTypes.arrayOf(PropTypes.string),
  showButton: PropTypes.bool,
  buttonLabel: PropTypes.string,
  remainingFte: PropTypes.number,
  label: PropTypes.string,
  buttonIcon: PropTypes.array,
  preselectedGroup: PropTypes.object,
  divider: PropTypes.node,
  isClearable: PropTypes.bool,
  groupTypes: GroupTypesPropType,
  placeholder: PropTypes.string,
  limit: PropTypes.number,
};

export default React.memo(TeamSearch);
