/* eslint-disable react/prop-types */
import React from "react";
import { Card, Small, Text, Flex, FlexItem, Icon } from "orcs-design-system";
import { find, get, defaultTo, round, forEach } from "lodash";
import { numberToLocaleString } from "src/util/toLocaleString";
import FeedbackEmoji from "src/components/FeedbackEmoji/FeedbackEmoji";
import DragIcon from "./DragIcon";
import HelpIcon from "./HelpIcon";

const GenderInsightCard = ({
  icon = ["far", "transgender-alt"],
  title,
  dataKey,
  insights: { valueLists } = {},
  loading,
  compare,
  helpContent,
}) => {
  const insightOfInterest = find(valueLists, { key: dataKey });
  const genderInsights = get(insightOfInterest, "items", []);

  let maleCount = 0;
  let maleComparisonCount = 0;
  let femaleCount = 0;
  let femaleComparisonCount = 0;
  let unknownCount = 0;
  let unknownComparisonCount = 0;
  let otherCount = 0;
  let otherComparisonCount = 0;

  forEach(genderInsights, ({ key, value, comparisonValue }) => {
    switch (key) {
      case "Male":
        maleCount = defaultTo(value, 0);
        maleComparisonCount = defaultTo(comparisonValue, 0);
        break;
      case "Female":
        femaleCount = defaultTo(value, 0);
        femaleComparisonCount = defaultTo(comparisonValue, 0);
        break;
      case "Unknown":
        unknownCount = defaultTo(value, 0);
        unknownComparisonCount = defaultTo(comparisonValue, 0);
        break;
      case "_null_":
        unknownCount = defaultTo(value, 0);
        unknownComparisonCount = defaultTo(comparisonValue, 0);
        break;
      default:
        otherCount += defaultTo(value, 0);
        otherComparisonCount += defaultTo(comparisonValue, 0);
    }
  });

  const total = maleCount + femaleCount + otherCount + unknownCount;
  const displayChange = (comparisonValue, value) => {
    const diff = value - comparisonValue;
    if (diff === 0) {
      return "No change";
    }
    const percentage = round((value / comparisonValue) * 100 - 100, 1);
    return isNaN(percentage) || percentage === Infinity
      ? `${numberToLocaleString(diff, { decimalplaces: 1 })}`
      : `${numberToLocaleString(diff, {
          decimalplaces: 1,
        })}${" "}(${percentage}%)`;
  };

  const subtitle = (
    <Flex alignItems="center">
      <HelpIcon content={helpContent} />
      <DragIcon />
    </Flex>
  );

  return (
    <Card fluid alternate icon={icon} title={title} subtitle={subtitle}>
      <Flex justifyContent="space-around" alignItems="center">
        <FlexItem>
          <Small display="block" uppercase color="greyDark" textAlign="center">
            FEMALE
          </Small>
          <Text
            display="block"
            fontSize="4rem"
            fontWeight="300"
            textAlign="center"
          >
            {!loading
              ? femaleCount
                ? round((femaleCount / total) * 100, 1)
                : 0
              : null}
            %
          </Text>
          {!loading && compare ? (
            <Small display="block" color="greyDark" textAlign="center">
              {femaleCount > femaleComparisonCount ? (
                <Icon icon={["fal", "arrow-up"]} />
              ) : femaleCount < femaleComparisonCount ? (
                <Icon icon={["fal", "arrow-down"]} />
              ) : null}{" "}
              {displayChange(femaleComparisonCount, femaleCount)}
            </Small>
          ) : null}
        </FlexItem>
        <FlexItem>
          <Small display="block" uppercase color="greyDark" textAlign="center">
            MALE
          </Small>
          <Text
            display="block"
            fontSize="4rem"
            fontWeight="300"
            textAlign="center"
          >
            {!loading
              ? maleCount
                ? round((maleCount / total) * 100, 1)
                : 0
              : null}
            %
          </Text>
          {!loading && compare ? (
            <Small display="block" color="greyDark" textAlign="center">
              {maleCount > maleComparisonCount ? (
                <Icon icon={["fal", "arrow-up"]} />
              ) : maleCount < maleComparisonCount ? (
                <Icon icon={["fal", "arrow-down"]} />
              ) : null}{" "}
              {displayChange(maleComparisonCount, maleCount)}
            </Small>
          ) : null}
        </FlexItem>
        <FlexItem>
          <Small display="block" uppercase color="greyDark" textAlign="center">
            OTHER
          </Small>
          <Text
            display="block"
            fontSize="4rem"
            fontWeight="300"
            textAlign="center"
          >
            {!loading
              ? otherCount
                ? round((otherCount / total) * 100, 1)
                : 0
              : null}
            %
          </Text>
          {!loading && compare ? (
            <Small display="block" color="greyDark" textAlign="center">
              {otherCount > otherComparisonCount ? (
                <Icon icon={["fal", "arrow-up"]} />
              ) : otherCount < otherComparisonCount ? (
                <Icon icon={["fal", "arrow-down"]} />
              ) : null}{" "}
              {displayChange(otherComparisonCount, otherCount)}
            </Small>
          ) : null}
        </FlexItem>
        <FlexItem>
          <Small display="block" uppercase color="greyDark" textAlign="center">
            UNKNOWN
          </Small>
          <Text
            display="block"
            fontSize="4rem"
            fontWeight="300"
            textAlign="center"
          >
            {!loading
              ? unknownCount
                ? round((unknownCount / total) * 100, 1)
                : 0
              : null}
            %
          </Text>
          {!loading && compare ? (
            <Small display="block" color="greyDark" textAlign="center">
              {unknownCount > unknownComparisonCount ? (
                <Icon icon={["fal", "arrow-up"]} />
              ) : unknownCount < unknownComparisonCount ? (
                <Icon icon={["fal", "arrow-down"]} />
              ) : null}{" "}
              {displayChange(unknownComparisonCount, unknownCount)}
            </Small>
          ) : null}
        </FlexItem>
      </Flex>
      <FeedbackEmoji contextId={`SummaryPage-${title}-${dataKey}`} />
    </Card>
  );
};

export default GenderInsightCard;
