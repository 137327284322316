import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { trim } from "lodash";
import {
  H1,
  Box,
  Flex,
  Button,
  Icon,
  TextInput,
  Notification,
  Spacer,
} from "orcs-design-system";
import GroupPropType from "src/custom-prop-types/group";

import {
  ILLEGAL_TEAM_NAME_CHARACTERS,
  MAX_TEAM_NAME_LENGTH,
  MIN_TEAM_NAME_LENGTH,
} from "src/consts/global";

// TODO: Find out a comfortable input width

const validateNewName = (newName) => {
  if (!newName || newName.length < MIN_TEAM_NAME_LENGTH) {
    return "Minimum team name length is 3 characters excluding leading and trailing spaces.";
  }

  return null;
};

// eslint-disable-next-line react/display-name
const ErrorNotification = React.memo(({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <Notification
      floating
      centered
      colour="danger"
      icon={["far", "exclamation-circle"]}
    >
      {error}
    </Notification>
  );
});

ErrorNotification.propTypes = {
  error: PropTypes.string,
};

// eslint-disable-next-line react/display-name
const TeamName = React.memo(({ isEditMode, name }) => {
  if (isEditMode) {
    return null;
  }

  return <H1 className="guided-tour-team-details-team-name">{name}</H1>;
});

TeamName.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

// eslint-disable-next-line react/display-name
const EditBtn = React.memo(({ isEditMode, onEdit }) => {
  if (isEditMode) {
    return null;
  }

  return (
    <Button
      dataTestId="edit-btn"
      iconOnly
      onClick={onEdit}
      height="20px"
      width="20px"
      padding="2px"
      ariaLabel="Change team name"
    >
      <Icon icon={["fas", "pencil"]} fontSize="0" />
    </Button>
  );
});

EditBtn.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/display-name
const ConfirmAndCancelBtns = React.memo(
  ({ isEditMode, onSave, saveDisabled, onCancel, canCancel, isLoading }) => {
    if (!isEditMode) {
      return null;
    }

    return (
      <Flex alignItems="center">
        <Spacer mx="xs">
          <Button
            dataTestId="save-btn"
            iconOnly
            ariaLabel="Save team name"
            variant={saveDisabled || isLoading ? "disabled" : "success"}
            onClick={onSave}
            height="40px"
            px="11px"
            disabled={saveDisabled || isLoading}
            isLoading={isLoading}
          >
            <Icon icon={["fas", "check"]} />
          </Button>
          {canCancel && !isLoading && (
            <Button
              dataTestId="cancel-btn"
              small
              iconOnly
              ariaLabel="Cancel"
              variant="danger"
              onClick={onCancel}
              height="40px"
              px="14px"
            >
              <Icon icon={["fas", "times"]} />
            </Button>
          )}
        </Spacer>
      </Flex>
    );
  }
);

ConfirmAndCancelBtns.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  canCancel: PropTypes.bool.isRequired,
  saveDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const TeamNameInput = ({
  isEditMode,
  value,
  onChange,
  onSave,
  onCancel,
  isLoading,
}) => {
  const handleFocus = (e) => e.target.select(); // auto select text
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSave();
    }
    if (e.key === "Escape") {
      onCancel();
    }
  };

  if (!isEditMode) {
    return null;
  }

  return (
    <Box width="350px">
      <TextInput
        autoFocus
        fullWidth
        data-testid="team-name-editor-input"
        ariaLabel="Team name"
        className="guided-tour-team-details-team-name"
        disabled={isLoading}
        maxLength={MAX_TEAM_NAME_LENGTH}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
      />
    </Box>
  );
};

TeamNameInput.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const TeamNameEditor = ({
  team,
  updateName,
  isEditMode = false,
  canCancel = true,
  autoExitEditMode = true,
}) => {
  const { name } = team;

  const [editMode, setEditMode] = useState(isEditMode);
  const [isLoading, setLoading] = useState(false);
  const [newName, setNewName] = useState(name);
  const [error, setError] = useState(null);

  useEffect(() => {
    setNewName(name);
  }, [name]);

  useEffect(() => {
    if (!error) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setError(null);
    }, 5000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timeoutId);
    };
  }, [error]);

  const onEdit = useCallback(() => {
    setEditMode(true);
  }, []);

  const onSave = useCallback(async () => {
    const proposedName = trim(newName);

    if (proposedName === name) {
      return;
    }

    const result = validateNewName(proposedName);

    if (result) {
      setError(result);
      return;
    }

    try {
      setLoading(true);
      await updateName(proposedName);
      // TODO: Update team name immediately?
      setLoading(false);
    } catch (err) {
      setError(err.toString());
      setLoading(false);
    } finally {
      if (autoExitEditMode) {
        setEditMode(false);
      }
    }
  }, [newName, name, updateName, autoExitEditMode]);

  const onCancel = useCallback(() => {
    setNewName(name);
    setEditMode(false);
    setError(null);
  }, [name]);

  const onNameChange = useCallback((e) => {
    const proposedName = e.target.value.replace(
      ILLEGAL_TEAM_NAME_CHARACTERS,
      ""
    );

    setNewName(proposedName);
  }, []);

  const onDismiss = useCallback(() => {
    setError(null);
  }, []);

  return (
    <Flex alignItems="center">
      <Spacer mr="xxs">
        <TeamName isEditMode={editMode} name={name} />
        <TeamNameInput
          isEditMode={editMode}
          isLoading={isLoading}
          value={newName}
          onChange={onNameChange}
          onSave={onSave}
          onCancel={onCancel}
        />
        <EditBtn isEditMode={editMode} onEdit={onEdit} />
        <ConfirmAndCancelBtns
          isEditMode={editMode}
          isLoading={isLoading}
          canCancel={canCancel}
          saveDisabled={newName === name}
          onSave={onSave}
          onCancel={onCancel}
        />
        <ErrorNotification error={error} onDismiss={onDismiss} />
      </Spacer>
    </Flex>
  );
};

TeamNameEditor.propTypes = {
  team: GroupPropType.isRequired,
  updateName: PropTypes.func,
  isEditMode: PropTypes.bool,
  canCancel: PropTypes.bool,
  autoExitEditMode: PropTypes.bool,
};

export default TeamNameEditor;
