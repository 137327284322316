import React, { useState, useContext, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { Box, Notification, Divider } from "orcs-design-system";
import { get } from "lodash";

import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import PATHS from "../../consts/urlPaths";
import { VisualisationContext } from "../../contexts/visualisationContext";
import { buildPersonGraph, buildTeamGraph } from "../../util/buildNodes";
import { visualisationPath } from "../../util/routing";
import Loading from "../LoadingPage";
import NodeGraph from "../Visualisations/Reagraph/NodeGraph";
import DataNotAvailable from "./DataNotAvailable";
import ImportDateHeading from "./ImportDateHeading";
import NotFoundNotification from "./NotFoundNotification";
import PersonHeading from "./PersonHeading";
import PersonSearchInput from "./PersonSearchInput";
import TeamHeading from "./TeamHeading";
import TooManyNodesNotification from "./TooManyNodesNotification";
import * as vsUtils from "./VisualisationPage.utils";

// TODO: Refine naming conventions for "page" components that sit in /src/components.
// They shouldn't be a page, confusing since we already have page component
const VisualisationPage = ({ history, match, location }) => {
  const [state, dispatch] = useContext(VisualisationContext);
  const { aggregateId, pageType = "person" } = match.params;
  const highlightSelectedNode = vsUtils.getHighlightedNode(location);
  const selectedNode = useRef(vsUtils.getSelectedNode(aggregateId, pageType));
  const initialNodes = aggregateId ? highlightSelectedNode : [];

  const [importDate, setImportDate] = useState(null);
  const [subject, setSubject] = useState(null);
  const [nodes, setNodes] = useState(initialNodes);
  const [notFound, setNotFound] = useState(false);

  const groupTypes = useGroupTypes();

  const searchTerms =
    aggregateId || (state.searchTerms ? state.searchTerms : "");
  const isTipsNotificationDisplayed = state.tipsNotification;
  const showDescendants = get(
    state,
    "visualisation.settings.showDescendants",
    false
  );

  const currentQuery = vsUtils.getVisualisationPageQueryAndVariables(
    pageType,
    searchTerms,
    showDescendants,
    !searchTerms
  );

  const [isResetClear, setIsResetClear] = useState(false);

  const onNodeSelect = (type, id) => {
    selectedNode.current = vsUtils.getSelectedNode(id, type);
    if (id) {
      setIsResetClear(false);
      if (type === "person" || (!type && pageType === "person")) {
        dispatch({
          type: "setSearchTerms",
          searchTerms: id,
        });
        dispatch({
          type: "applyVisualisationSettings",
          settings: {
            person: true,
          },
        });
      }
      history.push({
        pathname: visualisationPath(type, id),
        state: { sidebar: { action: "preventCollapse" } },
      });
    } else {
      // clear scenario
      setIsResetClear(true);
      history.push({
        pathname: PATHS.VISUALISATION,
        state: { sidebar: { action: "preventCollapse" } },
      });
    }
  };

  const onDismiss = () => {
    dispatch({
      type: "setTipsNotification",
      isTipsNotificationEnabled: false,
    });
  };

  const onSetDownloadFunction = (fn) => {
    dispatch({
      type: "setDownloadChartFn",
      downloadChart: fn,
    });
  };

  const onSetHighlightTargetFunction = (fn) => {
    dispatch({
      type: "setHighlightTargetFunction",
      highlightTargetNode: fn,
    });
  };

  // get person
  const {
    loading: visualisationLoading,
    error: visualisationError,
    data: visualisationData,
  } = useQuery(currentQuery.query, {
    variables: currentQuery.variables,
    fetchPolicy: "network-only",
  });

  const type = pageType.toLowerCase();

  useEffect(() => {
    if (
      !visualisationError &&
      match.params.pageType &&
      ["person", "team"].includes(type) &&
      visualisationData
    ) {
      if (!visualisationData[type]) {
        setNotFound(true);
      } else {
        const newSubject = visualisationData[type];
        setSubject(newSubject);
        setNodes(
          type === "person"
            ? buildPersonGraph(newSubject, groupTypes)
            : buildTeamGraph(
                newSubject,
                state.visualisation.settings,
                groupTypes
              )
        );
        setImportDate(visualisationData.importDate);
      }
    }
  }, [
    visualisationError,
    match.params.pageType,
    type,
    visualisationData,
    groupTypes,
    state.visualisation.settings,
  ]);

  if (visualisationLoading) {
    return <Loading />;
  }

  const isValidImportDate = vsUtils.isValidImportDate(importDate);
  const isPersonHeadingDisplay = vsUtils.isHeadingDisplayed(
    "Person",
    subject,
    isResetClear
  );
  const isTeamHeadingDisplayed = vsUtils.isHeadingDisplayed(
    "Group",
    subject,
    isResetClear
  );
  const isNodesDisplayed = !isResetClear && nodes && nodes.length > 0;

  return (
    <>
      <Box>
        {!importDate && !selectedNode.current && <DataNotAvailable />}
        {isValidImportDate && (
          <ImportDateHeading
            importDateFromNow={vsUtils.getDateFromNow(importDate)}
            importDate={vsUtils.getFormattedDate(importDate)}
          />
        )}
        <PersonSearchInput
          onNodeSelect={onNodeSelect}
          selectedNode={selectedNode.current}
          searchTerms={Array.isArray(searchTerms) ? searchTerms : [searchTerms]}
        />
      </Box>
      <Box
        borderRadius={2}
        boxBorder="default"
        p="r"
        mt="r"
        bg="white"
        height={[
          "calc(100vh - 243px)",
          "calc(100vh - 243px)",
          "calc(100vh - 243px)",
          "calc(100vh - 184px)",
        ]}
      >
        {isPersonHeadingDisplay && <PersonHeading person={subject} />}
        {isTeamHeadingDisplayed && <TeamHeading team={subject} />}
        {!!subject && <Divider light mt="r" />}

        {notFound && <NotFoundNotification />}
        {visualisationError && <TooManyNodesNotification />}
        {isNodesDisplayed && (
          <Box
            pt="r"
            height={
              isPersonHeadingDisplay ? "calc(100% - 48px)" : "calc(100% - 82px)"
            }
          >
            <NodeGraph
              nodes={nodes}
              targetNode={selectedNode.current}
              highlightTargetNode={highlightSelectedNode}
              handleNodeDoubleClick={onNodeSelect}
              setDownloadFunctionCallback={onSetDownloadFunction}
              setHighlightTargetFunctionCallback={onSetHighlightTargetFunction}
              layout={state.visualisation.chartType}
              settings={state.visualisation.settings}
            />
          </Box>
        )}
        {isTipsNotificationDisplayed && (
          <Notification
            centered
            floating
            bottom="50px"
            colour="primary"
            onDismiss={onDismiss}
          >
            TIP: Select and then double click on person or team to navigate.
          </Notification>
        )}
      </Box>
    </>
  );
};

VisualisationPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
};

export default VisualisationPage;
