import { Flex, Button } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const FooterContent = ({ onHideModal, isBusy, handleBulkMove }) => {
  return (
    <Flex alignItems="center" justifyContent="flex-end" width="100%">
      <Button onClick={onHideModal} variant="ghost" mr="s" disabled={isBusy}>
        Cancel
      </Button>
      <Button
        onClick={handleBulkMove}
        variant={isBusy ? "disabled" : "success"}
        disabled={isBusy}
      >
        Move members
      </Button>
    </Flex>
  );
};

FooterContent.propTypes = {
  handleBulkMove: PropTypes.func,
  onHideModal: PropTypes.func,
  isBusy: PropTypes.bool,
};

export default FooterContent;
