import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Spacer,
  Expandable,
  Flex,
  H3,
  Divider,
  Icon,
  Button,
} from "orcs-design-system";
import { map, get, orderBy, find } from "lodash";
import moment from "moment";

import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";
import ImportInProgress from "./ImportInProgress";
import ImportFailed from "./ImportFailed";
import ImportCompleted from "./ImportCompleted";
import ImportPreviewChanges from "./ImportPreviewChanges";

export const dataImports = {
  CREATED: {
    // TODO remove this when we can (replaced with QUEUED)
    badgeText: "IN PROGRESS ...",
    badgeColour: "warning",
    component: ImportInProgress,
    expandedByDefault: true,
  },
  QUEUED: {
    badgeText: "IN PROGRESS ...",
    badgeColour: "warning",
    component: ImportInProgress,
    expandedByDefault: true,
  },
  FETCHING: {
    badgeText: "IN PROGRESS ...",
    badgeColour: "warning",
    component: ImportInProgress,
    expandedByDefault: true,
  },
  PROCESSING: {
    badgeText: "IN PROGRESS ...",
    badgeColour: "warning",
    component: ImportInProgress,
    expandedByDefault: true,
  },
  FAILED: {
    badgeText: "FAILED",
    badgeColour: "danger",
    component: ImportFailed,
    expandedByDefault: false,
  },
  PROCESSED: {
    badgeText: "COMPLETED",
    badgeColour: "success",
    component: ImportCompleted,
    expandedByDefault: false,
  },
  PREVIEW_CHANGES: {
    badgeText: "PREVIEW",
    badgeColour: "warning",
    component: ImportPreviewChanges,
    expandedByDefault: false,
  },
  UNKNOWN: {
    badgeText: "UNKNOWN",
    badgeColour: "warning",
    component: ImportFailed,
    expandedByDefault: false,
  },
};

export const Imports = ({
  datasource,
  loadImportWarnings,
  importWarnings,
  onUseImportFieldsMap,
}) => {
  const featureFlags = useWorkspaceFeatureFlags();
  const { enableDeleteImportHistory: canDeleteImport } = featureFlags;

  const { imports, type: datasourceType } = datasource;

  const canRevertImport =
    // HELP-8990: reverting tags imports is causing problems
    // datasourceType === "personTags" ||
    // datasourceType === "entityTags" ||
    datasourceType === "file" ||
    datasourceType === "memberAndTeams" ||
    datasourceType === "csv" ||
    datasourceType === "teamsCsv";

  const sortedImports = orderBy(imports, "created", "desc");

  const onLoadWarnings = () => {
    loadImportWarnings();
  };

  return (
    <Spacer mb="r">
      <Flex alignItems="center" justifyContent="space-between">
        <H3 weight="bold">Import History</H3>
        <Button variant="ghost" small onClick={onLoadWarnings}>
          Load import warnings
        </Button>
      </Flex>
      <Divider light />
      {map(sortedImports, (dataImport, index) => {
        const { id, name, status, dataDate, pit, created, fieldsMap } =
          dataImport;
        const { warnings } = find(importWarnings, (iw) => iw.id === id) || {};
        const importConfig = get(dataImports, status, dataImports.UNKNOWN);
        const ImportComponent = importConfig.component;

        const formattedDisplayDate = moment(pit || dataDate).format(
          "DD MMM YYYY"
        );
        const formattedCreated = moment(created).fromNow();

        const setFieldsMap = () => {
          onUseImportFieldsMap(fieldsMap);
        };

        const subTitle = name ? (
          <Spacer my="xs">
            <Flex alignItems="center">
              <Icon icon={["far", "history"]} mr="s" />
              {` created ${formattedCreated}`}
            </Flex>
            <Flex alignItems="center">
              <Icon icon={["far", "file"]} mr="s" />
              {` ${name} `}
            </Flex>
          </Spacer>
        ) : (
          <Flex alignItems="center">
            <Icon icon={["far", "calendar-day"]} mr="s" />
            {` created ${formattedCreated}`}
          </Flex>
        );
        return (
          <Expandable
            key={id}
            title={formattedDisplayDate}
            subtitle={subTitle}
            badge={`${importConfig.badgeText}${
              warnings && warnings.length > 0 ? " (Please check warnings)" : ""
            }`}
            badgeColour={importConfig.badgeColour}
            isOpen={importConfig.expandedByDefault}
          >
            <Spacer>
              <ImportComponent
                datasource={datasource}
                datasourceType={datasourceType}
                dataImport={dataImport}
                warnings={warnings}
                canDeleteImport={canDeleteImport}
                canRevertImport={canRevertImport && index === 0}
              />
              {!!fieldsMap && (
                <Button variant="ghost" small onClick={setFieldsMap}>
                  Use fields map
                </Button>
              )}
            </Spacer>
          </Expandable>
        );
      })}
    </Spacer>
  );
};

Imports.propTypes = {
  datasource: PropTypes.shape({
    imports: PropTypes.array.isRequired,
    type: PropTypes.string,
    canRevertImport: PropTypes.bool,
  }).isRequired,
  loadImportWarnings: PropTypes.func,
  importWarnings: PropTypes.object,
  onUseImportFieldsMap: PropTypes.func,
};

const ImportWrapper = ({
  datasource,
  loadImportWarnings,
  importWarnings,
  onUseImportFieldsMap,
}) => {
  return (
    <Box borderRadius={2} bg="white" boxBorder="default" p="r">
      <Imports
        datasource={datasource}
        loadImportWarnings={loadImportWarnings}
        importWarnings={importWarnings}
        onUseImportFieldsMap={onUseImportFieldsMap}
      />
    </Box>
  );
};

ImportWrapper.propTypes = {
  datasource: PropTypes.shape({
    imports: PropTypes.array.isRequired,
    type: PropTypes.string,
    canRevertImport: PropTypes.bool,
  }).isRequired,
  loadImportWarnings: PropTypes.func,
  importWarnings: PropTypes.object,
  onUseImportFieldsMap: PropTypes.func,
};

export default ImportWrapper;
