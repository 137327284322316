import { isEmpty } from "lodash";
import {
  Box,
  Badge,
  Divider,
  Flex,
  H4,
  Small,
  Spacer,
  StyledLink,
} from "orcs-design-system";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { teamDetailsPath } from "src/util/routing";
import { useOrgChartContext } from "src/components/OrgChart/context/OrgChartContext";
import TeamAvatar from "src/components/TeamAvatar/TeamAvatar";
import TeamDetailsActionMenu from "src/components/TeamDetailPage/ActionsMenu";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import TagDisplay from "src/components/TagDisplay";
import { ENTITY_TYPES } from "src/consts/comments";
import useRestricted from "src/app/Restricted/useRestricted";
import { ACTION_TYPES } from "src/components/OrgChart/context/actionTypes";
import NodeDetailsIcon from "src/components/NodeVisualizer/components/NodeDetailsIcon";
import { isReadOnly } from "src/components/OrgChart/context/utils/getCalculatedState";
import { EventBus } from "src/services/eventEmitter";
import { PANEL_ID } from "src/components/OrgChart/components/OrgChartPanel/const";
import GroupTypeBadge from "../../../Badge/GroupTypeBadge";
import { TEAM_ACTION_MENU_TYPE } from "../../../TeamDetailPage/ActionsMenu/const";
import {
  EDGE_COLOR,
  SourceHandle,
  StyledCard,
  TargetHandle,
  DIFF_STATE_THEMES,
  DEFAULT_CARD_THEME,
  changes,
} from "../node.styled";
import { RoundIconButton } from "../RoundIconButton";
import { useNodeProperties } from "../../hooks/useNodeProperties";
import GroupNodeSkeleton from "./GroupNodeSkeleton";

const StyledButton = styled(RoundIconButton)`
  position: absolute;
  left: calc(50% - 10px);
  bottom: -10px;
`;

const ExpandUpwardsButton = styled(RoundIconButton)`
  position: absolute;
  left: calc(50% - 10px);
  top: -40px;
`;

const DottedLine = styled.div`
  position: absolute;
  top: -30px;
  left: calc(50% - 1.5px);

  border-left: 2px solid ${EDGE_COLOR};
  height: 30px;
  width: 1px;
`;

const THEMES = {
  default: DEFAULT_CARD_THEME,
  diffStates: DIFF_STATE_THEMES,
};

const getTheme = (diffState) => {
  if (diffState) {
    return THEMES.diffStates[diffState];
  }

  return THEMES.default;
};

const GroupNode = ({ data, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);

  const workspace = useWorkspace();
  const tagsToBeDisplayed = useNodeProperties({
    tags: data?.tags,
    entityType: ENTITY_TYPES.GROUP,
  });

  const [isDirectoryUser] = useRestricted();

  const history = useHistory();

  const {
    loadSpecificTeamDataById,
    state,
    toggleExpandedNode,
    dispatch: orgChartDispatch,
  } = useOrgChartContext();
  const viewOptions = state?.viewOptions;

  const isCurrentTeam = useMemo(
    () => state?.currentViewTeam?.id === data?.id,
    [state?.currentViewTeam?.id, data?.id]
  );

  const isExpanded = useMemo(
    () => state?.expandedNodes?.includes(data?.id),
    [state?.expandedNodes, data?.id]
  );

  const handleIconClick = useCallback(() => {
    if (isCurrentTeam) {
      return;
    }

    if (state?.expandedNodes?.includes(data?.id)) {
      toggleExpandedNode(data?.id);
    } else {
      loadSpecificTeamDataById(data.id);
    }
  }, [
    data,
    loadSpecificTeamDataById,
    isCurrentTeam,
    state?.expandedNodes,
    toggleExpandedNode,
  ]);

  const onExpandUpwardsClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const teamPath = teamDetailsPath(data?.directParentId);

      history.push(teamPath);
    },
    [data?.directParentId, history]
  );

  const handleGroupClick = useCallback(() => {
    // Orcs FloatingPanels listens to this event
    EventBus.emit("FloatingPanels_SetSelectedPanel", PANEL_ID.details);

    const isSelected = state?.expandedPanel?.data?.selectedEntity === data.id;
    // not selected?
    if (orgChartDispatch && !isSelected) {
      // select
      orgChartDispatch({
        type: ACTION_TYPES.UPDATE_EXPANDED_PANEL,
        payload: {
          data: {
            selectedEntity: data.id,
            entity: data,
          },
        },
      });
    }
  }, [data, orgChartDispatch, state?.expandedPanel?.data?.selectedEntity]);

  if (data?.isLoading || isEmpty(data)) {
    return <GroupNodeSkeleton />;
  }

  const readOnly = isReadOnly(data);

  return (
    <StyledCard
      onClick={handleGroupClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
      width="400px"
      $hidden={data?.hidden}
      cursor="grab"
      $theme={getTheme(data?.diffType)}
      className={`${data?.state?.toLowerCase()} ${props.className || ""}`}
      key={`{data?.directParentId}-${data?.id}`}
    >
      <TargetHandle $hidden type="target" position="top" />
      {isCurrentTeam && data?.directParentId && (
        <>
          <DottedLine />
          <ExpandUpwardsButton
            className="tree-navigator light"
            icon={["fas", "chevron-up"]}
            onClick={onExpandUpwardsClick}
            ariaLabel="Navigate up to parent team"
          />
        </>
      )}
      <Spacer mb="s">
        {data?.diffType && (
          <Badge variant="warning">{changes[data?.diffType]}</Badge>
        )}
        <Flex alignItems="flex-start" justifyContent="space-between">
          <Flex alignItems="center">
            <TeamAvatar group={data} sizing="large" mr="r" />
            <Flex alignItems="flex-start" flexDirection="column" gap="4px">
              <StyledLink to={teamDetailsPath(data?.id)}>
                <H4>{data?.name}</H4>
              </StyledLink>
              <GroupTypeBadge
                group={data}
                groupTypes={workspace?.config?.groupTypes}
              />
            </Flex>
          </Flex>
          {!isDirectoryUser && !readOnly && (
            <TeamDetailsActionMenu
              team={data}
              showInsightIcon={false}
              actionMenuTypes={[
                TEAM_ACTION_MENU_TYPE.ADD_TEAM,
                TEAM_ACTION_MENU_TYPE.DOWNLOAD,
              ]}
              direction="right-start"
              runGroupCacheUpdateOnAddition={false}
              showChangesEnabled={viewOptions?.showChanges}
            />
          )}
        </Flex>
      </Spacer>
      <Flex mt="s" justifyContent="space-between" alignItems="flex-end">
        <Small color="grey" fontSize="0" display="block">
          {data?.memberCount} members / {data?.childTeams?.length} teams
        </Small>
        <Box mt="s">
          <NodeDetailsIcon
            isSelected={state?.expandedPanel?.data?.selectedEntity === data.id}
            isHovered={isHovered}
          />
        </Box>
      </Flex>

      {!isEmpty(tagsToBeDisplayed) && (
        <>
          <Divider mt="xs" mb="s" />
          <Spacer mb="s">
            <TagDisplay tags={tagsToBeDisplayed} />
          </Spacer>
        </>
      )}
      <SourceHandle $hidden type="source" position="bottom" />
      {!isCurrentTeam && data?.isExpandable && (
        <StyledButton
          className="tree-navigator"
          icon={["fas", isExpanded ? "chevron-up" : "chevron-down"]}
          ariaLabel={`Click to ${isExpanded ? "collapse" : "expand"} this team`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleIconClick();
          }}
        />
      )}
    </StyledCard>
  );
};

GroupNode.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
};

export default React.memo(GroupNode);
