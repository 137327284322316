import { useMutation } from "@apollo/client";
import { isEmpty } from "lodash";
import {
  Button,
  Flex,
  Modal,
  H3,
  Box,
  Spacer,
  P,
  Popover,
  Small,
  Divider,
  Notification,
} from "orcs-design-system";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import styled from "styled-components";

import { useCachedTag } from "src/hooks/useCachedTag";
import { useCachedGroup } from "src/hooks/useSearchGroups";
import { useCachedPerson } from "src/hooks/useSearchPeople";
import ErrorNotification from "src/components/ErrorNotification";

import { useTagFormValidator } from "src/hooks/useTagFormValidator";
import { RichTextContentViewer } from "src/shared/RichTextContent/RichTextContent";
import { getUpdatedAttributes } from "src/components/TagsEditor/utils/getUpdatedAttributes";
import { ATTRIBUTE_ROW_TYPE } from "src/pages/Workspaces/consts";
import {
  updateTagValue as updateTagValueMutation,
  updateEntityTag as updateEntityTagMutation,
} from "../queries/tags.graphql";
import {
  isTagDisplayValueEditable,
  updateTagsForEntityCache,
} from "../TagsEditor.util";

import {
  buildDisplayValue,
  filterTagAttributeTypes,
  isAttributesChanged,
} from "./TagAttributeModal.logic";
import TagAttributesFields, { AdjustedTextInput } from "./TagAttributesFields";

const DetailsWrapper = styled(Box)`
  overflow-y: auto;
  max-height: 150px;
`;

const EmptyAttrTypes = [];

const TagAttributesModal = ({
  onClose,
  entityTag,
  tagTypeConfig,
  isNewTag,
  setNewTag,
  comments,
  entityId,
  tagTypes,
  featureFlags,
  tagTypesConfig,
  editMode = false,
}) => {
  const [displayValue, setDisplayValue] = useState(entityTag.displayValue);
  const [updatedAttributes, setUpdatedAttributes] = useState(
    getUpdatedAttributes({
      tagType: tagTypeConfig,
      entityId,
      attributes: entityTag.attributes || {},
      typeKeys: [ATTRIBUTE_ROW_TYPE.others, ATTRIBUTE_ROW_TYPE.values],
    })
  );
  const [updatedEntityTagAttributes, setUpdatedEntityTagAttributes] = useState(
    entityTag.entityTagAttributes || {}
  );
  const isMounted = useRef(false);
  const { getCachedGroup } = useCachedGroup();
  const { getCachedPerson } = useCachedPerson();
  const { getCachedTag } = useCachedTag();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const attributeValues = {
    ...updatedAttributes,
    ...updatedEntityTagAttributes,
  };

  const {
    onFieldUpdate,
    onFormSave,
    fieldToBeFocused,
    fieldsToShowInvalidity,
    clearFieldToBeFocused,
  } = useTagFormValidator({
    fields: attributeValues,
    tagType: tagTypeConfig,
    tagValue: {
      ...entityTag,
      attributes: attributeValues,
    },
    includeEntityAttributes: true,
  });

  const {
    name: tagTypeName,
    hasDetails,
    detailsParsed: parsedDetails,
    attributes: meta = {},
  } = tagTypeConfig || {};

  const [updateTagValue] = useMutation(updateTagValueMutation);
  const [updateEntityTag] = useMutation(updateEntityTagMutation, {
    update: updateTagsForEntityCache({ entityId, tagTypes, entityTag }),
  });

  const {
    separator = "_",
    values: nameAttrTypes = EmptyAttrTypes,
    others: otherAttrTypes = EmptyAttrTypes,
    entityTag: entityTagAttrTypes = EmptyAttrTypes,
  } = meta || {};

  const isDisplayValueEditable = isTagDisplayValueEditable(
    nameAttrTypes,
    otherAttrTypes,
    tagTypeConfig
  );

  const [readonlyAttrTypes, editableAttrTypes, uniqueAttrTypes] =
    useMemo(() => {
      return filterTagAttributeTypes(
        nameAttrTypes,
        otherAttrTypes,
        entityTagAttrTypes
      );
    }, [nameAttrTypes, otherAttrTypes, entityTagAttrTypes]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    if (isEmpty(nameAttrTypes)) {
      return;
    }
    const combinedValue = buildDisplayValue({
      attrTypeValues: nameAttrTypes,
      attributes: updatedAttributes,
      separator,
      getCachedGroup,
      getCachedPerson,
      getCachedTag,
      entityId,
    });
    setDisplayValue(combinedValue);
  }, [
    nameAttrTypes,
    separator,
    updatedAttributes,
    getCachedGroup,
    getCachedPerson,
    getCachedTag,
    entityId,
  ]);

  const onDisplayValueChange = (e) => {
    setDisplayValue(e.target.value);
  };

  const onAttrValueChange = (type, isEntityTagAttr) => (e) => {
    const newValue = e.target.value;
    let targetAttributes = updatedAttributes;
    let updateMethod = setUpdatedAttributes;

    if (isEntityTagAttr) {
      targetAttributes = updatedEntityTagAttributes;
      updateMethod = setUpdatedEntityTagAttributes;
    }
    const newAttributes = {
      ...targetAttributes,
      [type]: newValue,
    };

    updateMethod(newAttributes);
    onFieldUpdate(newAttributes);
    clearFieldToBeFocused();
  };

  const onSelectAttrValue = (id, isEntityTagAttr) => (option) => {
    let targetAttributes = updatedAttributes;
    let updateMethod = setUpdatedAttributes;

    if (isEntityTagAttr) {
      targetAttributes = updatedEntityTagAttributes;
      updateMethod = setUpdatedEntityTagAttributes;
    }

    const newAttributes = {
      ...targetAttributes,
      [id]: option ? option.value : undefined,
    };

    updateMethod(newAttributes);
    onFieldUpdate(newAttributes);
    clearFieldToBeFocused();
  };

  const onDateChange = (id, isEntityTagAttr) => (value) => {
    let targetAttributes = updatedAttributes;
    let updateMethod = setUpdatedAttributes;

    if (isEntityTagAttr) {
      targetAttributes = updatedEntityTagAttributes;
      updateMethod = setUpdatedEntityTagAttributes;
    }

    const newAttributes = {
      ...targetAttributes,
      [id]: value,
    };
    updateMethod(newAttributes);
    onFieldUpdate(newAttributes);
    clearFieldToBeFocused();
  };

  const onSave = async () => {
    const invalidFields = onFormSave({ attributes: attributeValues });

    if (!isEmpty(invalidFields)) {
      return;
    }

    setIsSaving(true);
    try {
      if (isNewTag) {
        await setNewTag(
          {
            ...entityTag,
            displayValue,
            attributes: updatedAttributes,
          },
          tagTypeConfig
        );
      } else if (
        isAttributesChanged(entityTag?.tag.attributes, updatedAttributes) ||
        displayValue !== entityTag?.displayValue
      ) {
        await updateTagValue({
          variables: {
            input: {
              id: entityTag.tagId,
              type: tagTypeConfig.id,
              displayValue,
              isActive: true,
              attributes: updatedAttributes,
            },
          },
        });
      }

      if (
        isAttributesChanged(
          entityTag.entityTagAttributes,
          updatedEntityTagAttributes
        )
      ) {
        await updateEntityTag({
          variables: {
            input: {
              id: entityTag.id,
              tagType: tagTypeConfig.id,
              attributes: updatedEntityTagAttributes,
              comments: {
                ...comments,
                tag: {
                  tagId: entityTag.tagId,
                  tagType: entityTag.type,
                  displayValue: entityTag.displayValue,
                },
              },
            },
          },
        });
      }

      setIsSaving(false);
      onClose();
    } catch (e) {
      setError(e);
      setIsSaving(false);
    }
  };

  const { appliedCount } = entityTag;

  const modalHeader = (
    <Flex px="xs">
      <Flex width="50%" alignItems="center" justifyContent="space-between">
        <H3>Tag details</H3>
        {appliedCount > 0 && (
          <Flex alignItems="center">
            <Popover variant="tooltip" />
            <Small ml="s">{`Tag applied ${appliedCount} ${pluralize(
              "time",
              appliedCount
            )}`}</Small>
          </Flex>
        )}
      </Flex>
      <Box width="50%">
        <H3 ml="19px">Tag attributes</H3>
      </Box>
    </Flex>
  );
  const modalFooter = (
    <Flex pl="4px">
      <Button
        onClick={onSave}
        isLoading={isSaving}
        disabled={isSaving || !editMode}
        variant={isSaving ? "disabled" : "success"}
        mr="s"
      >
        Save
      </Button>
      <Button onClick={onClose} variant="ghost">
        Close
      </Button>
    </Flex>
  );

  const allAttributes = { ...updatedAttributes, ...updatedEntityTagAttributes };

  return (
    <Modal
      width={["90vw", "90vw", "90vw", "80vw", "70vw", "50vw"]}
      height={["80vh", "80vh", "80vh", "80vh", "60vh"]}
      maxWidth="90vw"
      maxHeight="90vh"
      visible
      onClose={(e) => {
        if (e) {
          onClose();
        }
      }}
      headerContent={modalHeader}
      footerContent={modalFooter}
    >
      <Flex width="100%" p="xs" pt="r">
        <Box mr="r" width="50%">
          <Spacer mb="r">
            <AdjustedTextInput
              name="tag-type"
              type="text"
              id="tagType"
              label="Tag type"
              fullWidth
              value={tagTypeName}
              disabled
            />
            {!isDisplayValueEditable && (
              <AdjustedTextInput
                data-testid="tag-display-input"
                name="tag-display-value"
                type="text"
                id="tag-value-textinput"
                label="Tag display value"
                fullWidth
                value={displayValue}
                disabled
              />
            )}

            {hasDetails && (
              <Box>
                <P mb="4px">Tag type description</P>
                <DetailsWrapper p="s" boxBorder="default" borderRadius={2}>
                  <RichTextContentViewer
                    content={parsedDetails}
                    fontSize="14px"
                  />
                </DetailsWrapper>
              </Box>
            )}
            <TagAttributesFields
              attrTypeValues={readonlyAttrTypes}
              updatedAttributes={allAttributes}
              onAttrValueChange={onAttrValueChange}
              onSelectAttrValue={onSelectAttrValue}
              onDateChange={onDateChange}
              featureFlags={featureFlags}
              tagTypesConfig={tagTypesConfig}
              editMode={editMode}
            />
          </Spacer>
        </Box>
        <Box width="50%">
          <Notification
            closable={false}
            colour="warning"
            icon={["fas", "exclamation-triangle"]}
            mb="r"
          >
            Values changed below will apply to ALL people / teams that have this
            tag.
          </Notification>
          {isDisplayValueEditable && (
            <AdjustedTextInput
              data-testid="tag-display-input"
              name="tag-display-value"
              type="text"
              id="tag-value-name"
              label="Tag display value"
              fullWidth
              value={displayValue}
              onChange={onDisplayValueChange}
              mandatory
              disabled={!editMode}
            />
          )}
          <TagAttributesFields
            attrTypeValues={editableAttrTypes}
            updatedAttributes={allAttributes}
            onAttrValueChange={onAttrValueChange}
            onSelectAttrValue={onSelectAttrValue}
            onDateChange={onDateChange}
            fieldToBeFocused={fieldToBeFocused}
            fieldsToShowInvalidity={fieldsToShowInvalidity}
            featureFlags={featureFlags}
            tagTypesConfig={tagTypesConfig}
            editMode={editMode}
          />
        </Box>
        {error && <ErrorNotification message={error?.message} error={error} />}
      </Flex>
      {!isEmpty(uniqueAttrTypes) && (
        <Flex width="100%" p="xs" pt="r" flexDirection="column">
          <Divider light />
          <Box width="50%" mt="r">
            <Box mb="r">
              <H3>Unique attributes</H3>
              Note: Changing these will update these values only for this
              entity.
            </Box>
            <Notification closable={false} icon={["fas", "info-circle"]} mb="r">
              Values changed below will apply to ONLY this person / team that
              has this tag.
            </Notification>
            <TagAttributesFields
              attrTypeValues={uniqueAttrTypes}
              updatedAttributes={allAttributes}
              onAttrValueChange={onAttrValueChange}
              onSelectAttrValue={onSelectAttrValue}
              onDateChange={onDateChange}
              fieldToBeFocused={fieldToBeFocused}
              fieldsToShowInvalidity={fieldsToShowInvalidity}
              featureFlags={featureFlags}
              tagTypeConfig={tagTypeConfig}
              editMode={editMode}
            />
          </Box>
        </Flex>
      )}
    </Modal>
  );
};

TagAttributesModal.propTypes = {
  onClose: PropTypes.func,
  entityTag: PropTypes.object,
  tagTypeConfig: PropTypes.object,
  isNewTag: PropTypes.bool,
  setNewTag: PropTypes.func,
  comments: PropTypes.object,
  entityId: PropTypes.string,
  tagTypes: PropTypes.arrayOf(PropTypes.string),
  featureFlags: PropTypes.object,
  tagTypesConfig: PropTypes.array,
  editMode: PropTypes.bool,
};

export default TagAttributesModal;
