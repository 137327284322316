import { useEffect } from "react";
import { sortBy } from "lodash";
import eventEmitter, { EVENTS } from "src/services/eventEmitter";

const useUpdateTeamStoreListener = ({ mergePartialData, state }) => {
  useEffect(() => {
    const handler = (newGroup, proxy, variables) => {
      const team = state.teams[variables.parentId];

      if (!team) {
        return;
      }

      const childTeams = sortBy([...(team.childTeams || []), newGroup], "name");

      mergePartialData({
        teams: {
          [newGroup.id]: { ...newGroup, directParentId: team.id },
          [team.id]: {
            ...team,
            childTeams,
          },
        },
      });
    };

    eventEmitter.on(EVENTS.NEW_GROUP_ADDED, handler);

    return () => eventEmitter.off(EVENTS.NEW_GROUP_ADDED, handler);
  }, [state, mergePartialData]);
};

export default useUpdateTeamStoreListener;
