import styled from "styled-components";
import { Box, systemtheme } from "orcs-design-system";

export const StyledContainer = styled(Box)`
  .vis-timeline.vis-bottom {
    border: none;
  }

  .vis-panel.vis-left,
  .vis-panel.vis-right {
    border-bottom-style: dotted;
    border-top-style: dotted;
  }

  .vis-panel.vis-bottom,
  .vis-panel.vis-center,
  .vis-panel.vis-top {
    border-left-style: dotted;
    border-right-style: dotted;
    border-bottom-style: none;
    border-top-style: dotted;
    border-top-color: transparent;
    cursor: grab;
  }

  .vis-panel.vis-top .vis-time-axis.vis-foreground {
    border-bottom: 1px dotted #bfbfbf;
  }

  .vis-item .vis-item-content {
    padding: 0;
  }

  .vis-tooltip {
    font-size: ${systemtheme.fontSizes[0]};
    font-weight: ${systemtheme.fontWeights[1]};
    text-align: center;
    background-color: ${systemtheme.colors.white};
    border: solid 1px ${systemtheme.colors.greyLight};
    box-shadow: ${systemtheme.shadows.boxDefault};
    color: ${systemtheme.colors.greyDarkest};
    border-radius: ${systemtheme.radii[1]};
    padding: ${systemtheme.space.xs} ${systemtheme.space.s};
  }

  .vis-inner small,
  .vis-major,
  .vis-minor {
    font-weight: 600;
    color: black;
    font-size: 1.4rem;
  }

  .vis-labelset,
  .vis-panel.vis-left {
    overflow: visible !important;
  }

  .vis-labelset .vis-label .vis-inner {
    width: 100%;
  }

  .vis-label.vis-group-level-0 {
    max-width: 400px;
    display: flex !important;
    align-items: center;
  }

  .vis-panel.vis-center {
    border-bottom-style: dotted;
  }

  .vis-grid.vis-vertical.vis-minor {
    border-left-style: dotted;
  }

  .vis-panel.vis-left .vis-labelset .vis-label:not(:last-child) {
    border-bottom-style: dotted;
  }

  .vis-itemset .vis-foreground .vis-group:not(:last-child) {
    border-bottom-style: dotted;
  }

  .vis-rolling-mode-btn {
    height: 30px;
    width: 30px;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    top: 0;
    right: 0;
  }
`;
