import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import PersonPropType from "src/custom-prop-types/person";
import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";
import LoadingPage from "../LoadingPage";
import Heading from "./Heading";
import PeopleList from "./PeopleList";

const PeopleListPage = ({
  people,
  totalCount,
  hasMore = false,
  searchMorePeople,
  importDate,
  loading,
  onPeopleSearchInputChanged = () => {},
}) => {
  const [formValues, updateFormValues] = useState({
    peopleSearch: "",
  });

  const debouncedChangedHandler = useMemo(
    () => debounce(onPeopleSearchInputChanged, DEBOUNCE_SEARCH_TIME),
    [onPeopleSearchInputChanged]
  );

  const onChange = (values) => {
    updateFormValues(values);
    debouncedChangedHandler(values.peopleSearch);
  };

  return (
    <>
      <Heading
        totalCount={totalCount}
        onChange={onChange}
        formValues={formValues}
        latestImportDate={importDate}
        loading={loading}
      />

      {people.length > 0 && (
        <PeopleList
          people={people}
          hasMore={hasMore}
          searchMorePeople={searchMorePeople}
        />
      )}
      {people.length === 0 && loading && (
        <LoadingPage waitingFor="peopleList" />
      )}
    </>
  );
};

PeopleListPage.propTypes = {
  people: PropTypes.arrayOf(PersonPropType).isRequired,
  hasMore: PropTypes.bool,
  totalCount: PropTypes.number.isRequired,
  searchMorePeople: PropTypes.func.isRequired,
  importDate: PropTypes.any,
  loading: PropTypes.bool.isRequired,
  onPeopleSearchInputChanged: PropTypes.func.isRequired,
};

export default PeopleListPage;
