import { Button, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const EditBtn = ({ isEditMode, onEdit }) => {
  if (isEditMode) {
    return null;
  }

  return (
    <Button
      dataTestId="edit-btn"
      iconOnly
      onClick={onEdit}
      height="l"
      width="l"
      padding="xxs"
      ariaLabel="Edit team name"
    >
      <Icon icon={["fas", "pencil"]} fontSize="0" />
    </Button>
  );
};

EditBtn.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default React.memo(EditBtn);
