import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { throwUnlessIgnored } from "src/util/errorUtil";

const InfiniteContainer = styled.div`
  position: relative;
`;
const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
`;

const InfiniteScroll = ({
  hasMore,
  loadMore,
  loader,
  children,
  scrollContainer = "window",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const onLoadMore = async () => {
    setIsLoading(true);
    try {
      await loadMore();
      setIsLoading(false);
    } catch (e) {
      throwUnlessIgnored(e);
    }
  };
  const infiniteRef = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMore,
    onLoadMore,
    scrollContainer,
  });

  return (
    <InfiniteContainer ref={infiniteRef}>
      {children}
      {isLoading && <LoaderContainer>{loader}</LoaderContainer>}
    </InfiniteContainer>
  );
};

InfiniteScroll.propTypes = {
  children: PropTypes.node,
  loader: PropTypes.node.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  scrollContainer: PropTypes.string,
};

export default InfiniteScroll;
