import { get } from "lodash";
import { Box, Flex, H3, Small } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import GroupTypeBadge from "src/components/Badge/GroupTypeBadge";
import * as placeholder from "src/components/Placeholder/PlaceholderStyles";
import GroupPropType from "src/custom-prop-types/group";
import { getGroupBgImg, isGroupVisible } from "src/util/group";
import { getTeamGradient } from "src/util/gradient";
import { getTeamsPath } from "src/util/paths";
import { getMemberCountDisplay } from "src/util/search";

import { useGlobalConfig } from "src/contexts/global/GlobalContext";
import { Block, BlockContainer, BlockLink } from "./TeamBlock.styled";

const TeamBlock = ({ group, loading, workspace }) => {
  const globalConfig = useGlobalConfig();
  const groupId = get(group, "id");
  const bgGradient = getTeamGradient(globalConfig);
  const bgImg = getGroupBgImg(group);
  const groupTypes = get(workspace, "config.groupTypes");
  if (!loading && !isGroupVisible(group)) {
    return null;
  }

  const memberCount = getMemberCountDisplay(group.memberCount);

  return (
    <BlockContainer>
      <Block
        data-testid="cp-explore-team-block-panel"
        bgImg={bgImg}
        bgGradient={bgGradient}
        loading={loading}
        p="l"
        width="100%"
        height="100%"
        minHeight="200px"
      >
        {groupId && (
          <BlockLink
            aria-label={`Navigate to ${group.name}`}
            to={getTeamsPath(groupId)}
          />
        )}
        <Flex alignItems="flex-start" justifyContent="space-between">
          <Box
            p="r"
            display="inline-block"
            bg={loading ? "white20" : "black70"}
          >
            {loading && (
              <placeholder.Line
                data-testid="cp-explore-team-block-title-placeholder"
                width={120}
                height={40}
              />
            )}
            {!loading && (
              <>
                <Flex alignItems="center" flexWrap="wrap">
                  <H3
                    mr="s"
                    data-testid="cp-explore-team-block-title"
                    color="white"
                    breakWord
                  >
                    {group.name}
                  </H3>
                  <Box my="2px">
                    <GroupTypeBadge group={group} groupTypes={groupTypes} />
                  </Box>
                </Flex>
                <Small color="white">{memberCount}</Small>
              </>
            )}
          </Box>
        </Flex>
      </Block>
    </BlockContainer>
  );
};

TeamBlock.propTypes = {
  group: PropTypes.oneOfType([GroupPropType, PropTypes.number]), // number for placeholder
  loading: PropTypes.bool,
  workspace: PropTypes.object,
};

export default TeamBlock;
