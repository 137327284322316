import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { filter } from "lodash";
import { Grid } from "orcs-design-system";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";
import HistoryFilters from "./HistoryFilters";
import HistoryRecordList from "./HistoryRecordList";
import * as TYPES from "./consts";

const getInitialFilters = () => {
  return {
    [TYPES.TYPE_MEMBERSHIPS]: true,
    [TYPES.TYPE_ALLOCATION]: true,
    [TYPES.TYPE_OBJECTIVES]: true,
    [TYPES.TYPE_PLANNING]: true,
    [TYPES.TYPE_FORECAST]: true,
    [TYPES.TYPE_SUPPLY_UPDATE]: true,
    [TYPES.TYPE_TAGGING]: true,
    [TYPES.TYPE_DOWNLOAD]: true,
    [TYPES.TYPE_GROUP_UPDATE]: true,
    [TYPES.TYPE_PERSON_UPDATE]: true,
  };
};

const History = ({ historyRecords }) => {
  const [filters, setFilter] = useState(() => getInitialFilters());

  const onFilterChange = useCallback(
    (type) => () => {
      setFilter((f) => ({ ...f, [type]: !f[type] }));
      trackEvent(EVENT_TRACKING.SETTING_CHANGED, {
        setting: "history_filter_by_type",
        value: type,
      });
    },
    []
  );

  // Only display if history item doesn't have a type, or the type of filters is true
  const filteredRecords = filter(historyRecords, (h) => filters[h.type]);

  return (
    <Grid
      gridTemplateColumns="320px 1fr"
      gridAutoFlow="row"
      gridGap="r"
      minHeight="0"
      height="100%"
      flex="1 0 auto"
    >
      <HistoryFilters filters={filters} onFilterChange={onFilterChange} />
      <HistoryRecordList historyRecords={filteredRecords} />
    </Grid>
  );
};

History.propTypes = {
  historyRecords: PropTypes.array.isRequired,
};

export default History;
