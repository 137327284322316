import React, { useMemo } from "react";
import { Box, Flex, Spacer } from "orcs-design-system";

import { sample } from "lodash";
import { Circle, Line } from "src/components/Placeholder/PlaceholderStyles";
import { SourceHandle, StyledCard, TargetHandle } from "../node.styled";

const PersonNodeSkeleton = () => {
  const randomNameLength = useMemo(() => sample([60, 80, 100, 120]), []);

  return (
    <StyledCard width="auto" minWidth="350px" maxWidth="450px" cursor="grab">
      <TargetHandle $hidden type="target" position="top" />
      <Spacer mb="s">
        <Flex alignItems="flex-start" justifyContent="space-between">
          <Flex alignItems="center">
            <Box mr="s">
              <Circle width={36} height={36} />
            </Box>
            <Flex alignItems="flex-start" flexDirection="column" gap="4px">
              <Flex alignItems="center" gap="xs">
                <Line width={randomNameLength} height={18} />
                <Line width={20} height={20} />
              </Flex>
              <Line width={30} height={16} />
            </Flex>
          </Flex>
        </Flex>
      </Spacer>
      <Flex gap="xs">
        <Line width={30} height={16} />
        <Line width={60} height={16} />
      </Flex>

      <SourceHandle $hidden type="source" position="bottom" />

      <TargetHandle $hidden type="target" position="top" />
    </StyledCard>
  );
};

export default PersonNodeSkeleton;
