/* eslint-disable react/prop-types */
import React from "react";
import { Card, Small, Text, Flex, FlexItem, Icon } from "orcs-design-system";
import { find, defaultTo, get, toUpper, round } from "lodash";
import { numberToLocaleString } from "src/util/toLocaleString";
import FeedbackEmoji from "src/components/FeedbackEmoji/FeedbackEmoji";
import DragIcon from "./DragIcon";
import HelpIcon from "./HelpIcon";

const SingleStatCard = ({
  icon,
  title,
  dataKey,
  insights: { singleStats = {} } = {},
  loading,
  compare,
  helpContent,
}) => {
  const insightOfInterest = defaultTo(find(singleStats, { key: dataKey }), {});

  const createFlexItem = (insightOfInterestLoc, key) => {
    const comparisons = defaultTo(insightOfInterestLoc.comparisons, {});
    const mainValue = round(defaultTo(get(insightOfInterestLoc, key, 0), 0), 1);
    const comparisonValue = round(
      defaultTo(get(comparisons, key, mainValue), mainValue),
      1
    );

    const displayChange = (comparisonValueLoc, value) => {
      const diff = value - comparisonValueLoc;
      if (diff === 0) {
        return "No change";
      }
      const percentage = round((value / comparisonValueLoc) * 100 - 100, 1);
      return isNaN(percentage) || percentage === Infinity
        ? `${numberToLocaleString(diff, { decimalplaces: 1 })}`
        : `${numberToLocaleString(diff, {
            decimalplaces: 1,
          })}${" "}(${percentage}%)`;
    };

    if (loading) {
      return (
        <FlexItem>
          <Small display="block" uppercase color="greyDark" textAlign="center">
            {toUpper(key)}
          </Small>
          <Text
            display="block"
            fontSize="4rem"
            fontWeight="300"
            textAlign="center"
          />
        </FlexItem>
      );
    }

    return (
      <FlexItem>
        <Small display="block" uppercase color="greyDark" textAlign="center">
          {toUpper(key)}
        </Small>
        <Text
          display="block"
          fontSize="4rem"
          fontWeight="300"
          textAlign="center"
        >
          {!loading && numberToLocaleString(mainValue)}
        </Text>
        {compare ? (
          <Small display="block" color="greyDark" textAlign="center">
            {mainValue > comparisonValue ? (
              <Icon icon={["fal", "arrow-up"]} />
            ) : mainValue < comparisonValue ? (
              <Icon icon={["fal", "arrow-down"]} />
            ) : null}{" "}
            {displayChange(comparisonValue, mainValue)}
          </Small>
        ) : null}
      </FlexItem>
    );
  };

  const subtitle = (
    <Flex alignItems="center">
      <HelpIcon content={helpContent} />
      <DragIcon />
    </Flex>
  );

  return (
    <Card fluid alternate icon={icon} title={title} subtitle={subtitle}>
      <Flex justifyContent="space-around" alignItems="center">
        {createFlexItem(insightOfInterest, "min")}
        {createFlexItem(insightOfInterest, "avg")}
        {createFlexItem(insightOfInterest, "max")}
      </Flex>
      <FeedbackEmoji contextId={`SummaryPage-${title}-${dataKey}`} />
    </Card>
  );
};

export default SingleStatCard;
