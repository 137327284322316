import React, { useCallback, useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import moment from "moment";
import { isEmpty, keyBy, difference, uniq } from "lodash";

import {
  useGroupTypes,
  useWorkspaceTagConfig,
} from "src/contexts/global/WorkspaceContext";
import { API_DATE_FORMAT } from "src/consts/global";
import { getDirectTeamCollectionId } from "../hooks/useOrgChartNodesAndEdges/utils";
import useUpdateTeamStoreListener from "../hooks/useUpdateTeamStoreListener";
import useUpdateMemberListener from "../hooks/useUpdateMemberListener";
import useGetTeamsForOrgChart, {
  ORG_CHART_STATE_KEYS,
} from "../hooks/useGetTeamsForOrgChart";
import useGetActiveAllocationProjectForOrgChart from "../hooks/useGetActiveAllocationProjectForOrgChart";
import OrgChartContext from "./OrgChartContext";
import { orgChartReducer, getInitialOrgChartState } from "./reducer";
import { ACTION_TYPES } from "./actionTypes";
import { getDiffs } from "./utils/getCalculatedState";

const mergeWithPrevState = (prev, next, isLoading = false) => {
  const mergedTeams = Object.values(next?.teams || {})
    .filter((t) => t?.id)
    .map((team) => {
      const prevTeamData = prev.teams[team?.id] || {};
      return {
        ...prevTeamData, // get old data if exists
        childTeams: [...(team?.childTeams || prevTeamData?.childTeams || [])],
        ...team, // merge new data
        // This is called when data is fetched so we can set this to false
        isLoading,
      };
    });

  const mergedMembers = Object.values(next?.members || {}).map((member) => {
    return {
      ...(prev.members[member?.aggregateId] || {}), // get old data if exists
      ...member, // merge new data
    };
  });

  return {
    teams: { ...prev.teams, ...keyBy(mergedTeams, "id") },
    members: { ...prev.members, ...keyBy(mergedMembers, "aggregateId") },
  };
};

const OrgChartContextProvider = ({ teamId, children }) => {
  const tagConfig = useWorkspaceTagConfig();
  const groupTypes = useGroupTypes();

  const activeAllocationProject = useGetActiveAllocationProjectForOrgChart();
  const baselineDate = moment(activeAllocationProject?.baselineDate).format(
    API_DATE_FORMAT
  );

  const [state, setState] = useState(
    getInitialOrgChartState({ tagConfig, teamId })
  );

  const dispatch = useCallback((action) => {
    setState((prevState) => orgChartReducer(prevState, action));
  }, []);

  const toggleExpandedNode = useCallback((targetTeamId, newCurrentViewId) => {
    setState((prevState) => {
      if (newCurrentViewId) {
        return { ...prevState, expandedNodes: [newCurrentViewId] };
      }

      const isRemoving = prevState.expandedNodes.includes(targetTeamId);
      let expandedNodes = [...prevState.expandedNodes, targetTeamId];

      const getChildTeamsRecusively = (id) => {
        const node = prevState.teams[id];
        if (!node) {
          return [];
        }

        return node.childTeams
          .map((child) => [child.id, ...getChildTeamsRecusively(child.id)])
          .flat();
      };

      if (isRemoving) {
        const childTeamIds = getChildTeamsRecusively(targetTeamId);

        const nodesToCollapse = [...childTeamIds, targetTeamId]
          .map((id) => [id, getDirectTeamCollectionId(id)])
          .flat();

        expandedNodes = prevState.expandedNodes.filter(
          (id) => !nodesToCollapse.includes(id)
        );
      }
      return { ...prevState, expandedNodes };
    });
  }, []);

  const normalData = useGetTeamsForOrgChart({
    pitDate: null,
    teamId,
    setState,
    stateKey: ORG_CHART_STATE_KEYS.NORMAL,
    state,
  });

  const baselineData = useGetTeamsForOrgChart({
    pitDate: baselineDate,
    teamId,
    setState,
    stateKey: ORG_CHART_STATE_KEYS.DIFF_BASELINE,
    state,
  });

  const nextData = useGetTeamsForOrgChart({
    pitDate: state.viewOptions?.toDate?.format(API_DATE_FORMAT),
    teamId,
    setState,
    stateKey: ORG_CHART_STATE_KEYS.DIFF_NEXT,
    state,
  });

  const mergePartialData = useCallback(
    (payload) => dispatch({ type: ACTION_TYPES.MERGE_PARTIAL_DATA, payload }),
    [dispatch]
  );

  useUpdateMemberListener({ mergePartialData });
  useUpdateTeamStoreListener({ state, mergePartialData });

  const loadSpecificTeamDataById = useCallback(
    async (id, currentViewTeamId) => {
      // Show loading state for the team that is being loaded
      setState((prevState) => {
        if (!prevState.teams[id]) {
          const loadingState = {
            teams: { [id]: { id, childTeams: [] } },
          };

          const { teams, members } = mergeWithPrevState(
            prevState,
            loadingState,
            true
          );

          const currentViewTeam =
            teams?.[currentViewTeamId] || prevState.currentViewTeam;

          return {
            ...prevState,
            teams,
            members,
            expandedNodes: [...prevState.expandedNodes, id],
            currentViewTeam,
          };
        }

        return {
          ...prevState,
          expandedNodes: [...prevState.expandedNodes, id],
        };
      });

      const [normal, baseline, next] = await Promise.all([
        normalData?.loadSpecificTeamDataById(id),
        baselineData?.loadSpecificTeamDataById(id),
        nextData?.loadSpecificTeamDataById(id),
      ]);

      if (!isEmpty(normal) && isEmpty(baseline) && isEmpty(next)) {
        setState((prevState) => {
          const { teams, members } = mergeWithPrevState(prevState, normal);

          const currentViewTeam =
            teams?.[currentViewTeamId] || prevState.currentViewTeam;

          const nextState = {
            ...prevState,
            teams,
            members,
            expandedNodes: [...prevState.expandedNodes, id],
            currentViewTeam,
          };

          return nextState;
        });
        return;
      }

      // loadMissingTeamsForDiff
      // Refactor duplicate code/logic
      let extraBaselineTeams = {};
      let extraNextTeams = {};

      let extraBaselineMembers = {};
      let extraNextMembers = {};

      const baselineIds = Object.keys(baseline.teams);
      const nextIds = Object.keys(next.teams);

      const baselineDiff = difference(baselineIds, nextIds);
      const nextDiff = difference(nextIds, baselineIds);
      const missingTeamIds = uniq([...baselineDiff, ...nextDiff]);

      const baselineMemberIds = Object.keys(baseline.members);
      const nextMemberIds = Object.keys(next.members);

      const baselineMembersDiff = difference(baselineMemberIds, nextMemberIds);
      const nextMembersDiff = difference(nextMemberIds, baselineMemberIds);
      const missingMemberIds = uniq([
        ...baselineMembersDiff,
        ...nextMembersDiff,
      ]);

      if (missingMemberIds.length) {
        const [missingBaselineMembers, missingNextMembers] = await Promise.all([
          baselineData?.loadMissingMembersForDiff(missingMemberIds),
          nextData?.loadMissingMembersForDiff(missingMemberIds),
        ]);

        extraBaselineMembers = keyBy(missingBaselineMembers, "aggregateId");
        extraNextMembers = keyBy(missingNextMembers, "aggregateId");
      }

      if (missingTeamIds.length) {
        const [missingBaselineTeams, missingNextTeams] = await Promise.all([
          baselineData?.loadMissingTeamsForDiff(missingTeamIds),
          nextData?.loadMissingTeamsForDiff(missingTeamIds),
        ]);

        extraBaselineTeams = keyBy(missingBaselineTeams, "id");
        extraNextTeams = keyBy(missingNextTeams, "id");
      }

      const { teams, members } = getDiffs(
        {
          ...baseline,
          teams: { ...baseline.teams, ...extraBaselineTeams },
          members: { ...baseline.members, ...extraBaselineMembers },
        },
        {
          ...next,
          teams: { ...next.teams, ...extraNextTeams },
          members: { ...next.members, ...extraNextMembers },
        },
        state?.teams,
        state?.members
      );

      setState((prevState) => {
        const nextTeams = { ...prevState.teams, ...teams };
        const currentViewTeam =
          teams?.[currentViewTeamId] || prevState.currentViewTeam;

        const nextState = {
          ...prevState,
          teams: nextTeams,
          members: { ...prevState.members, ...members },
          expandedNodes: [...prevState.expandedNodes, id],
          currentViewTeam,
        };

        return nextState;
      });
    },
    [normalData, baselineData, nextData, state?.members, state?.teams]
  );

  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        teams: {},
        members: {},
        expandedNodes: [teamId],
      };
    });
  }, [state?.viewOptions?.showChanges, teamId]);

  useEffect(() => {
    loadSpecificTeamDataById(teamId, teamId);
    /**
     * HACK: This is a hack to load the team data when the teamId changes between page changes.
     * If I add loadSpecificTeamDataById to the dependencies, it will cause an infinite loop.
     * It works like this so don't touch it otherwise it will break the org chart.
     *  */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, state?.viewOptions?.showChanges]);

  const refreshTeams = useCallback(async () => {
    return loadSpecificTeamDataById(teamId);
  }, [loadSpecificTeamDataById, teamId]);

  return (
    <OrgChartContext.Provider
      value={{
        groupTypes,
        state,
        dispatch,
        toggleExpandedNode,
        mergePartialData,
        teamId,
        refreshTeams,
        setState,
        loadSpecificTeamDataById,
        activeAllocationProject,
      }}
    >
      {children}
    </OrgChartContext.Provider>
  );
};

OrgChartContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  teamId: PropTypes.string,
};

export default OrgChartContextProvider;
