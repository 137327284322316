/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import PropTypes from "prop-types";
import { Card } from "orcs-design-system";
import { replace, map, get, round, lowerCase, pick, has, isNil } from "lodash";

import pluralize from "pluralize";
import { numberToLocaleString } from "src/util/toLocaleString";

const fixupLegacyImportMetrics = (metrics) => {
  if (!metrics) {
    return null;
  }
  const beforeStats = metrics.beforeStats || {
    total: metrics.before,
    active: metrics.before,
  };
  const afterStats = metrics.afterStats || {
    total: metrics.after,
    active: metrics.after,
  };
  // add processed in from sum of written/skipped/errored if processed is not there
  const processed =
    metrics.processed ||
    metrics.written ||
    0 + metrics.skipped ||
    0 + metrics.errored ||
    0;
  return {
    ...metrics,
    beforeStats,
    afterStats,
    processed,
  };
};

const absoluteChange = (after, before) => {
  if (isNil(after) || isNil(before)) {
    return "";
  }
  return numberToLocaleString(Math.abs(before - after));
};

const percentageChange = (before, after) => {
  const change = after - before;
  if (change === 0) {
    return "no change";
  }
  if (before === 0) {
    return "initial import";
  }
  return `${round((after / before - 1) * 100, 1)}%`;
};

const changeIcon = (before, after) => {
  if (before === after) {
    return "dash";
  }
  if (after > before) {
    return "arrowUp";
  }
  return "arrowDown";
};

const changeColour = (before, after, failedCount) => {
  if (failedCount > 0) {
    return "";
  }
  if (before === after) {
    return "primary";
  }
  return "success";
};

const totalsForComparison = (metrics) => {
  const hasActiveMetrics =
    !isNil(get(metrics, "beforeStats.active")) &&
    !isNil(get(metrics, "afterStats.active"));

  if (hasActiveMetrics) {
    return [
      get(metrics, "beforeStats.active"),
      get(metrics, "afterStats.active"),
    ];
  }
  return [
    get(metrics, "beforeStats.total", 0),
    get(metrics, "afterStats.total", 0),
  ];
};

const importStatsDelta = (beforeValue, value) => {
  if (isNil(beforeValue) || value === beforeValue) {
    return "";
  }
  const delta = value - beforeValue;
  const sign = delta > 0 ? "+" : "-";
  return `(${sign}${Math.abs(delta)})`;
};
const ImportMetricValue = ({
  value,
  beforeValue,
  entityName,
  displayLabel,
}) => {
  if (isNil(value)) {
    return null;
  }
  return (
    <li>
      <strong> {numberToLocaleString(value)}</strong>{" "}
      {pluralize(lowerCase(entityName), value)} {displayLabel}{" "}
      {importStatsDelta(beforeValue, value)}
    </li>
  );
};

ImportMetricValue.propTypes = {
  entityName: PropTypes.string.isRequired,
  displayLabel: PropTypes.string,
  value: PropTypes.number,
  beforeValue: PropTypes.number,
};

const DISPLAY_METRIC_KEYS = ["processed", "written", "failed", "skipped"];
const DISPLAY_METRIC_LABELS = [
  "to be imported",
  "created/updated",
  "failed",
  "skipped",
];
const DISPLAY_DELTA_METRIC_KEYS = ["active", "inactive"];
const DISPLAY_DELTA_METRIC_LABELS = ["active", "inactive/hidden"];

const ItemImportMetrics = ({ metrics: metricsRaw, entityName }) => {
  const metrics = fixupLegacyImportMetrics(metricsRaw);
  const { failed } = metrics;
  const entityDisplayName = replace(entityName, /_/g, " ");
  const [before, after] = totalsForComparison(metrics);
  const hasChanged = before !== after;
  return (
    <Card
      alternate
      boxBorder="default"
      mb="r"
      icon={["far", "users"]}
      title={`${numberToLocaleString(after)} ${entityDisplayName} records`}
      changeValue={`${absoluteChange(before, after)} (${percentageChange(
        before,
        after
      )})`}
      changeIcon={changeIcon(before, after)}
      colour={changeColour(before, after, failed)}
    >
      <ul>
        {map(DISPLAY_METRIC_KEYS, (key, i) => {
          const metricValue = get(metrics, key);
          const displayLabel = DISPLAY_METRIC_LABELS[i];
          return (
            <ImportMetricValue
              key={key}
              value={metricValue}
              displayLabel={displayLabel}
              entityName={entityDisplayName}
            />
          );
        })}
      </ul>
      {hasChanged && has(metrics, "beforeStats.active") && (
        <>
          <p>After running the import:</p>
          <ul>
            {map(DISPLAY_DELTA_METRIC_KEYS, (key, i) => {
              const metricValue = get(metrics.afterStats, key);
              const beforeValue = get(metrics.beforeStats, key);
              const displayLabel = DISPLAY_DELTA_METRIC_LABELS[i];
              return (
                <ImportMetricValue
                  key={key}
                  value={metricValue}
                  entityName={entityDisplayName}
                  beforeValue={beforeValue}
                  displayLabel={displayLabel}
                />
              );
            })}
          </ul>
        </>
      )}
    </Card>
  );
};
ItemImportMetrics.propTypes = {
  entityName: PropTypes.string.isRequired,
  metrics: PropTypes.object.isRequired,
};

const ImportMetrics = ({ metrics, datasourceType }) => {
  const peopleMetrics = get(metrics, "people");

  const legacyTeamMetrics = get(metrics, "legacyTeamMetrics");
  // adapt legacy data structure of metrics.teams to metrics.groups.team unless metrics.groups already there
  const groupMetrics = pick(
    get(metrics, "groups", { team: legacyTeamMetrics }),
    ["team", "externally_defined_team", "division", "objective"]
  );
  return (
    <>
      {peopleMetrics && peopleMetrics.processed > 0 && (
        <ItemImportMetrics
          metrics={peopleMetrics}
          entityName="People"
          datasourceType={datasourceType}
          icon={["far", "users"]}
        />
      )}
      {map(
        groupMetrics,
        (groupKindMetric, kind) =>
          groupKindMetric && (
            <ItemImportMetrics
              key={kind}
              metrics={groupKindMetric}
              entityName={kind}
              datasourceType={datasourceType}
              icon={["far", "users"]}
            />
          )
      )}
    </>
  );
};
ImportMetrics.propTypes = {
  datasourceType: PropTypes.string.isRequired,
  metrics: PropTypes.object,
};
export default ImportMetrics;
