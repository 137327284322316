import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Box, Divider } from "orcs-design-system";

import FancyCard, {
  FancyCardBody,
  FancyCardFooter,
} from "src/components/FancyCard/FancyCard";

import Settings, { Copywriting } from "./Settings";

const getNotification = (notification) => {
  const { message, colour, isActive, visualTreatment } = notification || {};
  return {
    message: message || "",
    colour: colour || "",
    isActive: isActive || false,
    visualTreatment: visualTreatment || "",
  };
};

const GlobalNotificationSettings = ({
  notifications,
  onSave,
  isSaving,
  children,
  global = true,
}) => {
  const { mapperNotification = {}, directoryNotification = {} } =
    notifications || {};

  const [mapperData, setMapperData] = useState(
    getNotification(mapperNotification)
  );
  const [directoryData, setDirectoryData] = useState(
    getNotification(directoryNotification)
  );

  const handleOnSave = () => {
    onSave({ mapperData, directoryData });
  };

  return (
    <FancyCard height="100%">
      <FancyCardBody>
        <Box>
          <Copywriting global={global} />
          <Grid gridTemplateColumns="1fr 1fr" gridGap="r">
            <Box pb="r" pr="r" borderRight="1px solid rgba(0, 0, 0, 0.2)">
              <Settings
                title="ADMIN/POWER role user announcement"
                notification={mapperData}
                updateNotification={setMapperData}
              />
            </Box>
            <Box pb="r">
              <Settings
                title="DIRECTORY role user announcement"
                notification={directoryData}
                updateNotification={setDirectoryData}
              />
            </Box>
          </Grid>
        </Box>
        <Divider />
        {children}
      </FancyCardBody>
      <FancyCardFooter>
        <Button
          onClick={handleOnSave}
          isLoading={isSaving}
          disabled={isSaving}
          variant={isSaving ? "disabled" : "success"}
        >
          Save changes
        </Button>
      </FancyCardFooter>
    </FancyCard>
  );
};

GlobalNotificationSettings.propTypes = {
  notifications: PropTypes.object,
  onSave: PropTypes.func,
  isSaving: PropTypes.bool,
  global: PropTypes.bool,
  children: PropTypes.node,
};

export default GlobalNotificationSettings;
