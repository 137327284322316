import React from "react";
import { filter, get, map } from "lodash";
import { Box, Flex, H5, P } from "orcs-design-system";
import PropTypes from "prop-types";
import { copywriting } from "src/pages/PersonDetailPage/PersonDetailPage.config";
import { numberToLocaleString } from "src/util/toLocaleString";
import {
  getPersonEmailAttribute,
  getPersonMobileNumberAttribute,
  getPersonTelephoneAttribute,
} from "src/util/person";

const getPersonDetails = (person) => {
  const personDetails = [
    {
      label: copywriting.Employed_by,
      value: person.company,
    },
    {
      label: copywriting.Location,
      value: person.address,
    },
    {
      label: copywriting.Employee_ID,
      value: get(person, "employeeId") || "No Employee ID",
    },
    {
      label: copywriting.FTE,
      value: numberToLocaleString(get(person, "fte"), {
        defaultValue: "No FTE",
      }),
    },
    {
      label: copywriting.Email,
      value: getPersonEmailAttribute(person)?.value,
    },
    {
      label: copywriting.Phone,
      value:
        getPersonMobileNumberAttribute(person) ||
        getPersonTelephoneAttribute(person),
    },
  ];

  // create all possible person details and then filter out those without values
  return filter(personDetails, (detail) => detail.value);
};

export const Details = ({ person }) => {
  const details = React.useMemo(() => getPersonDetails(person), [person]);

  return (
    <Flex flexDirection="column" gap="s">
      {map(details, (detail) => {
        return (
          <Box key={detail.label}>
            <H5 sizing="small" weight="bold" color="greyDark">
              {detail.label}
            </H5>
            <P>{detail.value}</P>
          </Box>
        );
      })}
    </Flex>
  );
};

Details.propTypes = {
  person: PropTypes.object,
};
