import React from "react";
import {
  VisAxis,
  VisStackedBar,
  VisTooltip,
  VisXYContainer,
} from "@unovis/react";
import styled from "styled-components";
import { Box } from "orcs-design-system";
import { StackedBar } from "@unovis/ts";
import PropTypes from "prop-types";

const CustomBox = styled(Box)`
  width: 100%;
  position: relative;
  &:before {
    content: "";
    position: absolute;
  }
`;

const SMALL_SIZE = 10;

export default function StackedBarChart({
  data,
  yKey = "value",
  yLabel,
  xLabel,
  tooltipTemplate,
}) {
  const isSmallScreen =
    typeof window !== "undefined" && window.innerWidth < 768;

  const dynamicHeight = isSmallScreen ? data.length * 10 : data.length * 25;
  return (
    <CustomBox>
      <VisXYContainer
        height={dynamicHeight > 400 ? dynamicHeight : 400}
        yDirection="south"
      >
        <VisStackedBar
          color={["rgba(0, 123, 199, 0.7)"]}
          data={data}
          barWidth={data.length > SMALL_SIZE ? 20 : undefined}
          x={(d, i) => i}
          y={(d) => d[yKey]}
          orientation="horizontal"
        />
        <VisTooltip
          triggers={{
            [StackedBar.selectors.bar]: tooltipTemplate,
          }}
        />
        <VisAxis type="x" gridLine={true} label={xLabel} />
        <VisAxis
          tickTextFitMode="trim"
          type="y"
          label={isSmallScreen ? null : yLabel}
          numTicks={data.length - 1}
          tickFormat={(_, i) => data[i]?.label.replace("&", "and")}
          tickTextForceWordBreak={true}
          gridLine={false}
          tickTextWidth={150}
        />
      </VisXYContainer>
    </CustomBox>
  );
}

StackedBarChart.propTypes = {
  tooltipTemplate: PropTypes.func,
  yKey: PropTypes.string,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      comparisonValue: PropTypes.number,
      group: PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string,
        id: PropTypes.string,
        parentIds: PropTypes.arrayOf(PropTypes.string),
        hierarchy: PropTypes.string,
      }),
    })
  ).isRequired,
};
