/* eslint-disable react/prop-types */
import { get, concat } from "lodash";
import { Modal, Box, Spacer, P } from "orcs-design-system";
import React, { useState, useCallback, useMemo } from "react";

import GroupBadge from "src/components/Badge/GroupBadge";

import ErrorNotification from "../ErrorNotification";

import FulfillVacantRoleSearchInput from "./components/FulfillVacantRoleSearchInput";
import { getSearchFilter } from "./FulfillVacantRoleModal.logic";
import useFulfillVacantRole from "./hooks/useFulfillVacantRole";
import ModalFooter from "./ModalFooter";
import ModalHeader from "./ModalHeader";

const FulfillVacantRoleModal = ({
  isOpen,
  targetGroupId,
  sourceGroup,
  allocationProjectId,
  groupTypes,
  onHideModal,
  onPersonSelect,
  person,
  isDemandTeam,
  vacantRoleLabel,
}) => {
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [allocatingPerson, setAllocatingPerson] = useState(false);
  const [membersAdded, setMembersAdded] = useState([]);
  const [error, setError] = useState(null);

  const handleHideModal = useCallback(() => {
    setSelectedPerson(null);
    setAllocatingPerson(false);
    setMembersAdded([]);
    setError(null);
    onHideModal();
  }, [onHideModal]);

  const fulfillVacantRole = useFulfillVacantRole();

  const searchFilter = useMemo(() => {
    return getSearchFilter(sourceGroup, groupTypes);
  }, [sourceGroup, groupTypes]);

  const onClickFulfill = useCallback(async () => {
    setError(null);
    try {
      setAllocatingPerson(true);
      await fulfillVacantRole({
        placeholderId: person.aggregateId,
        allocationProjectId,
        personId: selectedPerson.aggregateId,
      });
      setMembersAdded(concat(membersAdded, [selectedPerson]));
      setAllocatingPerson(false);
      onPersonSelect();
      onHideModal();
    } catch (e) {
      setError(e);
    }
  }, [
    fulfillVacantRole,
    person.aggregateId,
    allocationProjectId,
    selectedPerson,
    membersAdded,
    onPersonSelect,
    onHideModal,
  ]);

  const onPersonSelected = useCallback(async (optionValue) => {
    const personSelected = get(optionValue, "person");
    if (!personSelected) {
      return;
    }
    setSelectedPerson(personSelected);
  }, []);

  const hasSourceGroup = !!sourceGroup;

  return (
    <Modal
      data-testid="add-member-modal"
      visible={isOpen}
      height="30vh"
      width="40vw"
      maxHeight="90vh"
      maxWidth="90vw"
      onClose={handleHideModal}
      overflow="visible"
      headerContent={
        <ModalHeader vacantRoleLabel={vacantRoleLabel} person={person} />
      }
      footerContent={
        <ModalFooter
          allocatingPerson={allocatingPerson}
          selectedPerson={selectedPerson}
          onClickFulfill={onClickFulfill}
          handleHideModal={handleHideModal}
        />
      }
    >
      <Box mt="s">
        <P mb="s">
          Search and select individual
          {hasSourceGroup && (
            <>
              {" "}
              from <GroupBadge group={sourceGroup} groupTypes={groupTypes} />
            </>
          )}{" "}
          to fulfil this role:
        </P>

        <FulfillVacantRoleSearchInput
          targetGroupId={targetGroupId}
          autoSearch={hasSourceGroup}
          searchFilter={searchFilter}
          onPersonSelected={onPersonSelected}
          groupTypes={groupTypes}
          disableCache={!isDemandTeam}
        />
        {error && (
          <Spacer my="l">
            <ErrorNotification
              error={error}
              message={error.message}
              closable={false}
              report={false}
            />
          </Spacer>
        )}
      </Box>
    </Modal>
  );
};

export default FulfillVacantRoleModal;
