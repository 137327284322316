import moment from "moment";

import { fullDisplayName } from "../../util/personName";
import getPersonQuery from "./getPerson.graphql";
import getTeamWithDescendantsQuery from "./getTeamWithDescendants.graphql";
import getTeamQuery from "./getTeam.graphql";

export const isHeadingDisplayed = (heading, subject, isResetClear) =>
  !isResetClear && subject && subject.__typename === heading;

export const isPersonType = (type) => type.toLowerCase() === "person";

export const isValidImportDate = (date) =>
  date && moment(date) && moment(date).isValid();

export const getDateFromNow = (date) => moment(date).fromNow();

export const getFormattedDate = (date) =>
  moment(date).format("hh:mma DD/MM/YYYY");

export const getFormattedPeopleData = (people) =>
  people.map((person) => ({
    label: fullDisplayName(person),
    value: person.aggregateId,
  }));

export const getFormattedSearchTerms = (inputValue) =>
  typeof inputValue === "string" ? inputValue.trim().split(" ") : [];

export const getHighlightedNode = (location) =>
  location.state &&
  location.state.visualisation &&
  location.state.visualisation.highlight;

export const getSelectedNode = (aggregateId, pageType) => {
  if (!aggregateId) {
    return {
      id: null,
      type: null,
    };
  }
  return {
    id: aggregateId,
    type: pageType.toLowerCase(),
  };
};

export const getVisualisationPageQuery = (type, isShowDescendants) => {
  if (isPersonType(type)) {
    return getPersonQuery;
  }

  if (isShowDescendants) {
    return getTeamWithDescendantsQuery;
  }

  return getTeamQuery;
};

export const getVisualisationPageQueryAndVariables = (
  type,
  searchTerm,
  isShowDescendants
) => {
  const queryParam = isPersonType(type) ? "aggregateId" : "groupId";
  return {
    query: getVisualisationPageQuery(type, isShowDescendants),
    variables: {
      [queryParam]: searchTerm,
    },
  };
};
