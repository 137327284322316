import React from "react";
import { TabsAlt, Box } from "orcs-design-system";
import { map, capitalize } from "lodash";

export const ADMIN_DASHBOARD_TABS = {
  BRANDING: "branding",
  GLOBAL_NOTIFICATION: "tenant-announcements",
  TENANT_FEATURE_FLAGS: "tenant-feature-flags",
};

export const GlobalAdminTabs = () => {
  return (
    <Box mb="r" boxBorder="default" borderTop="none" borderBottom="none">
      <TabsAlt
        tabsList={map(ADMIN_DASHBOARD_TABS, (tab) => {
          const tabName = capitalize(tab.replace(/-/g, " "));
          return {
            label: tabName,
            path: tab,
          };
        })}
      />
    </Box>
  );
};
