import { Flex, Spacer, TextInput, Toggle } from "orcs-design-system";
import React from "react";
import { PropTypes } from "prop-types";

const SlackConfigInput = ({ onChange, value }) => {
  return (
    <Flex alignItems="center">
      <Spacer mr="r">
        <Toggle
          id="slack-enabled-toggle"
          label="Slack"
          checked={value?.enabled}
          onChange={(e) => onChange({ ...value, enabled: e.target.checked })}
        />
        <TextInput
          value={value.workspaceName}
          placeholder="Enter workspace name"
          onChange={(e) =>
            onChange({ ...value, workspaceName: e.target.value })
          }
        />
      </Spacer>
    </Flex>
  );
};

SlackConfigInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.objectOf({
    enabled: PropTypes.bool,
    workspaceName: PropTypes.string,
  }),
};

export default SlackConfigInput;
