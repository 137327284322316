import { useApolloClient } from "@apollo/client";
import themeGet from "@styled-system/theme-get";
import { get, isEmpty, filter, join } from "lodash";
import { Box, H5, P, Spacer, Expandable } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import styled from "styled-components";

import PersonContactDetails from "src/components/PersonContactDetails";
import Panel from "src/components/Panel";
import PersonItem from "src/components/Person/PersonItem";
import * as placeholder from "src/components/Placeholder/PlaceholderStyles";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import PersonPropType from "src/custom-prop-types/person";
import { copywriting } from "src/pages/PersonDetailPage/PersonDetailPage.config";
import personTagsFragment from "src/queries/fragments/personTagsFragment.graphql";
import TagsEditorContainer from "src/components/TagsEditor";
import { getSupplyBadgeGroups, HIERARCHY_SEPARATOR } from "src/util/group";
import { isValidPerson, fullLegalName, getName } from "src/util/person";
import { isTagTypeReadOnly } from "src/util/tags";
import { numberToLocaleString } from "src/util/toLocaleString";

import useHandleEntityTagClick from "src/hooks/useHandleEntityTagClick";
import Bands from "./Bands";

const CustomExpandable = styled(Expandable)`
  > button {
    background: transparent;
    padding: 0;
    padding-top: ${themeGet("space.r")};
    border: 0;
    border-top: solid 1px ${themeGet("colors.greyLighter")};
    border-radius: 0;
    &:before,
    &:after {
      left: 95px;
      top: 50%;
      margin-top: 7px;
    }
    h4 {
      transition: ${themeGet("transition.transitionDefault")};
    }
    &:hover,
    &:focus {
      background: transparent;
      border: 0;
      border-top: solid 1px ${themeGet("colors.greyLighter")};
      &:before,
      &:after {
        background: ${themeGet("colors.primary")};
      }
      h4 {
        color: ${themeGet("colors.primary")};
      }
    }
  }
  > div {
    padding: 0;
    overflow: visible;
  }
`;

const DetailsWrapper = ({ closable, detailsTitle, children }) => {
  if (closable) {
    return (
      <Box mt="r">
        <CustomExpandable title={detailsTitle}>
          <Box mt="r">{children}</Box>
        </CustomExpandable>
      </Box>
    );
  }

  return (
    <Panel
      title={closable ? "" : detailsTitle}
      mt={closable ? "0" : undefined}
      data-testid="cp-person-details-panel"
    >
      {children}
    </Panel>
  );
};

DetailsWrapper.propTypes = {
  closable: PropTypes.bool,
  detailsTitle: PropTypes.string,
  children: PropTypes.node,
};

const PlaceHolder = () => {
  return (
    <placeholder.Line
      data-testid="cp-person-details-placeholder"
      width={240}
      height={28}
    />
  );
};

const Division = ({ division }) => {
  if (!division) {
    return null;
  }

  const { hierarchy } = division;

  const paths = join(filter(hierarchy.split(HIERARCHY_SEPARATOR)), " > ");

  return <P>{paths}</P>;
};

Division.propTypes = {
  division: PropTypes.object,
};

const PersonDetails = ({
  loading,
  person,
  groupTypes,
  tagConfig,
  closable = false,
  detailsTitle = "Details",
  user,
  workspace,
  allocationProject,
  featureFlags,
  userPerson,
  showExtra,
  tagsVisibleIn,
}) => {
  const client = useApolloClient();

  const managingPerson = get(person, "managedBy");
  const showManagingPerson = isValidPerson(managingPerson);

  const workspaceFeatureFlags = get(workspace, "config.featureFlags", {});

  const personTagTypes = get(workspace, "config.tagTypes.person", []);
  const hideDirectReports = get(
    workspace,
    "config.allocation.hideDirectReports"
  );

  const showReportsTo = showManagingPerson && !hideDirectReports;

  const personTags = get(person, "tags", []);
  const readOnlyTagTypes = filter(personTagTypes, (tagType) => {
    return isTagTypeReadOnly({
      tagType,
      tagConfig,
      user,
    });
  });
  const isAllTagTypesReadOnly =
    readOnlyTagTypes.length === personTagTypes.length;

  const onEntityTagClick = useHandleEntityTagClick();

  const aggregateId = get(person, "aggregateId");
  const updateTagsCache = useCallback(
    (tags) => {
      try {
        // WARN: Should always make sure PERSON_TAG_TYPES load all tag types that are displayed in UI
        // The tags fragment must exist as the tags is loaded in PersonFragment
        client.writeFragment({
          id: `Person:{"aggregateId":"${aggregateId}"}`,
          fragment: personTagsFragment,
          data: {
            aggregateId,
            tags,
          },
        });
      } catch {
        // console.log(e);
        // Do nothing, if writeFragment failed, need a refresh
      }
    },
    [aggregateId, client]
  );

  const EmployedByTitle = loading ? <PlaceHolder /> : <P>{person.company}</P>;

  const LocationDetails = loading ? <PlaceHolder /> : <P>{person.address}</P>;

  const DivisionDetails = loading ? (
    <PlaceHolder />
  ) : (
    <Division division={person.division} />
  );

  const ManagedByDetails = loading ? (
    <PlaceHolder />
  ) : (
    <PersonItem
      reportsTo
      isDisplayActionsButton={false}
      person={managingPerson}
      badgeGroups={getSupplyBadgeGroups({
        person: managingPerson,
        groupTypes,
      })}
      groupTypes={groupTypes}
      user={user}
      workspace={workspace}
      allocationProject={allocationProject}
      featureFlags={featureFlags}
      userPerson={userPerson}
    />
  );

  const showTagsEditor =
    !loading && aggregateId && (!isEmpty(personTags) || !isAllTagTypesReadOnly);

  const detailsContent = (
    <>
      <Spacer mb="r">
        {showTagsEditor && (
          <Box>
            <H5 sizing="small" weight="bold" color="greyDark" uppercase>
              {copywriting.TAGS}
            </H5>
            <TagsEditorContainer
              entityType="person"
              entityId={aggregateId}
              entityName={getName(person)}
              readOnly={isAllTagTypesReadOnly}
              updateCache={updateTagsCache}
              onEntityTagClick={onEntityTagClick}
              comments={{}}
              tagsVisibleIn={tagsVisibleIn}
              featureFlags={workspaceFeatureFlags}
            />
          </Box>
        )}
        {(showReportsTo || loading) && (
          <Box>
            <H5 sizing="small" weight="bold" color="greyDark" uppercase>
              {copywriting.REPORTS_TO}
            </H5>
            {ManagedByDetails}
          </Box>
        )}
        {(person?.company || loading) && (
          <Spacer mb="xs">
            <H5 sizing="small" weight="bold" color="greyDark" uppercase>
              {copywriting.EMPLOYED_BY}
            </H5>
            {EmployedByTitle}
          </Spacer>
        )}
        {(person?.address || loading) && (
          <Spacer mb="xs">
            <H5 sizing="small" weight="bold" color="greyDark" uppercase>
              {copywriting.LOCATION}
            </H5>
            {LocationDetails}
          </Spacer>
        )}
        {(person?.division || loading) && (
          <Spacer mb="xs">
            <H5 sizing="small" weight="bold" color="greyDark" uppercase>
              {copywriting.DIVISION}
            </H5>
            {DivisionDetails}
          </Spacer>
        )}
      </Spacer>
      {showExtra && (
        <Spacer mb="r">
          <Spacer mb="xs">
            <H5 sizing="small" weight="bold" color="greyDark" uppercase>
              {copywriting.Employee_ID}
            </H5>
            {loading ? (
              <PlaceHolder />
            ) : (
              <P>{get(person, "employeeId") || "No Employee ID"}</P>
            )}
          </Spacer>
          <Spacer mb="xs">
            <H5 sizing="small" weight="bold" color="greyDark" uppercase>
              {copywriting.Full_name}
            </H5>
            {loading ? <PlaceHolder /> : <P>{fullLegalName(person)}</P>}
          </Spacer>
          <Spacer mb="xs">
            <H5 sizing="small" weight="bold" color="greyDark" uppercase>
              {copywriting.Band}
            </H5>
            {loading ? (
              <PlaceHolder />
            ) : (
              <Bands person={person} loading={loading} />
            )}
          </Spacer>
          <Spacer mb="xs">
            <H5 sizing="small" weight="bold" color="greyDark" uppercase>
              {copywriting.FTE}
            </H5>
            {loading ? (
              <PlaceHolder />
            ) : (
              <P>
                {numberToLocaleString(get(person, "fte"), {
                  defaultValue: "No FTE",
                })}
              </P>
            )}
          </Spacer>
        </Spacer>
      )}
    </>
  );

  return closable ? (
    <DetailsWrapper
      justifyContent="between"
      detailsTitle={detailsTitle}
      closable={closable}
    >
      {detailsContent}
    </DetailsWrapper>
  ) : (
    <Panel
      title={detailsTitle}
      actions={<PersonContactDetails person={person} />}
    >
      {detailsContent}
    </Panel>
  );
};

PersonDetails.propTypes = {
  loading: PropTypes.bool,
  person: PersonPropType,
  closable: PropTypes.bool,
  detailsTitle: PropTypes.string,
  groupTypes: GroupTypesPropType,
  tagConfig: PropTypes.object,
  user: PropTypes.object,
  workspace: PropTypes.object,
  allocationProject: PropTypes.object,
  featureFlags: PropTypes.object,
  userPerson: PersonPropType,
  showExtra: PropTypes.bool,
  tagsVisibleIn: PropTypes.string,
};

export default PersonDetails;
