import React, { Fragment, useState, useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { withRouter, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { omit } from "lodash";
import {
  Box,
  Spacer,
  Card,
  Flex,
  Small,
  Icon,
  FlexItem,
  Divider,
  Avatar,
  Loading,
} from "orcs-design-system";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import {
  useFilters,
  useActiveFilterCount,
} from "src/contexts/filterAndFte/FilterAndFteContext";
import { fullDisplayName, displayInitials } from "src/util/personName";
import { numberToLocaleString } from "src/util/toLocaleString";
import FeedbackEmoji from "src/components/FeedbackEmoji/FeedbackEmoji";
import { PERSON_TAB } from "src/consts/urlPaths";
import { personDetailsPath } from "src/util/routing";
import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING, INSIGHT_INTERACTION } from "src/consts/eventTracking";
import { useDateSettings } from "src/contexts/global/ApolloSettingsContext";
import PieInsight from "../../../Visualisations/Recharts/pieChart";
import DragIcon from "../DragIcon";
import HelpIcon from "../HelpIcon";
import { getAllocationDistribution } from "../../queries.graphql";
import ClickableStyleLink from "./ClickableStyledLink";

const InsightStyle = styled.div`
  height: 100%;
  .root {
    fill: ${themeGet("colors.primary10")};
    stroke: ${themeGet("colors.primary20")};
  }
`;

const ExpandButton = styled.button`
  font-family: ${themeGet("fonts.main")};
  font-weight: ${themeGet("fontWeights.2")};
  font-size: ${themeGet("fontSizes.2")};
  appearance: none;
  color: ${themeGet("colors.greyDark")};
  background: transparent;
  border: none;
  padding: 0;
  width: 100%;
  border-radius: 0;
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${themeGet("colors.black")};
  }
`;

const LegendDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%; /* Makes the div a circle */
  display: inline-block;
  background-color: ${(props) => props.legendColour};
`;

const CustomCard = styled(Card)`
  overflow: hidden;
`;

const allocationMap = {
  overAllocated: { label: "Over Allocated", colour: "rgba(0, 123, 199, 0.4)" },
  underAllocated: {
    label: "Under Allocated",
    colour: "rgba(0, 123, 199, 0.7)",
  },
  unallocated: { label: "Unallocated", colour: "rgba(0, 123, 199, 1)" },
};
const fullyAllocated = {
  key: "fullyAllocated",
  label: "Fully Allocated",
  colour: "rgba(0, 123, 199, 0.1)",
};

const formatChartData = (data) => {
  if (!data) {
    return [];
  }
  const chartData = Object.entries(allocationMap)
    .filter(([key]) => data[key]?.length > 0)
    .map(([key, value]) => ({
      ...value,
      key,
      value: data[key]?.length || 0,
    }));

  chartData.push({
    ...fullyAllocated,
    value: data.fullyAllocated,
  });
  return chartData;
};

const LegendItem = ({ item, colour, isExpanded }) => (
  <Flex alignItems="center" p="xs 0">
    <LegendDot legendColour={colour} />
    <Small
      ml="s"
      display="block"
      fontWeight="2"
      fontSize="1"
      color={isExpanded ? "black" : "greyDark"}
    >
      {numberToLocaleString(item.value, {
        decimalplaces: 1,
      })}{" "}
      {item.label}
    </Small>
  </Flex>
);

const PieChartCard = (props) => {
  const {
    icon,
    title,
    dataKey,
    layoutKey,
    updateCardSize = () => {},
    helpContent,
  } = props;

  const filters = useFilters();
  const dateSettings = useDateSettings();
  const activeFilterCount = useActiveFilterCount();

  const [itemCount, updateItemCount] = useState(12);
  const [expandedItems, setExpandedItems] = useState({}); // Use an object to track expanded state
  const [hoveredItem, setHoveredItem] = useState(null);
  const history = useHistory();

  const toggleItem = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the state of the current item
    }));
  };

  const handleMouseEnter = (aggregateId) => {
    setHoveredItem(aggregateId);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const teamsFilter = filters?.teams?.teams?.map((team) => team.value);
  // Allocation Distribution currently only works if a single team is selected
  const validFilters = useMemo(
    () =>
      teamsFilter?.length === 1 &&
      activeFilterCount === 1 &&
      dateSettings.comparisonMode !== true,
    [activeFilterCount, dateSettings, teamsFilter]
  );

  const variables = {
    filter: { teams: { teams: teamsFilter } },
  };
  const { data, loading, error, refetch } = useQuery(
    getAllocationDistribution,
    {
      variables,
      skip: !validFilters,
      nextFetchPolicy: "cache-first",
      initialFetchPolicy: "cache-first",
    }
  );

  useEffect(() => {
    if (validFilters) {
      refetch();
    }
  }, [refetch, validFilters, dateSettings]);

  const handledError = data?.allocationDistribution?.error;

  const subtitle = (
    <Flex alignItems="center">
      <HelpIcon content={helpContent} />
      <DragIcon />
    </Flex>
  );

  if (error || handledError) {
    return (
      <InsightStyle className="insights-card">
        <CustomCard
          fluid
          alternate
          icon={icon}
          title={title}
          subtitle={subtitle}
        >
          {handledError || "Allocation Distribution not available"}
        </CustomCard>
      </InsightStyle>
    );
  }

  const chartData = formatChartData(data?.allocationDistribution);
  const insights = omit(data?.allocationDistribution, "error") || {};

  if (!validFilters) {
    return (
      <InsightStyle className="insights-card">
        <CustomCard
          fluid
          alternate
          icon={icon}
          title={title}
          subtitle={subtitle}
        >
          Insight is only available when filtering on a single supply team
        </CustomCard>
      </InsightStyle>
    );
  }

  if (loading) {
    return (
      <InsightStyle className="insights-card">
        <CustomCard
          fluid
          alternate
          icon={icon}
          title={title}
          subtitle={subtitle}
        >
          <Spacer p="r">
            <Loading centered />
          </Spacer>
        </CustomCard>
      </InsightStyle>
    );
  }

  return (
    <InsightStyle className="insights-card">
      <CustomCard
        fluid
        alternate
        icon={icon}
        title={title}
        changeValue=""
        changeIcon={null}
        subtitle={subtitle}
      >
        <Box my="r">
          <Flex width="100%" justifyContent="center">
            <PieInsight data={chartData} />
          </Flex>
        </Box>
        {chartData.map((item) => {
          if (item.key === fullyAllocated.key) {
            return (
              <Flex
                key={item.key}
                alignItems="center"
                justifyContent="space-between"
                py="s"
              >
                <LegendItem
                  item={item}
                  colour={fullyAllocated.colour}
                  isExpanded={expandedItems[item.key]}
                />
              </Flex>
            );
          }

          return (
            <Fragment key={item.key}>
              <ExpandButton
                onClick={() => {
                  toggleItem(item.key);
                  trackEvent(EVENT_TRACKING.INSIGHT_INTERACTION, {
                    interaction: INSIGHT_INTERACTION.ALLOCATION_INSIGHT_EXPAND,
                  });

                  const size = Math.max(
                    itemCount,
                    insights[item.key].length * 0.8
                  );
                  updateItemCount(size);
                  updateCardSize(layoutKey, size);
                }}
              >
                <Flex
                  key={item.key}
                  alignItems="center"
                  justifyContent="space-between"
                  py="xs"
                >
                  <LegendItem
                    item={item}
                    isExpanded={expandedItems[item.key]}
                    colour={allocationMap[item.key].colour}
                  />
                  <Flex alignItems="center" justifyContent="space-between">
                    <Spacer ml="s">
                      <Flex alignItems="center">
                        <Spacer my="xs">
                          <Small>
                            <Icon
                              icon={
                                expandedItems[item.key]
                                  ? ["fas", "chevron-up"]
                                  : ["fas", "chevron-down"]
                              }
                            />
                          </Small>
                        </Spacer>
                      </Flex>
                    </Spacer>
                  </Flex>
                </Flex>
              </ExpandButton>
              <Divider light />
              {expandedItems[item.key] &&
                insights[item.key].map((person) => {
                  const personName = fullDisplayName(person);
                  const initials = displayInitials(person);
                  return (
                    <Fragment key={`person-${person.aggregateId}`}>
                      <ClickableStyleLink
                        display="block"
                        onClick={() => toggleItem(person.aggregateId)}
                        onMouseEnter={() =>
                          handleMouseEnter(person.aggregateId)
                        }
                        onMouseLeave={handleMouseLeave}
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                          py="xs"
                        >
                          <FlexItem flex="1 1 auto">
                            <Avatar
                              sizing="small"
                              title={personName}
                              initials={initials}
                              image={person.avatar}
                              onClick={(e) => {
                                e.preventDefault();
                                trackEvent(EVENT_TRACKING.INSIGHT_INTERACTION, {
                                  interaction:
                                    INSIGHT_INTERACTION.ALLOCATION_PERSON_DETAILS,
                                });
                                history.push(
                                  personDetailsPath(
                                    { aggregateId: person.aggregateId },
                                    PERSON_TAB.DETAILS
                                  )
                                );
                              }}
                            />
                          </FlexItem>
                          <FlexItem flex="0 0 auto">
                            {hoveredItem === person.aggregateId && (
                              <ClickableStyleLink
                                key={`allocate-${person.aggregateId}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  trackEvent(
                                    EVENT_TRACKING.INSIGHT_INTERACTION,
                                    {
                                      interaction:
                                        INSIGHT_INTERACTION.ALLOCATION_PERSON_ALLOCATION,
                                    }
                                  );
                                  history.push(
                                    personDetailsPath(
                                      { aggregateId: person.aggregateId },
                                      PERSON_TAB.ALLOCATIONS
                                    )
                                  );
                                }}
                              >
                                Allocate
                              </ClickableStyleLink>
                            )}
                          </FlexItem>
                          <Spacer />
                        </Flex>
                      </ClickableStyleLink>
                      <Divider light />
                    </Fragment>
                  );
                })}
            </Fragment>
          );
        })}
        <FeedbackEmoji contextId={`SummaryPage-${title}-${dataKey}`} />
      </CustomCard>
    </InsightStyle>
  );
};

PieChartCard.propTypes = {
  icon: PropTypes.array.isRequired,
  updateCardSize: PropTypes.func,
  title: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  layoutKey: PropTypes.string.isRequired,
  helpContent: PropTypes.node,
};

LegendItem.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  colour: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};

export default withRouter(PieChartCard);
