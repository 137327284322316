import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { cloneDeep, find, findIndex, isEmpty, isString } from "lodash";
import RichTextContent from "src/components/RichTextContent";
import RichTextEditor from "src/components/RichTextEditor";
import { attributeTypes } from "src/util/group";

export const Description = ({ team, isEditing, setNewAttributes }) => {
  // get desc attr from team
  const [descAttr, descValue] = useMemo(() => {
    const attr = find(team.attributes, {
      attributeType: attributeTypes.DESCRIPTION,
    });
    return [attr, attr?.value];
  }, [team.attributes]);

  const onChange = useCallback(
    (text) => {
      // format text
      const value = JSON.stringify(text);
      // clone attrs
      const attrsClone = cloneDeep(team.attributes);
      // find desc attr
      const descIndex = findIndex(attrsClone, {
        attributeType: attributeTypes.DESCRIPTION,
      });

      // have existing desc attr?
      if (descIndex >= 0) {
        // update attr
        attrsClone[descIndex] = {
          ...descAttr,
          value,
        };
      } else {
        // new attr
        attrsClone.push({
          attributeType: attributeTypes.DESCRIPTION,
          value,
        });
      }

      setNewAttributes(attrsClone);
    },
    [descAttr, setNewAttributes, team.attributes]
  );

  if (isEditing) {
    return (
      <RichTextEditor
        defaultValue={descValue}
        placeholder="Description"
        onTextChange={onChange}
        isWithMentions={true}
      />
    );
  }

  if (!isString(descValue) || isEmpty(descValue)) {
    return <em>None</em>;
  }

  return <RichTextContent fontSize="r" content={descValue} />;
};

Description.propTypes = {
  team: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setNewAttributes: PropTypes.func.isRequired,
};
