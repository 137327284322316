import React from "react";
import { Flex, Small } from "orcs-design-system";
import { useOrgChartContext } from "src/components/OrgChart/context/OrgChartContext";

import { PersonDetails } from "./PersonDetails/PersonDetails";
import { TeamDetails } from "./TeamDetails/TeamDetails";

export const Details = () => {
  const { state, refreshTeams } = useOrgChartContext() ?? {};
  const selectedPanelEntity = state?.expandedPanel?.data?.selectedEntity;

  // display selected person's details?
  const selectedPerson = state?.members?.[selectedPanelEntity];
  if (selectedPerson) {
    return <PersonDetails selectedPerson={selectedPerson} />;
  }

  // display selected team's details
  const selectedTeam =
    state?.expandedPanel?.data?.entity || state?.teams?.[selectedPanelEntity];
  if (selectedTeam) {
    return (
      <TeamDetails selectedTeam={selectedTeam} refreshTeams={refreshTeams} />
    );
  }

  // nothing selected
  return (
    <Flex justifyContent="center">
      <Small color="greyDark">
        <em>Select a graph node to see its details.</em>
      </Small>
    </Flex>
  );
};
