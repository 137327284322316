/* eslint-disable react/prop-types */
import React from "react";
import {
  StyledLink,
  Card,
  Small,
  Text,
  H2,
  Icon,
  Flex,
  Popover,
} from "orcs-design-system";
import { round } from "lodash";
import DownloadButton from "src/dataExport/components/CsvDownloadButton";

import { numberToLocaleString } from "src/util/toLocaleString";
import FeedbackEmoji from "src/components/FeedbackEmoji/FeedbackEmoji";
import { EVENT_LOCATIONS } from "src/consts/eventTracking";
import PATH, { url } from "../../../consts/urlPaths";
import DragIcon from "./DragIcon";
import HelpIcon from "./HelpIcon";

const PeopleCountInsightCard = ({
  icon,
  title,
  insights: { total = 0, totalComparison } = {},
  loading,
  compare,
  helpContent,
}) => {
  const displayChange = (comparisonValue, value) => {
    const diff = Math.abs(comparisonValue - value);
    if (diff === 0) {
      return (
        <Small textAlign="center" color="greyDark" px="xs">
          No change
        </Small>
      );
    }
    const percentage = round((value / comparisonValue) * 100 - 100, 1);
    return isNaN(percentage) || percentage === Infinity
      ? `${numberToLocaleString(diff, { decimalplaces: 1 })}`
      : `${numberToLocaleString(diff, {
          decimalplaces: 1,
        })}${" "}(${percentage}%)`;
  };

  const subtitle = (
    <Flex alignItems="center">
      <HelpIcon content={helpContent} />
      <DownloadButton
        loading={loading}
        totalCount={total}
        location={EVENT_LOCATIONS.INSIGHT_PAGE}
        buttonOverrides={{ mr: "s" }}
        popOverOverrides={{ direction: "bottomLeft" }}
      />
      <DragIcon />
    </Flex>
  );

  if (loading) {
    return (
      <Card fluid alternate icon={icon} title={title} subtitle={subtitle}>
        <Flex alignItems="center" justifyContent="center" />
      </Card>
    );
  }

  return (
    <Card fluid alternate icon={icon} title={title} subtitle={subtitle}>
      <Flex alignItems="center" justifyContent="center">
        <Popover
          width="180px"
          textAlign="center"
          direction="bottom"
          text="View the full list of people and apply additional filters"
        >
          <StyledLink to={url(PATH.PEOPLE)}>
            <Text fontSize="5rem" fontWeight="300" textAlign="center">
              {numberToLocaleString(total, { decimalplaces: 1 })}
            </Text>
          </StyledLink>
        </Popover>
        {compare ? (
          <H2 color="greyDark" ml="s">
            {total > totalComparison ? (
              <Icon icon={["fal", "arrow-up"]} />
            ) : total < totalComparison ? (
              <Icon icon={["fal", "arrow-down"]} />
            ) : null}{" "}
            {displayChange(totalComparison, total)}
          </H2>
        ) : null}
      </Flex>
      <FeedbackEmoji contextId={`SummaryPage-${title}`} />
    </Card>
  );
};

export default PeopleCountInsightCard;
