import { Flex, Small, Tag } from "orcs-design-system";
import React from "react";
import styled from "styled-components";
import GroupTypeBadgesWrapper from "./components/GroupTypeBadgesWrapper";

const StyledTag = styled(Tag)`
  * {
    cursor: unset !important;
  }
`;

const SPECIAL_TAG_LABEL = "Special tag name";
const TAG_LABEL = "Tag name";
const TAG_TYPE = "TAG TYPE";
const TAGS = "Tags";

const Legend = () => {
  return (
    <>
      <GroupTypeBadgesWrapper />
      <Small
        fontSize="0"
        fontWeight="600"
        display="block"
        color="greyDark"
        mb="s"
        mt="s"
      >
        {TAGS}
      </Small>
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        gap="s"
      >
        <StyledTag small highlighted selected tagType={TAG_TYPE}>
          {SPECIAL_TAG_LABEL}
        </StyledTag>
        <StyledTag small selected tagType={TAG_TYPE}>
          {TAG_LABEL}
        </StyledTag>
      </Flex>
    </>
  );
};

export default React.memo(Legend);
