import React from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  Avatar,
  Box,
  Button,
  Spacer,
  Flex,
  Icon,
  Loading,
  Popover,
} from "orcs-design-system";
import { themeGet } from "@styled-system/theme-get";
import PersonPropType from "src/custom-prop-types/person";
import { PERSON_TAB } from "src/consts/urlPaths";
import { personDetailsPath, visualisationPersonPath } from "src/util/routing";
import { useAllocationConfig } from "src/contexts/global/WorkspaceContext";
import { fullDisplayName, displayInitials } from "src/util/personName";

import InfiniteScroll from "../InfiniteScroll";

const StyledListLink = styled(Link)`
  display: block;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  border-bottom: solid 1px ${themeGet("colors.greyLightest")};
  transition: ${(props) => themeGet("transition.transitionDefault")(props)};
  &:hover,
  &:focus {
    outline: 0;
    background-color: rgba(156, 224, 248, 0.2);
  }
  &:last-of-type {
    border-bottom: none;
  }
  h3 {
    color: ${(props) => themeGet("colors.primary")(props)};
  }
`;

const PersonRow = ({ person, history, isShowAllocations }) => {
  return (
    <StyledListLink
      data-testid="cp-peoplelist-row"
      to={personDetailsPath(person, null)}
    >
      <Box p="r">
        <Flex alignItems="center" justifyContent="space-between">
          <Avatar
            image={person.avatar}
            title={fullDisplayName(person)}
            subtitle={person.jobTitle}
            initials={displayInitials(person)}
          />
          <Flex alignItems="center" alignCenter>
            <Spacer mx="xs">
              {isShowAllocations && (
                <Popover
                  direction="left"
                  width="160px"
                  textAlign="center"
                  text="Open person allocations"
                >
                  <Button
                    data-testid="cp-peoplelist-row-allocate"
                    small
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(
                        personDetailsPath(person, PERSON_TAB.ALLOCATIONS)
                      );
                    }}
                  >
                    Allocate
                  </Button>
                </Popover>
              )}
              <Popover
                direction="topLeft"
                width="154px"
                textAlign="center"
                text="Explore in Organisation"
              >
                <Button
                  small
                  height="25px"
                  width="25px"
                  iconOnly
                  ariaLabel="Explore in Organisation"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(visualisationPersonPath(person));
                  }}
                >
                  <Icon icon={["far", "chart-network"]} />
                </Button>
              </Popover>
            </Spacer>
          </Flex>
        </Flex>
      </Box>
    </StyledListLink>
  );
};

PersonRow.propTypes = {
  person: PersonPropType.isRequired,
  history: PropTypes.object.isRequired,
  isShowAllocations: PropTypes.bool,
};

const PeopleList = ({ people, hasMore, searchMorePeople }) => {
  const { disableIndividualAllocation } = useAllocationConfig();
  const history = useHistory();

  const loader = (
    <Box p="l" m="l" key={0}>
      <Loading large centered />
    </Box>
  );

  return (
    <Box
      data-testid="cp-peoplelist-container"
      bg="white"
      mt="r"
      borderRadius="2"
      boxBorder="default"
    >
      <InfiniteScroll
        loadMore={searchMorePeople}
        hasMore={hasMore}
        loader={loader}
      >
        {people.map((person) => {
          return (
            <PersonRow
              person={person}
              key={person.aggregateId}
              history={history}
              isShowAllocations={!disableIndividualAllocation}
            />
          );
        })}
      </InfiniteScroll>
    </Box>
  );
};

PeopleList.propTypes = {
  people: PropTypes.arrayOf(PersonPropType).isRequired,
  hasMore: PropTypes.bool.isRequired,
  searchMorePeople: PropTypes.func.isRequired,
};

export default PeopleList;
