import { useMutation, useLazyQuery } from "@apollo/client";
import { get } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useEffect } from "react";

import { createParentGroupOptions, getSubGroupTypes } from "src/util/group";
import AddNewTeamModal from "src/allocation/components/AddNewTeamModal";
import { getParentGroups as getParentGroupsQuery } from "src/queries/group.graphql";
import { createTeam as createTeamMutation } from "src/queries/mutations/group.graphql";
import eventEmitter, { EVENTS } from "src/services/eventEmitter";
import { canCreateChild } from "src/util/customerConfig";

import { updateCache } from "./util";

const AddNewTeamModalContainer = ({
  parentGroup,
  isVisible,
  onHideModal,
  groupTypes,
}) => {
  const [createTeam] = useMutation(createTeamMutation);

  const subGroupTypes = useMemo(() => {
    return getSubGroupTypes(groupTypes, parentGroup);
  }, [groupTypes, parentGroup]);

  const [loadParentGroups, { data: groupsData, loading: groupsLoading }] =
    useLazyQuery(getParentGroupsQuery, {
      variables: {
        groupId: parentGroup.id,
        groupTypes: subGroupTypes,
      },
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (
      isVisible &&
      !groupsData &&
      !groupsLoading &&
      subGroupTypes.length > 0
    ) {
      loadParentGroups();
    }
  }, [isVisible, groupsData, groupsLoading, subGroupTypes, loadParentGroups]);

  const parentGroupOptions = useMemo(() => {
    return createParentGroupOptions(groupsData, parentGroup);
  }, [groupsData, parentGroup]);

  // Get all sub, sub-sub groups
  const createTeamAndReload = useCallback(
    async (newGroup) => {
      await createTeam({
        variables: newGroup,
        update: (proxy, result) => {
          updateCache(proxy, result, newGroup);
          eventEmitter.emit(EVENTS.NEW_GROUP_ADDED, get(result, "data.team"));
        },
      });

      if (canCreateChild(groupTypes, newGroup)) {
        // The createdTeam is undefined (based on the implementation),
        // so if user created a new group that can have child,
        // need to reload all the sub type groups again.
        // TODO: Find a good way to do this...
        loadParentGroups();
      }
    },
    [createTeam, groupTypes, loadParentGroups]
  );

  if (!canCreateChild(groupTypes, parentGroup)) {
    return null;
  }

  return (
    <AddNewTeamModal
      visible={isVisible}
      parentGroup={parentGroup}
      onHideModal={onHideModal}
      onCreateTeam={createTeamAndReload}
      loading={groupsLoading}
      parentGroupOptions={parentGroupOptions}
      groupTypes={groupTypes}
    />
  );
};

AddNewTeamModalContainer.propTypes = {
  parentGroup: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onHideModal: PropTypes.func.isRequired,
  groupTypes: PropTypes.object.isRequired,
};

export default React.memo(AddNewTeamModalContainer);
