/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { isEmpty, mergeWith, unionBy } from "lodash";

export const DIFF_TYPES = {
  ADDED: "added",
  REMOVED: "removed",
  MOVED_IN: "movedIn",
  MOVED_OUT: "movedOut",
  NEW: "new",
};

export const isReadOnly = (data) =>
  data?.diffType === DIFF_TYPES.MOVED_OUT ||
  data?.diffType === DIFF_TYPES.REMOVED;

export function getTeamDifferences(teamsBefore, teamsAfter) {
  function buildParentToChildMap(teams) {
    const parentToChild = {};
    for (const team of Object.values(teams)) {
      const parentId = team.directParentId;
      if (parentId) {
        if (!parentToChild[parentId]) {
          parentToChild[parentId] = [];
        }
        parentToChild[parentId].push({ id: team.id, directParentId: parentId });
      }
    }
    return parentToChild;
  }

  const allChanges = [];
  const beforeMap = buildParentToChildMap(teamsBefore);
  const afterMap = buildParentToChildMap(teamsAfter);

  // Find Removed and Moved Out Teams
  for (const [parentId, children] of Object.entries(beforeMap)) {
    for (const child of children) {
      const teamId = child.id;
      const afterTeam = teamsAfter[teamId];

      if (!afterTeam || afterTeam?.isHidden || afterTeam?.isRemoved) {
        // Team was removed
        allChanges.push({
          type: DIFF_TYPES.REMOVED,
          path: `teams.${parentId}.childTeams[${teamId}]`,
          value: { id: teamId },
          from: parentId,
          which: null,
        });
      } else if (afterTeam.directParentId !== parentId) {
        // Team moved out to another parent
        allChanges.push({
          type: DIFF_TYPES.MOVED_OUT,
          path: `teams.${parentId}.childTeams[${teamId}]`,
          value: { id: teamId },
          from: parentId,
          which: afterTeam.directParentId,
        });
      }
    }
  }

  // Find Added and Moved In Teams
  for (const [parentId, children] of Object.entries(afterMap)) {
    for (const child of children) {
      const teamId = child.id;
      const beforeTeam = teamsBefore[teamId];

      if (!beforeTeam) {
        // New team added
        allChanges.push({
          type: DIFF_TYPES.ADDED,
          path: `teams.${parentId}.childTeams[${teamId}]`,
          value: { id: teamId },
          to: parentId,
          which: null,
        });
      } else if (beforeTeam.directParentId !== parentId) {
        // Team moved in from another parent
        allChanges.push({
          type: DIFF_TYPES.MOVED_IN,
          path: `teams.${parentId}.childTeams[${teamId}]`,
          value: { id: teamId },
          to: parentId,
          which: beforeTeam.directParentId,
        });
      }
    }
  }

  return allChanges;
}

function getMemberDifferences(membersBefore, membersAfter, members) {
  const result = [];
  const allMemberIds = new Set([
    ...Object.keys(members),
    ...Object.keys(membersBefore),
    ...Object.keys(membersAfter),
  ]);

  for (const memberId of allMemberIds) {
    const before = membersBefore[memberId];
    const after = membersAfter[memberId];

    const beforeGroups = new Set((before?.memberOf || []).map((g) => g.id));
    const afterGroups = new Set((after?.memberOf || []).map((g) => g.id));

    const movedOut = [...beforeGroups].filter((id) => !afterGroups.has(id));
    const movedIn = [...afterGroups].filter((id) => !beforeGroups.has(id));

    if (!before) {
      result.push({
        type: DIFF_TYPES.NEW,
        path: `members.${memberId}`,
        value: { id: memberId },
        from: [],
        to: movedIn,
      });
      continue;
    }

    if (!after) {
      result.push({
        type: DIFF_TYPES.MOVED_OUT,
        path: `members.${memberId}`,
        value: { id: memberId },
        from: movedOut,
        to: [],
      });
      continue;
    }

    if (movedOut.length > 0) {
      result.push({
        type: DIFF_TYPES.MOVED_OUT,
        path: `members.${memberId}`,
        value: { id: memberId },
        from: movedOut,
        to: [],
      });
    }

    if (movedIn.length > 0) {
      result.push({
        type: DIFF_TYPES.MOVED_IN,
        path: `members.${memberId}`,
        value: { id: memberId },
        from: [],
        to: movedIn,
      });
    }
  }

  return result;
}

function mergeObjects(obj1, obj2) {
  return mergeWith({}, obj1, obj2, (objValue, srcValue) => {
    if (Array.isArray(objValue) && Array.isArray(srcValue)) {
      return unionBy(objValue, srcValue, "id");
    }
    return undefined;
  });
}

function applyDiffToEveryTeam(mergedObject, diffArray, allTeams) {
  function applyMove(type, value, from = null, to = null) {
    if (from) {
      const actualTeam = allTeams.find(
        (t) => t.id === value.id && from === t.directParentId
      );
      if (actualTeam) {
        return { [from]: { _type: type, from, to } };
      }
    }

    if (to) {
      const actualTeam = allTeams.find(
        (t) => t.id === value.id && to === t.directParentId
      );
      if (actualTeam) {
        return { [to]: { _type: type, from, to } };
      }
    }

    return {};
  }

  Object.keys(mergedObject).forEach((teamId) => {
    mergedObject[teamId].diff =
      mergedObject[teamId] && mergedObject[teamId].diff
        ? mergedObject[teamId].diff
        : {};

    const diffArrayFiltered = diffArray.filter(({ value }) => {
      return teamId === value.id;
    });

    diffArrayFiltered.forEach((change) => {
      const { type, value, from, to } = change;
      const diff = applyMove(type, value, from, to);

      mergedObject[teamId].diff = {
        ...mergedObject[teamId].diff,
        ...diff,
      };
    });
  });

  return mergedObject;
}

function applyDiffToEveryMember(mergedObject, diffArray) {
  function applyMove(type, value, from, to) {
    const diff = {};

    if (!isEmpty(from)) {
      from.forEach((f) => {
        diff[f] = { _type: type };
      });
    }

    if (!isEmpty(to)) {
      to.forEach((t) => {
        diff[t] = { _type: type };
      });
    }

    return diff;
  }

  Object.keys(mergedObject).forEach((memberId) => {
    mergedObject[memberId].diff =
      mergedObject[memberId] && mergedObject[memberId].diff
        ? mergedObject[memberId].diff
        : {};

    const diffArrayFiltered = diffArray.filter(({ value }) => {
      return memberId === value.id;
    });

    diffArrayFiltered.forEach((change) => {
      const { type, value, from, to } = change;
      const diff = applyMove(type, value, from, to);
      mergedObject[memberId].diff = {
        ...mergedObject[memberId].diff,
        ...diff,
      };
    });
  });

  return mergedObject;
}

export const getDiffs = (baseline, next, teams, members) => {
  const allTeams = [
    ...Object.values(teams),
    ...Object.values(baseline.teams),
    ...Object.values(next.teams),
  ];
  const allMembers = [
    ...Object.values(members),
    ...Object.values(baseline.members),
    ...Object.values(next.members),
  ];

  const diffDataTeams = getTeamDifferences(baseline.teams, next.teams);

  const diffDataMembers = getMemberDifferences(
    baseline.members,
    next.members,
    members
  );

  const mergedBaselineTeams = mergeObjects(baseline.teams, next.teams);
  const mergedBaselineMembers = mergeObjects(baseline.members, next.members);
  const allPreviousMembers = mergeObjects(mergedBaselineMembers, members);

  const finalBaselineTeams = applyDiffToEveryTeam(
    mergedBaselineTeams,
    diffDataTeams,
    allTeams
  );
  const finalBaselineMembers = applyDiffToEveryMember(
    allPreviousMembers,
    diffDataMembers,
    allMembers
  );

  return {
    teams: finalBaselineTeams,
    members: finalBaselineMembers,
  };
};
