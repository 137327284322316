/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import themeGet from "@styled-system/theme-get";
import { Box } from "orcs-design-system";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const BlockContainer = styled(Box)`
  border-radius: ${themeGet("radii.2")};
  transition: ${themeGet("transition.transitionDefault")};
  border: solid 1px ${themeGet("colors.white")};
  overflow: hidden;
  text-decoration: none;
  &:hover,
  &:focus {
    outline: 0;
    border-color: ${themeGet("colors.black80")};
    text-decoration: none;
  }
  &:active,
  &:visited {
    text-decoration: none;
  }
`;

export const Block = styled(Box)`
  position: relative;
  background-image: ${({ bgGradient, bgImg }) =>
    bgImg ? `url(${bgImg})` : bgGradient};
  background-size: 100%;
  background-position: center center;
  border-radius: ${(props) => (props.parent ? themeGet("radii.2") : "0")};
  transition: background-size 0.5s ease-in-out;
  &:hover {
    background-size: 110%;
  }
  h2 {
    text-shadow: 0 0px 4px rgba(0, 0, 0, 0.3);
  }
`;

export const BlockLink = styled(Link)`
  width: 100%;
  height: 100%;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;

export const BlockButton = styled.button`
  border: none;
  appearance: none;
  box-shadow: none;
  background: transparent;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;
