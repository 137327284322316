import { map, get, keys } from "lodash";
import {
  Box,
  Divider,
  Icon,
  Button,
  TextInput,
  H5,
  Small,
  Spacer,
  Flex,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import icon from "src/config/icons";
import { FormGroupAttribute } from "src/custom-prop-types/groupAttribute";
import { changeTypes } from "../TeamEdit.config";

const EditableInputRows = ({
  formAttributes,
  title,
  attributeType,
  placeholder,
  onChange,
  hasError,
  validateField,
}) => {
  const [numberRows, setNumberRows] = useState(0);

  useEffect(() => {
    // Only set numberRows when formAttributes length is greater than user action
    const attrLength = keys(formAttributes).length;
    if (attrLength > numberRows) {
      setNumberRows(attrLength);
    }
  }, [formAttributes, numberRows]);

  const rowsArray = Array(numberRows).fill(0);
  const addInputRow = () => setNumberRows(numberRows + 1);

  return (
    <>
      <H5 weight="bold" mb="r">
        {title}
      </H5>
      {map(rowsArray, (row, index) => {
        const attributeFormId = `${attributeType}-${index + 1}`;
        const formAttribute = formAttributes[attributeFormId];
        if (get(formAttribute, "deleted")) {
          return null;
        }

        // TODO: remove the subtype-readonly check after JIRA integration refactor.
        // The current, preferred way to make a URL readonly is to use: `readonly: true`
        // on the attribute. Using `subtype: "readonly"` is deprecated behaviour.
        // However we still support it, because currently subtype-readonly is in use
        // by the Jira integration.
        const isReadonly =
          get(formAttribute, "subtype", false) === "readonly" ||
          get(formAttribute, "readonly", false);

        return (
          <Box key={`cp-editable-row-${attributeFormId}`} mb="s">
            <Spacer mb="s">
              <TextInput
                fullWidth
                id={`cp-editable-label-${attributeFormId}`}
                data-testid={`cp-editable-label-${attributeFormId}`}
                key={`cp-editable-label-${attributeFormId}`}
                onChange={onChange(attributeFormId, changeTypes.LABEL)}
                defaultValue={get(formAttribute, "label")}
                type="text"
                label={placeholder.LABEL_DESCRIPTION}
                placeholder={placeholder.PLACEHOLDER_DESCRIPTION}
                disabled={isReadonly}
              />
              <TextInput
                fullWidth
                id={`cp-editable-${attributeFormId}`}
                data-testid={`cp-editable-${attributeFormId}`}
                key={`cp-editable-${attributeFormId}`}
                onBlur={validateField(attributeFormId)}
                onChange={onChange(attributeFormId, changeTypes.VALUE)}
                invalid={hasError(attributeFormId)}
                defaultValue={get(formAttribute, "value")}
                type="text"
                label={placeholder.LABEL_VALUE}
                placeholder={placeholder.PLACEHOLDER_VALUE}
                disabled={isReadonly}
              />
              <Flex justifyContent="flex-end">
                <Button
                  ariaLabel={placeholder.DELETE_ITEM_TEXT}
                  onClick={onChange(attributeFormId, changeTypes.DELETE)}
                  iconLeft
                  small
                  variant="danger"
                  disabled={isReadonly}
                >
                  <Icon icon={icon.trash} />
                  {placeholder.DELETE_ITEM_TEXT}
                </Button>
              </Flex>
              {hasError(attributeFormId) && (
                <Small color="danger">
                  <Icon icon={icon.exclamation} mr={2} />
                  {`${placeholder.ERROR_TEXT} ${placeholder.LABEL_VALUE}`}
                </Small>
              )}
            </Spacer>
          </Box>
        );
      })}
      <Button
        ariaLabel={placeholder.ADD_ITEM_TEXT}
        onClick={addInputRow}
        iconLeft
        small
      >
        <Icon icon={icon.plus} />
        {placeholder.ADD_ITEM_TEXT}
      </Button>
      <Divider my="r" light />
    </>
  );
};

EditableInputRows.propTypes = {
  formAttributes: PropTypes.objectOf(FormGroupAttribute),
  attributeType: PropTypes.string.isRequired,
  placeholder: PropTypes.shape({
    ADD_ITEM_TEXT: PropTypes.string,
    DELETE_ITEM_TEXT: PropTypes.string,
    ERROR_TEXT: PropTypes.string,
    LABEL_DESCRIPTION: PropTypes.string,
    LABEL_VALUE: PropTypes.string,
    PLACEHOLDER_DESCRIPTION: PropTypes.string,
    PLACEHOLDER_VALUE: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  hasError: PropTypes.func.isRequired,
  validateField: PropTypes.func.isRequired,
};

export default EditableInputRows;
