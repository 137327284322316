import PropTypes from "prop-types";
import React from "react";
import {
  Box,
  P,
  H4,
  Flex,
  Spacer,
  StyledLink,
  Loading,
} from "orcs-design-system";
import moment from "moment";
import { url } from "src/consts/urlPaths";

const formatDate = (date) => {
  return date && date.format("DD/MM/YYYY");
};

const HeaderContainer = ({ children }) => {
  return (
    <Box bg="white" p="r" boxBorder="default" borderRadius={2} mb="r">
      <Flex alignItems="baseline">{children}</Flex>
    </Box>
  );
};

HeaderContainer.propTypes = {
  children: PropTypes.node,
};

const ImportDate = ({
  importDate,
  loading,
  toDate,
  fromDate,
  comparisonMode, // TODO: Confusing naming convention! Use IsBooleanName convention
  fteMode, // TODO: Confusing naming convention! Use IsBooleanName convention
}) => {
  if (loading) {
    return (
      <HeaderContainer>
        <Loading small centered />
        <Spacer ml="s">
          <H4>Loading...</H4>
        </Spacer>
      </HeaderContainer>
    );
  }

  if (!importDate) {
    return (
      <HeaderContainer>
        <Spacer mr="s">
          <H4>
            No data available to view. Click&nbsp;
            <StyledLink
              bold
              to={url("/:workspaceId?/configuration/datasources/select")}
            >
              here
            </StyledLink>
            &nbsp;to import data.
          </H4>
        </Spacer>
      </HeaderContainer>
    );
  }

  if (importDate && comparisonMode && !fteMode) {
    return (
      <HeaderContainer>
        <Spacer mr="s">
          {moment(importDate).isSame(moment(fromDate), "day") && (
            <H4>Viewing data {moment(importDate).fromNow()}</H4>
          )}
          {!moment(importDate).isSame(moment(fromDate), "day") && (
            <H4>Viewing data from {moment(fromDate).fromNow()}</H4>
          )}
          <P color="greyDark">{formatDate(fromDate)}</P>
          {moment(importDate).isSame(moment(toDate), "day") && (
            <H4>to data {moment(importDate).fromNow()}</H4>
          )}
          {!moment(importDate).isSame(moment(toDate), "day") && (
            <H4>to data {moment(toDate).fromNow()}</H4>
          )}
          <P color="greyDark">{formatDate(toDate)}</P>
        </Spacer>
      </HeaderContainer>
    );
  }

  if (importDate && comparisonMode && fteMode) {
    return (
      <HeaderContainer>
        <Spacer mr="s">
          {moment(importDate).isSame(moment(fromDate), "day") && (
            <H4>Viewing FTE data {moment(importDate).fromNow()}</H4>
          )}
          {!moment(importDate).isSame(moment(fromDate), "day") && (
            <H4>Viewing FTE data from {moment(fromDate).fromNow()}</H4>
          )}
          <P color="greyDark">{formatDate(fromDate)}</P>
          {moment(importDate).isSame(moment(toDate), "day") && (
            <H4>to FTE data {moment(importDate).fromNow()}</H4>
          )}
          {!moment(importDate).isSame(moment(toDate), "day") && (
            <H4>to FTE data {moment(toDate).fromNow()}</H4>
          )}
          <P color="greyDark">{formatDate(toDate)}</P>
        </Spacer>
      </HeaderContainer>
    );
  }

  if (importDate && fteMode) {
    return (
      <HeaderContainer>
        <Spacer mr="s">
          <H4>Viewing data in FTE mode</H4>
        </Spacer>
      </HeaderContainer>
    );
  }

  return null;
};

ImportDate.propTypes = {
  importDate: PropTypes.string,
  loading: PropTypes.bool,
  toDate: PropTypes.func,
  fromDate: PropTypes.func,
  comparisonMode: PropTypes.bool,
  fteMode: PropTypes.bool,
};

export default ImportDate;
