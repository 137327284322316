/* eslint-disable react/prop-types */

import React from "react";

const Text = ({ x, y, type, value }) => (
  <text
    className={type}
    x={x || 0}
    y={y || 0}
    textAnchor="middle"
    alignmentBaseline="middle"
  >
    {value}
  </text>
);

export default Text;
