/* eslint-disable react/prop-types */

import React from "react";
import { map, indexOf } from "lodash";

import Bubble from "../Bubble";
import * as cpUtils from "./ReactCirclePack.utils";

const MAX_BUBBLES = 101;

const CirclePack = (props) => {
  const {
    data,
    onBubbleClick,
    selectedBubbles,
    labelAsPercentage = true,
  } = props;

  const [hoverItem, setHoverItem] = React.useState(null);
  const { bubbleValSum, bubbles } = cpUtils.getBubbleValSumAndBubbles(
    data,
    MAX_BUBBLES
  );
  const [bubbleDetails, setBubbleDetails] = React.useState({
    bubbleValSum,
    bubbles,
    rootBubble: cpUtils.getRootBubble(bubbles),
    childBubbles: cpUtils.getChildBubbles(bubbles),
  });

  const getBubbleLabel = (itemValue) =>
    labelAsPercentage
      ? cpUtils.toPercentageString(itemValue, bubbleDetails.bubbleValSum)
      : cpUtils.toCountString(itemValue, bubbleDetails.bubbleValSum);

  const getBubbleType = (id, type) => {
    return hoverItem && hoverItem.data.id === id
      ? `${type} bubbleselected`
      : indexOf(selectedBubbles, id) !== -1
      ? `${type} bubbleselected`
      : type;
  };

  const onBubbleMouseEnterOrMove = (d, x, y, r) => () =>
    setHoverItem({
      data: d,
      x,
      y: y - r,
    });

  const onBubbleMouseLeave = () => setHoverItem(null);

  React.useEffect(() => {
    // TODO: this can be fixed by properly splitting out components
    // eslint-disable-next-line no-shadow
    const { bubbleValSum, bubbles } = cpUtils.getBubbleValSumAndBubbles(
      data,
      MAX_BUBBLES
    );
    setBubbleDetails({
      bubbleValSum,
      bubbles,
      rootBubble: cpUtils.getRootBubble(bubbles),
      childBubbles: cpUtils.getChildBubbles(bubbles),
    });
  }, [data, selectedBubbles]);

  return (
    <div className="bubble-chart-container">
      <svg
        width={300}
        height={300}
        className="bubble-chart-svg-container"
        style={{ overflow: "visible" }}
      >
        <Bubble
          key={bubbleDetails.rootBubble.id}
          x={bubbleDetails.rootBubble.x}
          y={bubbleDetails.rootBubble.y}
          r={bubbleDetails.rootBubble.r}
          type={bubbleDetails.rootBubble.type}
        />
        {map(bubbleDetails.childBubbles, (item, i) => {
          if (item && item.data) {
            const { x, y, r, data: itemData } = item;
            const { id, type, value } = itemData;

            return (
              <React.Fragment key={id || i + type}>
                <Bubble
                  x={x}
                  y={y}
                  r={r}
                  label={getBubbleLabel(value)}
                  type={getBubbleType(id, type)}
                  onClick={() => onBubbleClick(itemData)}
                  onMouseEnter={onBubbleMouseEnterOrMove(itemData, x, y, r)}
                  onMouseLeave={onBubbleMouseLeave}
                  onMouseMove={onBubbleMouseEnterOrMove(itemData, x, y, r)}
                />
              </React.Fragment>
            );
          }

          return <React.Fragment key={`no-bubble-${i}`} />;
        })}
      </svg>

      {hoverItem ? (
        <div
          className="tooltip"
          style={{
            position: "absolute",
            top: hoverItem.y - 20,
            left: hoverItem.x + 10,
          }}
        >
          {hoverItem.data.name}
        </div>
      ) : null}
    </div>
  );
};

export default CirclePack;
