/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */

import React from "react";
import {
  Flex,
  Card,
  Small,
  H4,
  P,
  Text,
  Grid,
  Divider,
  GridItem,
  Icon,
} from "orcs-design-system";
import { find, defaultTo, get, toUpper, round } from "lodash";
import pluralize from "pluralize";
import { numberToLocaleString } from "src/util/toLocaleString";
import FeedbackEmoji from "src/components/FeedbackEmoji/FeedbackEmoji";
import DragIcon from "./DragIcon";
import HelpIcon from "./HelpIcon";

const displayChange = (comparisonValue, value) => {
  const diff = value - comparisonValue;
  if (diff === 0) {
    return "No change";
  }
  const percentage = round((value / comparisonValue) * 100 - 100, 1);
  return isNaN(percentage) || percentage === Infinity
    ? `${numberToLocaleString(diff, { decimalplaces: 1 })}`
    : `${numberToLocaleString(diff, {
        decimalplaces: 1,
      })}${" "}(${percentage}%)`;
};

const InsightTitle = ({ insight }) => {
  const samples = get(insight, "samples");
  const type = pluralize(get(insight, "key"), samples);
  return (
    <GridItem>
      <H4>
        {samples} {type}
      </H4>
    </GridItem>
  );
};

const NumberGridItem = ({ insight, statType, compare }) => {
  const comparisons = defaultTo(insight.comparisons, {});
  const mainValue = numberToLocaleString(
    defaultTo(get(insight, statType, 0), 0),
    { decimalplaces: 1 }
  );
  const comparisonValue = numberToLocaleString(
    defaultTo(get(comparisons, statType, mainValue), mainValue),
    { decimalplaces: 1 }
  );
  return (
    <GridItem>
      <Small display="block" uppercase color="greyDark" textAlign="center">
        {toUpper(statType)}
      </Small>
      <Text display="block" fontSize="3rem" textAlign="center">
        {mainValue}
      </Text>
      {compare ? (
        <Small display="block" color="greyDark" textAlign="center">
          {mainValue > comparisonValue ? (
            <Icon icon={["fal", "arrow-up"]} />
          ) : mainValue < comparisonValue ? (
            <Icon icon={["fal", "arrow-down"]} />
          ) : null}{" "}
          {displayChange(comparisonValue, mainValue)}
        </Small>
      ) : null}
    </GridItem>
  );
};

const StatListCard = ({
  icon,
  title,
  dataKey,
  insights: { statLists } = {},
  compare,
  emtpyContent,
  helpContent,
}) => {
  const insightOfInterest = find(statLists, { key: dataKey });
  const subtitle = (
    <Flex alignItems="center">
      <HelpIcon content={helpContent} />
      <DragIcon />
    </Flex>
  );

  return (
    <div className="insights-card">
      <Card fluid alternate icon={icon} title={title} subtitle={subtitle}>
        {(!insightOfInterest || insightOfInterest.items.length === 0) && (
          <P>{emtpyContent}</P>
        )}
        <Grid
          gridTemplateColumns="repeat(5, 1fr)"
          gridGap="10px"
          alignItems="center"
        >
          {insightOfInterest &&
            insightOfInterest.items.map((insight, index) => [
              <InsightTitle key={`title-${index}`} insight={insight} />,
              <NumberGridItem
                key={`min-${index}`}
                insight={insight}
                statType="min"
                compare={compare}
              />,
              <NumberGridItem
                key={`avg-${index}`}
                insight={insight}
                statType="avg"
                compare={compare}
              />,
              <NumberGridItem
                key={`max-${index}`}
                insight={insight}
                statType="max"
                compare={compare}
              />,
              <NumberGridItem
                key={`total-${index}`}
                insight={insight}
                statType="total"
                compare={compare}
              />,
              <Divider light spanGrid key={`divider-${index}`} />,
            ])}
        </Grid>
        <FeedbackEmoji contextId={`SummaryPage-${title}-${dataKey}`} />
      </Card>
    </div>
  );
};

export default StatListCard;
