export const VIEW_MODE_STORAGE_KEY = "team-info-view-mode";

export const FLOATING_CONTROL_ID = {
  CHART_ZOOM_IN: "chart-zoom-in",
  CHART_ZOOM_OUT: "chart-zoom-out",
  CHART_FIT_VIEW: "chart-fit-view",
  CHART_FULL_SCREEN: "chart-full-screen",
  LIST_VIEW: "list-view",
  CHART_VIEW: "chart-view",
};
