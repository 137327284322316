import { get, isEmpty } from "lodash";
import { Flex } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import TagSelectorContainer from "src/components/TagsEditor/sub-components/TagSelectorContainer";
import TagsEditor from "src/components/TagsEditor/TagsEditor";
import { ENTITY_TYPES } from "src/consts/comments";

import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import useHandleEntityTagClick from "src/hooks/useHandleEntityTagClick";
import { getHighlightedTags } from "../HighlightedTags/utils";

const configKeyName = {
  [ENTITY_TYPES.GROUP]: "isGroupBadge",
  [ENTITY_TYPES.PERSON]: "isPersonBadge",
};

const TagDisplay = ({
  tags,
  entityType = ENTITY_TYPES.GROUP,
  showHighlightedTagsOnly = false,
}) => {
  const workspace = useWorkspace();
  const handleTagClick = useHandleEntityTagClick();
  const tagConfig = get(workspace, "config.tagConfig", {});

  const filteredTags = showHighlightedTagsOnly
    ? getHighlightedTags(tags, tagConfig, configKeyName[entityType])
    : tags;

  if (isEmpty(filteredTags)) {
    return null;
  }

  return (
    <Flex alignItems="center" flexWrap="wrap" role="list" height="100%">
      <TagsEditor
        readOnly
        tagTypesConfig={tagConfig}
        existingTags={filteredTags}
        onEntityTagClick={handleTagClick}
        components={{ TagSelector: TagSelectorContainer }}
        small
        mt="xs"
        entityType={entityType}
      />
    </Flex>
  );
};

TagDisplay.propTypes = {
  tags: PropTypes.object.isRequired,
  entityType: PropTypes.string,
  showHighlightedTagsOnly: PropTypes.bool,
};

export default TagDisplay;
