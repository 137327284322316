import { trim } from "lodash";
import { Box, Flex, FlexItem } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";

import ErrorNotification from "src/components/ErrorNotification";
import {
  ILLEGAL_TEAM_NAME_CHARACTERS,
  MIN_TEAM_NAME_LENGTH,
} from "src/consts/global";

import ConfirmAndCancelBtns from "./ConfirmAndCancelBtns";
import EditBtn from "./EditBtn";
import TeamName from "./TeamName";
import TeamNameInput from "./TeamNameInput";

const validateNewName = (newName) => {
  if (!newName || newName.length < MIN_TEAM_NAME_LENGTH) {
    return "Minimum name length is 3 characters.";
  }

  return null;
};

const TeamNameEditor = ({
  team,
  updateName,
  label,
  isEditMode = false,
  canCancel = true,
  autoExitEditMode = true,
}) => {
  const { name } = team;

  const [editMode, setEditMode] = useState(isEditMode);
  const [isLoading, setLoading] = useState(false);
  const [newName, setNewName] = useState(name);
  const [error, setError] = useState(null);

  useEffect(() => {
    setNewName(name);
  }, [name]);

  const onEdit = useCallback(() => {
    setEditMode(true);
  }, []);

  const onSave = useCallback(async () => {
    const proposedName = trim(newName);

    if (proposedName === name) {
      return;
    }

    const result = validateNewName(proposedName);

    if (result) {
      setError(result);
      return;
    }

    try {
      setLoading(true);
      await updateName(proposedName);
    } catch (err) {
      setError(err.toString());
    } finally {
      setError(null);
      setLoading(false);
      if (autoExitEditMode) {
        setEditMode(false);
      }
    }
  }, [newName, name, updateName, autoExitEditMode]);

  const onCancel = useCallback(() => {
    setNewName(name);
    setEditMode(false);
    setError(null);
  }, [name]);

  const onNameChange = useCallback((e) => {
    const proposedName = e.target.value.replace(
      ILLEGAL_TEAM_NAME_CHARACTERS,
      ""
    );

    setNewName(proposedName);
  }, []);

  return (
    <Box>
      <Flex alignItems="center" mb="r" flexWrap="wrap">
        <TeamName isEditMode={editMode} name={name} />
        <FlexItem flex="1 1 270px">
          <TeamNameInput
            isEditMode={editMode}
            isLoading={isLoading}
            value={newName}
            onChange={onNameChange}
            onSave={onSave}
            onCancel={onCancel}
            label={label}
          />
        </FlexItem>
        <EditBtn isEditMode={editMode} onEdit={onEdit} />
        <ConfirmAndCancelBtns
          isEditMode={editMode}
          isLoading={isLoading}
          canCancel={canCancel}
          saveDisabled={newName === name}
          onSave={onSave}
          onCancel={onCancel}
        />
      </Flex>
      {error && (
        <ErrorNotification message={error} error={error} report={false} />
      )}
    </Box>
  );
};

TeamNameEditor.propTypes = {
  team: PropTypes.object.isRequired,
  label: PropTypes.string,
  updateName: PropTypes.func,
  isEditMode: PropTypes.bool,
  canCancel: PropTypes.bool,
  autoExitEditMode: PropTypes.bool,
};

export default TeamNameEditor;
