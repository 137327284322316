import moment from "moment";
import { merge, mergeWith, isObject } from "lodash";
import { getOrgChartPropertiesFromLocalStorage } from "../utils/storeInLocalStorage";
import { ACTION_TYPES } from "./actionTypes";

export const initialOrgChartState = {
  // Teams to display
  teams: {},
  members: {},

  viewOptions: {
    groupMembersBySupplyOrDemand: false,
    showPeople: true,
    // fromDate: moment(), // Is BASELINE date
    fromDate: moment("2020-01-01"), // Is BASELINE date
    toDate: moment(), // Must be after baseline!,
    showChanges: false,
  },
  properties: {
    peopleData: [],
    teamsData: [],
  },
  expandedNodes: [],
  expandedPanel: {
    data: null,
  },
};

export const getInitialOrgChartState = ({ tagConfig, teamId }) => {
  const storedOrgChartProperties = getOrgChartPropertiesFromLocalStorage({
    tagConfig,
  });

  const defaultPanelEntityState = {
    expandedPanel: {
      data: {
        selectedEntity: teamId,
      },
    },
  };

  return merge(
    initialOrgChartState,
    storedOrgChartProperties,
    defaultPanelEntityState
  );
};

export const orgChartReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_VIEW_OPTIONS: {
      const showChangesHasChanged =
        action.payload.showChanges !== state.viewOptions.showChanges;

      if (showChangesHasChanged) {
        return {
          ...state,
          viewOptions: {
            ...state.viewOptions,
            ...action.payload,
          },
        };
      }

      return {
        ...state,
        viewOptions: {
          ...state.viewOptions,
          ...action.payload,
        },
      };
    }
    case ACTION_TYPES.UPDATE_PROPERTIES: {
      return {
        ...state,
        properties: {
          ...state.properties,
          ...action.payload,
        },
      };
    }

    case ACTION_TYPES.UPDATE_LOADED_TEAMS: {
      return {
        ...state,
        teams: { ...state.teams, ...action.payload },
      };
    }
    case ACTION_TYPES.UPDATE_PERSON_PARTIAL: {
      const { aggregateId } = action.payload;

      return {
        ...state,
        members: {
          ...state.members,
          [aggregateId]: {
            ...state.members[aggregateId],
            ...action.payload,
          },
        },
      };
    }
    case ACTION_TYPES.UPDATE_TEAM_TAGS: {
      const { teamId } = action.payload;

      return {
        ...state,
        teams: {
          ...state.teams,
          [teamId]: {
            ...state.teams[teamId],
            tags: action.payload.tags,
          },
        },
      };
    }
    case ACTION_TYPES.MERGE_PARTIAL_DATA: {
      const mergeHandler = (obj, source) => {
        if (isObject(obj) || isObject(source)) {
          return { ...obj, ...source };
        }

        return source || obj;
      };

      return {
        ...state,
        teams: mergeWith(state.teams, action.payload.teams, mergeHandler),
        members: mergeWith(state.members, action.payload.members, mergeHandler),
      };
    }
    case ACTION_TYPES.UPDATE_EXPANDED_PANEL: {
      return {
        ...state,
        expandedPanel: {
          ...state.expandedPanel,
          ...(action.payload.data !== undefined && {
            data: {
              ...state.expandedPanel.data,
              ...action.payload.data,
            },
          }),
        },
      };
    }
    default: {
      return state;
    }
  }
};
