import React, { useState, useCallback } from "react";
import { Box, Button, Icon, Popover } from "orcs-design-system";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import ErrorNotification from "src/components/ErrorNotification";

const JsonFileDropZone = ({ onImportFromJson, btnDescription, btnLabel }) => {
  const [fileError, setFileError] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFileError(null);

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        // reader.onabort = () => console.log("file reading was aborted");
        // reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          const str = reader.result;
          try {
            const jsonObj = JSON.parse(str);
            onImportFromJson(jsonObj);
          } catch (e) {
            setFileError(e);
          }
        };

        reader.readAsText(file);
      });
    },
    [onImportFromJson]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Box {...getRootProps()}>
      <input {...getInputProps()} />
      <Popover text={btnDescription} direction="topLeft" width="105px">
        <Button iconOnly variant="ghost" ariaLabel="Import json file">
          <Icon icon={["fas", "upload"]} />
          {btnLabel}
        </Button>
      </Popover>
      {fileError && (
        <ErrorNotification
          message={fileError.message}
          report={false}
          onDismiss={() => setFileError(null)}
        />
      )}
    </Box>
  );
};

JsonFileDropZone.propTypes = {
  onImportFromJson: PropTypes.func,
  btnDescription: PropTypes.string,
  btnLabel: PropTypes.node,
};

export default JsonFileDropZone;
