import { H3 } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const SectionHeader = ({ children, mt = "r" }) => {
  return (
    <H3 sizing="small" fontWeight="600" mt={mt} mb="xs">
      {children}
    </H3>
  );
};

SectionHeader.propTypes = {
  children: PropTypes.string,
  mt: PropTypes.string,
};

export default React.memo(SectionHeader);
