import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { isEmpty, map } from "lodash";
import styled from "styled-components";
import LinksListItem from "./LinksListItem";

const LinksListWrapper = styled.ul`
  padding: 0 0 0 20px;
  margin: 0;
`;

const LinksListNoBulletWrapper = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const LinksList = ({ links, hideBullets }) => {
  const renderedLinks = useMemo(
    () =>
      map(links, (link, i) => (
        <LinksListItem key={`cp-teamdetails-heading-link-${i}`} link={link} />
      )),
    [links]
  );

  if (isEmpty(renderedLinks)) {
    return null;
  }

  if (hideBullets) {
    return <LinksListNoBulletWrapper>{renderedLinks}</LinksListNoBulletWrapper>;
  }

  return <LinksListWrapper>{renderedLinks}</LinksListWrapper>;
};

LinksList.propTypes = {
  links: PropTypes.array,
  hideBullets: PropTypes.bool,
};

export default LinksList;
