import { useQuery } from "@apollo/client";
import { get, map, reduce, uniqBy, filter } from "lodash";
import { FlexItem, Select } from "orcs-design-system";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { canAddAssociation } from "src/util/associations";
import { numberToLocaleString } from "src/util/toLocaleString";
import useSortOptions, { sortPrecedence } from "src/hooks/useSortOptions";
import { GROUPS_SORT_OPTIONS } from "src/consts/group";
import GroupsList from "src/components/Team/GroupsList";
import { Line as PlaceholderLine } from "src/components/Placeholder/PlaceholderStyles";
import Panel from "src/components/Panel";
import GroupPropType from "src/custom-prop-types/group";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import { copywriting } from "src/pages/TeamPage/sub-components/TeamMembersPanel.config";
import { getTeamAssociationsQuery } from "src/queries/group.graphql";
import { getLocalSortOption } from "src/services/localStorage";
import { isGroupActive, sortGroupsByName } from "src/util/group";
import { SORT_INDEX } from "src/consts/storage";

import AddAssociationButton from "./AddAssociationButton";
import RemoveAssociationButton from "./RemoveAssociationButton";

const PLACEHOLDER_ROWS = 6;

const getGroupAssociations = (groupAssociations) => {
  return filter(groupAssociations, (g) => g.tag.type !== "NEW_PARENT");
};

const TeamAssociationsPanel = ({
  teamId,
  team,
  groupTypes,
  user,
  workspace,
  tagsVisibleIn,
}) => {
  const {
    data: groupAssociationsData,
    loading,
    refetch,
  } = useQuery(getTeamAssociationsQuery, {
    variables: {
      groupId: teamId,
    },
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    skip: !teamId,
  });

  const associations =
    get(groupAssociationsData, "team.groupAssociations") ||
    get(team, "groupAssociations");
  const groupAssociations = getGroupAssociations(associations);
  const associatedGroups = uniqBy(map(groupAssociations, "group"), "id");

  const tagsByGroupId = reduce(
    groupAssociations,
    (tagsByGroupIdPrevious, groupAssociation) => {
      if (!tagsByGroupIdPrevious[groupAssociation.group.id]) {
        // eslint-disable-next-line no-param-reassign
        tagsByGroupIdPrevious[groupAssociation.group.id] = [];
      }
      tagsByGroupIdPrevious[groupAssociation.group.id].push({
        ...groupAssociation.tag,
        associationDirection: groupAssociation.associationDirection,
      });
      return tagsByGroupIdPrevious;
    },
    {}
  );

  const initialSort = sortPrecedence({
    sortOptions: GROUPS_SORT_OPTIONS,
    sortTeamsByGroupType: get(
      workspace,
      "config.featureFlags.sortTeamsByGroupType"
    ),
    sessionSortValue: getLocalSortOption(SORT_INDEX.TEAM_ASSOCIATION_PANEL),
  });

  const { options, selectedSort, onSelectSortType } = useSortOptions(
    GROUPS_SORT_OPTIONS,
    SORT_INDEX.TEAM_ASSOCIATION_PANEL,
    initialSort
  );

  const showAddAssociation = canAddAssociation(user) && isGroupActive(team);
  const groups = loading
    ? Array(PLACEHOLDER_ROWS).fill(0)
    : sortGroupsByName(associatedGroups);

  const groupsCount = groups.length;
  const groupsCountDisplay = loading ? "-" : numberToLocaleString(groupsCount);

  const RemoveAssociationAction = useMemo(
    () =>
      // eslint-disable-next-line react/display-name, react/prop-types
      ({ team: associatedTeam, tags }) => {
        if (showAddAssociation) {
          return (
            <RemoveAssociationButton
              subjectTeam={team}
              associatedTeam={associatedTeam}
              tags={tags}
              refetch={refetch}
            />
          );
        }
        return null;
      },
    [showAddAssociation, team, refetch]
  );

  const GroupActions = Boolean(groupsCount) && (
    <FlexItem flex="0 0 140px">
      <Select
        data-testid="cp-team-explore-select"
        inputId="cp-team-explore-select"
        options={options}
        isSearchable={false}
        value={selectedSort}
        onChange={onSelectSortType}
        ariaLabel="Sort teams by"
      />
    </FlexItem>
  );

  return (
    <Panel
      title={`${groupsCountDisplay} ${pluralize(
        copywriting.associatedGroup,
        groupsCount
      )}`}
      height="100%"
      actions={GroupActions}
      wrap="wrap"
      data-testid="cp-associated-groups-container"
    >
      {loading && (
        <PlaceholderLine
          data-testid="cp-associated-groups-placeholder-title"
          width={240}
          height={54}
        />
      )}
      <GroupsList
        loading={loading}
        supportedGroups={groups}
        groupTypes={groupTypes}
        withSort
        selectedSort={selectedSort}
        tagsByGroupId={tagsByGroupId}
        showAssociationTagBadges
        actionItem={RemoveAssociationAction}
        largerColumns
        workspace={workspace}
      />
      {!loading && showAddAssociation && (
        <AddAssociationButton
          team={team}
          groupTypes={groupTypes}
          refetch={refetch}
          tagsVisibleIn={tagsVisibleIn}
        />
      )}
    </Panel>
  );
};

TeamAssociationsPanel.propTypes = {
  team: PropTypes.oneOfType([PropTypes.number, GroupPropType]),
  teamId: PropTypes.string,
  groupTypes: GroupTypesPropType,
  user: PropTypes.object,
  workspace: PropTypes.object,
  tagsVisibleIn: PropTypes.string,
};

export default React.memo(TeamAssociationsPanel);
