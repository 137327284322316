import { filter, flatten, forEach, includes, isEmpty, values } from "lodash";
import { TAG_ATTRIBUTE_TYPES } from "src/consts/tags";

/**
 * @param {Object} params
 * @param {Object} params.attributeType
 * @param {Object} params.attributes
 * @param {string} params.entityId
 * @returns {*} The value of the updated attribute, or undefined if not found.
 */
const getAttributeValue = ({ attributeType, attributes, entityId }) => {
  switch (attributeType?.type) {
    case TAG_ATTRIBUTE_TYPES.ENTITY_ID:
      if (entityId && !attributes?.[attributeType?.id]) {
        return entityId;
      }
      return attributes?.[attributeType?.id];
    default:
      return attributes?.[attributeType?.id];
  }
};

/**
 * @param {Object} params
 * @param {Object} params.attributes
 * @param {string} params.entityId
 * @param {Object} params.attributeTypes
 * @param {string[]} params.typeKeys
 * @returns {Object}
 */
const updateAttributes = ({
  attributes,
  entityId,
  attributeTypes,
  typeKeys,
}) => {
  const clonedAttributes = !isEmpty(attributes) ? { ...attributes } : {};
  const types = isEmpty(typeKeys)
    ? flatten(values(attributeTypes))
    : flatten(
        values(filter(attributeTypes, (_, key) => includes(typeKeys, key)))
      );

  forEach(types, (type) => {
    const value = getAttributeValue({
      attributeType: type,
      attributes: clonedAttributes,
      entityId,
    });
    if (value) {
      clonedAttributes[type.id] = value;
    }
  });
  return clonedAttributes;
};

/**
 * @param {Object} params
 * @param {Object} params.attributes
 * @param {string} params.entityId - The ID of the entity where a tag is being added.
 * @param {Object} params.tagType
 * @param {string[]} params.typeKeys
 * @returns {Object}
 */
export const getUpdatedAttributes = ({
  attributes,
  entityId,
  tagType,
  typeKeys,
}) => {
  if (!tagType || isEmpty(tagType?.attributes)) {
    return attributes;
  }

  return updateAttributes({
    attributes,
    entityId,
    attributeTypes: tagType.attributes,
    typeKeys,
  });
};
