import React from "react";
import { Button, Popover, Icon } from "orcs-design-system";
import PropTypes from "prop-types";

const SortIcon = ({ sortDsc, onClick }) => (
  <Popover
    width="100px"
    textAlign="center"
    direction="bottom"
    text={`Sort ${sortDsc === true ? "descending" : "ascending"}`}
  >
    <Button
      variant="ghost"
      iconOnly
      height="25px"
      width="25px"
      mr="xs"
      onClick={onClick}
      small
      ariaLabel={`Sort ${sortDsc === true ? "descending" : "ascending"}`}
    >
      <Icon
        icon={[
          "fas",
          sortDsc === true ? "sort-amount-down-alt" : "sort-amount-up-alt",
        ]}
      />
    </Button>
  </Popover>
);

SortIcon.propTypes = {
  onClick: PropTypes.func,
  sortDsc: PropTypes.bool.isRequired,
};

export default SortIcon;
