import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import TimelineGraph from "react-vis-timeline-3";

import { StyledContainer } from "./index.styles";

const Timeline = React.forwardRef(
  ({ options, initialGroups, initialItems, onTimelineRendered }, ref) => {
    const containerRef = useRef(null);

    useEffect(() => {
      if (!containerRef.current) {
        return;
      }

      const container = containerRef.current;

      const observer = new MutationObserver(() => {
        if (container.querySelector(".vis-timeline")) {
          observer.disconnect();
          onTimelineRendered();
        }
      });

      observer.observe(container, {
        childList: true,
        subtree: true,
      });
    });

    const mouseDownHandler = (e) => {
      if (e.event.target.style) {
        e.event.target.style.cursor = "ew-resize";
      }
    };

    return (
      <StyledContainer ref={containerRef}>
        <TimelineGraph
          ref={ref}
          options={options}
          initialItems={initialItems}
          initialGroups={initialGroups}
          mouseDownHandler={mouseDownHandler}
        />
      </StyledContainer>
    );
  }
);

Timeline.propTypes = {
  options: PropTypes.object,
  initialItems: PropTypes.array.isRequired,
  initialGroups: PropTypes.array.isRequired,
  onTimelineRendered: PropTypes.func.isRequired,
};

export default Timeline;
