import { map, get, pullAt, unset } from "lodash";
import {
  Box,
  Divider,
  Icon,
  Button,
  TextInput,
  H5,
  Small,
  Spacer,
  Flex,
  FlexItem,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import icon from "src/config/icons";
import { isValidUrl } from "src/util/url";

/**
 * Simple component to manage a list of url links
 *
 * Link shape is `{ label: string; url: string; error?: string }`
 *
 * Notes:
 * - [Expected] Links array should be stored in external state
 * - [Expected] Overwrite external links on `onLinksChange`
 * - [Optionally] Pre-populate internal links array via `preloadedLinks`
 * - [Optionally] Prevent modification via `isReadonly`
 */
const LinksList = ({
  title,
  preloadedLinks,
  onLinksChange,
  isReadonly = false,
  displayDividers = true,
}) => {
  const [links, setLinks] = useState(preloadedLinks || []);

  // one time validate initial links
  useEffect(() => {
    setLinks((list) => {
      return map(list, (link) => {
        if (!isValidUrl(link.url)) {
          return {
            ...link,
            error: "Please enter a valid URL",
          };
        }

        return link;
      });
    });
  }, []);

  // update parent when links change
  useEffect(() => {
    onLinksChange?.(links);
  }, [links, onLinksChange]);

  const onAddLink = useCallback(() => {
    setLinks((list) => [
      ...list,
      {
        label: "",
        url: "",
      },
    ]);
  }, [setLinks]);

  const onRemoveLink = useCallback(
    (index) => {
      setLinks((list) => {
        const copy = [...list];
        pullAt(copy, [index]);
        return copy;
      });
    },
    [setLinks]
  );

  const onUpdateLabel = useCallback(
    (index, event) => {
      setLinks((list) => {
        const copy = [...list];
        copy[index] = { ...copy[index], label: get(event, "target.value") };
        return copy;
      });
    },
    [setLinks]
  );

  const onUpdateUrl = useCallback(
    (index, event) => {
      setLinks((list) => {
        const copy = [...list];
        copy[index] = { ...copy[index], url: get(event, "target.value") };
        return copy;
      });
    },
    [setLinks]
  );

  const onValidateUrl = useCallback(
    (index, event) => {
      const url = get(event, "target.value");
      if (!isValidUrl(url)) {
        setLinks((list) => {
          const copy = [...list];
          copy[index] = { ...copy[index], error: "Please enter a valid URL" };
          return copy;
        });
      } else {
        setLinks((list) => {
          const copy = [...list];
          unset(copy[index], "error");
          return copy;
        });
      }
    },
    [setLinks]
  );

  return (
    <div id="cp-linkslist" data-testid="cp-linkslist">
      {title && (
        <H5 weight="bold" mb="r">
          {title}
        </H5>
      )}
      {map(links, (link, i) => {
        return (
          <Box key={`cp-linkslist-link-${i}`} mb={4}>
            <Spacer mb="s">
              <TextInput
                fullWidth
                id={`cp-linkslist-link-label-${i}`}
                data-testid={`cp-linkslist-link-label-${i}`}
                key={`cp-linkslist-link-label-${i}`}
                onChange={(e) => onUpdateLabel(i, e)}
                value={link.label}
                type="text"
                label="Link text"
                placeholder="Add the text that will show with this link"
                disabled={isReadonly}
              />
              <TextInput
                fullWidth
                id={`cp-linkslist-link-url-${i}`}
                data-testid={`cp-linkslist-link-url-${i}`}
                key={`cp-linkslist-link-url-${i}`}
                onBlur={(e) => onValidateUrl(i, e)}
                onChange={(e) => {
                  onUpdateUrl(i, e);
                  onValidateUrl(i, e);
                }}
                invalid={link.error}
                value={link.url}
                type="text"
                label="Link URL"
                placeholder="Enter URL for link (e.g. Jira, Confluence, Trello, etc.)"
                disabled={isReadonly}
              />
              <Flex justifyContent="space-between">
                <FlexItem align="left">
                  {link.error && (
                    <Small
                      id={`cp-linkslist-link-error-${i}`}
                      data-testid={`cp-linkslist-link-error-${i}`}
                      color="danger"
                    >
                      <Icon icon={icon.exclamation} mr={2} />
                      {link.error}
                    </Small>
                  )}
                </FlexItem>
                <FlexItem align="right">
                  <Button
                    id={`cp-linkslist-delete-${i}`}
                    data-testid={`cp-linkslist-delete-${i}`}
                    onClick={() => onRemoveLink(i)}
                    iconLeft
                    small
                    variant="danger"
                    disabled={isReadonly}
                  >
                    <Icon icon={icon.trash} />
                    Delete link
                  </Button>
                </FlexItem>
              </Flex>
              {displayDividers && <Divider my="r" />}
            </Spacer>
          </Box>
        );
      })}
      <Button
        id="cp-linkslist-add"
        data-testid="cp-linkslist-add"
        onClick={() => onAddLink()}
        disabled={isReadonly}
        iconLeft
        small
      >
        <Icon icon={icon.plus} />
        Add link
      </Button>
    </div>
  );
};

LinksList.propTypes = {
  title: PropTypes.string,
  preloadedLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  onLinksChange: PropTypes.func,
  isReadonly: PropTypes.bool,
  displayDividers: PropTypes.bool,
};

export default LinksList;
