import { useQuery } from "@apollo/client";
import { isEmpty, get } from "lodash";
import { Notification, StyledLink, Flex, FlexItem } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { isDemand } from "src/util/customerConfig";
import { getTeamMembersQuery } from "src/queries/group.graphql";
import {
  getGroupDemandTypeFilters,
  getGroupSupplyTypeFilters,
} from "src/util/group";
import { getTeamsPath } from "src/util/paths";

import BulkMoveMembersModal from "./components/BulkMoveMembersModal/index";
import MembersListSection from "./components/MembersListSection/index";
import TeamSearchSection from "./components/TeamSearchSection/index";
import useBulkMoveMembers from "./hooks/useBulkMoveMembers";
import { getSortedMembers } from "./utils";

const BulkMoveMembersModalContainer = ({
  team,
  isVisible,
  onHideModal,
  selectedPeople,
  groupTypes,
  allocationProject,
}) => {
  const { data } = useQuery(getTeamMembersQuery, {
    variables: { groupId: team?.id },
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    skip: !team?.id,
  });

  const {
    handleSelectPerson,
    handleBulkMove,
    handleCloseModal,
    setTargetGroupOption,
    setSelectedMembers,
    error,
    targetGroupOption,
    bulkMoveSuccess,
    selectedMembers,
    isProcessing,
  } = useBulkMoveMembers({
    team,
    selectedPeople,
    allocationProject,
    onHideModal,
  });

  const groupFilter = isDemand(groupTypes, team)
    ? getGroupDemandTypeFilters(groupTypes)
    : getGroupSupplyTypeFilters(groupTypes);

  return (
    <BulkMoveMembersModal
      team={team}
      onHideModal={handleCloseModal}
      isVisible={isVisible}
      isProcessing={isProcessing}
      handleBulkMove={handleBulkMove}
      selectedMembers={selectedMembers}
    >
      <Flex alignItems="stretch" height="calc(100% - 16px)" mt="r">
        <MembersListSection
          group={team}
          groupTypes={groupTypes}
          isProcessing={isProcessing}
          peopleList={getSortedMembers(data?.team?.members)}
          selectedMembers={selectedMembers}
          setSelectedMembers={setSelectedMembers}
          onSelect={handleSelectPerson}
        />
        <FlexItem flex="1 1 50%" ml="s">
          <TeamSearchSection
            team={team}
            setTargetGroupOption={setTargetGroupOption}
            isProcessing={isProcessing}
            typeFilters={groupFilter}
            groupTypes={groupTypes}
            error={error}
            selectedMembers={selectedMembers}
          />
        </FlexItem>
      </Flex>
      {!isEmpty(targetGroupOption) && bulkMoveSuccess && isEmpty(error) && (
        <Notification centered floating colour="success" bottom="20px">
          Bulk move successfully completed.
          <StyledLink
            onClick={handleCloseModal}
            white
            bold
            to={getTeamsPath(get(targetGroupOption, "group.id"))}
          >
            Click here to go to the team you chose to move to.
          </StyledLink>
        </Notification>
      )}
    </BulkMoveMembersModal>
  );
};

BulkMoveMembersModalContainer.propTypes = {
  team: PropTypes.object,
  isVisible: PropTypes.bool,
  onHideModal: PropTypes.func,
  selectedPeople: PropTypes.object,
  groupTypes: PropTypes.object,
  allocationProject: PropTypes.func,
};

export default BulkMoveMembersModalContainer;
