/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  Expandable,
  Flex,
  FlexItem,
  Loading,
  P,
  Spacer,
  Table,
} from "orcs-design-system";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useInView } from "react-intersection-observer";
import { useQuery } from "@apollo/client";
import icons from "src/config/icons";

import ErrorNotification from "src/components/ErrorNotification";
import {
  getTransaction,
  getTransactionEffects,
} from "./ImportTransactionDetails.graphql";

const PAGE_LIMIT = 2000;

const EntityTypeDetails = ({ transactionId, entityType }) => {
  const [changes, setChanges] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [pagesLoading, setPagesLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const { ref, inView } = useInView({
    /* Optional options */
    triggerOnce: true,
  });

  const { error } = useQuery(getTransactionEffects, {
    skip: !inView,
    variables: {
      transactionId,
      entityType,
      offset: pageCount * PAGE_LIMIT,
      limit: PAGE_LIMIT,
      includeTotal: pageCount === 0,
    },
    onCompleted: (data) => {
      setChanges([...changes, ...data.result.changes]);
      if (data.result.total) {
        setTotal(data.result.total);
      }
      if (data.result.changes.length === PAGE_LIMIT) {
        setPageCount(pageCount + 1);
      } else {
        setPagesLoading(false);
      }
    },
  });

  const columns = [
    ...(entityType === "group" || entityType === "person"
      ? [
          {
            header: "Id",
            accessorKey: "entityId",
          },
        ]
      : /** membershipId = personId + groupId */ [
          { header: "Person Id", accessorKey: "entityId.personId" },
          { header: "Group Id", accessorKey: "entityId.groupId" },
        ]),
    {
      header: "Attribute",
      accessorKey: "attributeType",
    },
    {
      header: "Value",
      accessorKey: "value",
      accessorFn: (row) => {
        if (row.value === null) {
          return null;
        }

        if (typeof row.value === "string") {
          return row.value;
        }
        return JSON.stringify(row.value);
      },
    },
    ...(entityType === "group" || entityType === "person"
      ? [
          {
            header: "Existing",
            accessorKey: "existingValue",
            accessorFn: (row) => {
              if (row.existingValue === null) {
                return null;
              }

              if (typeof row.existingValue === "string") {
                return row.existingValue;
              }
              return JSON.stringify(row.existingValue);
            },
          },
        ]
      : []),
  ];

  return (
    <div ref={ref}>
      {pagesLoading && (
        <Flex>
          <Loading />
          <Spacer p="s" />
          {total > 0 && (
            <P>
              {changes.length} of {total} attributes
            </P>
          )}
        </Flex>
      )}
      {error && (
        <ErrorNotification
          message={
            error?.message ?? "Unknown error loading transaction group details"
          }
          report={true}
          floating={false}
        />
      )}
      {!error && changes && (
        <Table
          data={changes}
          columns={columns}
          enableRowVirtualization={true}
          rowVirtualizerOptions={{
            overscan: 5, // adjust the number or rows that are rendered above and below the visible area of the table
          }}
          enablePagination={false}
        />
      )}
    </div>
  );
};

EntityTypeDetails.propTypes = {
  transactionId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
};

const TransactionDetails = ({ transaction, importId }) => {
  if (!transaction) {
    return (
      <ul>
        <li>Import ID: {importId}</li>
        <li>No changes detected</li>
      </ul>
    );
  }

  return (
    <Flex flexDirection="column" alignItems="stretch">
      <ul>
        <li>Import/Transaction ID: {transaction.transactionId}</li>
        <li>{transaction.isReverted ? "Not in effect" : "In effect"}</li>
        <li>Effective Date: {transaction.pit}</li>
        <li>Import Date: {transaction.timestamp}</li>
      </ul>
      <FlexItem>
        <Expandable
          title={`${transaction.effects?.groups?.total} Groups`}
          small={true}
        >
          <EntityTypeDetails
            entityType="group"
            transactionId={transaction.transactionId}
          />
        </Expandable>
      </FlexItem>
      <FlexItem>
        <Expandable
          title={`${transaction.effects?.people?.total} People`}
          small
        >
          <EntityTypeDetails
            entityType="person"
            transactionId={transaction.transactionId}
          />
        </Expandable>
      </FlexItem>
      <FlexItem>
        <Expandable
          title={`${transaction.effects?.memberships?.total} Memberships`}
          small
        >
          <EntityTypeDetails
            entityType="membership"
            transactionId={transaction.transactionId}
          />
        </Expandable>
      </FlexItem>
    </Flex>
  );
};

TransactionDetails.propTypes = {
  transaction: PropTypes.object,
  importId: PropTypes.string.isRequired,
};

const ImportTransactionDetails = ({ importId }) => {
  const { ref, inView } = useInView({
    /* Optional options */
    triggerOnce: true,
  });

  const { data, loading, error } = useQuery(getTransaction, {
    skip: !inView,
    variables: {
      transactionId: importId,
    },
  });

  if (loading) {
    return <Loading />;
  }

  const transaction = data?.result?.transactions?.[0];
  return (
    <div ref={ref}>
      <Card
        alternate
        title="Details"
        boxBorder="default"
        icon={icons.infoCircle}
        colour="success"
      >
        {loading && <Loading />}
        {!loading && !error && (
          <TransactionDetails transaction={transaction} importId={importId} />
        )}
        {error && (
          <ErrorNotification
            message={
              error?.message ?? "Unknown error loading transaction details"
            }
            report={true}
            floating={false}
          />
        )}
      </Card>
    </div>
  );
};

ImportTransactionDetails.propTypes = {
  importId: PropTypes.string.isRequired,
};

export default ImportTransactionDetails;
