import { isEmpty } from "lodash";
import { useCallback, useEffect, useState, useMemo } from "react";

import { useFeatureFlags } from "src/contexts/global/FeatureFlagsContext";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import {
  updateTreeObject,
  buildTreeFromGroups,
} from "src/shared/TreeView/TreeView.util";

import useGroupsTreeData from "src/components/GroupsNavigation/useGroupsTreeData";

const useLoadGroupsTree = ({
  filteredGroups,
  targetGroupTypes,
  additionalVariables,
  createTreeNodeImpl,
  convertChildrenToArrayImpl,
  addGroupsToRootsImpl,
}) => {
  const workspace = useWorkspace();
  const featureFlags = useFeatureFlags();
  const [groupsTree, setGroupsTree] = useState([]);

  const { treeData, ...others } = useGroupsTreeData({
    workspace,
    featureFlags,
    isSelected: true,
    targetGroupTypes,
    additionalVariables,
    createTreeNodeImpl,
    convertChildrenToArrayImpl,
    addGroupsToRootsImpl,
  });

  const filteredTree = useMemo(() => {
    const tree = buildTreeFromGroups(
      filteredGroups,
      "none",
      convertChildrenToArrayImpl
    );
    return tree;
  }, [convertChildrenToArrayImpl, filteredGroups]);

  // Put the treeData into a state, so that we can force a re-render by updating it
  useEffect(() => {
    if (isEmpty(filteredTree)) {
      setGroupsTree(treeData);
    } else {
      setGroupsTree(filteredTree);
    }
  }, [filteredTree, treeData]);

  const refreshTree = useCallback(() => {
    setGroupsTree((td) => updateTreeObject(td));
  }, []);

  const navigateTo = useCallback(() => {}, []);

  return {
    ...others,
    treeData: groupsTree,
    navigateTo,
    refreshTree,
  };
};

export default useLoadGroupsTree;
