import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { slice } from "lodash";
import { Box, Loading } from "orcs-design-system";

import DataGrid from "./DataGrid";
import StaticMappingTable from "./StaticMappingTable";
import FieldsMappingEditor from "./FieldsMappingEditor";
import createFileProcessWorker from "./fileProcessWorker";

const FieldsMapping = ({
  datasource,
  file,
  fileContent,
  defaultImportFields,
  updateDatasource,
  isSavingDatasource,
  savingDatasourceError,
  setIsNewMapping,
  importFlags,
}) => {
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [header, setHeader] = useState(null);

  const isCsvFile = file?.type === "text/csv";

  useEffect(() => {
    if (!fileContent || !file) {
      return () => {};
    }

    setLoading(true);
    setHeader(null);
    setFileData(null);

    const worker = createFileProcessWorker();

    worker.onmessage = (e) => {
      const { status, data } = e.data;
      if (status === "ready") {
        return;
      }
      if (status === "done" && data) {
        setLoading(false);
        const firstPart = slice(data, 0, 10);
        setFileData(firstPart);

        if (isCsvFile) {
          setHeader(firstPart[0]);
        }
      }
    };
    worker.postMessage({
      fileContent,
      options: { type: "binary" },
      url: window.location.origin,
    });

    return () => {
      worker.terminate();
    };
  }, [file, fileContent, isCsvFile]);

  if (!file) {
    return (
      <StaticMappingTable
        datasource={datasource}
        defaultFieldsMap={defaultImportFields?.fieldsMap}
        importFlags={importFlags}
      />
    );
  }

  return (
    <Box bg="white">
      {loading && <Loading />}
      <DataGrid
        loading={loading}
        data={fileData}
        setHeader={setHeader}
        canSetHeader={!isCsvFile}
      />
      <FieldsMappingEditor
        datasource={datasource}
        headerFields={header}
        defaultImportFields={defaultImportFields}
        updateDatasource={updateDatasource}
        isSavingDatasource={isSavingDatasource}
        savingDatasourceError={savingDatasourceError}
        setIsNewMapping={setIsNewMapping}
        importFlags={importFlags}
      />
    </Box>
  );
};

FieldsMapping.propTypes = {
  datasource: PropTypes.object,
  file: PropTypes.object,
  fileContent: PropTypes.string,
  defaultImportFields: PropTypes.object,
  updateDatasource: PropTypes.func,
  isSavingDatasource: PropTypes.bool,
  setIsNewMapping: PropTypes.func,
  savingDatasourceError: PropTypes.object,
  importFlags: PropTypes.object,
};

export default FieldsMapping;
