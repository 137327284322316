import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Controls,
  MiniMap,
  Panel as RFPanel,
  useNodesState,
  useEdgesState,
  useReactFlow,
  getNodesBounds,
} from "reactflow";
import PropTypes from "prop-types";
import styled, { createGlobalStyle, useTheme } from "styled-components";
import { get } from "lodash";
import { themeGet } from "@styled-system/theme-get";
import "reactflow/dist/style.css";
import { StyledReactFlow } from "./node.styled";
import PersonNode from "./Nodes/PersonNode";
import { NODE_THEMES, NODE_TYPES } from "./consts";
import ObjectiveNode from "./Nodes/ObjectiveNode";
import { EDGE_COLOR } from "./Nodes/node.styled";
import ObjectiveHeaderNode from "./Nodes/ObjectiveHeaderNode";
import { useObjectiveState } from "./contexts/ObjectiveProviderContext";
import MoreObjectivesNode from "./Nodes/MoreObjectivesNode";
import { THEME } from "./Nodes/ObjectiveNode/node.styled";
import ObjectiveEdge from "./Edges/ObjectiveEdge";
import { useEdgeEvents } from "./contexts/EdgeEventProvider";
import GroupNode from "./Nodes/GroupNode";
import CollectionNode from "./Nodes/CollectionNode";
import ExpandButtonNode from "./Nodes/ExpandButtonNode";
import { useNodeLayout, isValidNodePosition } from "./hooks/useNodeLayout";
import CustomControlButton from "./components/CustomControlButton";

const ReactFlowAnimations = createGlobalStyle`
  @keyframes react-flow-edge-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
const StyledControls = styled(Controls)`
  &.react-flow__controls {
    bottom: 27px !important;
    left: 0 !important;
    margin: 0 !important;
  }
`;

const proOptions = {
  account: "paid-pro",
  hideAttribution: true,
};

const nodeTypes = {
  [NODE_TYPES.PERSON_NODE]: PersonNode,
  [NODE_TYPES.OBJECTIVE_NODE]: ObjectiveNode,
  [NODE_TYPES.OBJECTIVE_HEADER_NODE]: ObjectiveHeaderNode,
  [NODE_TYPES.SHOW_MORE_OBJECTIVES_NODE]: MoreObjectivesNode,
  [NODE_TYPES.GROUP_NODE]: GroupNode,
  [NODE_TYPES.COLLECTION_NODE]: CollectionNode,
  [NODE_TYPES.EXPAND_BUTTON_NODE]: ExpandButtonNode,
};

const defaultEdgeOptions = {
  animated: false,
  style: {
    strokeWidth: 2,
    stroke: EDGE_COLOR,
    animation: "react-flow-edge-fade-in 1.75s",
  },
};

const edgeTypes = { "objective-edge": ObjectiveEdge };

export default function ReactFlowLayout(props) {
  const {
    onNodeClick = () => null,
    getLayout,
    initialNodes,
    initialEdges,
    afterLayout,
    showMiniMap,
    controlsPosition = "bottom-left",
    children,
    animatedNodesEnabled = false,
    toggleFullScreen,
    isFullScreen,
    shouldPreventUpdatingNodes,
    shouldUseRemoteControl = false,
    fitToBounds = true,
  } = props;
  const reactFlow = useReactFlow();
  const edgeEvents = useEdgeEvents();

  //   const [layoutMode, setLayoutMode] = useState(layoutModes[0]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [moveStartCoords, setMoveStartCoords] = useState();
  const [isLayouted, setIsLayouted] = useState(false);
  const themeContext = useTheme();

  const [preventScrolling, setPreventScrolling] = useState(true);
  const { mutateNodesAndEdges } = useObjectiveState();

  const { readyForLayout, layoutPositions } = useNodeLayout({
    getLayout,
    nodes,
    edges,
    setNodes,
    setEdges,
    setIsLayouted,
    animatedNodesEnabled,
    initialNodes,
  });

  const minimapStyle = {
    height: 120,
    borderRadius: themeGet("radii.2")({ theme: themeContext }),
    margin: themeGet("space.r")({ theme: themeContext }),
    overflow: "hidden",
    backgroundColor: "rgba(255,255,255,0.8)",
  };

  useEffect(() => {
    setNodes((previousNodes) => {
      const updatedNodes = initialNodes.map((node) => {
        const trackedNode = previousNodes.find((tNode) => node.id === tNode.id);

        if (trackedNode) {
          return {
            ...node,
            width: trackedNode.width,
            height: trackedNode.height,
          };
        }

        return node;
      });
      if (shouldPreventUpdatingNodes && shouldPreventUpdatingNodes()) {
        return previousNodes;
      }

      return updatedNodes;
    });
    setEdges(initialEdges);
  }, [
    initialNodes,
    initialEdges,
    setNodes,
    setEdges,
    shouldPreventUpdatingNodes,
  ]);

  useEffect(() => {
    if (!isLayouted) {
      return;
    }

    afterLayout?.({ nodes, edges, reactFlow });
  }, [isLayouted, nodes, edges, reactFlow, afterLayout]);

  const visibleEdges = useMemo(() => {
    return edges.filter((edge) => {
      const source = nodes.find((node) => node.id === edge.source);
      const target = nodes.find((node) => node.id === edge.target);
      const hasLayout = source?.data.isLayouted && target?.data.isLayouted;

      return !source?.data.hidden && !target?.data.hidden && hasLayout;
    });
  }, [edges, nodes]);

  const visibleNodes = useMemo(() => {
    return nodes.map((node) => {
      return { ...node, zIndex: node.data?.hidden ? -1 : node.zIndex || 10 };
    });
  }, [nodes]);

  const [mutatedNodes, mutatedEdges] = useMemo(() => {
    const [mutNodes, mutEdges] = mutateNodesAndEdges(
      visibleNodes,
      visibleEdges,
      readyForLayout
    );

    const forcePositionNodes = mutNodes.map((node) => {
      if (isValidNodePosition(node.position)) {
        return node;
      }

      const prevPosition = layoutPositions[node.id];
      if (prevPosition) {
        return {
          ...node,
          data: { ...node.data, isLayouted: true },
          position: prevPosition,
        };
      }

      return node;
    });

    return [forcePositionNodes, mutEdges];
  }, [
    visibleNodes,
    visibleEdges,
    mutateNodesAndEdges,
    readyForLayout,
    layoutPositions,
  ]);

  const onMoveStart = useCallback(
    (_, coords) => setMoveStartCoords(coords),
    [setMoveStartCoords]
  );

  const onMoveEnd = useCallback(
    (e, coords) => {
      if (moveStartCoords === undefined) {
        return;
      }

      const dx = coords.x - moveStartCoords.x;
      const dy = coords.y - moveStartCoords.y;

      const distance = Math.sqrt(dx * dx + dy * dy);

      // Sometimes a click is mistaken for a drag/pan movement by react-flow.
      // This method checks if the movement traveled is small enough to be considered a click and triggers the node expand method.
      if (distance <= 1) {
        nodes.forEach((node) => {
          const nodeEl = document.getElementById(`node-${node.id}`);

          if (nodeEl?.contains(e.target)) {
            onNodeClick(node);
          }
        });
      }
    },
    [moveStartCoords, nodes, onNodeClick]
  );

  const onMouseMove = useCallback(
    (e) => {
      const scrollableElements = document.querySelectorAll(
        '[data-is-scrollable="true"]'
      );
      let shouldPreventScrolling = true;
      scrollableElements.forEach((el) => {
        if (el.contains(e.target)) {
          shouldPreventScrolling = false;
        }
      });

      if (shouldPreventScrolling !== preventScrolling) {
        setPreventScrolling(shouldPreventScrolling);
      }
    },
    [preventScrolling]
  );

  let translateExtent;
  if (fitToBounds) {
    const bounds = getNodesBounds(mutatedNodes);
    const paddingX = bounds.width;
    const paddingY = bounds.height;
    translateExtent = [
      [bounds.x - paddingX, bounds.y - 100],
      [bounds.x + bounds.width + paddingX, bounds.y + bounds.height + paddingY],
    ];
  }

  return (
    <StyledReactFlow
      panOnScroll={true}
      autoPanOnNodeDrag={false}
      panOnDrag={2}
      nodes={mutatedNodes}
      edges={mutatedEdges}
      translateExtent={translateExtent}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onMoveStart={onMoveStart}
      onMoveEnd={onMoveEnd}
      onMouseMove={onMouseMove}
      preventScrolling={preventScrolling}
      nodesDraggable={false}
      nodesConnectable={false}
      zoomOnDoubleClick={false}
      elementsSelectable={true}
      defaultEdgeOptions={defaultEdgeOptions}
      proOptions={proOptions}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onNodeClick={onNodeClick}
      nodeDragThreshold={100}
      fitView={true}
      onlyRenderVisibleElements={mutatedNodes.length > 100}
      maxZoom={2}
      minZoom={0.15}
      {...edgeEvents.reactFlowProps}
    >
      <ReactFlowAnimations />
      {!shouldUseRemoteControl && (
        <RFPanel position={controlsPosition}>
          <StyledControls showInteractive={false} className="controls" />
          <CustomControlButton
            onClick={toggleFullScreen}
            label="Toggle full screen"
            icon={[
              "fas",
              isFullScreen ? "compress-arrows-alt" : "expand-arrows-alt",
            ]}
          />
        </RFPanel>
      )}
      {children}
      {showMiniMap && (
        <MiniMap
          style={minimapStyle}
          zoomable
          pannable
          nodeColor={(node) => {
            const theme = THEME[node.data.nodeType];

            if (
              node.type === NODE_TYPES.OBJECTIVE_HEADER_NODE &&
              node.data.nodeType === NODE_THEMES.TEAM
            ) {
              return "white";
            }

            return get(themeContext, [
              "colors",
              `${theme?.color || "primary"}Lighter`,
            ]);
          }}
          maskColor="rgba(0,0,0,0.5)"
        />
      )}
    </StyledReactFlow>
  );
}

ReactFlowLayout.propTypes = {
  initialNodes: PropTypes.array,
  initialEdges: PropTypes.array,
  onNodeClick: PropTypes.func,
  getLayout: PropTypes.func,
  afterLayout: PropTypes.func,
  showMiniMap: PropTypes.bool,
  controlsPosition: PropTypes.string,
  animatedNodesEnabled: PropTypes.bool,
  children: PropTypes.node,
  toggleFullScreen: PropTypes.func,
  isFullScreen: PropTypes.bool,
  shouldPreventUpdatingNodes: PropTypes.func,
  shouldUseRemoteControl: PropTypes.bool,
  fitToBounds: PropTypes.bool,
};
