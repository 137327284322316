const updateStyles = (customStyles) => {
  const { multiValueLabel } = customStyles;
  const updatedMultiValueLabel = (provided, state) => {
    const styles = multiValueLabel(provided, state);

    return {
      ...styles,
      backgroundColor: "transparent",
      padding: "0 !important",
      borderRadius: "0 !important",
    };
  };

  const menuList = (styles) => ({
    ...styles,
    backgroundColor: "white",
    maxHeight: "250px",
  });

  const option = (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? "#d2eeff" : "white",
    ":hover": {
      backgroundColor: "#d2eeff",
    },
  });

  const singleValue = (styles) => ({
    ...styles,
    display: "flex",
  });

  return {
    ...customStyles,
    menuList,
    option,
    singleValue,
    multiValueLabel: updatedMultiValueLabel,
  };
};

export default updateStyles;
