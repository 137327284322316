import { first, get, last } from "lodash";
import {
  Box,
  Flex,
  Button,
  Icon,
  H4,
  Modal,
  Spacer,
  Small,
  Grid,
  GridItem,
  Divider,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState, useCallback, useEffect } from "react";

import GroupTypeBadge from "src/components/Badge/GroupTypeBadge";
import { moveGroupRealTime } from "src/queries/mutations/group.graphql";
import EventBus, { EVENTS } from "src/services/eventEmitter";
import GroupHierarchyBreadcrumb from "src/components/GroupHierarchyBreadcrumb";
import TeamDropdown from "src/components/TeamDropdown";
import ErrorNotification from "src/components/ErrorNotification";
import { useLoadGroup, useSearchGroups } from "src/hooks/useSearchGroups";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { getValidParentTypes } from "src/util/group";

const MoveGroupModal = ({
  isVisible,
  onHideModal,
  group,
  groupTypes,
  onGroupMoved,
  eventEmitter = EventBus,
}) => {
  // filter parent groups
  const groupFilter = getValidParentTypes(groupTypes, group);

  const { loadOptions, isSearching, client } = useSearchGroups({
    groupTypes: groupFilter,
    hideHidden: true,
  });
  const [newParent, setNewParent] = useState(null);
  const [curParent, setCurParent] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [apiError, setApiError] = useState(null);
  const { loadGroup, isLoading } = useLoadGroup();

  // load current parent when need
  useEffect(() => {
    const parentId = last(group.parentIds);
    if (parentId && (!curParent || curParent.id !== parentId) && !isLoading) {
      loadGroup(parentId).then((parentGroup) => {
        if (!parentGroup) {
          return;
        }
        setCurParent(parentGroup);
      });
    }
  });

  const onCancelModal = useCallback(() => {
    onHideModal();
    setApiError(null);
    setNewParent(null);
  }, [onHideModal, setApiError]);

  const onSelectNewParent = useCallback(
    (option) => {
      setNewParent(option);
      setApiError(null);
    },
    [setNewParent, setApiError]
  );

  const onMoveGroup = useCallback(async () => {
    setIsProcessing(true);
    setApiError(null);

    try {
      const response = await client.mutate({
        mutation: moveGroupRealTime,
        variables: {
          input: {
            targetGroupId: group.id,
            parentGroupId: newParent.value,
          },
        },
      });

      const { directGroups, validationError } = get(
        response,
        "data.result",
        {}
      );

      // Failed to move group?
      if (validationError) {
        setApiError(new Error(validationError));
        return;
      }

      // Update sidebar navigation hierarchy
      const updatedGroup = first(directGroups);
      if (updatedGroup) {
        // Finish all other tasks first, then refresh the side bar tree
        eventEmitter.emit(EVENTS.GROUP_REMOVED, group);
        eventEmitter.emit(EVENTS.NEW_GROUP_ADDED, updatedGroup);
      }

      setNewParent(null);
      if (onGroupMoved) {
        onGroupMoved(updatedGroup, parent);
      }
    } catch (err) {
      setApiError(
        new Error(
          "Sorry, an error occurred, please refresh and try search again"
        )
      );
    } finally {
      setIsProcessing(false);
      trackEvent(EVENT_TRACKING.TEAM_MOVED);
    }
  }, [
    group,
    newParent,
    setIsProcessing,
    setApiError,
    client,
    onGroupMoved,
    eventEmitter,
  ]);

  const isMoveBtnEnabled = group && newParent && !isProcessing;
  const isCancelBtnEnabled = !isProcessing;

  return (
    <Modal
      visible={isVisible}
      width={["90vw", "90vw", "80vw", "70vw", "60vw"]}
      maxHeight="90vh"
      maxWidth="90vw"
      onClose={onCancelModal}
      headerContent={
        <Flex flexWrap="wrap">
          <H4 mr="s">Move team:</H4>
          <Flex flexWrap="wrap">
            <H4 mr="s">{group.name}</H4>
            <GroupTypeBadge group={group} groupTypes={groupTypes} />
          </Flex>
        </Flex>
      }
      data-testid="cp-move-group-modal"
      overflow="visible"
    >
      <Box p="s">
        <Spacer my="s">
          <TeamDropdown
            id="move-group-select-parent"
            label="Select new parent team"
            placeholder="Search for new parent team"
            isSearchable
            isLoading={isSearching}
            options={[]}
            loadOptions={loadOptions}
            onSelectTeam={onSelectNewParent}
            value={newParent}
            cacheOptions={false}
            groupTypes={groupTypes}
            height="54px"
          />
        </Spacer>
        <Box mt="r">
          <Grid
            gridAutoFlow="row"
            gridTemplateColumns="1fr 40px 1fr"
            gridGap="0px"
          >
            <GridItem>
              <Flex
                role="heading"
                aria-level="5"
                justifyContent="left"
                flexWrap="no-wrap"
              >
                <Small fontSize="1" fontWeight="bold" mb="s">
                  Current parent
                </Small>
              </Flex>
              {curParent && (
                <Flex justifyContent="left" flexWrap="wrap">
                  <GroupHierarchyBreadcrumb group={curParent} />
                  <GroupTypeBadge group={curParent} groupTypes={groupTypes} />
                </Flex>
              )}
            </GridItem>
            <GridItem>
              {curParent && newParent && (
                <Flex height="100%" justifyContent="center" alignItems="center">
                  <Icon mx="s" icon={["fas", "chevron-right"]} />
                </Flex>
              )}
            </GridItem>
            <GridItem>
              <Flex
                role="heading"
                aria-level="5"
                justifyContent="right"
                flexWrap="no-wrap"
              >
                <Small fontSize="1" fontWeight="bold" mb="s">
                  New parent
                </Small>
              </Flex>
              {newParent && (
                <Flex justifyContent="right" flexWrap="wrap">
                  <GroupHierarchyBreadcrumb group={newParent?.group} />
                  <GroupTypeBadge
                    group={newParent?.group}
                    groupTypes={groupTypes}
                  />
                </Flex>
              )}
            </GridItem>
          </Grid>
        </Box>
        <Divider my="l" />
        <Flex flexDirection={["column", "row"]}>
          <Button
            variant={isMoveBtnEnabled ? "success" : "disabled"}
            onClick={onMoveGroup}
            isLoading={isProcessing}
            iconLeft
            mr={["0", "r"]}
            mb={["s", "0"]}
          >
            <Icon icon={["fas", "chevron-right"]} />
            Change parent
          </Button>
          <Button
            variant={isCancelBtnEnabled ? "ghost" : "disabled"}
            onClick={onCancelModal}
            iconLeft
          >
            <Icon icon={["fas", "times"]} />
            Cancel
          </Button>
        </Flex>
      </Box>
      {apiError && (
        <ErrorNotification message={apiError?.message} error={apiError} />
      )}
    </Modal>
  );
};

MoveGroupModal.propTypes = {
  group: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onHideModal: PropTypes.func,
  groupTypes: GroupTypesPropType,
  onGroupMoved: PropTypes.func,
  eventEmitter: PropTypes.object,
};

export default MoveGroupModal;
