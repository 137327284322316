import { isEmpty, slice, last } from "lodash";
import { useEffect } from "react";

import EventBus, { EVENTS } from "src/services/eventEmitter";
import { NOOP } from "src/util/consts";
import {
  findTreeNodes,
  createTreeNode,
} from "src/shared/TreeView/TreeView.util";

const useAddNewGroupListener = (
  root,
  groupTypes,
  refreshTree,
  eventEmitter = EventBus
) => {
  useEffect(() => {
    if (isEmpty(root)) {
      return NOOP;
    }

    const handler = (newGroup) => {
      Promise.resolve().then(() => {
        if (!newGroup) {
          return;
        }
        const { hierarchyIds: ids } = newGroup;
        // Find tree nodes by hierarchyIds, the children of the node may not be loaded
        const parentIds = slice(ids, 0, -1);
        const parentNodes = findTreeNodes(root, parentIds);
        const parentNode = last(parentNodes);

        // Loaded parent nodes not reaching the direct parent of the new group
        // It will be loaded when the parent node reached, so do nothing here
        if (parentNodes.length < parentIds.length) {
          return;
        }

        // Parent node is in the tree, but not loaded, need to add the toggle icon
        // as we already know there is a children under this parent
        if (parentNode.loading) {
          if (!parentNode.children) {
            parentNode.children = [];
          }
          return;
        }

        // Add the new node into the parent node children
        const newNode = createTreeNode(newGroup, groupTypes);
        if (parentNode.children) {
          parentNode.children.push(newNode);
        } else {
          parentNode.children = [newNode];
        }
        refreshTree();
      });
    };

    eventEmitter.on(EVENTS.NEW_GROUP_ADDED, handler);

    return () => {
      eventEmitter.removeListener(EVENTS.NEW_GROUP_ADDED, handler);
    };
  }, [root, groupTypes, refreshTree, eventEmitter]);
};

export default useAddNewGroupListener;
