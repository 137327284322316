import React from "react";
import PropTypes from "prop-types";
import { keys, map, omit } from "lodash";
import { Box, P, H4 } from "orcs-design-system";

import { isReservedFields } from "./FieldsMappingEditor.util";
import { StyledTable } from "./MappingTable.styles";
import FieldsMapFlagEditor from "./FieldsMapFlagEditor";

const MappingTable = ({ datasource, defaultFieldsMap, importFlags }) => {
  const savedMap = datasource?.fieldsMap;
  const fieldsMap = savedMap || defaultFieldsMap;

  const mapToUse = omit(fieldsMap, keys(importFlags));

  const headers = keys(mapToUse).sort();

  return (
    <Box overflowX="scroll" width="100%" bg="white">
      <H4 my="s" fontWeight="bold">
        {savedMap ? "Saved fields mapping" : "Default fields mapping"}
      </H4>
      <FieldsMapFlagEditor fieldsMap={fieldsMap} importFlags={importFlags} />
      <StyledTable>
        <thead>
          <tr>
            <th>Source fields</th>
            <th>Mapping</th>
            <th>Target fields</th>
          </tr>
        </thead>
        <tbody>
          {map(headers, (fieldName) => {
            const value = mapToUse[fieldName];
            const isReserved = isReservedFields(fieldName);
            return (
              <tr key={fieldName}>
                <td>
                  <P minWidth="200px">{fieldName}</P>
                </td>
                <td>{value ? "Import" : "Don't import"}</td>
                <td>
                  {map(value, (v, k) => {
                    const label = v && v.label ? `:${v.label}` : "";
                    return (
                      <P key={k} minWidth="200px">
                        {`${k}${label}${isReserved ? " - reserved" : ""}`}
                      </P>
                    );
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </Box>
  );
};

MappingTable.propTypes = {
  datasource: PropTypes.object,
  defaultFieldsMap: PropTypes.object,
  importFlags: PropTypes.object,
};

export default MappingTable;
