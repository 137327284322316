import PropTypes from "prop-types";
import React from "react";
import { Flex, H4, P } from "orcs-design-system";

const ImportDateHeading = ({ importDateFromNow, importDate }) => (
  <Flex alignItems="baseline">
    <H4 mr="s">Viewing data as of {importDateFromNow}</H4>
    <P color="greyDarker">({importDate})</P>
  </Flex>
);

ImportDateHeading.propTypes = {
  importDateFromNow: PropTypes.string,
  importDate: PropTypes.string,
};

export default ImportDateHeading;
