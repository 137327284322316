import { get } from "lodash";
import React from "react";
import PropTypes from "prop-types";
import PersonItem from "src/components/Person/PersonItem";
import { useAuth0 } from "src/contexts/auth0Provider";
import { useUserPerson } from "src/contexts/global/GlobalContext";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { getSupplyBadgeGroups } from "src/util/group";
import { isValidPerson } from "src/util/person";

export const Reports = ({ person }) => {
  const { user } = useAuth0();
  const userPerson = useUserPerson();
  const workspace = useWorkspace();
  const groupTypes = get(workspace, "config.groupTypes");
  const featureFlags = get(workspace, "config.featureFlags", {});

  const hideDirectReports = get(
    workspace,
    "config.allocation.hideDirectReports"
  );
  const managingPerson = get(person, "managedBy");
  const showReportsTo = isValidPerson(managingPerson) && !hideDirectReports;

  if (!showReportsTo) {
    return <em>None</em>;
  }

  return (
    <PersonItem
      reportsTo
      isDisplayActionsButton={false}
      person={managingPerson}
      badgeGroups={getSupplyBadgeGroups({
        person: managingPerson,
        groupTypes,
      })}
      groupTypes={groupTypes}
      user={user}
      workspace={workspace}
      featureFlags={featureFlags}
      userPerson={userPerson}
      isLastItem
      excludeMargin
    />
  );
};

Reports.propTypes = {
  person: PropTypes.object,
};
