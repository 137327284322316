import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { loadScript } from "common-dom-utils";
import { H4 } from "orcs-design-system";

import { NOOP } from "src/allocation/consts";

const loadCanvasDatagrid = () => {
  return loadScript("/libs/canvas-datagrid-0.3.24.js");
};

// Load canvas-datagrid
if (!customElements.get("canvas-grid")) {
  loadCanvasDatagrid();
}

const Args = {
  allowColumnReordering: false,
  allowColumnResize: false,
  allowRowHeaderResize: false,
  allowRowResize: false,
  allowSorting: false,
  editable: false,
  columnHeaderClickBehavior: "none",
};

const DataGrid = ({ loading, data, setHeader, canSetHeader }) => {
  const datagridRef = useRef();

  useEffect(() => {
    const datagrid = datagridRef.current;

    if (!datagrid) {
      return;
    }

    map(Args, (v, k) => {
      datagrid.attributes[k] = v;
    });
  }, []);

  useEffect(() => {
    const datagrid = datagridRef.current;

    if (!datagrid) {
      return NOOP;
    }

    if (!data) {
      datagrid.data = [];
      return NOOP;
    }

    // Needs to fill in first row with empty cells, or canvas-datagrid does not show all fields
    let maxLength = 0;
    data.forEach((r) => {
      if (maxLength < r.length) {
        maxLength = r.length;
      }
    });

    for (let i = data[0].length; i < maxLength; i += 1) {
      // eslint-disable-next-line no-param-reassign
      data[0][i] = "";
    }

    datagrid.data = data;

    // Set the datagrid height and width to show the scroll bar
    setTimeout(() => {
      datagrid.style.width = "100%";
      datagrid.style.height = "280px";
    }, 150);

    if (!canSetHeader) {
      return () => {};
    }

    const clickHandler = (e) => {
      setHeader(e.cell.data);
    };
    datagrid.addEventListener("click", clickHandler);

    return () => {
      datagrid.removeEventListener("click", clickHandler);
    };
  }, [data, setHeader, canSetHeader]);

  return (
    <div hidden={loading || !data}>
      <H4 mt="l" mb="s" fontWeight="bold">
        {canSetHeader ? "Please click the header row" : "File preview"}
      </H4>
      <canvas-datagrid ref={datagridRef} />
    </div>
  );
};

DataGrid.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  setHeader: PropTypes.func,
  canSetHeader: PropTypes.bool,
};

export default DataGrid;
