import { map, get, cloneDeep, forEach, isEmpty } from "lodash";
import {
  Box,
  Icon,
  Button,
  TextInput,
  Small,
  Flex,
  FlexItem,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import icon from "src/config/icons";
import { attributeTypes } from "src/util/groupAttributes";
import { isValidUrl } from "src/util/url";

const isLinkAttr = (attr) => attr.attributeType === attributeTypes.LINK;
// this is how links are deleted via the useUpdateTeamAttributes hook
const isDeletedAttr = (attr) => attr.deleted === true;
// some links have a readonly subtype, namely JIRA links (Duncan knows more about this)
// TODO: remove the subtype-readonly check after JIRA integration refactor.
// The current, preferred way to make a URL readonly is to use: `readonly: true`
// on the attribute. Using `subtype: "readonly"` is deprecated behaviour.
// However we still support it, because currently subtype-readonly is in use
// by the Jira integration.
const isReadonlyAttr = (attr) => attr.subtype === "readonly" || attr.readonly;

/**
 * Editable list of team link attributes for use with useUpdateTeamAttributes
 *
 * NOTE:
 *  - If the allTeamAttributes prop changes, state will be reset
 *
 * @param {object} allTeamAttributes All team attributes (don't filter them)
 * @param {function} onAttributesChange altered attributes
 * @param {boolean} isReadonly Whether all links are readonly
 * @param {React.node} dividingContent Content between each link (e.g. a Divider)
 */
export const TeamAttributeLinksList = ({
  allTeamAttributes = [],
  onAttributesChange,
  readonly = false,
  dividingContent,
}) => {
  const [attributes, setAttributes] = useState(allTeamAttributes);
  const [errors, setErrors] = useState({});

  // update inner attributes when parent changes (after team mutates)
  useEffect(() => {
    setAttributes(allTeamAttributes);
  }, [allTeamAttributes]);

  // validate values on change
  useEffect(() => {
    const errs = {};
    forEach(attributes, (attr, i) => {
      if (
        isLinkAttr(attr) &&
        !isDeletedAttr(attr) &&
        !isValidUrl(attr.value || "")
      ) {
        errs[i] = "Please enter a valid URL";
      }
    });

    setErrors(errs);
  }, [attributes]);

  // update parent when links change
  useEffect(() => {
    onAttributesChange?.(attributes, errors);
  }, [errors, attributes, onAttributesChange]);

  const onAddLink = useCallback(() => {
    setAttributes([
      ...attributes,
      {
        // id populated by backend
        attributeType: attributeTypes.LINK,
        label: "",
        value: "",
      },
    ]);
  }, [attributes]);

  const onRemoveLink = useCallback(
    (index) => {
      const attrs = cloneDeep(attributes);
      // if link has id, it exists in backend, so mark it as deleted for backend
      if (!isEmpty(attrs[index].id)) {
        attrs[index].deleted = true;
      }
      // if link has no id, it's new and can be removed without notifying backend
      else {
        attrs.splice(index, 1);
      }
      setAttributes(attrs);
    },
    [attributes]
  );

  const onUpdateLabel = useCallback(
    (index, event) => {
      const attrs = cloneDeep(attributes);
      attrs[index].label = get(event, "target.value");
      setAttributes(attrs);
    },
    [attributes]
  );

  const onUpdateValue = useCallback(
    (index, event) => {
      const attrs = cloneDeep(attributes);
      attrs[index].value = get(event, "target.value");
      setAttributes(attrs);
    },
    [attributes]
  );

  return (
    <div id="cp-linkslist" data-testid="cp-linkslist">
      {
        /*
          Iterating all attrs (including non-links) is intentional, it allows us to rely on an attribute's index
          to find the correct attribute to update. This is necessary when adding 1+ new links that have no id
          (until the backend assignes them, perhaps via the useUpdateTeamAttributes hook).
        */
        map(attributes, (attr, i) => {
          if (!isLinkAttr(attr) || isDeletedAttr(attr)) {
            return null;
          }

          const attrIsReadonly = readonly || isReadonlyAttr(attr);
          const attrError = errors[i];

          return (
            <Box key={`cp-linkslist-link-${i}`}>
              <Flex flexDirection="column" gap="s">
                <TextInput
                  fullWidth
                  id={`cp-linkslist-link-label-${i}`}
                  data-testid={`cp-linkslist-link-label-${i}`}
                  onChange={(e) => onUpdateLabel(i, e)}
                  value={attr.label}
                  type="text"
                  label="Link text"
                  placeholder="Add the text that will show with this link"
                  disabled={attrIsReadonly}
                />
                <TextInput
                  fullWidth
                  id={`cp-linkslist-link-url-${i}`}
                  data-testid={`cp-linkslist-link-url-${i}`}
                  onChange={(e) => onUpdateValue(i, e)}
                  invalid={attrError}
                  value={attr.value}
                  type="text"
                  label="Link URL"
                  placeholder="Enter URL for link (e.g. Jira, Confluence, Trello, etc.)"
                  disabled={attrIsReadonly}
                />
                <Flex justifyContent="space-between">
                  <FlexItem align="left">
                    {attrError && (
                      <Small
                        id={`cp-linkslist-link-error-${i}`}
                        data-testid={`cp-linkslist-link-error-${i}`}
                        color="danger"
                      >
                        <Icon icon={icon.exclamation} mr={2} />
                        {attrError}
                      </Small>
                    )}
                  </FlexItem>
                  <FlexItem align="right">
                    <Button
                      id={`cp-linkslist-delete-${i}`}
                      data-testid={`cp-linkslist-delete-${i}`}
                      onClick={() => onRemoveLink(i)}
                      iconLeft
                      small
                      variant="danger"
                      disabled={attrIsReadonly}
                    >
                      <Icon icon={icon.trash} />
                      Delete link
                    </Button>
                  </FlexItem>
                </Flex>
                {dividingContent}
              </Flex>
            </Box>
          );
        })
      }
      <Button
        id="cp-linkslist-add"
        data-testid="cp-linkslist-add"
        onClick={onAddLink}
        disabled={readonly}
        iconLeft
        small
        mt="r"
      >
        <Icon icon={icon.plus} />
        Add link
      </Button>
    </div>
  );
};

TeamAttributeLinksList.propTypes = {
  allTeamAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      subtype: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onAttributesChange: PropTypes.func,
  readonly: PropTypes.bool,
  dividingContent: PropTypes.node,
};
