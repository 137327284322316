import { find, sortBy } from "lodash";
import { createEdge } from "./shared";
import { createMembersCollectionNode } from "./createMembersCollectionNode";

export const getNodesAndEdgesGroupMembersByGroupType = ({
  type,
  contextualData,
  state,
}) => {
  const { groupTypes } = contextualData;

  const allMembers = Object.values(state.members);

  const expandedTeams = state.expandedNodes.map(
    (nodeId) => state.teams[nodeId]
  );

  const nodes = [];
  const edges = [];
  const memberCollections = {};

  const updateMemberCollections = ({ group, member, groupParentId }) => {
    if (memberCollections[group.id]) {
      if (
        !find(memberCollections[group.id].members, {
          aggregateId: member.aggregateId,
        })
      ) {
        memberCollections[group.id].members.push(member);
      }
    } else {
      memberCollections[group.id] = {
        name: group.name,
        group,
        members: [member],
        groupParentId,
      };
    }
  };

  expandedTeams.forEach((expandedTeam) => {
    const expandedTeamMembers = allMembers.filter((member) =>
      member.memberOf.map((t) => t.id).includes(expandedTeam.id)
    );

    expandedTeamMembers.forEach((member) => {
      const groupableTeams = member.memberOf.filter((group) => {
        const groupType = groupTypes[group.type];

        if (!groupType) {
          return false;
        }

        if (type === "supply" && groupType.isSupply) {
          return true;
        }

        if (type === "demand" && groupType.isDemand) {
          return true;
        }

        return false;
      });

      groupableTeams.forEach((group) => {
        updateMemberCollections({
          group,
          member,
          groupParentId: expandedTeam.id,
        });
      });

      if (!groupableTeams.length) {
        updateMemberCollections({
          group: { id: "unallocated", name: "Unallocated" },
          member,
          groupParentId: expandedTeam.id,
        });
      }
    });
  });

  const sortedCollections = sortBy(Object.values(memberCollections), "name");

  sortedCollections.forEach((collection) => {
    const membersNode = createMembersCollectionNode({
      members: collection.members,
      group: collection.group,
      isGroupedBySupplyOrDemand: true,
      contextualData,
      state,
    });

    const membersEdge = createEdge(collection.groupParentId, membersNode.id);

    nodes.push(membersNode);
    edges.push(membersEdge);
  });

  return {
    nodes,
    edges,
  };
};
