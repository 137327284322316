import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Avatar } from "orcs-design-system";

import { useGlobalConfig } from "src/contexts/global/GlobalContext";
import { getGroupBgImg } from "src/util/group";
import { getTeamGradient, AvatarWithGradient } from "src/util/gradient";

const TeamAvatar = ({ group, ...props }) => {
  const globalConfig = useGlobalConfig();

  const image = useMemo(() => {
    return getGroupBgImg(group);
  }, [group]);

  const bgGradient = useMemo(() => {
    return !image && getTeamGradient(globalConfig);
  }, [image, globalConfig]);

  const AvatarComponent = useMemo(() => {
    return image ? Avatar : AvatarWithGradient;
  }, [image]);

  return (
    <AvatarComponent
      shape="square"
      image={image}
      imageAlt={`Avatar of ${group.name}`}
      bgGradient={bgGradient}
      {...props}
    />
  );
};

TeamAvatar.propTypes = {
  group: PropTypes.object,
};

export default TeamAvatar;
