import React from "react";
import PropTypes from "prop-types";
import { Spacer } from "orcs-design-system";

const HelpContent = ({ text }) => {
  return (
    <Spacer p="xs">
      {text
        .split("\n") // respect new lines
        .map((t) => t.trim()) // clean up white space
        .filter((t) => t) // remove empties
        .map((t) => {
          return <div key={t}>{t}</div>;
        })}
    </Spacer>
  );
};

HelpContent.propTypes = {
  text: PropTypes.string.isRequired,
};

export default HelpContent;
