import React from "react";
import { Box, Flex, Spacer } from "orcs-design-system";
import styled from "styled-components";

import { Circle, Line } from "src/components/Placeholder/PlaceholderStyles";
import { SourceHandle, StyledCard, TargetHandle } from "../node.styled";

const PositionedCirclePlaceholder = styled.div`
  position: absolute;
  left: calc(50% - 10px);
  bottom: -10px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
`;

const GroupNodeSkeleton = ({ ...props }) => {
  return (
    <StyledCard width="400px" cursor="grab" {...props}>
      <TargetHandle $hidden type="target" position="top" />
      <Spacer mb="r">
        <Flex alignItems="flex-start" justifyContent="space-between">
          <Flex alignItems="center">
            <Box mr="r">
              <Line width={70} height={70} />
            </Box>
            <Flex alignItems="flex-start" flexDirection="column" gap="4px">
              <Line width={150} height={16} />
              <Line width={100} height={12} />
            </Flex>
          </Flex>

          <Line width={30} height={30} />
        </Flex>
      </Spacer>
      <Flex justifyContent="space-between">
        <Line width={40} height={12} />
        <Line width={60} height={12} />
      </Flex>

      <SourceHandle $hidden type="source" position="bottom" />
      <PositionedCirclePlaceholder>
        <Circle width={20} height={20} />
      </PositionedCirclePlaceholder>
    </StyledCard>
  );
};

export default GroupNodeSkeleton;
