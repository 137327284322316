import React from "react";
import PropTypes from "prop-types";

export const GroupsSearchContext = React.createContext({});

export const useGroupsSearchContext = () => {
  return React.useContext(GroupsSearchContext);
};

export const GroupsSearchProvider = ({ children, value }) => {
  return (
    <GroupsSearchContext.Provider value={value}>
      {children}
    </GroupsSearchContext.Provider>
  );
};

GroupsSearchProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object,
};
