import { cloneDeep } from "lodash";

const localStorageLayoutKey = () => `summaryPageLayout-v11`;

const getLayoutFromLocalStorage = (storageKey) => {
  if (window.localStorage) {
    try {
      return JSON.parse(window.localStorage.getItem(storageKey));
    } catch {
      return undefined;
    }
  }
  return undefined;
};

const resetDimensionsToDefault = (cards = [], defaultLayout) => {
  // TODO simplify and make this logic more performant
  // eslint-disable-next-line no-restricted-syntax
  for (const card of cards) {
    const defaultCard = defaultLayout.lg.find((c) => c.i === card.i);
    if (defaultCard) {
      card.h = defaultCard.h;
      card.w = defaultCard.w;
    }
  }
};

const saveLayoutToLocalStorage = (layout, defaultLayout, storageKey) => {
  if (window.localStorage) {
    // don't save updated heights and widths (they are dynamic)
    const persistedLayout = cloneDeep(layout);
    resetDimensionsToDefault(persistedLayout.xs, defaultLayout);
    resetDimensionsToDefault(persistedLayout.sm, defaultLayout);
    resetDimensionsToDefault(persistedLayout.md, defaultLayout);
    resetDimensionsToDefault(persistedLayout.lg, defaultLayout);

    window.localStorage.setItem(storageKey, JSON.stringify(persistedLayout));
  }
};

export {
  localStorageLayoutKey,
  getLayoutFromLocalStorage,
  saveLayoutToLocalStorage,
};
