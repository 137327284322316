/* istanbul ignore file */
import { Flex, Button, P, Modal } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const MarkActiveConfirmationDialog = ({
  onConfirm,
  onClose,
  currentActiveWorkspace,
  chosenActiveWorkspace,
}) => {
  const modalFooter = (
    <Flex mt="r">
      <Button variant="danger" onClick={onConfirm}>
        Yes, switch the active workspace
      </Button>
      <Button variant="ghost" onClick={onClose} ml="r">
        Cancel
      </Button>
    </Flex>
  );
  return (
    <Modal
      visible={true}
      handleOnConfirm={onConfirm}
      onClose={onClose}
      footerContent={modalFooter}
      width="50vw"
      height="40vh"
      maxHeight="90vh"
      maxWidth="90vw"
    >
      <P weight="bold" fontSize={3}>
        This will change the default workspace from{" "}
        {currentActiveWorkspace.name} ({currentActiveWorkspace.id}) to{" "}
        {chosenActiveWorkspace.name} ({chosenActiveWorkspace.id}) ).
      </P>
      <P mt="r" fontSize={3}>
        Changing the default workspace is rarely done, please confirm your
        intent.
      </P>
      <P mt="r" fontSize={3}>
        Please note:
      </P>
      <P mt="r" fontSize={3}>
        - Workspace will become available to all users regardless of workspace
        access rules. Any restriction to ADMIN users or the allow list will be
        ignored.
      </P>
      <P mt="r" fontSize={3}>
        - Reporting will automatically switch to the default workspace for the
        -user datasource. This may impact existing queries if they rely on data
        from the previous default workspace.
      </P>
      <P mt="r" fontSize={3}>
        - Users who have saved links to particular teams, people or tags may
        no-longer work and will receive 404 errors if they try the saved links
      </P>
    </Modal>
  );
};

MarkActiveConfirmationDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  currentActiveWorkspace: PropTypes.object.isRequired,
  chosenActiveWorkspace: PropTypes.object.isRequired,
};

export default MarkActiveConfirmationDialog;
