import React from "react";
import PropTypes from "prop-types";
import { Card, Box, Spacer, P, H3 } from "orcs-design-system";
import {
  capitalize,
  mapValues,
  groupBy,
  every,
  map,
  get,
  replace,
  isEmpty,
  keys,
} from "lodash";
import pluralize from "pluralize";
import icons from "src/config/icons";
import TeamLink from "src/components/TeamLink";

/* eslint-disable react/prop-types */

// there may be others but these are recognised by the UI right now
export const CATEGORY_TITLES = {
  cycles_detected: "Invalid tree structure detected",
  duplicate_team_names: "Duplicate team names",
  group_schema_error: "Cannot apply imported group to existing records",
  group_upsert_parameter_schema_error: "Import data in invalid format",
  group_type_mismatch:
    "Invalid types applied according to active group type configuration",
  orphaned_teams: "Invalid parent group",
  upsert_metadata_invalid: "Import metadata contains invalid fields",
};
export const ERROR_CATEGORIES = keys(CATEGORY_TITLES);

const InvalidGroupsList = ({ groups, kind }) => {
  if (isEmpty(groups)) {
    return null;
  }
  return (
    <ul>
      {map(groups, (group) => (
        <li key={group.id}>
          {group.name}, ID: {group.id}, type: {group.type}, parent id:{" "}
          {group.directParentId || "n/a"} (
          {kind !== "objective" && (
            <TeamLink team={group} teamName="Details page" muted />
          )}
          {group.directParentId && kind !== "objective" && (
            <>
              {", "}
              <TeamLink
                ml="r"
                team={{ id: group.directParentId }}
                teamName="Parent details page"
                muted
              />
            </>
          )}
          )
        </li>
      ))}
    </ul>
  );
};

const ImportValidationError = ({ validationError, kind }) => {
  const { message, groups } = validationError;
  return (
    <Box>
      <P>{message}</P>
      <InvalidGroupsList groups={groups} kind={kind} />
    </Box>
  );
};
const ImportValidationErrorCategoryCard = ({
  validationErrors,
  errorCategory,
  showAsErrors,
  kind,
}) => {
  if (isEmpty(validationErrors)) {
    return null;
  }

  const kindDisplayName = replace(kind, /_/g, " ");

  const categoryTitle = CATEGORY_TITLES[errorCategory] || errorCategory;
  return (
    <Card
      boxBorder="default"
      colour={showAsErrors ? "danger" : "warning"}
      icon={icons.exclamationTriangle}
      title={`${capitalize(pluralize(kindDisplayName))}: ${categoryTitle}`}
      alternate
    >
      <Spacer my="l">
        {map(validationErrors, (validationError) => (
          <ImportValidationError
            key={get(validationError, "key")}
            validationError={validationError}
            kind={kind}
          />
        ))}
      </Spacer>
    </Card>
  );
};
const ImportValidationErrorsForGroupKind = ({
  validationErrors,
  kind,
  showAsErrors,
}) => {
  if (isEmpty(validationErrors)) {
    return null;
  }
  const validationErrorsByCategory = groupBy(validationErrors, "errorCategory");
  return (
    <Spacer my="r">
      {map(
        validationErrorsByCategory,
        (validationErrorsForCategory, errorCategory) => (
          <ImportValidationErrorCategoryCard
            kind={kind}
            showAsErrors={showAsErrors}
            validationErrors={validationErrorsForCategory}
            key={errorCategory}
            errorCategory={errorCategory}
          />
        )
      )}
    </Spacer>
  );
};

const ImportValidationErrors = ({ metrics, showAsErrors }) => {
  const groupMetrics = get(metrics, "groups", {});
  const validationErrorsByGroupKind = mapValues(
    groupMetrics,
    "validationErrors"
  );

  if (every(validationErrorsByGroupKind, isEmpty)) {
    return null;
  }

  // only groups have validation errors right now (this could change)
  return (
    <Box my="r">
      {" "}
      <H3>
        {" "}
        {showAsErrors ? "Validation checks failed" : "Validation warnings"}
      </H3>
      <P my="r">
        {showAsErrors
          ? `Imported data caused one or more mandatory checks to fail. Please correct the errors below and try. Please contact support if you have further questions about these messages.`
          : `Imported data in the import caused one or more mandatory checks to fail. The workspace is configured to ignore validation errors on import. Consider reviewing and addressing the errors below to ensure data quality.`}
      </P>
      {map(validationErrorsByGroupKind, (validationErrors, kind) => (
        <ImportValidationErrorsForGroupKind
          key={kind}
          kind={kind}
          validationErrors={validationErrors}
          showAsErrors={showAsErrors}
        />
      ))}
    </Box>
  );
};

ImportValidationErrors.propTypes = {
  metrics: PropTypes.object,
  showAsErrors: PropTypes.bool,
};

export default ImportValidationErrors;
