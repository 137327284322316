import { NODE_TYPES } from "src/components/NodeVisualizer/consts";
import { DIFF_TYPES } from "src/components/OrgChart/context/utils/getCalculatedState";

export const createNode = (id, data, type, position = { x: 0, y: 0 }) => {
  const diffType = data?.diff?.[data?.directParentId]?._type;
  const isExpandable = ![DIFF_TYPES.MOVED_OUT, DIFF_TYPES.REMOVED].includes(
    diffType
  );

  const nodeId = isExpandable ? id : `${id}${diffType ? `-${diffType}` : ""}`;

  const node = {
    id: nodeId,
    isExpandable,
    data: { ...data, diffType, isExpandable },
    type,
    position,
  };

  if (data?.isAnchor) {
    node.isAnchor = true;
  }

  return node;
};

export const createCollectionNode = ({
  id,
  items,
  label,
  prefixItemCountToLabel,
  data = {},
}) => {
  return createNode(
    id,
    { ...data, id, items, label, prefixItemCountToLabel },
    NODE_TYPES.COLLECTION_NODE
  );
};

export const getCreatableNodeTypes = ({
  groupType,
  isGroupedBySupplyOrDemand,
  group,
  canAllocateMembers,
}) => {
  if (
    (groupType?.isSupply && !isGroupedBySupplyOrDemand) ||
    group.id === "unallocated"
  ) {
    return [];
  }

  return [
    { type: NODE_TYPES.CREATE_PERSON_NODE, canCreate: canAllocateMembers },
  ];
};

export const createEdge = (source, target, type = "smoothstep") => ({
  id: `${target}-${source}`,
  source,
  target,
  type,
});
