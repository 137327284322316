import React from "react";
import PropTypes from "prop-types";
import { toLower } from "lodash";
import { Badge } from "orcs-design-system";
import styled, { css } from "styled-components";
import { JIRA_STATUS_COLOR_TO_BADGE_VARIANT } from "src/consts/jira";
import { OBJECTIVE_SOURCE } from "src/consts/objectives";
import colorToLuminance from "src/util/colorToLuminance";

// sets text color based on luminance of background color (defaults to black)
const StyledBadge = styled(Badge)(
  ({ statusColor }) => css`
    background-color: ${statusColor};
    color: ${(colorToLuminance(statusColor) || 1) > 0.65 ? "black" : "white"};
  `
);

const BADGE_VARIANTS = [
  "success",
  "warning",
  "danger",
  "primaryLight",
  "primary",
  "primaryDark",
  "secondary",
];

const StatusCell = ({ row }) => {
  const { datasource, status, statusColor } = row.original;

  // no status?
  if (!status) {
    return null;
  }

  // if status color source is jira, translate color to orcs variant
  if (datasource?.type === OBJECTIVE_SOURCE.Jira) {
    const variant = JIRA_STATUS_COLOR_TO_BADGE_VARIANT[toLower(statusColor)];
    return <Badge variant={variant ?? "default"}>{status}</Badge>;
  }

  // status color is actually a badge variant?
  if (BADGE_VARIANTS.includes(statusColor)) {
    return <Badge variant={statusColor}>{status}</Badge>;
  }

  // else use raw status color
  return <StyledBadge statusColor={statusColor}>{status}</StyledBadge>;
};

StatusCell.propTypes = {
  row: PropTypes.object,
};

export default StatusCell;
