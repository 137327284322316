import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Box, H2, Flex, Icon } from "orcs-design-system";

import { themeGet } from "@styled-system/theme-get";

const BaseCard = styled(Box)`
  position: relative;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  overflow: hidden;
  height: ${(props) => (props.height ? props.height : "auto")};
`;

const FancyCard = (props, height) => (
  <BaseCard
    bg="white"
    borderRadius="2"
    boxBorder="default"
    height={height}
    p="r"
    {...props}
  />
);

const FancyCardHeaderWrapper = styled.div`
  flex: 0 0 auto;
  border-bottom: solid 1px ${(props) => themeGet("colors.greyLighter")(props)};
  padding-bottom: ${(props) => themeGet("space.r")(props)};
`;

export const FancyCardHeader = ({ icon, children, title }) => (
  <FancyCardHeaderWrapper>
    {title ? (
      <Flex alignItems="center">
        {icon && <Icon icon={icon} size="lg" mr="xxs" />}
        <H2 sizing="small" weight="light">
          {title}
        </H2>
      </Flex>
    ) : null}
    {children}
  </FancyCardHeaderWrapper>
);

export const FancyCardBody = styled.div`
  --scrollbar-size: 8px;
  --scrollbar-minlength: 30px; /* Minimum length of scrollbar thumb (width of horizontal, height of vertical) */
  --scrollbar-ff-width: thin; /* FF-only accepts auto, thin, none */
  --scrollbar-track-color: rgba(0, 0, 0, 0.05);
  --scrollbar-color: rgba(0, 0, 0, 0.2);
  --scrollbar-color-hover: rgba(0, 0, 0, 0.35);
  --scrollbar-color-active: rgba(0, 0, 0, 0.5);
  flex: 1 1 auto;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: var(--scrollbar-ff-width);
  scrollbar-color: var(--scrollbar-color) var(--scrollbar-track-color);
  &::-webkit-scrollbar {
    height: var(--scrollbar-size);
    width: var(--scrollbar-size);
  }
  &::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color-hover);
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: var(--scrollbar-color-active);
  }
  &::-webkit-scrollbar-thumb:vertical {
    min-height: var(--scrollbar-minlength);
  }
  &::-webkit-scrollbar-thumb:horizontal {
    min-width: var(--scrollbar-minlength);
  }
`;

export const FancyCardFooter = styled.div`
  flex: 0 0 auto;
  border-top: solid 1px ${(props) => themeGet("colors.greyLighter")(props)};
  padding-top: ${(props) => themeGet("space.r")(props)};
  &:empty {
    display: none;
  }
`;

FancyCardHeader.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  icon: PropTypes.arrayOf(PropTypes.string),
};

export default FancyCard;
