import React, { useState } from "react";
import PropTypes from "prop-types";
import { P, Select, Box } from "orcs-design-system";
import { isEmpty } from "lodash";

import { getOptionByValue } from "src/util/selectUtil";
import TargetFieldsSelector from "./TargetFieldsSelector";
import { isDisabledField } from "./FieldsMappingEditor.util";

const EmptyOption = {
  label: "Don't import",
  value: false,
};

const ImportOption = {
  label: "Import",
  value: true,
};

const ImportSelectOptions = [EmptyOption, ImportOption];

const MappingTableRow = ({
  header,
  targets,
  fieldOptions,
  updateField,
  clearField,
}) => {
  const [isImport, setIsImport] = useState(!isEmpty(targets));

  const onImportSelectChange = (option) => {
    // delete or set the target
    const next = !!option?.value;
    setIsImport(next);

    if (!next) {
      clearField(header);
    }
  };

  const onTargetFieldSelected = (targetField, oldTargetField) => {
    updateField(header, targetField, oldTargetField);

    if (targetField) {
      setIsImport(true);
    }
  };

  // Disable Teams field for now - Teams:xxxx rows in people csv import
  const disabled = isDisabledField(header);

  return (
    <tr>
      <td>
        <P>{header}</P>
      </td>
      <td>
        <Box minWidth="135px">
          {disabled ? (
            <P>Import</P>
          ) : (
            <Select
              data-testid="cp-select-import"
              ariaLabel="Select whether to import this field or not"
              placeholder="Don't import"
              options={ImportSelectOptions}
              value={getOptionByValue(ImportSelectOptions, isImport)}
              onChange={onImportSelectChange}
            />
          )}
        </Box>
      </td>
      <td>
        <Box minWidth="300px">
          <TargetFieldsSelector
            header={header}
            disabled={disabled}
            targets={targets}
            fieldOptions={fieldOptions}
            onTargetFieldSelected={onTargetFieldSelected}
          />
        </Box>
      </td>
    </tr>
  );
};

MappingTableRow.propTypes = {
  header: PropTypes.string,
  targets: PropTypes.object,
  fieldOptions: PropTypes.array,
  updateField: PropTypes.func,
  clearField: PropTypes.func,
};

export default MappingTableRow;
