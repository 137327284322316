import { filter, get, isArray, keyBy } from "lodash";
import { useOrgChartContext } from "src/components/OrgChart/context/OrgChartContext";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { ENTITY_TYPES } from "src/consts/comments";
import { getHighlightedTags } from "src/components/HighlightedTags/utils";

const ENTITY_TYPE_KEYS = {
  [ENTITY_TYPES.GROUP]: { STATE_KEY: "teamsData", BADGE_KEY: "isGroupBadge" },
  [ENTITY_TYPES.PERSON]: {
    STATE_KEY: "peopleData",
    BADGE_KEY: "isPersonBadge",
  },
};

/**
 * If the hooks is used in the context of the OrgChart, it returns the tags that are allowed to be displayed
 * otherwise it returns highlighted tags
 */
export const useNodeProperties = ({ tags, entityType }) => {
  const { state } = useOrgChartContext() || {};
  const workspace = useWorkspace();

  const enableAllTagsToBeVisible = get(
    workspace,
    "config.featureFlags.enableAllTagsToBeVisible"
  );

  if (enableAllTagsToBeVisible) {
    return tags;
  }

  const { STATE_KEY, BADGE_KEY } = ENTITY_TYPE_KEYS[entityType];

  const entityTagProperties = state?.properties?.[STATE_KEY];

  const shouldRenderHighlightedTags = isArray(entityTagProperties);

  return !shouldRenderHighlightedTags
    ? getHighlightedTags(tags, workspace?.config?.tagConfig, BADGE_KEY)
    : filter(tags, ({ type }) => {
        const allowedTypes = keyBy(entityTagProperties, "value");

        return allowedTypes[type] && allowedTypes[type]?.data?.isSelected;
      });
};
