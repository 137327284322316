import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { filter } from "lodash";
import { attributeTypes } from "src/util/group";
import LinksList from "src/components/TeamDescription/LinksList";
import { TeamAttributeLinksList } from "src/components/TeamAttributeLinksList/TeamAttributeLinksList";

export const Links = ({ team, isEditing, setNewAttributes }) => {
  // get links from team for display
  const linkAttrs = useMemo(() => {
    return filter(team.attributes, {
      attributeType: attributeTypes.LINK,
    });
  }, [team.attributes]);

  if (isEditing) {
    return (
      <TeamAttributeLinksList
        allTeamAttributes={team.attributes}
        onAttributesChange={setNewAttributes}
      />
    );
  }

  if (!linkAttrs.length) {
    return <em>None</em>;
  }

  return <LinksList links={linkAttrs} hideBullets={true} />;
};

Links.propTypes = {
  team: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setNewAttributes: PropTypes.func.isRequired,
};
