import { useMutation } from "@apollo/client";
import { size, isEmpty, chunk } from "lodash";
import { useCallback, useState } from "react";

import { bulkMoveIndividuals } from "src/allocation/allocation.graphql";
import { getMemberCountDisplay } from "src/util/search";

import { MAX_MOVE_LIMIT, STATUS } from "../consts";
import { getAllocationsFromMembers } from "../utils/index";

const useBulkMoveMembers = ({
  team,
  selectedPeople,
  allocationProject,
  onHideModal = () => {},
}) => {
  const [status, setStatus] = useState(null);
  const [bulkMoveMutation] = useMutation(bulkMoveIndividuals);
  const [bulkMoveSuccess, setBulkMoveSuccess] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState(selectedPeople || {});
  const [targetGroupOption, setTargetGroupOption] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  const handleCloseModal = useCallback(() => {
    if (isProcessing) {
      return;
    }
    setStatus(null);
    setError(null);
    setSelectedMembers({});
    setIsProcessing(false);
    setTargetGroupOption(null);
    setBulkMoveSuccess(false);
    onHideModal();
  }, [
    isProcessing,
    setStatus,
    setError,
    setSelectedMembers,
    setIsProcessing,
    setTargetGroupOption,
    setBulkMoveSuccess,
    onHideModal,
  ]);

  const handleSelectPerson = useCallback(
    (person) => {
      const memberList = { ...selectedMembers };
      if (memberList[person.aggregateId]) {
        delete memberList[person.aggregateId];
      } else {
        memberList[person.aggregateId] = person;
      }
      setSelectedMembers(memberList);
    },
    [selectedMembers, setSelectedMembers]
  );

  const handleBulkMove = async () => {
    const count = size(selectedMembers);
    setBulkMoveSuccess(false);
    if (!targetGroupOption || !targetGroupOption.group) {
      setStatus({
        status: STATUS.ERROR,
        message: "Target group is not selected",
      });
      setError(new Error("Target group is not selected"));
      return;
    }

    if (isEmpty(selectedMembers)) {
      setStatus({
        status: STATUS.ERROR,
        message: "No members are selected to be moved",
      });
      setError(new Error("No members are selected to be moved"));
      return;
    }

    if (size(selectedMembers) > MAX_MOVE_LIMIT) {
      setStatus({
        status: STATUS.ERROR,
        message: `Too many members to move (selected ${size(
          selectedMembers
        )} limit: ${MAX_MOVE_LIMIT}).`,
      });
      setError(
        new Error(
          `Too many members to move (selected ${size(
            selectedMembers
          )} limit: ${MAX_MOVE_LIMIT}).`
        )
      );
      return;
    }

    try {
      setError(null);
      setIsProcessing(true);

      setStatus({
        status: STATUS.PROGRESS,
        message: `Moving ${getMemberCountDisplay(count)} in progress...`,
      });

      const allocations = getAllocationsFromMembers(selectedMembers);
      const chunks = chunk(allocations, 10);

      for (let i = 0, j = chunks.length; i < j; i += 1) {
        const current = chunks[i];
        // eslint-disable-next-line no-await-in-loop
        await bulkMoveMutation({
          variables: {
            input: {
              allocations: current,
              fromGroupId: team.id,
              toGroupId: targetGroupOption.group.id,
              allocationProjectId: allocationProject.id,
            },
          },
        });
      }
      setSelectedMembers({});
      setBulkMoveSuccess(true);
      setStatus({
        status: STATUS.SUCCESS,
        message: `${getMemberCountDisplay(count)} moved successfully to ${
          targetGroupOption?.group?.name
        }`,
      });
    } catch (err) {
      setError(err);
      setBulkMoveSuccess(false);
      setStatus({
        status: STATUS.ERROR,
        message: `Failed moving members: ${err?.message}`,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return {
    handleSelectPerson,
    handleBulkMove,
    isProcessing,
    error,
    setTargetGroupOption,
    targetGroupOption,
    bulkMoveSuccess,
    selectedMembers,
    setSelectedMembers,
    handleCloseModal,
    status,
    setStatus,
  };
};

export default useBulkMoveMembers;
