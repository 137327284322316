import PropTypes from "prop-types";
import React from "react";
import { Popover } from "orcs-design-system";

const HelpIcon = ({ content }) => (
  <Popover
    variant="tooltip"
    width="250px"
    direction="bottomLeft"
    text={content}
    mr="s"
  />
);

HelpIcon.propTypes = {
  content: PropTypes.node,
};

export default HelpIcon;
