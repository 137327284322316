import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Loading, Text } from "orcs-design-system";
import { isEmpty } from "lodash";

const btnStyles = {
  add: {
    ariaLabel: "Add",
    icon: "plus",
    color: "primary",
  },
  remove: {
    ariaLabel: "Remove",
    icon: "minus",
    color: "danger",
  },
  approve: {
    ariaLabel: "Approve",
    icon: "check",
    color: "success",
  },
  edit: {
    ariaLabel: "Edit",
    icon: "pencil",
    color: "ghost",
  },
  cancel: {
    ariaLabel: "Cancel",
    icon: "times",
    color: "ghost",
  },
  save: {
    ariaLabel: "Approve",
    icon: "save",
    color: "success",
  },
  // loading (doesn't need a config)
};

export const PanelIconButton = ({ label, type, onClick }) => {
  const config = btnStyles[type];

  let labelEle = null;
  if (label) {
    labelEle = (
      <Text fontSize="12px" fontWeight="600" pr="xs" pl="xxs">
        {label}
      </Text>
    );
  }

  if (type === "loading") {
    return <Loading small mb="xs" />;
  }

  if (isEmpty(config)) {
    return null;
  }

  return (
    <Button
      small
      iconOnly
      variant={config.color}
      p="xs"
      ariaLabel={config.ariaLabel}
      onClick={onClick}
    >
      <Icon fixedWidth icon={["fas", config.icon]} /> {labelEle}
    </Button>
  );
};

PanelIconButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf([
    "add",
    "remove",
    "edit",
    "approve",
    "cancel",
    "save",
    "loading",
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
};
