import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";
import moment from "moment";
import { isEqual } from "lodash";
import {
  Box,
  Expandable,
  Flex,
  Button,
  H3,
  Divider,
  Notification,
  Text,
} from "orcs-design-system";

import JsonFileDropZone from "src/components/JsonFileDropZone";
import FieldsMapFlagEditor from "./FieldsMapFlagEditor";

const downloadFieldsMap = (workspace, datasource, fieldsMap) => {
  const filename = `fields-map-${workspace.name}(${workspace.id})-${
    datasource.name
  }(${datasource.id})-${moment().format("YYYYMMDD-HHmm")}.json`;

  const content = encodeURIComponent(JSON.stringify(fieldsMap, null, 4));

  saveAs(`data:text/json;charset=utf-8,${content}`, filename);
};

const FieldsMapJsonUploader = ({
  workspace,
  datasource,
  updateDatasource,
  integrationType,
  currentFieldsMap,
  selectedFieldsMap,
  updateSelectedFieldsMap,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const { fieldsDescription, importFlags } = integrationType;

  const onDownloadAsJson = () => {
    if (!selectedFieldsMap) {
      return;
    }
    downloadFieldsMap(workspace, datasource, selectedFieldsMap);
  };

  const updateFieldsMap = useCallback(
    (newMap) => {
      updateSelectedFieldsMap(newMap);
      setIsSaved(false);
    },
    [updateSelectedFieldsMap]
  );

  const importFromJson = useCallback(
    (jsonObject, updateFn = updateFieldsMap) => {
      if (isEqual(selectedFieldsMap, jsonObject)) {
        return;
      }

      updateFn(jsonObject);
    },
    [selectedFieldsMap, updateFieldsMap]
  );

  const onImportFromJson = (jsonObject) => {
    importFromJson(jsonObject);
  };

  const onSaveFieldsMap = async () => {
    setIsSaving(true);
    try {
      await updateDatasource({
        variables: {
          input: {
            id: datasource.id,
            fieldsMap: selectedFieldsMap,
          },
        },
      });
      setIsSaved(true);
    } finally {
      setIsSaving(false);
    }
  };

  const isSameFieldsMap = isEqual(currentFieldsMap, selectedFieldsMap);

  return (
    <Expandable title="Fields Mapping Settings" mb="r">
      <Box>
        <Divider />
        {fieldsDescription}
        <Divider />
        <Box>
          <FieldsMapFlagEditor
            fieldsMap={selectedFieldsMap}
            updateFieldsMap={updateFieldsMap}
            importFlags={importFlags}
          />
          {!isSameFieldsMap && (
            <Notification closable={false} colour="warning">
              Fields map updated, not saved.
            </Notification>
          )}
          <Flex justifyContent="space-between" mt="l">
            <Flex>
              <Button onClick={onDownloadAsJson} mr="r">
                Download fields map
              </Button>
              <JsonFileDropZone
                onImportFromJson={onImportFromJson}
                btnDescription="Upload fields map JSON file"
                btnLabel={<Text ml="s">Upload</Text>}
              />
            </Flex>
            <Button
              onClick={onSaveFieldsMap}
              isLoading={isSaving}
              disabled={isSaved || isSameFieldsMap || isSaving}
              variant={isSaving ? "disabled" : "success"}
            >
              Save to datasource
            </Button>
          </Flex>
        </Box>
        {!!selectedFieldsMap && (
          <Box mt="r">
            <Divider />
            <H3 mt="s">Current fields map:</H3>
            <pre
              style={{
                maxWidth: "100%",
                width: "100%",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                overflowX: "auto",
              }}
            >
              {JSON.stringify(selectedFieldsMap, null, 2)}
            </pre>
          </Box>
        )}
        <Divider />
      </Box>
    </Expandable>
  );
};

FieldsMapJsonUploader.propTypes = {
  workspace: PropTypes.object.isRequired,
  datasource: PropTypes.object.isRequired,
  updateDatasource: PropTypes.func.isRequired,
  integrationType: PropTypes.object.isRequired,
  currentFieldsMap: PropTypes.object.isRequired,
  selectedFieldsMap: PropTypes.object.isRequired,
  updateSelectedFieldsMap: PropTypes.func.isRequired,
};

export default FieldsMapJsonUploader;
