import React from "react";
import PropTypes from "prop-types";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import OrgChartContextProvider from "./context/OrgChartContextProvider";
import TeamVisualiser from "./components/TeamVisualiser";

const OrgChart = ({ teamId, groupTypes }) => {
  return (
    <OrgChartContextProvider
      key={teamId}
      teamId={teamId}
      groupTypes={groupTypes}
    >
      <TeamVisualiser />
    </OrgChartContextProvider>
  );
};

OrgChart.propTypes = {
  groupTypes: GroupTypesPropType,
  teamId: PropTypes.string,
};

export default React.memo(OrgChart);
