import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { Box, Flex, H3, P } from "orcs-design-system";
import { map, range } from "lodash";
import { useNodes } from "reactflow";
import pluralize from "pluralize";
import { SourceHandle, StyledCard, TargetHandle } from "../node.styled";
import {
  DEFAULT_COLLAPSE_COUNT,
  useObjectiveState,
} from "../../contexts/ObjectiveProviderContext";
import { moreObjectivesNodePropType } from "../propTypes";
import ObjectiveHeaderNode from "../ObjectiveHeaderNode";
import { RoundIconButton } from "../RoundIconButton";

const StyledBox = styled(Box)`
  position: relative;
`;
const PositionedCard = styled(StyledCard)`
  position: absolute;
  bottom: -${({ position }) => position * 15}px;
  z-index: -${({ position }) => position};
`;

const StyledButton = styled(RoundIconButton)`
  position: absolute;
  left: calc(50% - 15px);
  bottom: -35px;

  background: black;
  &:hover {
    background: black;
    border-color: white;
  }
`;

const MoreObjectivesNode = ({ data }) => {
  const { collapsedNodes, headerId, hierarchyParentIds } = data;
  const collapsedCount = collapsedNodes.length;

  const { onToggleExpandedHeaders, setHoveredObjective } = useObjectiveState();
  const nodes = useNodes();

  const humanReadableNodeType = pluralize("item", collapsedCount);

  const displayCards = useMemo(() => {
    return collapsedCount > 3 ? range(4) : range(collapsedCount);
  }, [collapsedCount]);

  const [nodeHeader, typeCount] = useMemo(() => {
    const headerNode = nodes.find((n) => n.id === headerId);
    return [headerNode, headerNode?.data?.typeCount ?? 0];
  }, [nodes, headerId]);

  const showFooter =
    nodeHeader && typeCount - collapsedNodes.length >= DEFAULT_COLLAPSE_COUNT;

  const onClick = useCallback(
    () => onToggleExpandedHeaders(data),
    [data, onToggleExpandedHeaders]
  );

  const onMouseEnter = useCallback(
    () => setHoveredObjective(data),
    [data, setHoveredObjective]
  );

  const onMouseLeave = useCallback(
    () => setHoveredObjective(null),
    [setHoveredObjective]
  );

  const positionedCards = useMemo(() => {
    return map(displayCards, (i) => <PositionedCard key={i} position={i} />);
  }, [displayCards]);

  return (
    <>
      <StyledBox>
        <StyledCard
          cursor="pointer"
          className="objective-header-node"
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {hierarchyParentIds.length > 0 && (
            <TargetHandle
              id="left-handle"
              type="target"
              position="left"
              isConnectable={false}
              $yPosition="40px"
              className="triangle"
            />
          )}

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <H3 fontWeight="bold">
              {collapsedCount} collapsed {humanReadableNodeType}
            </H3>
            <P mt="r">Click to show</P>
          </Flex>

          <SourceHandle
            type="source"
            position="right"
            isConnectable={false}
            $yPosition="40px"
          />
        </StyledCard>
        <StyledButton large icon={["fas", "chevron-down"]} onClick={onClick} />
        {positionedCards}
      </StyledBox>
      {showFooter && (
        <Box mt="200px">
          <ObjectiveHeaderNode data={nodeHeader?.data} isFooter />
        </Box>
      )}
    </>
  );
};

MoreObjectivesNode.propTypes = {
  data: moreObjectivesNodePropType,
};

export default MoreObjectivesNode;
