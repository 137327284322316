import { debounce } from "lodash";
import { Select } from "orcs-design-system";
import React from "react";
import { Query } from "@apollo/client/react/components";
import PropTypes from "prop-types";

import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";
import { VisualisationContext } from "../../contexts/visualisationContext";
import ErrorPage from "../ErrorPage";
import searchPeopleQuery from "./searchPeople.graphql";
import * as vsUtils from "./VisualisationPage.utils";

const PersonSearchInput = ({ onNodeSelect, selectedNode, searchTerms }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = React.useContext(VisualisationContext);
  const refetchSearchPeopleQuery = React.useRef();

  const onPeopleSearchInputChanged = (inputValue) => {
    if (inputValue) {
      const formattedSearchTerms = vsUtils.getFormattedSearchTerms(inputValue);
      if (refetchSearchPeopleQuery.current) {
        refetchSearchPeopleQuery.current({ searchTerms: formattedSearchTerms });
      }
      dispatch({
        type: "setSearchTerms",
        searchTerms: inputValue,
      });
    }
  };

  const onSearchForPerson = (inputValue) => {
    // input field cleared scenario
    if (!inputValue && refetchSearchPeopleQuery.current) {
      refetchSearchPeopleQuery.current({ searchTerms: "" });
    }
    onNodeSelect("person", inputValue && inputValue.value);
  };

  return (
    <Query query={searchPeopleQuery} variables={{ searchTerms }}>
      {({ data, loading, error, refetch }) => {
        let people = [];
        refetchSearchPeopleQuery.current = refetch;

        if (data && data.searchPeople) {
          people = vsUtils.getFormattedPeopleData(data.searchPeople.people);
        }

        if (error) {
          return <ErrorPage error={error} />;
        }

        return (
          <Select
            height="58px"
            inputId="selectPerson"
            ariaLabel="Search for a person"
            placeholder="Search for a person"
            options={people}
            value={selectedNode.id} // TODO: we should probably set the type for search term as well, ie PERSON OR TEAM
            filterOptions={false}
            onInputChange={debounce(
              onPeopleSearchInputChanged,
              DEBOUNCE_SEARCH_TIME
            )}
            onChange={onSearchForPerson}
            backspaceRemoves={false}
            isLoading={loading}
          />
        );
      }}
    </Query>
  );
};

PersonSearchInput.propTypes = {
  onNodeSelect: PropTypes.func,
  searchTerms: PropTypes.array,
  selectedNode: PropTypes.object,
};

export default PersonSearchInput;
