/* eslint-disable no-unused-vars */

import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useQuery } from "@apollo/client";
import { withRouter, useHistory } from "react-router-dom";
import { get, sumBy, includes, map, find, round } from "lodash";
import PropTypes from "prop-types";
import {
  Box,
  Spacer,
  Card,
  Flex,
  Small,
  Loading,
  Icon,
  FlexItem,
  Divider,
} from "orcs-design-system";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import { hierarchy } from "d3-hierarchy";
import {
  useFilters,
  useApplyFilters,
} from "src/contexts/filterAndFte/FilterAndFteContext";
import { numberToLocaleString } from "src/util/toLocaleString";
import FeedbackEmoji from "src/components/FeedbackEmoji/FeedbackEmoji";
import GroupTypeBadge from "src/components/GroupTypeBadge";
import BarInsight from "../../../Visualisations/Unovis/barChart";
import DragIcon from "../DragIcon";
import HelpIcon from "../HelpIcon";
import SortIcon from "../SortIcon";
import TeamHierarchyBreadcrumb from "../../../TeamHierarchyBreadcrumb";
import ClickableStyleLink from "./ClickableStyledLink";
import ShowMore from "./ShowMore";

const InsightStyle = styled.div`
  height: 100%;
  width: 100%;
  .root {
    fill: ${themeGet("colors.primary10")};
    stroke: ${themeGet("colors.primary20")};
  }
`;

const CustomCard = styled(Card)`
  overflow: hidden;
`;

const PAGINATION_COUNT = 20;

const formatGroupHierarchy = (groupHierarchy) => {
  const split = groupHierarchy.split("\\");
  return split.slice(0, split.length - 1).join(" > ");
};

const tooltipTemplate = (d) => {
  const type = d?.group?.type;

  const hierarchyHeading = `<div style="color: #666; text-align: center">${formatGroupHierarchy(
    d?.group?.hierarchy
  )}</div>`;
  const heading = `<div style=" text-align: center">${
    d.group?.name || d.label
  } | ${type} </div>`;
  const total = `<div style=" text-align: center">Descendant Member Count: <b>${d.value}</b></br></div>`;
  return `<div style="font-size: 12px">${hierarchyHeading}${heading}${total}</div>`;
};

const HorizontalBarCard = (props) => {
  const {
    icon,
    title,
    insights: { valueLists = {} } = {},
    loading,
    filterKey,
    dataKey,
    layoutKey,
    updateCardSize = () => {},
    labelAsPercentage,
    compare,
    helpContent,
  } = props;

  const filters = useFilters();
  const applyFilters = useApplyFilters();
  const [itemCount, updateItemCount] = useState(10);
  const [sortDsc, updateSortDsc] = useState(true);

  const onFilterByItem = useCallback(
    (itemFilterKey, itemId, label, groupHierarchy) => {
      // for the division & country filter, the click overwrites existing division filters.
      const currentValues = get(filters, itemFilterKey);
      const selection = [
        {
          value: itemId,
          label,
          optionalLabel: formatGroupHierarchy(groupHierarchy),
        },
      ];
      if (itemFilterKey === "teams") {
        applyFilters({
          teams: {
            ...currentValues,
            teams: selection,
          },
        });
      } else if (itemFilterKey === "divisions") {
        applyFilters({
          divisions: {
            ...currentValues,
            divisions: selection,
            includeSubDivisions: true,
          },
        });
      } else {
        const values = map(currentValues, "value");
        if (includes(values, itemId)) {
          return;
        }
        applyFilters({ [itemFilterKey]: selection });
      }
    },
    [filters, applyFilters]
  );

  const subtitle = (
    <Flex alignItems="center">
      <HelpIcon content={helpContent} />
      <SortIcon onClick={() => updateSortDsc(!sortDsc)} sortDsc={sortDsc} />
      <DragIcon />
    </Flex>
  );

  const insightOfInterest = find(valueLists, { key: dataKey });
  const insights = useMemo(() => {
    return get(insightOfInterest, "items", [])
      .slice()
      .sort((a, b) => (sortDsc ? b.value - a.value : a.value - b.value));
  }, [insightOfInterest, sortDsc]);

  if (loading) {
    return (
      <InsightStyle className="insights-card">
        <CustomCard
          fluid
          alternate
          icon={icon}
          title={title}
          subtitle={subtitle}
        >
          <Spacer p="r">
            <Loading centered />
          </Spacer>
        </CustomCard>
      </InsightStyle>
    );
  }

  if (insights.length === 0) {
    return (
      <InsightStyle className="insights-card">
        <CustomCard
          fluid
          alternate
          icon={icon}
          title={title}
          subtitle={subtitle}
        >
          Your selection has 0 {title}
        </CustomCard>
      </InsightStyle>
    );
  }

  const cardInsights = map(insights, (insight) => {
    const { label } = insight;
    return {
      ...insight,
      label: label === "_null_" ? "Unknown" : label,
    };
  });

  const displayChange = (comparisonValue, value) => {
    const diff = Math.abs(comparisonValue - value);
    if (diff === 0) {
      return "No change";
    }
    const percentage = (value / comparisonValue) * 100 - 100;
    const res =
      isNaN(percentage) || percentage === Infinity
        ? `${numberToLocaleString(diff, { decimalplaces: 1 })}`
        : `${numberToLocaleString(diff, { decimalplaces: 1 })}${" "}(${round(
            percentage,
            1
          )}%)`;
    return res;
  };

  const cardTotalCount = get(insightOfInterest, "value", 0);
  const comparisonTotalCount = get(
    insightOfInterest,
    "comparisonValue",
    cardTotalCount
  );

  const changeIcon =
    cardTotalCount > comparisonTotalCount
      ? "arrowUp"
      : cardTotalCount < comparisonTotalCount
      ? "arrowDown"
      : null;

  const nextItemCount =
    itemCount + PAGINATION_COUNT < cardInsights.length
      ? itemCount + PAGINATION_COUNT
      : cardInsights.length;

  return (
    <InsightStyle className="insights-card">
      <CustomCard
        fluid
        alternate
        icon={icon}
        title={`${numberToLocaleString(cardTotalCount)} ${title}`}
        changeValue=""
        changeIcon={compare && changeIcon ? changeIcon : null}
        subtitle={subtitle}
      >
        <Box my="r">
          <Flex width="100%" justifyContent="center">
            <BarInsight
              data={cardInsights.slice(0, itemCount)}
              tooltipTemplate={tooltipTemplate}
              xLabel="Direct Member Count"
            />
          </Flex>
        </Box>

        {cardInsights.slice(0, itemCount).map((item) => {
          return (
            <Fragment key={item.key}>
              <Flex
                key={item.key}
                alignItems="center"
                justifyContent="space-between"
                py="xs"
              >
                <FlexItem>
                  {item.group && (
                    <TeamHierarchyBreadcrumb
                      muted
                      team={item.group}
                      key={item.group.id}
                      id={item.group.id}
                      filterKey={filterKey}
                      onFilterByItem={onFilterByItem}
                    />
                  )}
                  <ClickableStyleLink
                    onClick={() => {
                      onFilterByItem(
                        filterKey,
                        item.key,
                        item.label,
                        item?.group?.hierarchy
                      );
                    }}
                  >
                    <Small>{item.label}</Small>
                  </ClickableStyleLink>
                </FlexItem>
                <Flex alignItems="center" justifyContent="space-between">
                  <Spacer ml="s">
                    {item.group && <GroupTypeBadge group={item.group} />}
                    <Flex alignItems="center">
                      <Spacer my="xs">
                        <Small>
                          {numberToLocaleString(item.value, {
                            decimalplaces: 1,
                          })}
                        </Small>
                        {compare ? (
                          item.value !== item.comparisonValue ? (
                            <Small color="greyDark">
                              {item.value > item.comparisonValue ? (
                                <Icon icon={["fal", "arrow-up"]} />
                              ) : item.value < item.comparisonValue ? (
                                <Icon icon={["fal", "arrow-down"]} />
                              ) : null}{" "}
                              {displayChange(item.comparisonValue, item.value)}
                            </Small>
                          ) : (
                            <Small color="greyDark">
                              {displayChange(item.comparisonValue, item.value)}
                            </Small>
                          )
                        ) : null}
                      </Spacer>
                    </Flex>
                  </Spacer>
                </Flex>
              </Flex>
              <Divider light />
            </Fragment>
          );
        })}
        <ShowMore
          cardTotalCount={cardTotalCount}
          countItems={nextItemCount - itemCount}
          countSum={round(sumBy(cardInsights.slice(itemCount), "value"), 1)}
          onShowMore={() => {
            updateItemCount(nextItemCount);
            updateCardSize(layoutKey, nextItemCount);
          }}
        />
        <FeedbackEmoji contextId={`SummaryPage-${title}-${dataKey}`} />
      </CustomCard>
    </InsightStyle>
  );
};

HorizontalBarCard.propTypes = {
  insights: PropTypes.object.isRequired,
  compare: PropTypes.bool,
  icon: PropTypes.array.isRequired,
  updateCardSize: PropTypes.func,
  title: PropTypes.string.isRequired,
  filterKey: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  layoutKey: PropTypes.string.isRequired,
  labelAsPercentage: PropTypes.bool.isRequired,
  helpContent: PropTypes.node,
  loading: PropTypes.bool.isRequired,
};

export default withRouter(HorizontalBarCard);
