import { map, isString } from "lodash";
import { P } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import { Text } from "slate";

import { useTheme } from "styled-components";
import Element from "src/comments/components/RichTextEditor/sub-components/Element";
import { parseLeaf } from "src/comments/components/RichTextEditor/serializer/renderNodes";

import { getFormattedMsg, isTagMention } from "./RichTextContent.util";

const Content = ({ fontSize, node, white }) => {
  if (!node) {
    return null;
  }

  if (Text.isText(node)) {
    return parseLeaf(node, fontSize ? { fontSize } : undefined);
  }

  let children = null;

  // There is a children in Tag mention, need to exclude it
  if (!isTagMention(node)) {
    children = map(node.children, (n, i) => (
      <Content key={i} node={n} fontSize={fontSize} white={white} />
    ));
  }

  return (
    <Element element={node} fontSize={fontSize} white={white}>
      {children}
    </Element>
  );
};

Content.propTypes = {
  fontSize: PropTypes.string,
  node: PropTypes.object,
  white: PropTypes.bool,
};

const RichTextContent = ({ content, fontSize, white = false }) => {
  const theme = useTheme();
  const defaultFontSize = theme.fontSizes[2];
  const formattedContent = getFormattedMsg(content);
  const isStringContent = isString(formattedContent);

  return isStringContent ? (
    <P fontSize={fontSize || defaultFontSize}>{content}</P>
  ) : (
    <>
      {map(formattedContent, (n, i) => (
        <Content
          key={i}
          node={n}
          fontSize={fontSize || defaultFontSize}
          white={white}
        />
      ))}
    </>
  );
};

RichTextContent.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  white: PropTypes.bool,
  fontSize: PropTypes.string,
};

export default RichTextContent;
