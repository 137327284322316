import { shouldLoadIndirectMembersForGroup } from "src/util/groupType";
import { getTeamMembersQuery } from "src/queries/group.graphql";

export const getSearchFilter = (sourceGroup, groupTypes) => {
  if (!sourceGroup) {
    return undefined;
  }

  return {
    teams: {
      teams: [sourceGroup.id],
      includeIndirectTeamMembers: shouldLoadIndirectMembersForGroup(
        sourceGroup,
        groupTypes
      ),
      excludeVacancies: true,
    },
  };
};

export const refetchMemberList = ({ client, teamId }) => {
  const currentResult = client.readQuery({
    query: getTeamMembersQuery,
    variables: {
      groupId: teamId,
    },
  });

  if (!currentResult) {
    return;
  }

  client.refetchQueries({
    include: [
      {
        query: getTeamMembersQuery,
        variables: {
          groupId: teamId,
        },
      },
    ],
  });
};
