import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { keyBy, omit } from "lodash";
import {
  Divider,
  Flex,
  FlexItem,
  Loading,
  Button,
  Icon,
  Box,
} from "orcs-design-system";

import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import { getDemandGroupTypes } from "src/util/customerConfig";
import ErrorNotification from "src/components/ErrorNotification";

import GroupsSearch from "src/components/GroupsSearch";
import { getEndDateFromStartDate } from "../util/membership";
import {
  validateFteCap,
  getErrorMessage,
  FIELD_FTE,
  FIELD_DATES,
  ZERO_FTE_WARNING,
} from "../util/validateMembership";
import { InputBadge } from "./InputBadge";
import MembershipDate from "./MembershipDate";
import MembershipGroup from "./MembershipGroup";
import { StyledForm } from "./MembershipForm.styled";
import MembershipActionsMenu from "./MembershipActionsMenu";

const MembershipForm = ({
  membership: currentMembership,
  onCreate,
  onDestroy,
  onUpdate,
  onReplace,
  isLoading,
  isSupply,
  withDateOption,
  enableChangeSupplyGroup,
  isAdmin,
  isPastEditEnabled,
  person,
  enableFteCap,
  demandMemberships,
  selectedGroup,
  setSelectedGroup,
  fteConfig,
  teamToAddError,
}) => {
  const groupTypes = useGroupTypes();
  const [membership, setMembership] = useState(currentMembership);
  const [isAdding, setIsAdding] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [apiError, setApiError] = useState(teamToAddError);
  const [overAllocatedPeriods, setOverAllocatedPeriods] = useState([]);
  const isNewMembership = !!onCreate;

  const targetGroupTypes = useMemo(() => {
    return keyBy(getDemandGroupTypes(groupTypes), "id");
  }, [groupTypes]);

  const isEditable = useMemo(() => {
    return !!onCreate || !!onUpdate;
  }, [onCreate, onUpdate]);

  const updateStatus = useCallback(
    ({ isProcessing, error }) => {
      setIsUpdating(!!isProcessing);

      if (error) {
        setApiError(error);
        setMembership(currentMembership);
      }
    },
    [currentMembership]
  );

  const clearWarnings = useCallback(() => {
    setApiError(null);
    setOverAllocatedPeriods([]);
  }, []);

  const validateFte = useCallback(
    (mutatedMembership) => {
      if (!enableFteCap) {
        return [];
      }

      const overAllocated = validateFteCap({
        person,
        updatedMembership: mutatedMembership,
        demandMemberships,
        fteConfig,
      });

      setOverAllocatedPeriods(overAllocated);

      return overAllocated;
    },
    [demandMemberships, enableFteCap, person, fteConfig]
  );

  const updateMembership = useCallback(
    async (params) => {
      // never clear start date, when clear the endDate, in fact the DatePicker also clears startDate
      const cleanedParams = params.startDate
        ? params
        : omit(params, "startDate");

      const mutatedMembership = {
        ...membership,
        ...cleanedParams,
        existingFte: currentMembership.fte,
      };

      if (onCreate) {
        setMembership(mutatedMembership);
      } else {
        await onUpdate(
          { ...mutatedMembership, existingMembership: currentMembership },
          updateStatus
        );
      }
    },
    [membership, currentMembership, onCreate, onUpdate, updateStatus]
  );

  const handleOnCreate = useCallback(() => {
    // Basic validation to prevent 400 for the moment
    const isZeroFteAllowed = { fteConfig };
    if (!membership.groupId) {
      setApiError({ message: "Please select a team" });
      return;
    }

    const overAllocated = validateFte(membership);

    // This is a backup, validation is done after changing FTE
    if (overAllocated.length > 0) {
      setApiError({ message: "Allocated FTE exceeds the person's capacity" });
      return;
    }

    if (membership.fte === 0 && !isZeroFteAllowed) {
      setApiError({ message: ZERO_FTE_WARNING });
      return;
    }

    onCreate(membership, setIsAdding);
  }, [membership, onCreate, fteConfig, validateFte]);

  const handleOnChangeFte = useCallback(
    (newFte, newStartDate) => {
      clearWarnings();

      if (newStartDate) {
        // End current membership and then create a new one with targetDate and fte
        const { currentCompositeId, groupId, personId, endDate } = membership;

        // In fact we are calling addMembership here, by giving the current a membership a new startDate and new FTE
        onReplace(
          {
            currentCompositeId,
            groupId,
            personId,
            startDate: newStartDate,
            endDate,
            fte: newFte,
            existingMembership: currentMembership,
            existingFte: currentMembership.fte,
          },
          updateStatus
        );
        return;
      }

      // If there is no newStartDate, update membership fte only
      updateMembership({ fte: newFte });
    },
    [
      updateMembership,
      membership,
      onReplace,
      currentMembership,
      updateStatus,
      clearWarnings,
    ]
  );

  const handleOnDatesChange = useCallback(
    (dateRange) => {
      const { startDate, endDate } = dateRange;

      // If startDate and endDate not changed
      if (
        startDate &&
        startDate.isSame(currentMembership.startDate, "day") &&
        ((!endDate && !currentMembership.endDate) ||
          (endDate && endDate.isSame(currentMembership.endDate, "day")))
      ) {
        return;
      }

      clearWarnings();
      updateMembership(dateRange);
    },
    [updateMembership, currentMembership, clearWarnings]
  );

  const handleOnGroupSelected = useCallback(
    (group) => {
      if (!targetGroupTypes[group?.type]) {
        return;
      }

      updateMembership({ groupId: group.id });
      setSelectedGroup(group);
    },
    [targetGroupTypes, updateMembership, setSelectedGroup]
  );

  const handleOnEndAllocation = useCallback(() => {
    const { startDate } = membership;
    const endDate = getEndDateFromStartDate(startDate);

    updateMembership({
      startDate,
      endDate,
    });
  }, [membership, updateMembership]);

  const handleOnDestroy = useCallback(
    () => onDestroy(membership, updateStatus),
    [membership, onDestroy, updateStatus]
  );

  const isOverAllocated = isNewMembership && overAllocatedPeriods.length > 0;
  const errorMessage = getErrorMessage({
    apiError,
    isOverAllocated,
    overAllocatedPeriods,
    membership,
    currentMembership,
  });

  const errorFieldName = apiError?.data?.fieldName;

  return (
    <StyledForm highlight={!!onCreate} inert={isLoading ? "true" : undefined}>
      <Flex
        p="s"
        flexDirection={[
          "column",
          "column",
          "column",
          onCreate ? "column" : "row",
          "row",
        ]}
        justifyContent="space-between"
        alignItems={["flex-end", "flex-end", "flex-end", "flex-end", "center"]}
      >
        <Flex
          mr={["0", "0", "0", onCreate ? "0" : "s", "s"]}
          mb={["s", "s", "s", "s", "0"]}
          width="100%"
        >
          {isNewMembership ? (
            <GroupsSearch
              placeholder="Click to search and select a team to add"
              actionLabel="Click to add"
              value={selectedGroup?.name}
              groupTypes={groupTypes}
              targetGroupTypes={targetGroupTypes}
              onGroupSelected={handleOnGroupSelected}
            />
          ) : (
            <MembershipGroup membership={membership} />
          )}
        </Flex>
        {isEditable && (
          <Flex
            alignItems="center"
            flex="1 0 auto"
            flexWrap={["wrap", "no-wrap"]}
          >
            {isUpdating || isAdding ? <Loading mr="r" /> : null}
            <MembershipDate
              membership={membership}
              onDatesChange={handleOnDatesChange}
              isPastEditEnabled={isPastEditEnabled}
              hasError={errorFieldName === FIELD_DATES}
              clearWarnings={clearWarnings}
            />
            <FlexItem mx="s">
              <InputBadge
                label="FTE"
                value={membership.fte}
                onChange={handleOnChangeFte}
                startDate={membership.startDate}
                endDate={membership.endDate}
                withDateOption={withDateOption}
                isAdmin={isAdmin}
                isPastEditEnabled={isPastEditEnabled}
                hasError={errorFieldName === FIELD_FTE}
                clearWarnings={clearWarnings}
                fteConfig={fteConfig}
              />
            </FlexItem>
            {isNewMembership && (
              <Button
                variant={isOverAllocated ? "disabled" : "success"}
                iconOnly
                type="button"
                width="38px"
                height="38px"
                disabled={isOverAllocated}
                onClick={handleOnCreate}
              >
                <Icon icon={["fas", "plus"]} />
              </Button>
            )}
          </Flex>
        )}
        {!!onDestroy && (
          <MembershipActionsMenu
            membership={membership}
            handleOnEndAllocation={handleOnEndAllocation}
            handleOnDestroy={handleOnDestroy}
            isSupply={isSupply}
            enableChangeSupplyGroup={enableChangeSupplyGroup}
            isPastEditEnabled={isPastEditEnabled}
          />
        )}
      </Flex>
      {errorMessage && (
        <Box mt="s">
          <ErrorNotification
            message={errorMessage}
            floating={false}
            closable={!isNewMembership}
            onDismiss={isNewMembership ? undefined : clearWarnings}
            colour="warning"
          />
        </Box>
      )}
      <Divider light />
    </StyledForm>
  );
};

MembershipForm.propTypes = {
  membership: PropTypes.object,
  person: PropTypes.object,
  isLoading: PropTypes.bool,
  isSupply: PropTypes.bool,
  onCreate: PropTypes.func,
  onDestroy: PropTypes.func,
  onUpdate: PropTypes.func,
  onReplace: PropTypes.func,
  withDateOption: PropTypes.bool,
  enableChangeSupplyGroup: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isPastEditEnabled: PropTypes.bool,
  enableFteCap: PropTypes.bool,
  demandMemberships: PropTypes.array,
  selectedGroup: PropTypes.object,
  setSelectedGroup: PropTypes.func,
  fteConfig: PropTypes.object,
  teamToAddError: PropTypes.object,
};

export default React.memo(MembershipForm);
