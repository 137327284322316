import React from "react";
import PropTypes from "prop-types";
import { ProgressBar, Spacer, Small, H4 } from "orcs-design-system";

export const niceProgressMessages = {
  CREATED: {
    title: "Queued",
    subtitle: () => "This import is queued and waiting to start processing",
  },
  QUEUED: {
    title: "Queued",
    subtitle: () => "This import is queued and waiting to start processing",
  },
  FETCHING: {
    title: "Importing data",
    subtitle: (datasourceType) =>
      `We are currently importing from ${datasourceType}`,
  },
  PROCESSING: {
    title: "Processing",
    subtitle: () =>
      `We are currently processing all the imported data and merging it with any existing data.`,
  },
};

const ImportInProgress = ({ dataImport, datasourceType }) => {
  const { title, subtitle } = niceProgressMessages[dataImport.status];
  return (
    <Spacer mb="r">
      <H4 color="warning">{title}</H4>
      <Small>{subtitle(datasourceType)}</Small>
      <ProgressBar containerWidth={100} fillWidth={dataImport.progress} />
    </Spacer>
  );
};

ImportInProgress.propTypes = {
  datasourceType: PropTypes.string.isRequired,
  dataImport: PropTypes.object.isRequired,
};
export default ImportInProgress;
