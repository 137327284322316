/* eslint-disable react/prop-types */
import React from "react";
import Text from "./Text";

const Bubble = (props) => {
  const { x, y, r, type, label } = props;
  return (
    <g {...props} r={r > 0 ? r : 1}>
      <circle
        className={type}
        r={r > 0 ? r : 1}
        transform={`translate(${x} ${y})`}
      >
        <animateTransform
          attributeName="transform"
          type="scale"
          additive="sum"
          values="0 0; 1.1 1.1; 1 1"
          keyTimes="0; 0.75; 1"
          begin={`${Math.random() * 1000}ms`}
          dur={`${Math.random() * 2000}ms`}
          repeatCount="1"
          keySplines="0.42, 0, 0.58, 1"
        />
      </circle>

      <Text id="textLabel" type="bubblelabel" value={label} x={x} y={y} />
    </g>
  );
};

export default Bubble;
