import React from "react";
import { Icon } from "orcs-design-system";
import PropTypes from "prop-types";

const CustomControlButton = ({ onClick, label, icon }) => {
  return (
    <div className="react-flow__controls">
      <button
        onClick={onClick}
        type="button"
        className="react-flow__controls-button react-flow__controls-zoomin"
        title={label}
        aria-label={label}
      >
        <Icon icon={icon} />
      </button>
    </div>
  );
};

CustomControlButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default CustomControlButton;
