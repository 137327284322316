import { map, groupBy } from "lodash";
import { Select } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";

import { NOOP } from "src/consts/global";
import { TagTypeConfigPropType } from "../propTypes";
import {
  canAddMultiTags,
  createTagSelectorOption,
  createSelectableTagOptions,
  getNewOptionData,
  canAddMultipleTagsForTagType,
  getUpdatingStateForAllTagsOfType,
  filterOptions,
} from "../TagsEditor.util";
import useSelectedValues from "../useSelectedValues";

import { SelectorContext } from "../contexts/selectorContext";
import formatOptionLabel from "./formatOptionLabel";
import CustomisedComponents, { CustomMenuList } from "./TagSelector.components";
import updateStyles from "./TagSelector.styles";

// TODO: Consolidate with src/pages/Workspaces/components/TagTypeConfig/TagAttributeFields/TagSelector/TagSelector.js

const TagSelector = ({
  loading,
  existingTags,
  allTags,
  setNewTag,
  tagTypesConfig,
  removeTag,
  onBlur,
  onEntityTagClick,
  setError,
  showTagTypeHelp,
  helpIsOpen,
  menuIsOpen,
  inputValue,
  isAssociationTag,
  onEditTagAttributes,
  selectorRef,
  onCreateNewTag,
  label,
  bulkTagMode = false,
  entityType,
  loadOptions,
  loadMore = NOOP,
  loadMoreLoading,
  clearSearchTerm = NOOP,
  menuPlacement = "bottom",
}) => {
  const { addTag, tags } = useSelectedValues(existingTags, entityType);
  const [tagsUpdating, setTagsUpdating] = useState({});
  const [typedValue, setTypedValue] = useState(inputValue);
  const [openMenu, setOpenMenu] = useState(menuIsOpen);
  const options = useMemo(
    () => createSelectableTagOptions(allTags, tagTypesConfig, existingTags),
    [allTags, tagTypesConfig, existingTags]
  );

  const existingTagValuesAsOptions = useMemo(
    () => map(tags, createTagSelectorOption),
    [tags]
  );

  const handleInputChange = useCallback(
    (value, action) => {
      if (action.action !== "input-blur" && action.action !== "menu-close") {
        setTypedValue(value);
        setOpenMenu(!!value);
        loadOptions(value);
      }
    },
    [loadOptions]
  );

  const handleMenuOpen = useCallback(() => {
    setOpenMenu(true);
  }, [setOpenMenu]);

  const handleMenuClose = useCallback(() => {
    if (!helpIsOpen) {
      setOpenMenu(false);
      setTypedValue("");
    }
  }, [helpIsOpen, setOpenMenu, setTypedValue]);

  const tagsByType = useMemo(() => {
    return groupBy(existingTags, "type");
  }, [existingTags]);

  const setTagUpdateState = useCallback(
    (tag, state) => {
      const tagType = tag?.type;
      const isMultiForTag = canAddMultipleTagsForTagType(
        tagType,
        tagTypesConfig
      );

      if (!isMultiForTag) {
        const allTagsOfType = getUpdatingStateForAllTagsOfType(
          tagsByType,
          tagType,
          state
        );
        setTagsUpdating({ ...tagsUpdating, [tag.id]: state, ...allTagsOfType });
      } else {
        setTagsUpdating({ ...tagsUpdating, [tag.id]: state });
      }
    },
    [tagTypesConfig, tagsByType, tagsUpdating]
  );

  const isMulti = canAddMultiTags(tagTypesConfig);
  const onChange = useCallback(
    async (newValue, operation) => {
      try {
        if (!operation || loading) {
          return;
        }
        if (
          operation.action === "remove-value" ||
          operation.action === "pop-value"
        ) {
          if (!operation.removedValue) {
            return;
          }

          const {
            data: {
              tag: { tagTypeConfig, ...tag },
            },
          } = operation.removedValue;

          if (tagTypeConfig.isReadonly) {
            return;
          }

          setTagUpdateState(tag, true);
          await removeTag(tag, tagTypeConfig);
          setTagUpdateState(tag, false);
        } else if (operation.action === "select-option") {
          const {
            data: {
              tag: { tagTypeConfig, ...tag },
            },
          } = isMulti ? operation.option : newValue;

          setTagUpdateState(tag, true);
          await setNewTag(tag, tagTypeConfig);
          setTagUpdateState(tag, false);
        }
      } catch (err) {
        // todo : NOT THIS!
        if (setError) {
          setError(err.toString());
        }
        throw err;
      }
    },
    [loading, setTagUpdateState, removeTag, isMulti, setNewTag, setError]
  );

  const onRemoveTag = useCallback(
    async (tag, tagTypeConfig) => {
      setTagUpdateState(tag, true);
      try {
        await removeTag(tag, tagTypeConfig);
      } catch (err) {
        if (setError) {
          setError(err.toString());
        }
      }
      setTagUpdateState(tag, false);
    },
    [removeTag, setError, setTagUpdateState]
  );

  const onAddTag = useCallback(
    async (tag, tagTypeConfig) => {
      addTag(tag);
      setTagUpdateState(tag, true);
      try {
        await setNewTag(tag, tagTypeConfig);
        // Clear user input, if there is any
        setTypedValue("");
        clearSearchTerm();
      } catch (err) {
        if (setError) {
          setError(err.toString());
        }
      }
      setTagUpdateState(tag, false);
    },
    [addTag, setTagUpdateState, setNewTag, clearSearchTerm, setError]
  );

  const onEditTag = useCallback(
    (tag, tagTypeConfig) => {
      onEditTagAttributes(tag, tagTypeConfig);
    },
    [onEditTagAttributes]
  );

  const onTagClick = useCallback(
    (tag, tagTypeConfig) => {
      if (onEntityTagClick) {
        onEntityTagClick(tag, tagTypeConfig);
      }
    },
    [onEntityTagClick]
  );

  const createNewTag = () => {
    onBlur();
    onCreateNewTag();
  };

  return (
    <SelectorContext.Provider
      value={{
        createNewTag,
        loadMoreLoading,
      }}
    >
      <Select
        ref={(ref) => {
          if (selectorRef) {
            // eslint-disable-next-line no-param-reassign
            selectorRef.current = ref;
          }
        }}
        inputId="tag-selector"
        label={label}
        isMulti={isMulti}
        autoFocus
        ariaLabel="Tag selector"
        placeholder={`Type to search ${
          isAssociationTag ? "association" : "tag"
        } name or type`}
        isLoading={loading}
        options={loading ? [] : options}
        value={existingTagValuesAsOptions}
        defaultValue={existingTagValuesAsOptions}
        onChange={onChange}
        onBlur={onBlur}
        formatGroupLabel={() =>
          `Create new ${isAssociationTag ? "association" : "tag"}`
        }
        formatOptionLabel={(option, { context }) => {
          return formatOptionLabel(option, {
            context,
            showTagTypeHelp,
            onRemoveTag,
            onEditTag,
            onAddTag,
            onTagClick,
            tagsUpdating,
            entityType,
          });
        }}
        isValidNewOption={() => {
          return false;
        }}
        isClearable={false}
        closeMenuOnSelect={false}
        selectType="creatable"
        getNewOptionData={(value) => {
          if (!isAssociationTag) {
            return {};
          }
          return getNewOptionData(value, tagTypesConfig, options);
        }}
        defaultInputValue={inputValue}
        menuIsOpen={openMenu}
        inputValue={typedValue}
        onInputChange={handleInputChange}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        components={{
          ...CustomisedComponents,
          ...(!isAssociationTag &&
            !bulkTagMode && {
              MenuList: CustomMenuList,
            }),
        }}
        updateStyles={updateStyles}
        filterOption={(...args) => {
          return filterOptions(existingTags, ...args);
        }}
        onMenuScrollToBottom={loadMore}
        menuPlacement={menuPlacement}
      />
    </SelectorContext.Provider>
  );
};

TagSelector.propTypes = {
  loading: PropTypes.bool,
  existingTags: PropTypes.array,
  allTags: PropTypes.array,
  setNewTag: PropTypes.func,
  removeTag: PropTypes.func,
  onBlur: PropTypes.func,
  onEntityTagClick: PropTypes.func,
  showTagTypeHelp: PropTypes.func,
  setError: PropTypes.func,
  tagTypesConfig: PropTypes.arrayOf(TagTypeConfigPropType).isRequired,
  inputValue: PropTypes.string,
  label: PropTypes.string,
  menuIsOpen: PropTypes.bool,
  helpIsOpen: PropTypes.bool,
  isAssociationTag: PropTypes.bool,
  onEditTagAttributes: PropTypes.func,
  selectorRef: PropTypes.object,
  onCreateNewTag: PropTypes.func,
  bulkTagMode: PropTypes.func,
  entityType: PropTypes.string,
  loadOptions: PropTypes.func,
  loadMore: PropTypes.func,
  loadMoreLoading: PropTypes.bool,
  clearSearchTerm: PropTypes.func,
  menuPlacement: PropTypes.string,
};

export default TagSelector;
