import React from "react";
import { isNil } from "lodash";
import PropTypes from "prop-types";

import { FlexItem } from "orcs-design-system";
import NoPrincipleSet from "./NoPrincipleSet";
import PrincipleResultList from "./PrincipleResultList";

const Principles = ({
  team,
  principleSets,
  mutating,
  history,
  setPrincipleSetId,
  principles,
}) => {
  const hasNoPrincipleSetAssigned = isNil(team?.principleSetId);
  return (
    <FlexItem flex="1 0 auto" height="100%">
      {hasNoPrincipleSetAssigned ? (
        <NoPrincipleSet
          history={history}
          mutating={mutating}
          team={team}
          principleSets={principleSets}
          setPrincipleSetId={setPrincipleSetId}
        />
      ) : (
        <PrincipleResultList
          mutating={mutating}
          team={team}
          principleSets={principleSets}
          setPrincipleSetId={setPrincipleSetId}
          principles={principles}
        />
      )}
    </FlexItem>
  );
};

Principles.propTypes = {
  team: PropTypes.object,
  principleSets: PropTypes.array,
  mutating: PropTypes.bool,
  history: PropTypes.object.isRequired,
  setPrincipleSetId: PropTypes.func.isRequired,
  principles: PropTypes.array,
};

export default Principles;
