import { isEmpty } from "lodash";
import { SideNav, Box } from "orcs-design-system";
import React, { useState } from "react";
import { useTheme } from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import useSideNavClickListener from "src/hooks/useSideNavClickListener";
import useWindowResizeListener from "src/hooks/useWindowResizeListener";
import { useWorkspaceTagConfig } from "src/contexts/global/WorkspaceContext";
import { useAuth0 } from "src/contexts/auth0Provider";
import { useFeatureFlags } from "src/contexts/global/FeatureFlagsContext";
import useSideNavItemBuilder from "src/sidebar/useSideNavItemBuilder";
import eventEmitter from "src/services/eventEmitter";
import resetAppHeight from "./libs/resetAppHeight";
import useFilterNavItems from "./hooks/useFilterNavItems";

const SideNavigation = () => {
  const isDemo = !!window?.location?.search?.includes("demo=true");
  const theme = useTheme();
  const [clickedItem, setClickedItem] = useState({});
  const tagTypesConfig = useWorkspaceTagConfig();
  const { user } = useAuth0();
  const featureFlags = useFeatureFlags();
  const navItems = useSideNavItemBuilder({
    clickedItem,
    user,
    tagTypesConfig,
    featureFlags,
    eventEmitter,
    setClickedItem,
  });
  useWindowResizeListener(resetAppHeight, isDemo, {
    ...theme,
    useNewNavBar: true,
  });
  useSideNavClickListener();
  const fileredNavItems = useFilterNavItems(navItems);
  if (isEmpty(fileredNavItems)) {
    return null;
  }

  return (
    <Box
      pb={["0", "0", "0", "r"]}
      pl={["0", "0", "0", "r"]}
      height={`calc(100vh - ${themeGet("appScale.newNavBarSize")({ theme })})`}
    >
      <SideNav items={fileredNavItems} sideNavHeight="100%" />
    </Box>
  );
};

export default SideNavigation;
