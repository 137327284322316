import { keys } from "lodash";
import { useCallback, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { searchGroups as searchGroupsQuery } from "./groupsSearch.graphql";

const EMPTY_ARRAY = [];

const useSearchGroups = ({ targetGroupTypes, additionalVariables = {} }) => {
  const client = useApolloClient();
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [searchInProgress, setSearchInProgress] = useState(false);

  const searchGroups = useCallback(
    async (searchTerm) => {
      // Only search when user typed more than 2 characters
      if (!searchTerm || searchTerm.length < 3) {
        setFilteredGroups(EMPTY_ARRAY);
        return;
      }

      setSearchInProgress(true);

      const { data } = await client.query({
        query: searchGroupsQuery,
        variables: {
          search: searchTerm,
          groupTypes: keys(targetGroupTypes),
          ...additionalVariables,
        },
      });

      setSearchInProgress(false);

      const targetGroups = data?.result.groups || [];
      setFilteredGroups(targetGroups);
    },
    [additionalVariables, client, targetGroupTypes]
  );

  const clearSearch = useCallback(() => {
    setFilteredGroups([]);
  }, []);

  return { searchGroups, filteredGroups, searchInProgress, clearSearch };
};

export default useSearchGroups;
