import React from "react";
import PropTypes from "prop-types";
import { Grid } from "orcs-design-system";
import QuickLinks from "src/components/PersonDetailPage/QuickLinks";
import WorkspaceAnnouncements from "src/components/PersonDetailPage/WorkspaceAnnouncements";

const UserDashboard = ({ person }) => {
  return (
    <Grid
      gridGap="r"
      height="100%"
      gridTemplateColumns={[
        "repeat(auto-fit,minmax(300px,1fr))",
        "repeat(auto-fit,minmax(310px,1fr))",
        "repeat(auto-fit,minmax(420px,1fr))",
      ]}
    >
      <QuickLinks person={person} />
      <WorkspaceAnnouncements />
    </Grid>
  );
};

UserDashboard.propTypes = {
  person: PropTypes.object.isRequired,
};

export default UserDashboard;
