import React from "react";
import { Box, Button, Flex, Icon } from "orcs-design-system";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router-dom";
import ConfiguredActionMenu from "src/components/ConfiguredActionMenu";
import { teamDetailsPath } from "src/util/routing";
import GroupHierarchyBreadcrumb from "src/components/GroupHierarchyBreadcrumb";
import { TEAM_TAB } from "src/consts/urlPaths";

const TeamsCell = ({ cell }) => {
  const cellValue = cell.getValue();
  const history = useHistory();

  if (!cellValue) {
    return null;
  }

  if (cellValue?.length > 1) {
    return (
      <Box>
        <Flex
          display="inline-flex"
          justifyContent="center"
          flexDirection="column"
        >
          <ConfiguredActionMenu
            actions={cellValue.map((group) => ({
              label: group.name,
              onClick: () =>
                history.push(teamDetailsPath(group, TEAM_TAB.STRATEGY)),
            }))}
            customTriggerComponent={
              <Button small variant="ghost" iconRight>
                shared by {cellValue.length} teams
                <Icon icon={["fas", "chevron-down"]} />
              </Button>
            }
          />
        </Flex>
      </Box>
    );
  }

  return (
    <Box>
      <Flex
        display="inline-flex"
        justifyContent="center"
        flexDirection="column"
      >
        {cellValue.map((group, i) => {
          return (
            <Box
              mr="s"
              mb={i >= cellValue.length - 1 ? "" : "l"}
              key={group.id}
            >
              <GroupHierarchyBreadcrumb group={group} />
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};

TeamsCell.propTypes = {
  cell: PropTypes.object,
  row: PropTypes.shape({
    original: PropTypes.shape({
      loadMore: PropTypes.bool,
      kind: PropTypes.string,
      dataSource: PropTypes.shape({
        type: PropTypes.string,
      }),
    }),
  }),
};

export default TeamsCell;
