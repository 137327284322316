import { get } from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import TagsEditorContainer from "src/components/TagsEditor";
import useHandleEntityTagClick from "src/hooks/useHandleEntityTagClick";
import useTagsVisibleInFilter from "src/app/Restricted/useTagsVisibleInFilter";
import { getName } from "src/util/person";
import { useUpdatePersonTagsCache } from "../hooks/useUpdatePersonTagsCache";

export const Tags = ({ person }) => {
  const tagsVisibleIn = useTagsVisibleInFilter();
  const workspace = useWorkspace();
  const workspaceFeatureFlags = get(workspace, "config.featureFlags", {});
  const onEntityTagClick = useHandleEntityTagClick();

  const aggregateId = get(person, "aggregateId");
  const { updateTagsCache } = useUpdatePersonTagsCache(aggregateId);

  return (
    <TagsEditorContainer
      entityType="person"
      entityId={aggregateId}
      entityName={getName(person)}
      updateCache={updateTagsCache}
      onEntityTagClick={onEntityTagClick}
      comments={{}}
      tagsVisibleIn={tagsVisibleIn}
      featureFlags={workspaceFeatureFlags}
      menuPlacement="top"
      mt="0"
    />
  );
};

Tags.propTypes = {
  person: PropTypes.object,
};
