export const getAncestorsAndChildren = (
  nodes,
  objectId,
  visited = new Set()
) => {
  const objective = nodes.find((node) => node.id === objectId);

  if (!objective) {
    return { error: "Object not found", ancestors: [], children: [] };
  }

  let ancestors = [];

  if (objective.data?.collapsedNodes) {
    const collapsedNodeAncestors = objective.data?.collapsedNodes.flatMap(
      (collapsedNode) => {
        return getAncestorsAndChildren(nodes, collapsedNode.id, visited)
          .ancestors;
      }
    );

    ancestors = ancestors.concat(collapsedNodeAncestors);
  }

  if (objective.data.hierarchyParentIds?.length > 0) {
    objective.data.hierarchyParentIds.forEach((parentId) => {
      if (!visited.has(parentId)) {
        visited.add(parentId);

        const parentNode = nodes.find((node) => {
          if (node?.data?.collapsedNodes && node.id !== parentId) {
            return node.data.collapsedNodes.some(
              (collapsedNode) => collapsedNode.id === parentId
            );
          }
          return node.id === parentId;
        });

        if (parentNode) {
          ancestors.push(parentNode);
          const parentAncestors = getAncestorsAndChildren(
            nodes,
            parentNode.id,
            visited
          ).ancestors;
          ancestors = ancestors.concat(parentAncestors);
        }
      }
    });
  }

  const children = nodes.filter((node) =>
    node.data.hierarchyParentIds?.includes(objectId)
  );

  return { objective, ancestors, children };
};

export const getAncestorsAndDescendants = (nodes, objective) => {
  const { ancestors } = getAncestorsAndChildren(nodes, objective.id, new Set());

  const getAllDescendants = (nodeId, visited = new Set()) => {
    if (visited.has(nodeId)) {
      return [];
    }
    visited.add(nodeId);

    const directChildren = nodes.filter((node) =>
      node.data.hierarchyParentIds?.includes(nodeId)
    );

    return [
      ...directChildren,
      ...directChildren.flatMap((child) => {
        if (child.data?.collapsedNodes) {
          return child.data.collapsedNodes.flatMap((collapsedNode) =>
            getAllDescendants(collapsedNode.id, visited)
          );
        }
        return getAllDescendants(child.id, visited);
      }),
    ];
  };

  const descendants = [...getAllDescendants(objective.id)];

  return { ancestors, descendants };
};

export const isDirectHierarchicalPath = ({ nodes, objective, edge }) => {
  if (!objective) {
    return false;
  }

  const collapsedNodes =
    objective?.data?.collapsedNodes || objective?.collapsedNodes;
  const objectivesToCheck = [objective, ...(collapsedNodes || [])];

  const { ancestors, descendants } = objectivesToCheck.reduce(
    (acc, obj) => {
      const result = getAncestorsAndDescendants(
        nodes,
        obj.data || obj,
        edge.id === "O0011-Initiative_header_2_collapsed_node"
      );

      return {
        ancestors: [...acc.ancestors, ...result.ancestors],
        descendants: [...acc.descendants, ...result.descendants],
      };
    },
    { ancestors: [], descendants: [] }
  );
  const isInPath = (nodeId, list) => list.some((node) => node.id === nodeId);

  return (
    (edge.target === objective.id && isInPath(edge.source, ancestors)) ||
    (edge.source === objective.id && isInPath(edge.target, descendants)) ||
    (isInPath(edge.source, ancestors) && isInPath(edge.target, ancestors)) ||
    (isInPath(edge.source, descendants) && isInPath(edge.target, descendants))
  );
};

export const createCollapsedNodeId = (headerId) =>
  `${headerId.replaceAll(" ", "_")}_collapsed_node`;
