import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Box, Flex, FlexItem, H1, P, Spacer } from "orcs-design-system";
import styled from "styled-components";
import Dialogue from "src/components/Dialogue";
import integrationTypes from "../../integrationTypes";

const Image = styled.img`
  width: auto;
  height: 50px;
  border: 0;
  display: block;
`;

const Heading = ({
  datasource,
  onDeleteDatasource,
  onLinkDatasource,
  showRemoveIntegration = true,
  showLinkIntegration = false,
}) => {
  const integrationType = get(integrationTypes, datasource.type, {});
  return (
    <Box bg="white" boxBorder="default" borderRadius="2" p="r" mb="r">
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          {integrationType.logoSrc && (
            <Image
              src={integrationType.logoSrc}
              alt={integrationType.logoAlt}
            />
          )}
          <FlexItem ml="r">
            <H1>{datasource.name}</H1>
            <P color="greyDark">{datasource.description}</P>
          </FlexItem>
        </Flex>

        <Flex>
          <Spacer mr="s">
            {showLinkIntegration && (
              <Dialogue
                width="322px"
                height="220px"
                buttonText="Link"
                variant="success"
                icon={["fas", "link"]}
                iconLeft
                confirmAction={() => {
                  onLinkDatasource(datasource.id);
                  return true;
                }}
                confirmText="OK"
                cancelText="Cancel"
                data-testid="connect-integration-dialogue"
              >
                <>
                  <P mb="r" weight="bold">
                    Continue to link this integration.
                  </P>
                  <P>
                    You must have permission to allow TeamForm to link to your
                    organization&apos;s system.
                  </P>
                </>
              </Dialogue>
            )}
            {showRemoveIntegration && (
              <Dialogue
                width="322px"
                height="220px"
                buttonText="Remove integration"
                confirmVariant="danger"
                variant="danger"
                icon={["far", "trash-alt"]}
                iconLeft
                confirmAction={() => {
                  onDeleteDatasource(datasource.id);
                  return true;
                }}
                confirmText="OK"
                cancelText="Cancel"
                data-testid="remove-integration-dialogue"
              >
                <>
                  <P mb="r" weight="bold">
                    This will remove this integration, but no data will be
                    removed.
                  </P>
                  <P>Do you wish to continue?</P>
                </>
              </Dialogue>
            )}
          </Spacer>
        </Flex>
      </Flex>
    </Box>
  );
};

// TODO: PropTypes object - use shape
Heading.propTypes = {
  datasource: PropTypes.object,
  onDeleteDatasource: PropTypes.func,
  onLinkDatasource: PropTypes.func,
  showRemoveIntegration: PropTypes.bool,
  showLinkIntegration: PropTypes.bool,
};

export default Heading;
