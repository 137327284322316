import React from "react";
import PropTypes from "prop-types";
import { Flex, H3 } from "orcs-design-system";
import GroupTypeBadge from "src/components/Badge/GroupTypeBadge";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import TeamAvatar from "src/components/TeamAvatar/TeamAvatar";

export const Header = ({ team }) => {
  const workspace = useWorkspace();

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <TeamAvatar
        sizing="large"
        group={team}
        imageAlt={`Avatar of ${team.name}`}
        mb="r"
      />
      <H3 mb="xs">{team.name}</H3>
      <GroupTypeBadge
        showBreadcrumbIcon={false}
        group={team}
        groupTypes={workspace?.config?.groupTypes}
      />
    </Flex>
  );
};

Header.propTypes = {
  team: PropTypes.object.isRequired,
};
