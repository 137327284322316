import React, { useMemo } from "react";
import { useNodes, useReactFlow } from "reactflow";
import pluralize from "pluralize";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Button, Flex, H3, Icon, Popover } from "orcs-design-system";
import { capitalize } from "lodash";
import { StyledBadge, StyledCard } from "../node.styled";
import { THEME } from "../ObjectiveNode/node.styled";
import { objectiveNodeHeaderDataPropType } from "../propTypes";
import { NODE_THEMES, NODE_TYPES } from "../../consts";
import {
  DEFAULT_COLLAPSE_COUNT,
  useObjectiveState,
} from "../../contexts/ObjectiveProviderContext";

const StyledButton = styled(Button)`
  background: none;
  border: none;
  outline: none;
  margin-left: auto;

  &:hover {
    background: none;
    border: none;
    outline: none;
    margin-left: auto;

    & span {
      color: gray;
    }
  }

  &:focus,
  &:active {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
  }
`;

const ObjectiveHeaderNode = ({ data, isFooter }) => {
  const { nodeType, label, typeCount } = data;

  const {
    onToggleExpandedHeaders,
    collapsedHeaders,
    getCollapsibleNodesCount,
  } = useObjectiveState();
  const nodes = useNodes();
  const reactFlow = useReactFlow();

  const visibleNodes = useMemo(() => {
    return nodes.filter(
      (n) =>
        n.depth === data.depth &&
        ![
          NODE_TYPES.OBJECTIVE_HEADER_NODE,
          NODE_TYPES.SHOW_MORE_OBJECTIVES_NODE,
        ].includes(n.type)
    );
  }, [nodes, data.depth]);

  const isCollapsed = useMemo(() => {
    return collapsedHeaders.map((n) => n.id).includes(data.id);
  }, [data.id, collapsedHeaders]);

  const collapsibleCount = useMemo(() => {
    return getCollapsibleNodesCount(data, nodes);
  }, [nodes, data, getCollapsibleNodesCount]);

  const iconButtons = useMemo(() => {
    return [
      {
        icon: ["fas", isFooter ? "arrow-to-top" : "arrow-to-bottom"],
        popoverText: isFooter ? "Jump to top" : "Jump to bottom",
        onClick: () => {
          const jumpTarget = nodes
            .filter((n) => n.depth === data.depth)
            .sort((a, b) =>
              isFooter
                ? b.position.y - a.position.y
                : a.position.y - b.position.y
            )
            .pop();

          reactFlow.fitView({
            nodes: [jumpTarget],
            duration: 500,
            maxZoom: 0.5,
          });
        },
        disabled: visibleNodes.length < DEFAULT_COLLAPSE_COUNT,
      },
      {
        icon: [
          "fas",
          isCollapsed ? "expand-arrows-alt" : "compress-arrows-alt",
        ],
        popoverText: `${
          isCollapsed
            ? "Expand all nodes"
            : collapsibleCount
            ? `Collapse ${collapsibleCount} unpinned ${
                collapsibleCount === 1 ? "node" : "nodes"
              }`
            : "No unpinned nodes to collapse"
        }`,
        onClick: () => onToggleExpandedHeaders(data, isFooter),
      },
    ];
  }, [
    collapsibleCount,
    data,
    isCollapsed,
    isFooter,
    nodes,
    onToggleExpandedHeaders,
    reactFlow,
    visibleNodes.length,
  ]);

  const theme = THEME[nodeType];
  const isTeamTypeNode = nodeType === NODE_THEMES.TEAM;

  return (
    <StyledCard
      cursor="grab"
      className="objective-header-node"
      $hidden={isTeamTypeNode}
    >
      <Flex alignContent="flex-start" alignItems="center">
        <StyledBadge color={theme?.secondaryColor}>
          {theme?.icon && (
            <Icon
              size="2x"
              icon={["far", theme.icon]}
              color={theme?.targetColor || theme?.color}
            />
          )}
        </StyledBadge>

        <H3 ml="r" color={theme?.color}>
          {typeCount} {capitalize(typeCount === 1 ? label : pluralize(label))}
        </H3>

        <Flex ml="auto">
          {iconButtons.map(({ icon, popoverText, onClick, disabled }) => (
            <Popover
              key={popoverText}
              width="fit-content"
              direction="top"
              text={popoverText}
            >
              <StyledButton
                large
                iconOnly
                p="s"
                ariaLabel={<Icon icon={icon} />}
                onClick={onClick}
                disabled={disabled}
              >
                <Icon icon={icon} color="lightgray" size="xl" />
              </StyledButton>
            </Popover>
          ))}
        </Flex>
      </Flex>
    </StyledCard>
  );
};

ObjectiveHeaderNode.propTypes = {
  data: objectiveNodeHeaderDataPropType,
  isFooter: PropTypes.bool,
};

export default ObjectiveHeaderNode;
