import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { keyBy } from "lodash";
import { Button, Divider, Flex } from "orcs-design-system";
import moment from "moment";

import { useGroupTypes } from "src/contexts/global/WorkspaceContext";
import { getSupplyGroupTypes } from "src/util/customerConfig";
import ErrorNotification from "src/components/ErrorNotification";

import GroupsSearch from "src/components/GroupsSearch";
import { StyledForm } from "./MembershipForm.styled";

const SupplyMembershipForm = ({
  membership,
  person,
  onUpdateGroup,
  isLoading,
}) => {
  const groupTypes = useGroupTypes();
  const [newSupplyGroup, setNewSupplyGroup] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [apiError, setApiError] = useState();

  const supplyGroupTypes = useMemo(() => {
    return keyBy(getSupplyGroupTypes(groupTypes), "id");
  }, [groupTypes]);

  const handleUpdate = useCallback(async () => {
    if (!newSupplyGroup) {
      return;
    }

    if (membership && membership.groupId === newSupplyGroup.id) {
      return;
    }

    const mutatedMemberships = {
      membershipToAdd: {
        ...(membership || {
          personId: person.aggregateId,
          endDate: null,
          fte: null,
        }),
        fte: null, // Make sure supply group fte is null
        startDate: moment().startOf("day"), // New membership start from today
        groupId: newSupplyGroup.id,
      },
    };

    if (membership) {
      // If current membership startDate is today or future, remove the membership
      if (membership.startDate.isSameOrAfter(moment().startOf("day"))) {
        mutatedMemberships.membershipToRemove = membership;
      } else {
        // If current membership startDate is in the past, end the membership by updating the end date to yesterday
        mutatedMemberships.membershipToUpdate = {
          ...membership,
          fte: null,
          endDate: moment().subtract(1, "days").startOf("day"),
        };
      }
    }

    const onUpdateStatus = (status) => {
      setIsUpdating(status?.isProcessing);
      setApiError(status?.error);
    };

    await onUpdateGroup(mutatedMemberships, onUpdateStatus);

    setNewSupplyGroup(null);
  }, [membership, newSupplyGroup, onUpdateGroup, person]);

  const handleOnGroupSelected = useCallback(
    (group) => {
      if (!supplyGroupTypes[group?.type]) {
        return;
      }
      setNewSupplyGroup(group);
    },
    [supplyGroupTypes]
  );

  return (
    <StyledForm highlight inert={isLoading ? "true" : undefined}>
      <Flex
        p="s"
        flexDirection={["column", "column", "column", "column", "row"]}
        justifyContent="space-between"
        alignItems={["flex-end", "flex-end", "flex-end", "flex-end", "center"]}
      >
        <Flex
          mr={["0", "0", "0", "0", "s"]}
          mb={["s", "s", "s", "s", "0"]}
          width="100%"
        >
          <GroupsSearch
            placeholder="Click to search and select a team"
            actionLabel="Click to select"
            groupTypes={groupTypes}
            targetGroupTypes={supplyGroupTypes}
            onGroupSelected={handleOnGroupSelected}
          />
        </Flex>
        <Flex>
          <Button
            iconOnly
            aria-label="Add"
            onClick={handleUpdate}
            variant="success"
            disabled={!newSupplyGroup || isUpdating}
            isLoading={isUpdating}
            height="38px"
          >
            {membership ? "Change" : "Add"}
          </Button>
        </Flex>
      </Flex>
      {apiError && (
        <ErrorNotification
          message={apiError.message}
          floating={false}
          colour="warning"
        />
      )}
      <Divider light />
    </StyledForm>
  );
};

SupplyMembershipForm.propTypes = {
  person: PropTypes.object,
  membership: PropTypes.object,
  onUpdateGroup: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default SupplyMembershipForm;
