import { isEmpty, map, isFinite, get } from "lodash";
import {
  Avatar,
  Badge,
  Flex,
  StyledLink,
  Spacer,
  ActionsMenu,
  ActionsMenuItem,
  Button,
  Icon,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import FteBadge from "src/components/Badge/FteBadge";
import GroupBadge from "src/components/Badge/GroupBadge";
import { copywriting } from "src/components/Person/PersonItem/PersonItem.config";
import GroupPropType from "src/custom-prop-types/group";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import PersonPropType from "src/custom-prop-types/person";
import { getPeoplePath } from "src/util/paths";
import { findFteToDisplay, getInitials, getName } from "src/util/person";

import { ENTITY_TYPES } from "src/consts/comments";
import TagDisplay from "src/components/TagDisplay";
import { displayLocalTime } from "../../utils";

import { useShouldDisplayLocalTime } from "../hooks/useShouldDisplayLocalTime";
import useFulfillVacantRole from "./hooks/useFulfillVacantRole";

// NOTE: Refactored to prevent cyclic dependency with ReportTeamChange -> PersonItem

const PersonAvatarAndDetails = ({
  badgeGroups,
  group,
  groupTypes,
  person,
  showBadges = true,
  cardView,
  isDisplayActionsButton,
  canChangeAllocations,
  onEditingAllocations,
  canReportTeamChange,
  onDisplayReportTeamChange,
  renderFteBadge,
  showLocalTime = false,
  workspace,
  user,
  allocationProject,
  subtitleContent,
}) => {
  const showLocalTimeForPerson = useShouldDisplayLocalTime({
    showLocalTime,
    member: person,
  });

  const enableAllTagsToBeVisible = get(
    workspace,
    "config.featureFlags.enableAllTagsToBeVisible"
  );

  const fte = group && findFteToDisplay(person, group, groupTypes);
  const showFte = isFinite(fte);
  const hasNoBadges = isEmpty(badgeGroups) && !showFte && !renderFteBadge;
  const { canShowFulfillButton, handleAddMember, vacantRoleLabel } =
    useFulfillVacantRole({
      person,
      group,
      workspace,
      user,
      groupTypes,
      allocationProject,
    });

  const memberBadges = (
    <>
      <Flex flexWrap="wrap" alignItems="center" role="list">
        {person.jobTitle && (
          <Badge mr="xs" mt="xs" aria-label="Job role" role="listitem">
            {person.jobTitle}
          </Badge>
        )}
        {!hasNoBadges && showBadges && (
          <Spacer mr="xs" mt="xs" role="listitem">
            {!isEmpty(badgeGroups) &&
              map(badgeGroups, (badgeGroup) => (
                <GroupBadge
                  aria-label="Team and Hierarchy"
                  group={badgeGroup}
                  groupTypes={groupTypes}
                  key={badgeGroup.id}
                />
              ))}
            {renderFteBadge
              ? renderFteBadge(person, group)
              : showFte && <FteBadge fte={fte} />}
          </Spacer>
        )}
      </Flex>
      <TagDisplay
        tags={person?.tags}
        entityType={ENTITY_TYPES.PERSON}
        showHighlightedTagsOnly={!enableAllTagsToBeVisible}
      />
    </>
  );

  const canShowActionsButton =
    isDisplayActionsButton &&
    (canChangeAllocations || canReportTeamChange || canShowFulfillButton);
  const personName = getName(person);

  const memberTitle = (
    <Flex justifyContent="flex-start" alignItems="center">
      <StyledLink
        aria-label={`Navigate to ${personName}`}
        to={getPeoplePath(person.aggregateId)}
      >
        {personName}
      </StyledLink>
      {canShowActionsButton && (
        <ActionsMenu
          ml="xs"
          data-testid="cp-personitem-actionsbtn"
          customTriggerComponent={
            <Button
              variant="ghost"
              iconOnly
              type="button"
              width="20px"
              height="20px"
              p="1px"
              ariaLabel={`Open actions menu for ${personName}`}
            >
              <Icon icon={["fas", "chevron-down"]} size="xs" />
            </Button>
          }
        >
          {canChangeAllocations && (
            <ActionsMenuItem onClick={onEditingAllocations}>
              {copywriting.EDIT_ALLOCATIONS}
            </ActionsMenuItem>
          )}
          {canReportTeamChange && (
            <ActionsMenuItem onClick={onDisplayReportTeamChange}>
              {copywriting.NOTIFY_TEAM_CHANGES}
            </ActionsMenuItem>
          )}
          {canShowFulfillButton && canChangeAllocations && (
            <ActionsMenuItem onClick={handleAddMember}>
              {`Fulfil ${vacantRoleLabel}`}
            </ActionsMenuItem>
          )}
        </ActionsMenu>
      )}
    </Flex>
  );

  return (
    <Avatar
      title={memberTitle}
      imageAlt={`Avatar of ${personName}`}
      initials={getInitials(person)}
      image={person.avatar}
      sizing={cardView ? "large" : "small"}
      subtitleContent={subtitleContent || memberBadges}
      localTime={showLocalTimeForPerson ? displayLocalTime(person) : null}
    />
  );
};

PersonAvatarAndDetails.propTypes = {
  person: PersonPropType.isRequired,
  isDisplayActionsButton: PropTypes.bool,
  badgeGroups: PropTypes.arrayOf(GroupPropType),
  groupTypes: GroupTypesPropType,
  cardView: PropTypes.bool,
  group: GroupPropType,
  showBadges: PropTypes.bool,
  canChangeAllocations: PropTypes.bool,
  onEditingAllocations: PropTypes.func,
  canReportTeamChange: PropTypes.bool,
  onDisplayReportTeamChange: PropTypes.func,
  renderFteBadge: PropTypes.func,
  showLocalTime: PropTypes.bool,
  workspace: PropTypes.object,
  user: PropTypes.object,
  allocationProject: PropTypes.object,
  subtitleContent: PropTypes.node,
};

export default PersonAvatarAndDetails;
