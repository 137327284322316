import React, { useState } from "react";
import PropTypes from "prop-types";
import { H2, Flex, Box, FlexItem } from "orcs-design-system";

import {
  useGroupTypes,
  useWorkspaceFeatureFlags,
  useWorkspaceFteConfig,
} from "src/contexts/global/WorkspaceContext";

import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";
import { ViewModeSwitcher, VIEW_MODES } from "./components/ViewModeSwitcher";
import ListView from "./components/ListView";
import TimelineView from "./components/TimelineView";
import { useMemberships } from "./hooks/useMemberships";

export const PersonMemberships = (props) => {
  const { enableFutureAllocationsTimeline } = useWorkspaceFeatureFlags();
  const fteConfig = useWorkspaceFteConfig();
  const groupTypes = useGroupTypes();

  const { layoutFunction } = props;
  const [viewMode, setViewMode] = useState(VIEW_MODES.LIST);

  const membershipData = useMemberships(props, viewMode, fteConfig);

  const handleViewModeChange = (e) => {
    setViewMode(e.currentTarget.value);
    trackEvent(EVENT_TRACKING.SETTING_CHANGED, {
      setting: "allocation_view_mode",
      value: e.currentTarget.value,
    });
  };

  let view;
  if (viewMode === VIEW_MODES.TIMELINE) {
    view = (
      <TimelineView
        {...membershipData}
        groupTypes={groupTypes}
        fteConfig={fteConfig}
      />
    );
  } else {
    view = <ListView {...membershipData} fteConfig={fteConfig} />;
  }

  const viewToggle = enableFutureAllocationsTimeline && (
    <FlexItem ml="auto">
      <ViewModeSwitcher
        viewMode={viewMode}
        handleViewModeChange={handleViewModeChange}
      />
    </FlexItem>
  );

  if (layoutFunction) {
    return layoutFunction(view, viewToggle);
  }

  return (
    <Box borderRadius={2} boxBorder="default" p="r" bg="white" height="100%">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        mb="s"
      >
        <FlexItem>
          <H2 sizing="small" weight="light">
            Allocations
          </H2>
        </FlexItem>
        {viewToggle}
      </Flex>
      {view}
    </Box>
  );
};

PersonMemberships.propTypes = {
  person: PropTypes.object,
  teamToAdd: PropTypes.object,
  teamToAddError: PropTypes.object,
  layoutFunction: PropTypes.object,
  onMembershipAdded: PropTypes.func,
  onMembershipRemoved: PropTypes.func,
  onMembershipFteUpdated: PropTypes.func,
};

export default PersonMemberships;
