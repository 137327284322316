import { pick } from "lodash";
import { Icon, Popover, Tag } from "orcs-design-system";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import React from "react";

import getPersonEntityProps from "src/util/getPersonEntityProps";
import icons from "../../config/icons";

const defaultGetTypeName = ({ tagTypeConfig: { name } }) => name;

const TagBadge = ({
  value,
  withType = false,
  isRemoved = false,
  getTypeName = defaultGetTypeName,
  disabled,
  selected,
  onSelect,
  showRemove,
  onRemove,
  showEdit,
  onEdit,
  withAppliedCount = false,
  showUpdating,
  small = true,
  highlighted = false,
  showInfo = false,
  tagTypeConfig,
  onInfo,
  ...props
}) => {
  if (!value) {
    return null;
  }
  const showTagType =
    value && value.tagTypeConfig
      ? !value.tagTypeConfig.hideTagTypeInTagDisplay
      : !tagTypeConfig?.hideTagTypeInTagDisplay;

  const { associationDirection, displayValue, appliedCount } = pick(value, [
    "associationDirection",
    "displayValue",
    "id",
    "appliedCount",
  ]);

  const fullTagLabel = withType
    ? `${getTypeName(value)}: ${displayValue}`
    : displayValue;
  const tagType = withType ? getTypeName(value) : undefined;
  const showEditButton = !showUpdating && showEdit;
  const showRemoveButton = !showUpdating && showRemove;

  if (isRemoved) {
    return (
      <Popover
        aria-label={fullTagLabel}
        direction="top"
        text="This tag has been removed"
        textAlign="center"
        data-testid="cp-exp-badge-item-popover"
        {...props}
      >
        <Tag
          data-testid="cp-exp-badge-item"
          role="listitem"
          tagType={showTagType ? tagType : null}
          disabled
          showStrikeThrough
          selected={false}
          small={small}
          highlighted={highlighted}
          personEntity={getPersonEntityProps(value, tagTypeConfig)}
          onInfo={onInfo}
        >
          {displayValue}
        </Tag>
      </Popover>
    );
  }

  if (associationDirection) {
    return (
      <Popover
        aria-label={fullTagLabel}
        direction="top"
        text={`${associationDirection.description}`}
        textAlign="center"
        data-testid="cp-exp-badge-item-popover"
        {...props}
      >
        <Tag
          data-testid="cp-exp-badge-item"
          role="listitem"
          tagType={showTagType ? tagType : null}
          disabled={disabled}
          selected={selected}
          onSelect={onSelect}
          showRemove={showRemoveButton}
          onRemove={onRemove}
          showEdit={showEditButton}
          onEdit={onEdit}
          isPending={showUpdating}
          small={small}
          highlighted={highlighted}
          showInfo={showInfo}
          personEntity={getPersonEntityProps(value, tagTypeConfig)}
          onInfo={onInfo}
        >
          <Icon icon={icons.directions[associationDirection.mode]} mr="xs" />
          {displayValue}
        </Tag>
      </Popover>
    );
  }

  if (withAppliedCount && appliedCount > 0) {
    return (
      <Popover
        aria-label={fullTagLabel}
        direction="top"
        text={`Tag applied ${appliedCount} ${pluralize("time", appliedCount)}`}
        textAlign="center"
        data-testid="cp-exp-badge-item-popover"
        {...props}
      >
        <Tag
          data-testid="cp-exp-badge-item"
          role="listitem"
          tagType={showTagType ? tagType : null}
          disabled={disabled}
          selected={selected}
          onSelect={onSelect}
          showRemove={showRemoveButton}
          onRemove={onRemove}
          showEdit={showEditButton}
          onEdit={onEdit}
          isPending={showUpdating}
          small={small}
          highlighted={highlighted}
          showInfo={showInfo}
          personEntity={getPersonEntityProps(value, tagTypeConfig)}
          onInfo={onInfo}
        >
          {displayValue}
        </Tag>
      </Popover>
    );
  }

  return (
    <Tag
      data-testid="cp-exp-badge-item"
      {...props}
      aria-label={fullTagLabel}
      role="listitem"
      tagType={showTagType ? tagType : null}
      disabled={disabled}
      selected={selected}
      onSelect={onSelect}
      showRemove={showRemoveButton}
      onRemove={onRemove}
      showEdit={showEditButton}
      onEdit={onEdit}
      isPending={showUpdating}
      small={small}
      highlighted={highlighted}
      showInfo={showInfo}
      personEntity={getPersonEntityProps(value, tagTypeConfig)}
      onInfo={onInfo}
    >
      {displayValue}
    </Tag>
  );
};

TagBadge.propTypes = {
  value: PropTypes.object,
  withType: PropTypes.bool,
  getTypeName: PropTypes.func,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  showRemove: PropTypes.bool,
  showEdit: PropTypes.bool,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  withAppliedCount: PropTypes.bool,
  showUpdating: PropTypes.bool,
  isRemoved: PropTypes.bool,
  small: PropTypes.bool,
  highlighted: PropTypes.bool,
  showInfo: PropTypes.bool,
  tagTypeConfig: PropTypes.object,
  onInfo: PropTypes.func,
};

export default TagBadge;
