import React from "react";
import { find, isNumber } from "lodash";
import PropTypes from "prop-types";
import { Flex, ProgressBar, Small } from "orcs-design-system";

const ObjectiveProgressBar = ({ objective, mt = "", containerWidth = 100 }) => {
  const attributes = objective?.attributes || [];
  const progress =
    find(attributes, { attributeType: "progressPercentage" }) ||
    (isNumber(objective?.progress) && { value: objective?.progress });

  const value = progress?.value || 0;
  const percentage = value > 100 ? 100 : value;

  if (!progress) {
    return null;
  }

  // there's a slight disconnect here in that the flex will have a greater width than the inner progress bar
  // perhaps the flex should be width=100%, or have a separate width prop
  return (
    <Flex mt={mt} pr="s" alignItems="center" width={`${containerWidth}%`}>
      <ProgressBar
        ariaLabel="Percent Completed"
        containerWidth={containerWidth}
        fillWidth={Number(percentage)}
        mr="s"
      />
      <Small fontWeight="300">{percentage}%</Small>
    </Flex>
  );
};

ObjectiveProgressBar.propTypes = {
  objective: PropTypes.object,
  mt: PropTypes.string,
  containerWidth: PropTypes.number,
};

export default ObjectiveProgressBar;
