import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PropTypes } from "prop-types";
import { getBezierPath, BaseEdge, useNodes } from "reactflow";
import { createPortal } from "react-dom";
import styled, { useTheme } from "styled-components";
import themeGet from "@styled-system/theme-get";
import { Box, Icon, Small } from "orcs-design-system";
import { useObjectiveState } from "../contexts/ObjectiveProviderContext";
import { isDirectHierarchicalPath } from "../contexts/utils";
import { EDGE_EVENTS, useEdgeEvents } from "../contexts/EdgeEventProvider";
import { NODE_THEMES, NODE_TYPES } from "../consts";

const CustomPopover = styled(Box)`
  position: absolute;

  z-index: 10;
  pointer-events: all;
  width: fit-content;
  bottom: 16px;
  pointer-events: none;

  & .icon-box {
    background: white;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    border: 1px solid ${themeGet("colors.greyLighter")};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    border-left-color: transparent;
    border-top-color: transparent;
    position: absolute;
    left: calc(50% - 10px);
    bottom: -10px;

    & svg {
      transform: rotate(-45deg);
    }
  }
`;

const PositionedBox = styled(Box)`
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  left: ${(props) => props.position[0]}px;
  top: ${(props) => props.position[1]}px;
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  width: fit-content;
`;

const CustomEdge = styled(BaseEdge)`
  pointer-events: all;
`;

const useEdgeEvent = (event, id, handler) => {
  useEffect(() => {
    const listener = (e) => {
      const { edge: targetEdge } = e.detail;

      if (id && targetEdge.id === id) {
        handler(e);
      }
    };

    document.addEventListener(event, listener);

    return () => document.removeEventListener(event, listener);
  }, [event, handler, id]);
};

const ObjectiveEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  style: baseStyle,
  source,
  target,
}) => {
  const { pinnedObjectives, hoveredObjective, onPinObjective } =
    useObjectiveState();

  const nodes = useNodes();
  const theme = useTheme();
  const edgeEvents = useEdgeEvents();
  const [clientCoords, setClientCoords] = useState([targetX, targetY]);

  const hoveredEdgeNodeToHighlight = useMemo(
    () =>
      nodes.find((node) => {
        const targetNode = node.id === edgeEvents?.hoveredEdge?.target;

        if (targetNode && node?.data?.nodeType !== NODE_THEMES.TEAM) {
          return true;
        }

        return node.id === edgeEvents?.hoveredEdge?.source;
      }),
    [nodes, edgeEvents.hoveredEdge]
  );

  const isHovered = edgeEvents.hoveredEdge?.id === id;
  const edge = useMemo(
    () => ({ data, id, source, target }),
    [data, id, source, target]
  );

  const directChildrenCount = useMemo(() => {
    const edgeParent = nodes.find((node) => node.id === source);
    const edgeChild = nodes.find((node) => node.id === target);
    let collapsedCount = 0;

    if (edgeChild?.type === NODE_TYPES.SHOW_MORE_OBJECTIVES_NODE) {
      edgeChild?.data?.collapsedNodes?.forEach((node) => {
        if (node.data.hierarchyParentIds.includes(edgeParent.data.id)) {
          collapsedCount++;
        }
      });
    }

    return collapsedCount;
  }, [nodes, source, target]);

  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [style, zIndex] = useMemo(() => {
    const activeEdgeStyle = {
      ...(baseStyle || {}),
      strokeWidth: 5,
      strokeDasharray: 5,
    };

    if (
      pinnedObjectives.some((objective) =>
        isDirectHierarchicalPath({ nodes, objective, edge })
      )
    ) {
      return [
        {
          ...activeEdgeStyle,
          stroke: themeGet("colors.primaryLight")({ theme }),
        },
        2,
      ];
    }

    if (
      isDirectHierarchicalPath({ nodes, objective: hoveredObjective, edge }) ||
      isDirectHierarchicalPath({
        nodes,
        objective: hoveredEdgeNodeToHighlight,
        edge,
      })
    ) {
      return [
        {
          ...activeEdgeStyle,
          stroke: themeGet("colors.successLighter")({ theme }),
        },
        2,
      ];
    }

    return [{ ...(edge.style || {}), strokeDasharray: 5 }, 1];
  }, [
    pinnedObjectives,
    edge,
    hoveredObjective,
    nodes,
    baseStyle,
    hoveredEdgeNodeToHighlight,
    theme,
  ]);

  const onClick = useCallback(
    () => onPinObjective(hoveredEdgeNodeToHighlight),
    [onPinObjective, hoveredEdgeNodeToHighlight]
  );

  const getMouseX = useCallback((e) => {
    const labelRenderer = document.querySelector(
      ".react-flow__edgelabel-renderer"
    );
    if (labelRenderer) {
      setClientCoords([e.detail.e.clientX, e.detail.e.clientY]);
    }
  }, []);

  const onMouseEnter = useCallback((e) => getMouseX(e), [getMouseX]);
  const onMouseLeave = useCallback((e) => getMouseX(e), [getMouseX]);
  const onMouseMove = useCallback((e) => getMouseX(e), [getMouseX]);

  useEdgeEvent(EDGE_EVENTS.CLICK, id, onClick);
  useEdgeEvent(EDGE_EVENTS.MOUSE_ENTER, id, onMouseEnter);
  useEdgeEvent(EDGE_EVENTS.MOUSE_LEAVE, id, onMouseLeave);
  useEdgeEvent(EDGE_EVENTS.MOUSE_MOVE, id, onMouseMove);

  return (
    <>
      <CustomEdge
        id={id}
        path={edgePath}
        style={style}
        zIndex={zIndex}
        interactionWidth={50}
        className="active"
      />

      {directChildrenCount &&
        createPortal(
          <PositionedBox
            visible={isHovered}
            position={clientCoords}
            onClick={onClick}
            key={id}
          >
            <CustomPopover
              width="fit-content"
              bg="white"
              p="s"
              shadow="default"
              borderRadius="2"
              boxBorder="default"
              minWidth="250px"
            >
              <Small textAlign="center" fontSize="0" display="block" mb="s">
                Linked to {directChildrenCount}{" "}
                {directChildrenCount === 1 ? "item" : "items"} in the collapsed
                list. <br />
                Click to show.
              </Small>

              <Box className="icon-box">
                <Icon icon={["fas", "question"]} size="xs" />
              </Box>
            </CustomPopover>
          </PositionedBox>,
          document.body
        )}
    </>
  );
};

ObjectiveEdge.propTypes = {
  id: PropTypes.string.isRequired,
  sourceX: PropTypes.number.isRequired,
  sourceY: PropTypes.number.isRequired,
  targetX: PropTypes.number.isRequired,
  targetY: PropTypes.number.isRequired,
  sourcePosition: PropTypes.string.isRequired,
  targetPosition: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  style: PropTypes.object,
  source: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
};

export default ObjectiveEdge;
