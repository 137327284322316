import React from "react";
import { Button, Popover, Icon } from "orcs-design-system";

const DragIcon = () => (
  <Popover
    width="172px"
    textAlign="center"
    direction="bottomLeft"
    text="Press and hold to drag card"
  >
    <Button
      variant="ghost"
      iconOnly
      height="25px"
      width="25px"
      small
      className="draggable"
      ariaLabel="Press and hold to drag card"
    >
      <Icon icon={["far", "arrows-alt"]} />
    </Button>
  </Popover>
);

export default DragIcon;
