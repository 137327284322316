/* eslint-disable no-param-reassign */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { isEmpty, map, reduce } from "lodash";
import { H4, Box, Expandable } from "orcs-design-system";

const WARNING_CATEGORIES = [
  "Invalid FTE:",
  "FTE Warning:",
  "New person:",
  "Person field updated:",
  "Person missing:",
  "New group added:",
  "Parent group changed:",
  "New parent group:",
  "Parent group changed:",
  "Create allocation command:",
  "Division assignment:",
  "Existing Source Group:",
  "Individual allocation:",
  "Event processed:",
  "New Tag:",
  "New tag type:",
  "Add tag:",
  "Remove tag:",
];

const CategoryWarnings = ({ warnings }) => {
  return (
    <ul>
      {map(warnings, (w, index) => (
        <li key={index}>{w}</li>
      ))}
    </ul>
  );
};

CategoryWarnings.propTypes = {
  warnings: PropTypes.arrayOf(PropTypes.string),
};

const ImportWarnings = ({
  warnings,
  headingText = "Warnings",
  headingColor = "warning",
}) => {
  const [filteredWarnings, categories] = useMemo(() => {
    const categorised = reduce(
      WARNING_CATEGORIES,
      (prev, c) => {
        prev[c] = [];
        return prev;
      },
      {}
    );

    const filtered = reduce(
      warnings,
      (prev, warningStr) => {
        for (let i = 0, j = WARNING_CATEGORIES.length; i < j; i += 1) {
          const category = WARNING_CATEGORIES[i];
          if (warningStr.startsWith(category)) {
            categorised[category].push(warningStr);
            return prev;
          }
        }

        prev.push(warningStr);
        return prev;
      },
      []
    );

    return [filtered, categorised];
  }, [warnings]);

  return (
    <Box>
      <H4 mb="r" color={headingColor}>
        {headingText}
      </H4>
      <ul>
        {map(filteredWarnings, (w, index) => {
          return <li key={index}>{w}</li>;
        })}
      </ul>
      {map(categories, (list, key) => {
        if (isEmpty(list)) {
          return null;
        }
        return (
          <Expandable title={key} key={key}>
            <CategoryWarnings warnings={list} />
          </Expandable>
        );
      })}
    </Box>
  );
};

ImportWarnings.propTypes = {
  warnings: PropTypes.arrayOf(PropTypes.string),
  headingText: PropTypes.string,
  headingColor: PropTypes.string,
};

export default React.memo(ImportWarnings);
