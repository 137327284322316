import { map } from "lodash";
import { Box, Grid, H4, Spacer } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import * as placeholder from "src/components/Placeholder/PlaceholderStyles";
import TeamBlock from "src/components/Team/TeamBlock";
import GroupPropType from "src/custom-prop-types/group";

const ExplorePanel = ({ loading, groups, title, workspace }) => {
  return (
    <Box mt="xl">
      <Spacer mt="s">
        {loading ? (
          <placeholder.Line
            data-testid="cp-explore-placeholder-title"
            width={120}
            height={24}
          />
        ) : (
          <H4>{title}</H4>
        )}
        <Grid
          gridTemplateColumns={[
            "repeat(auto-fit, minmax(300px, 1fr))",
            "repeat(auto-fit, minmax(300px, 1fr))",
            "repeat(auto-fit, minmax(400px, 1fr))",
          ]}
          gridGap="r"
          gridAutoFlow="row"
        >
          {map(groups, (group, index) => (
            <TeamBlock
              key={`cp-teamblock-${index}`}
              group={group}
              loading={loading}
              workspace={workspace}
            />
          ))}
        </Grid>
      </Spacer>
    </Box>
  );
};

ExplorePanel.propTypes = {
  groups: PropTypes.oneOfType([
    PropTypes.arrayOf(GroupPropType),
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  loading: PropTypes.bool,
  title: PropTypes.string,
  workspace: PropTypes.object,
};

export default ExplorePanel;
