import React from "react";
import { Box, Notification, P } from "orcs-design-system";

const TooManyNodesNotification = () => (
  <Box p="l">
    <Notification
      top="20px"
      centered
      colour="warning"
      icon={["far", "exclamation-triangle"]}
    >
      <P color="white"> There are too many nodes to display.</P>
      <P color="white">
        Viewing all of them will slow down your computer and may crash your
        browser.
      </P>
    </Notification>
  </Box>
);

export default TooManyNodesNotification;
