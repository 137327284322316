/* eslint-disable react/prop-types */
import React from "react";
import { Box, P, Small } from "orcs-design-system";

const formatFieldNameLabel = ({ label, field }) => {
  return (
    <Box>
      <P>{label}</P>
      {!!field.description && (
        <Small color="greyDark">{field.description}</Small>
      )}
    </Box>
  );
};

export default formatFieldNameLabel;
