import { filter, flatMap, includes, isEmpty, map, sortBy, union } from "lodash";
import { Flex } from "orcs-design-system";
import React, { useMemo } from "react";
import {
  useGroupTypes,
  useWorkspace,
} from "src/contexts/global/WorkspaceContext";
import { useOrgChartContext } from "src/components/OrgChart/context/OrgChartContext";
import GroupTypeBadges from "./GroupTypeBadges";

const DEFAULT_DEMAND_LABEL = "Demand team";
const DEFAULT_SUPPLY_LABEL = "Supply team";

const GROUP_TYPE = {
  DEMAND: "isDemand",
  SUPPLY: "isSupply",
};

const DEFAULT_DEMAND_COLOR = "success";
const DEFAULT_SUPPLY_COLOR = "secondary";

const getBadges = ({ teams, defaultColor }) => {
  return map(sortBy(teams, "name"), (team) => {
    return {
      label: team.name,
      variant: team.styleVariant || defaultColor,
    };
  });
};

const getTeamBadges = (groupTypes, teamTypes, type, defaultColor) =>
  getBadges({
    teams: filter(
      groupTypes,
      (groupType) => includes(teamTypes, groupType.id) && groupType[type]
    ),
    defaultColor,
  });

const GroupTypeBadgesWrapper = () => {
  const { state } = useOrgChartContext() || {};

  const expandedTeams = map(
    state?.expandedNodes,
    (nodeId) => state?.teams[nodeId]
  );

  const teamTypes = union(
    map(expandedTeams, "type"),
    flatMap(expandedTeams, (team) => map(team?.childTeams, "type"))
  );

  const groupTypes = useGroupTypes();
  const workspace = useWorkspace();

  const demandTeamLabel =
    workspace?.config?.allocation?.demandLabel || DEFAULT_DEMAND_LABEL;
  const supplyTeamLabel =
    workspace?.config?.allocation?.supplyLabel || DEFAULT_SUPPLY_LABEL;

  const demandTeams = useMemo(
    () =>
      getTeamBadges(
        groupTypes,
        teamTypes,
        GROUP_TYPE.DEMAND,
        DEFAULT_DEMAND_COLOR
      ),
    [groupTypes, teamTypes]
  );

  const supplyTeams = useMemo(
    () =>
      getTeamBadges(
        groupTypes,
        teamTypes,
        GROUP_TYPE.SUPPLY,
        DEFAULT_SUPPLY_COLOR
      ),
    [groupTypes, teamTypes]
  );

  return (
    <Flex flexDirection="column">
      {!isEmpty(demandTeams) && (
        <GroupTypeBadges label={demandTeamLabel} teams={demandTeams} mb="s" />
      )}
      {!isEmpty(supplyTeams) && (
        <GroupTypeBadges label={supplyTeamLabel} teams={supplyTeams} />
      )}
    </Flex>
  );
};

export default React.memo(GroupTypeBadgesWrapper);
