import { Button, Flex, H2, Icon, Spacer } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import * as placeholder from "src/components/Placeholder/PlaceholderStyles";
import icon from "src/config/icons";

const Header = ({
  isDisplayNavigateUp,
  loading,
  onNavigateUp,
  heading,
  navigateUpLabel,
}) => {
  return loading ? (
    <Spacer mb="r">
      <placeholder.Line
        data-testid="cp-explore-placeholder-heading"
        width={160}
        height={32}
      />
    </Spacer>
  ) : (
    <Spacer mb="r">
      <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <Spacer mt="xs">
          <H2 weight="light">{heading}</H2>
          {isDisplayNavigateUp && (
            <Button
              data-testid="cp-explore-navigate-up-btn"
              onClick={onNavigateUp}
              small
              iconLeft
            >
              <Icon icon={icon.arrowUp} />
              {navigateUpLabel}
            </Button>
          )}
        </Spacer>
      </Flex>
    </Spacer>
  );
};

Header.propTypes = {
  isDisplayNavigateUp: PropTypes.bool,
  onNavigateUp: PropTypes.func,
  loading: PropTypes.bool,
  heading: PropTypes.string,
  navigateUpLabel: PropTypes.string,
};

export default Header;
