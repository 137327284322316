import { themeGet } from "@styled-system/theme-get";
import { COLLECTION_NODE_DISPLAY_MODE } from "src/components/NodeVisualizer/Nodes/CollectionNode/index";
import {
  isRealTimeAllocationsEnabled,
  isAddingMemberEnabled,
} from "src/util/allocations";
import { getGroupBadgeVariant, isGroupActive } from "src/util/group";
import { NODE_TYPES } from "src/components/NodeVisualizer/consts";
import { getTargetGroupAndFilter } from "../../../utils/getTargetGroupAndFilter";
import {
  getCreatableNodeTypes,
  createCollectionNode,
  createNode,
} from "./shared";
import getMembersCollectionLabel from "./getMembersCollectionLabel";

export const getMembersCollectionId = (group) => {
  const { id: groupId = "", name: groupName = "" } = group || {};

  return `${groupId}_${groupName.split(" ").join("_")}_members_collection`;
};

export const createMembersCollectionNode = ({
  group,
  members = [],
  isGroupedBySupplyOrDemand,
  contextualData = {},
  state = {},
}) => {
  const { groupTypes, theme, user, workspace, allocationProject } =
    contextualData;
  const groupType = groupTypes[group.type];
  const getColor = (color) => themeGet(`colors.${color}`)({ theme });
  const isUnallocatedCollection = group.id !== "unallocated";

  const variant = groupType ? getGroupBadgeVariant(group, groupTypes) : "grey";

  const borderColor = getColor(`${variant}40`);
  const labelColor = getColor(variant);

  const { filter, targetGroup, filterGroup } = getTargetGroupAndFilter({
    state,
    collectionGroup: group,
    groupTypes,
  });

  const allocationsEnabled =
    isRealTimeAllocationsEnabled({
      user,
      workspace,
      allocationProject,
    }) && isAddingMemberEnabled(workspace, targetGroup);

  const canAllocateMembers = allocationsEnabled && isGroupActive(targetGroup);

  const id = getMembersCollectionId(group);

  const creatableNodeTypes = getCreatableNodeTypes({
    groupType,
    isGroupedBySupplyOrDemand,
    group,
    canAllocateMembers,
  });

  const items = members.map((member) =>
    createNode(member.aggregateId, member, NODE_TYPES.PERSON_NODE)
  );

  const itemCount = group?.directMemberCount || items.length;

  return createCollectionNode({
    id,
    items,
    label: getMembersCollectionLabel({
      group,
      groupType,
      itemCount,
      variant,
      isGroupedBySupplyOrDemand,
    }),
    data: {
      id,
      groupId: group.id,
      itemCount,
      borderStyle:
        isGroupedBySupplyOrDemand && isUnallocatedCollection
          ? `2px solid ${borderColor}`
          : `2px solid ${getColor("greyLight")}`,
      labelColor: isGroupedBySupplyOrDemand ? labelColor : getColor("grey"),
      displayMode: isGroupedBySupplyOrDemand
        ? COLLECTION_NODE_DISPLAY_MODE.SINGLE_COLUMN
        : null,
      variant,
      creatableNodeTypes,
      contextForCreation:
        creatableNodeTypes.filter(({ canCreate }) => canCreate).length === 0
          ? null
          : { team: group, filter, targetGroup, filterGroup },
      loadingType: NODE_TYPES.PERSON_NODE,
    },
  });
};
