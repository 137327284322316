import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Icon } from "orcs-design-system";

const StyledIcon = styled(Icon)`
  cursor: pointer;
  transition: all 200ms ease-in-out;
`;

const NodeDetailsIcon = ({
  enabled = true,
  isSelected = false,
  isHovered = false,
}) => {
  if (!enabled) {
    return null;
  }

  const iconColor = isSelected || isHovered ? "primary" : "grey";
  const iconFlavor = isSelected ? "fa-solid" : "fa-regular";

  return (
    <StyledIcon
      size="lg"
      color={iconColor}
      icon={[iconFlavor, "info-circle"]}
    />
  );
};

NodeDetailsIcon.propTypes = {
  enabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  isHovered: PropTypes.bool,
};

export default NodeDetailsIcon;
