/* eslint-disable no-else-return */
import { chain, get, isEmpty } from "lodash";
import { Box, Flex, Icon, Small, Badge, Divider } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router";
import FteMemberBadge from "src/components/FteBadge";
import ReportTeamChange from "src/components/Person/ReportTeamChange";
import { useAuth0 } from "src/contexts/auth0Provider";
import { useFeatureFlags } from "src/contexts/global/FeatureFlagsContext";
import { useAllocationProject } from "src/contexts/global/GlobalContext";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { useModal } from "src/contexts/withModal";
import PersonPropType from "src/custom-prop-types/person";
import { getAllBadgeGroups } from "src/util/group";
import PersonAllocationsModal from "src/components/Person/AllocationsModal";
import { ENTITY_TYPES } from "src/consts/comments";
import TagDisplay from "src/components/TagDisplay";
import { ACTION_TYPES } from "src/components/OrgChart/context/actionTypes";
import NodeDetailsIcon from "src/components/NodeVisualizer/components/NodeDetailsIcon";
import { EventBus } from "src/services/eventEmitter";
import { PANEL_ID } from "src/components/OrgChart/components/OrgChartPanel/const";
import PersonAvatarAndDetails from "../../../Person/PersonItem/sub-components/PersonAvatarAndDetails";
import { isRealTimeAllocationsEnabled } from "../../../../util/allocations";
import { isSuggestChangeEnabled } from "../../../../util/featureFlags";
import GroupBadge from "../../../Badge/GroupBadge";
import { useOrgChartContext } from "../../../OrgChart/context/OrgChartContext";
import {
  SourceHandle,
  StyledCard,
  StyledSimpleButton,
  TargetHandle,
  DIFF_STATE_THEMES,
  changes,
} from "../node.styled";
import { useNodeProperties } from "../../hooks/useNodeProperties";
import { usePersonNode } from "./PersonNodeProvider";
import { getAllocatedFteForOrgChart } from "./getAllocatedFteForOrgChart";
import { getNameWithFte } from "./getNameWithFte";
import PersonNodeSkeleton from "./PersonNodeSkeleton";

export const PERSON_CARD_THEMES = {
  default: {
    color: "greyLight",
    light: "greyLight",
    alt: "greyLight",
  },
  currentPerson: {
    color: "primaryLight",
    light: "primaryLight",
    alt: "primaryLight",
  },
  diffStates: DIFF_STATE_THEMES,
};

const getTheme = (isCurrentPerson, diffState) => {
  if (isCurrentPerson) {
    return PERSON_CARD_THEMES.currentPerson;
  }

  const diffStateTheme = PERSON_CARD_THEMES?.diffStates?.[diffState];

  if (diffStateTheme) {
    return diffStateTheme;
  }

  return PERSON_CARD_THEMES.default;
};

const PersonNode = ({
  data,
  overrideHidden = false,
  className,
  collectionGroupId,
  pinnable = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const person = data;
  const { jobTitle } = data;
  const workspace = useWorkspace();
  const tagConfig = get(workspace, "config.tagConfig", {});
  const groupTypes = get(workspace, "config.groupTypes");
  const { onExpandManagingClick } = usePersonNode();
  const {
    refetch: refetchOrgChartData,
    state,
    dispatch: orgChartDispatch,
  } = useOrgChartContext() || {};

  const { currentViewTeam, viewOptions = {} } = state || {};
  const { groupMembersBySupplyOrDemand } = viewOptions;

  const groupBadgeIdsToHide = useMemo(() => {
    if (!currentViewTeam) {
      return [];
    }
    const ids = [currentViewTeam.id];

    if (groupMembersBySupplyOrDemand) {
      ids.push(collectionGroupId);
    }

    return ids;
  }, [currentViewTeam, collectionGroupId, groupMembersBySupplyOrDemand]);

  const location = useLocation();
  const allocationProject = useAllocationProject();
  const { user } = useAuth0();
  const featureFlags = useFeatureFlags();
  const [, dispatch] = useModal();

  const [isCurrentPerson, isCurrentPersonManager] = useMemo(() => {
    return [
      location.pathname.includes(data.aggregateId),
      data.managing?.some((managee) =>
        location.pathname.includes(managee.aggregateId)
      ) ?? false,
    ];
  }, [data.aggregateId, data.managing, location.pathname]);

  const badgeGroups = useMemo(() => {
    return getAllBadgeGroups({ person, groupTypes });
  }, [person, groupTypes]);

  const onDisplayReportTeamChange = useCallback(() => {
    const onToggleVisibility = (isModalVisible) => () => {
      dispatch({ type: "setModalVisible", isModalVisible });
    };

    const modalComponent = (
      <ReportTeamChange
        person={person}
        groupTypes={groupTypes}
        isVisible={true}
        onToggleVisibility={onToggleVisibility}
        workspace={workspace}
        featureFlags={featureFlags}
      />
    );

    dispatch({ type: "setModalComponent", modalComponent });
  }, [dispatch, featureFlags, groupTypes, person, workspace]);

  const onEditingAllocations = useCallback(() => {
    const onHideModal = () => {
      dispatch({ type: "setModalVisible", isModalVisible: false });
      refetchOrgChartData?.();
    };

    // We need a person object that can be changed in the modal
    // When user change anything of the person, the modal is not re-rendered,
    // so we have to update the person and re-fresh the modal
    // TODO: Find a good way to do this...
    const personForUpdate = { ...person };
    const modalComponent = (
      <PersonAllocationsModal
        isVisible={true}
        person={personForUpdate}
        workspace={workspace}
        groupTypes={groupTypes}
        allocationProject={allocationProject}
        onHideModal={onHideModal}
        // currentGroup={group}
        forceUpdatePerson
      />
    );
    dispatch({ type: "setModalComponent", modalComponent });
  }, [
    allocationProject,
    dispatch,
    groupTypes,
    person,
    workspace,
    refetchOrgChartData,
  ]);

  const ManagingButton = useMemo(() => {
    return data.managing?.length > 1 ? (
      <StyledSimpleButton
        onClick={() => onExpandManagingClick?.(data, isCurrentPersonManager)}
        disabled={isCurrentPerson}
      >
        {data.managing?.length}
        <Icon ml="s" icon="users" color="greyDarker" />
        {!isCurrentPerson && (
          <Icon
            ml="s"
            icon={
              isCurrentPersonManager
                ? data.expanded
                  ? "chevron-up"
                  : "chevron-down"
                : "chevron-right"
            }
            color="greyDarker"
          />
        )}
      </StyledSimpleButton>
    ) : null;
  }, [data, isCurrentPerson, isCurrentPersonManager, onExpandManagingClick]);

  const tagsToBeDisplayed = useNodeProperties({
    entityType: ENTITY_TYPES.PERSON,
    tags: person.tags,
  });

  const groupBadges = useMemo(() => {
    if (isEmpty(badgeGroups)) {
      return null;
    }

    return chain(badgeGroups)
      .map((badgeGroup) => {
        const result = getAllocatedFteForOrgChart({
          group: badgeGroup,
          person,
          groupTypes,
        });

        return {
          ...result,
          badgeGroup,
        };
      })
      .filter(({ badgeGroup, isSupply }) => {
        // only hide group badges that are repeated on the card if
        // we are on a supply team.
        //
        // demand teams - we always show the badges, since people can
        // be part of multiple demand teams, and so it gets confusing
        // if you do not show all the badges.
        if (isSupply) {
          return !groupBadgeIdsToHide.includes(badgeGroup.id);
        } else {
          return true;
        }
      })
      .orderBy("fte", "desc")
      .map(({ badgeGroup, fte }) => {
        const badgeGroupWithFteInName = {
          ...badgeGroup,
          name: getNameWithFte(badgeGroup.name, fte),
        };

        return (
          <GroupBadge
            aria-label="Team and Hierarchy"
            group={badgeGroupWithFteInName}
            groupTypes={groupTypes}
            key={badgeGroup.id}
          />
        );
      })
      .value();
  }, [badgeGroups, groupTypes, person, groupBadgeIdsToHide]);

  const $hidden = !overrideHidden && (data.hidden || !data.isLayouted);
  const canChangeAllocations = isRealTimeAllocationsEnabled({
    user,
    workspace,
    allocationProject,
  });
  const canReportTeamChange = isSuggestChangeEnabled(workspace);

  const handlePersonClick = useCallback(() => {
    // Orcs FloatingPanels listens to this event
    EventBus.emit("FloatingPanels_SetSelectedPanel", PANEL_ID.details);

    const isSelected =
      state?.expandedPanel?.data?.selectedEntity === data.aggregateId;
    // not selected?
    if (orgChartDispatch && !isSelected) {
      // select
      orgChartDispatch({
        type: ACTION_TYPES.UPDATE_EXPANDED_PANEL,
        payload: {
          data: {
            selectedEntity: data.aggregateId,
          },
        },
      });
    }
  }, [
    data.aggregateId,
    orgChartDispatch,
    state?.expandedPanel?.data?.selectedEntity,
  ]);

  if (data.isLoading) {
    return <PersonNodeSkeleton />;
  }

  return (
    <StyledCard
      onClick={handlePersonClick}
      className={className}
      $hidden={$hidden}
      width="auto"
      minWidth="350px"
      maxWidth="350px"
      cursor="grab"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      $theme={getTheme(isCurrentPerson, data?.diff?.[collectionGroupId]?._type)}
    >
      <TargetHandle $hidden type="target" position="top" />
      <>
        {data?.diff?.[collectionGroupId]?._type && (
          <Badge variant="warning" mb="s">
            {changes[data?.diff?.[collectionGroupId]?._type]}
          </Badge>
        )}
        <Flex justifyContent="space-between">
          <PersonAvatarAndDetails
            debug
            tagConfig={tagConfig}
            showLocalTime
            person={data}
            badgeGroups={badgeGroups}
            groupTypes={groupTypes}
            isDisplayActionsButton
            canChangeAllocations={canChangeAllocations}
            canReportTeamChange={canReportTeamChange}
            onDisplayReportTeamChange={onDisplayReportTeamChange}
            onEditingAllocations={onEditingAllocations}
            workspace={workspace}
            user={user}
            allocationProject={allocationProject}
            subtitleContent={
              <Small color="greyDark" mr="xs" mt="xs">
                {jobTitle}
              </Small>
            }
          />
        </Flex>
      </>
      <Divider light mb="s" mt="s" />
      <Flex
        justifyContent="space-between"
        alignItems="flex-end"
        flexWrap="nowrap"
      >
        <Flex flexWrap="wrap" alignItems="center" role="list" gap="xs" mr="s">
          <FteMemberBadge showToolTip={true} fte={person.fte} />
          {groupBadges}
        </Flex>
        {isEmpty(tagsToBeDisplayed) && ManagingButton}
      </Flex>
      <Divider light mb="s" mt="s" />

      <Flex
        justifyContent="space-between"
        alignItems="flex-end"
        flexWrap="nowrap"
      >
        {isEmpty(tagsToBeDisplayed) && ManagingButton}
      </Flex>

      <Divider light mb="s" mt="s" />

      <Flex
        justifyContent="space-between"
        flexWrap="nowrap"
        alignItems="flex-end"
      >
        <TagDisplay tags={tagsToBeDisplayed} entityType={ENTITY_TYPES.PERSON} />
        {!isEmpty(tagsToBeDisplayed) && ManagingButton}
      </Flex>
      <Flex
        justifyContent="space-between"
        flexWrap="nowrap"
        alignItems="flex-end"
      >
        <Box>{/* person-relations button/icon goes here */}</Box>
        <NodeDetailsIcon
          enabled={pinnable}
          isSelected={
            state?.expandedPanel?.data?.selectedEntity === data.aggregateId
          }
          isHovered={isHovered}
        />
      </Flex>

      <SourceHandle $hidden type="source" position="bottom" />
    </StyledCard>
  );
};

PersonNode.propTypes = {
  data: PersonPropType,
  overrideHidden: PropTypes.bool,
  className: PropTypes.string,
  collectionGroupId: PropTypes.string,
  pinnable: PropTypes.bool,
};

export default PersonNode;
