import React, { useState } from "react";
import {
  Button,
  TextInput,
  Flex,
  Popover,
  Spacer,
  Icon,
} from "orcs-design-system";
import PropTypes from "prop-types";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "../../services/segment";

const FeedbackEmoji = ({ contextId }) => {
  const [feedback, setFeedback] = useState(null);
  const [additionalFeedback, setAdditionalFeedback] = useState("");
  const [isAdditionalFeedbackSubmitted, setIsAdditionalFeedbackSubmitted] =
    useState(false);

  const handleFeedback = (isPositive) => {
    if (feedback !== null) {
      return;
    } // Prevent multiple submissions

    const feedbackType = isPositive ? "positive" : "negative";
    setFeedback(feedbackType);

    // Trigger event tracking
    trackEvent(EVENT_TRACKING.USER_FEEDBACK, {
      context: contextId,
      feedback: feedbackType,
    });
  };

  const handleAdditionalFeedback = () => {
    if (additionalFeedback.trim() !== "") {
      // Trigger event tracking with additional feedback
      trackEvent(EVENT_TRACKING.USER_FEEDBACK, {
        context: contextId,
        feedback,
        additional_feedback: additionalFeedback,
      });
      setAdditionalFeedback(""); // Clear the input after submission
      setIsAdditionalFeedbackSubmitted(true); // Set flag to hide input and button
    }
  };

  return (
    <div className="feedback-emoji">
      <Flex
        alignItems="center"
        justifyContent="center"
        marginTop="10px"
        marginBottom="10px"
      >
        {feedback === null ? (
          <Popover
            text="Is this useful for you?"
            direction="top"
            textAlign="center"
          >
            <span
              onClick={() => handleFeedback(true)}
              onKeyPress={(e) => e.key === "Enter" && handleFeedback(true)}
              role="button"
              tabIndex={0}
              aria-label="Thumbs up"
              style={{
                cursor: "pointer",
                marginRight: "10px",
              }}
            >
              👍
            </span>
            <span
              onClick={() => handleFeedback(false)}
              onKeyPress={(e) => e.key === "Enter" && handleFeedback(false)}
              role="button"
              tabIndex={0}
              aria-label="Thumbs down"
              style={{ cursor: "pointer" }}
            >
              👎
            </span>
          </Popover>
        ) : (
          <>
            {!isAdditionalFeedbackSubmitted && (
              <Spacer mr="s">
                <TextInput
                  type="text"
                  placeholder="Care to explain why?"
                  value={additionalFeedback}
                  onChange={(e) => setAdditionalFeedback(e.target.value)}
                />
                <Button
                  iconOnly
                  variant="success"
                  onClick={handleAdditionalFeedback}
                  width="33px"
                  height="32px"
                  ariaLabel="Send feedback"
                  disabled={!additionalFeedback}
                >
                  <Icon icon={["fas", "check"]} />
                </Button>
                <Button
                  iconOnly
                  variant="ghost"
                  onClick={() => setIsAdditionalFeedbackSubmitted(true)}
                  width="33px"
                  height="32px"
                  ariaLabel="Decline providing feedback"
                >
                  <Icon icon={["fas", "times"]} />
                </Button>
              </Spacer>
            )}
            {isAdditionalFeedbackSubmitted && <p>Thanks! ❤️</p>}
          </>
        )}
      </Flex>
    </div>
  );
};

FeedbackEmoji.propTypes = {
  contextId: PropTypes.string.isRequired,
};

export default FeedbackEmoji;
