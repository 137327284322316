import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { H3, Box, Spacer } from "orcs-design-system";
import { useAllocationConfig } from "src/contexts/global/WorkspaceContext/WorkspaceContext";

import {
  TYPE_OBJECTIVES,
  TYPE_PLANNING,
  TYPE_FORECAST,
  TYPE_SUPPLY_UPDATE,
  TYPE_TAGGING,
  TYPE_DOWNLOAD,
  TYPE_GROUP_UPDATE,
  TYPE_MEMBERSHIPS,
} from "./consts";

import HistoryTypeCheckBox from "./HistoryTypeCheckBox";

const HistoryFilters = ({ filters, onFilterChange }) => {
  const { demandLabel, supplyLabel, forecastTabName, plannerTabName } =
    useAllocationConfig() || {};

  const allocationLabel = `${
    demandLabel ? `${demandLabel} allocations` : "Allocations"
  } proposed & changed`;

  const allocationTypeKey = TYPE_MEMBERSHIPS;

  const types = {
    [allocationTypeKey]: allocationLabel,
    [TYPE_OBJECTIVES]: "Objectives proposed & changed",
    [TYPE_PLANNING]: `${plannerTabName || "Planner"} interactions`,
    [TYPE_FORECAST]: `${forecastTabName || "Forecast"} interactions`,
    [TYPE_SUPPLY_UPDATE]: `${supplyLabel || "Supply group"} changes`,
    [TYPE_TAGGING]: "Tags",
    [TYPE_DOWNLOAD]: "Downloads",
    [TYPE_GROUP_UPDATE]: "Group changes",
  };

  return (
    <Box bg="white" borderRadius={2} boxBorder="default" p="r">
      <Spacer mb="r">
        <H3>Filter by</H3>
        {map(types, (label, type) => {
          return (
            <HistoryTypeCheckBox
              key={type}
              label={label}
              type={type}
              checked={filters[type]}
              onFilterChange={onFilterChange}
            />
          );
        })}
      </Spacer>
    </Box>
  );
};

HistoryFilters.propTypes = {
  filters: PropTypes.object,
  onFilterChange: PropTypes.func,
};

export default HistoryFilters;
