import React, { useState } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Button, Spacer, Box, TextInput, TextArea } from "orcs-design-system";
import integrationTypes from "src/integrationTypes";

const DatasourceForm = ({
  onSubmit,
  isLoading,
  integrationType,
  preferredUsername,
}) => {
  const { heading, defaultFieldsMap, merge } = get(
    integrationTypes,
    integrationType
  );

  const [formValues, setFormValues] = useState({
    name: `${heading} Imports`,
    description: `Created by ${preferredUsername} on ${new Date().toLocaleString(
      "en-AU"
    )}.`,
  });

  const onFieldChange = (name) => (e) => {
    setFormValues({
      ...formValues,
      [name]: e.target.value,
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    onSubmit({ ...formValues, fieldsMap: defaultFieldsMap, merge });
  };

  return (
    <Box maxWidth="500px" width="100%">
      <Spacer mb="r">
        <TextInput
          name="name"
          type="text"
          id="datasourceName"
          label="Name"
          mandatory
          fullWidth
          defaultValue={formValues.name}
          onChange={onFieldChange("name")}
          disabled={isLoading}
        />
        <TextArea
          fullWidth
          id="datasourceDescription"
          name="description"
          label="Description"
          defaultValue={formValues.description}
          onChange={onFieldChange("description")}
          rows="3"
          disabled={isLoading}
        />
        <Button
          onClick={onFormSubmit}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Create datasource
        </Button>
      </Spacer>
    </Box>
  );
};

DatasourceForm.propTypes = {
  integrationType: PropTypes.string,
  preferredUsername: PropTypes.string,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default DatasourceForm;
