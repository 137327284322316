import { isEmpty, reduce, cloneDeep } from "lodash";
import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Flex, Icon, H4, Button } from "orcs-design-system";

import ErrorNotification from "src/components/ErrorNotification";
import {
  hasAllHeaderFields,
  getTargetFields,
  updateFieldMapping,
  fillReservedFields,
  validateFieldsMap,
  ReservedMultipleFields,
} from "./FieldsMappingEditor.util";
import FieldsMapFlagEditor from "./FieldsMapFlagEditor";
import MappingTable from "./MappingTable";

const FieldsMappingEditor = ({
  datasource,
  headerFields,
  defaultImportFields,
  updateDatasource,
  savingDatasourceError,
  setIsNewMapping,
  importFlags,
}) => {
  const [fieldsMap, updateFieldsMap] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isClearing, setIsClearing] = useState(false);
  const [error, setError] = useState(null);
  const [canUseDefaultMapping, setCanUseDefaultMapping] = useState(false);

  const { fieldsDefinition, fieldsMap: defaultFieldsMap } =
    defaultImportFields || {};

  useEffect(() => {
    if (!headerFields) {
      return;
    }
    setError(null);

    // If there is savedMap, then try to use it
    const savedMap = datasource?.fieldsMap;
    if (savedMap) {
      updateFieldsMap(cloneDeep(savedMap));

      if (!hasAllHeaderFields(headerFields, savedMap)) {
        // If savedMap can't cover all headers, then try to use the default mapping
        setIsNewMapping(true);
      } else {
        // If savedMap can cover all headers, use it, no need to show default mapping
        setIsNewMapping(false);
        setCanUseDefaultMapping(false);
        return;
      }
    }

    // Fill in the fields we already know: Attributes and Teams
    const reservedFieldMaps = ReservedMultipleFields.map((fieldName) => {
      return fillReservedFields(headerFields, defaultFieldsMap, fieldName);
    });

    // Pick from default map with headers, there may be no covered header
    const targetFields = getTargetFields(headerFields, defaultFieldsMap);
    const mapToUse = cloneDeep(
      reduce(
        reservedFieldMaps,
        (prev, v) => {
          return {
            ...prev,
            ...v,
          };
        },
        { ...targetFields }
      )
    );

    // Validate whether the map to use can cover all headers
    const canUseDefault = hasAllHeaderFields(headerFields, mapToUse);

    // If saved map can not cover all headers, then try to use default mapping
    if (!savedMap) {
      updateFieldsMap(mapToUse);
    }

    if (!canUseDefault) {
      // Notify a new mapping needs to be confirmed
      if (!savedMap) {
        setIsNewMapping(true);
      }
      setCanUseDefaultMapping(false);
    } else {
      // When coming here, it means savedMap can not cover all headers
      // And default mapping can. If no savedMap, then we can import using the default mapping
      // Set the isNewMapping falg to flase.
      // If there is savedMap, the isNewMapping flag has been set to true, should not overwrite it
      if (!savedMap) {
        setIsNewMapping(false);
      }
      // There maybe savedMap, but default mapping can still be used
      setCanUseDefaultMapping(true);
    }
  }, [
    headerFields,
    defaultFieldsMap,
    importFlags,
    datasource,
    setIsNewMapping,
  ]);

  const fieldOptions = useMemo(() => {
    const targets = reduce(
      fieldsDefinition,
      (prev, v, k) => {
        if (!v.hidden) {
          prev.push({
            label: k,
            value: k,
            field: v,
          });
        }

        return prev;
      },
      []
    );

    return targets;
  }, [fieldsDefinition]);

  if (!headerFields || !headerFields.length) {
    return null;
  }

  const updateField = (fieldName, targetField, oldTarget) => {
    setError(null);
    let newFieldMap = { ...(fieldsMap[fieldName] || {}) };

    if (oldTarget) {
      delete newFieldMap[oldTarget];
    }

    if (targetField) {
      newFieldMap = updateFieldMapping(
        newFieldMap,
        targetField,
        fieldsDefinition
      );
    }

    // If there is no filed inside, set the value to null
    if (isEmpty(newFieldMap)) {
      newFieldMap = null;
    }

    updateFieldsMap({
      ...fieldsMap,
      [fieldName]: newFieldMap,
    });
  };

  const clearField = (fieldName) => {
    const newMap = {
      ...fieldsMap,
      [fieldName]: null,
    };

    updateFieldsMap(newMap);
  };

  const onSaveDatasource = async () => {
    setError(null);

    const { hasError, message } = validateFieldsMap(
      headerFields,
      fieldsMap,
      fieldsDefinition
    );

    if (hasError) {
      setError(message);
      return;
    }

    setIsSaving(true);
    try {
      // Fill all fields
      const allFields = reduce(
        headerFields,
        (prev, h) => {
          return {
            ...prev,
            [h]: null,
          };
        },
        {}
      );

      await updateDatasource({
        variables: {
          input: {
            id: datasource.id,
            fieldsMap: {
              ...allFields,
              ...fieldsMap,
            },
          },
        },
      });
      setIsNewMapping(false);
    } finally {
      setIsSaving(false);
    }
  };

  const onClearFieldsMap = async () => {
    setIsClearing(true);

    try {
      await updateDatasource({
        variables: {
          input: {
            id: datasource.id,
            fieldsMap: null,
          },
        },
      });
      setIsNewMapping(false);
    } finally {
      setIsClearing(false);
    }
  };

  if (fieldsMap === null) {
    return null;
  }

  return (
    <>
      <H4 mt="l" mb="s" fontWeight="bold">
        Data mapper tool
      </H4>
      <FieldsMapFlagEditor
        fieldsMap={fieldsMap}
        updateFieldsMap={updateFieldsMap}
        importFlags={importFlags}
      />
      <MappingTable
        headerFields={headerFields}
        fieldOptions={fieldOptions}
        fieldsMap={fieldsMap}
        updateField={updateField}
        clearField={clearField}
      />
      {savingDatasourceError && (
        <Box mt="s">
          <ErrorNotification
            message={savingDatasourceError.message}
            closable={false}
          />
        </Box>
      )}
      {error && (
        <Box mt="s">
          <ErrorNotification message={error} closable={false} />
        </Box>
      )}
      <Flex mt="r">
        <Button
          onClick={onSaveDatasource}
          iconLeft
          variant="success"
          isLoading={isSaving}
          disabled={isSaving || isClearing}
        >
          <Icon icon={["fas", "save"]} />
          Save mapping
        </Button>
        {canUseDefaultMapping && (
          <Button
            ml="r"
            iconLeft
            variant="ghost"
            onClick={onClearFieldsMap}
            isLoading={isClearing}
            disabled={isClearing || isSaving}
          >
            <Icon icon={["fas", "undo-alt"]} />
            Reset to default mapping
          </Button>
        )}
      </Flex>
    </>
  );
};

FieldsMappingEditor.propTypes = {
  datasource: PropTypes.object,
  headerFields: PropTypes.array,
  defaultImportFields: PropTypes.object,
  updateDatasource: PropTypes.func,
  savingDatasourceError: PropTypes.object,
  setIsNewMapping: PropTypes.func,
  importFlags: PropTypes.object,
};

export default FieldsMappingEditor;
