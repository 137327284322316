import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import TeamTagsSections from "src/pages/TeamPage/sub-components/TeamDetailsPanel/TeamTagsSections";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { useAuth0 } from "src/contexts/auth0Provider";
import useTagsVisibleInFilter from "src/app/Restricted/useTagsVisibleInFilter";
import { useUpdateTeamTagsCache } from "../hooks/useUpdateTeamTagsCache";

export const Tags = ({ team, readOnly = false }) => {
  const workspace = useWorkspace();
  const workspaceFeatureFlags = get(workspace, "config.featureFlags", {});
  const { user } = useAuth0();
  const tagsVisibleIn = useTagsVisibleInFilter();
  const { updateTeamTagsCache } = useUpdateTeamTagsCache(team.id);

  return (
    <TeamTagsSections
      team={team}
      user={user}
      tagsVisibleIn={tagsVisibleIn}
      featureFlags={workspaceFeatureFlags}
      onUpdateCache={updateTeamTagsCache}
      menuPlacement="top"
      readOnly={readOnly}
    />
  );
};

Tags.propTypes = {
  team: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};
