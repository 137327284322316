import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { get } from "lodash";
import { H1, Small, Button, Icon } from "orcs-design-system";

import { useQuery } from "@apollo/client";

import WorkspaceSwitcher from "src/components/Nav/WorkspaceSwitcher";
import LoadingPage from "src/components/LoadingPage";
import { getWorkspaces as getWorkspacesQuery } from "src/contexts/global/context.graphql";
import urlPaths, { url } from "src/consts/urlPaths";

import { NO_WORKSPACE_ID } from "src/app/apolloClient";
import { ADMIN_ROLE } from "src/services/auth";
import useUserRole from "src/app/Restricted/useUserRole";
import { copywriting } from "./ErrorPage.config";
import ErrorPageWrapper from "./ErrorPageWrapper";

const WorkspaceNotFoundError = ({ clientInfo, showHeader, showContainer }) => {
  const history = useHistory();
  const userRole = useUserRole();

  const isAdmin = userRole === ADMIN_ROLE;

  const {
    data: workspacesData,
    loading: loadingWorkspaces,
    error: workspacesQueryError,
  } = useQuery(getWorkspacesQuery, {
    context: { headers: { "x-workspace-id": NO_WORKSPACE_ID } },
    errorPolicy: "all",
  });

  if (loadingWorkspaces) {
    return <LoadingPage waitingFor="loadingWorkspaces" />;
  }

  if (workspacesQueryError) {
    throw workspacesQueryError;
  }

  const workspaces = get(workspacesData, "workspaces", []);

  return (
    <ErrorPageWrapper
      clientInfo={clientInfo}
      showHeader={showHeader}
      showContainer={showContainer}
      showIcon={true}
      showSideNav
    >
      <H1 mb="r">{copywriting.workspaceNotFoundErrorHeading}</H1>
      <WorkspaceSwitcher
        workspaces={workspaces}
        inverted={false}
        label={copywriting.workspaceSwitcherLabel}
      />
      {isAdmin && (
        <>
          <Small>{copywriting.workspaceNotFoundCreateMessage}</Small>
          <Button
            mt="xs"
            iconLeft
            variant="success"
            onClick={() => {
              history.push(url(urlPaths.CREATE_WORKSPACE));
              history.go(0);
            }}
          >
            <Icon icon={["fas", "plus"]} />
            Create new workspace
          </Button>
        </>
      )}
    </ErrorPageWrapper>
  );
};

WorkspaceNotFoundError.propTypes = {
  clientInfo: PropTypes.string,
  showHeader: PropTypes.bool,
  showContainer: PropTypes.bool,
};

export default WorkspaceNotFoundError;
