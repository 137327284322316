import React from "react";
import { Flex, H4, StyledLink } from "orcs-design-system";
import { url } from "src/consts/urlPaths";

const DataNotAvailable = () => {
  const importDataLink = (
    <StyledLink
      bold
      to={url("/:workspaceId?/configuration/datasources/select")}
    >
      here
    </StyledLink>
  );

  return (
    <Flex alignItems="baseline">
      <H4>No data available to view. Click {importDataLink} to import data.</H4>
    </Flex>
  );
};

export default DataNotAvailable;
