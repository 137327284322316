import { useCallback } from "react";
import { keyBy } from "lodash";
import { useLazyQuery } from "@apollo/client";
import { getDirectMembersAndChildTeamsQuery } from "src/queries/group.graphql";
import {
  getTeamDetail,
  getMissingTeamsForDiffQuery,
} from "src/pages/TeamDetailPage/team.graphql";

import { getMissingMembersForDiffQuery } from "src/allocation/members.graphql";

export const ORG_CHART_STATE_KEYS = {
  DIFF_BASELINE: "baseline",
  DIFF_NEXT: "next",
  NORMAL: "normal",
};
export const DIFF_STATES = [
  ORG_CHART_STATE_KEYS.DIFF_BASELINE,
  ORG_CHART_STATE_KEYS.DIFF_NEXT,
];

export const getQueryOptions = (pitDate, stateKey) => {
  let QUERY_OPTIONS = {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  };

  if (DIFF_STATES.includes(stateKey)) {
    QUERY_OPTIONS = {
      ...QUERY_OPTIONS,
      context: { headers: { "x-pit-date": pitDate } },
    };
  }
  return QUERY_OPTIONS;
};

const getCacheBuster = () => {
  return { _CACHE_BUSTER: Math.random() };
};

const shouldExecuteQuery = (stateKey, showChanges) => {
  if (stateKey === ORG_CHART_STATE_KEYS.NORMAL && showChanges) {
    return false;
  }

  // console.log({ stateKey, showChanges });

  if (!showChanges && DIFF_STATES.includes(stateKey)) {
    return false;
  }

  return true;
};

const useGetTeamsForOrgChart = ({
  pitDate,
  stateKey = ORG_CHART_STATE_KEYS.NORMAL,
  state,
}) => {
  const { showChanges } = state.viewOptions;

  const [getNextTeamDetail] = useLazyQuery(getTeamDetail);

  const [getDirectMembersAndChildTeams] = useLazyQuery(
    getDirectMembersAndChildTeamsQuery
  );
  const [getMissingTeamsForDiff] = useLazyQuery(getMissingTeamsForDiffQuery);
  const [getMissingMembersForDiff] = useLazyQuery(
    getMissingMembersForDiffQuery
  );

  const loadCurrentViewTeamById = useCallback(
    async (currentViewId) => {
      if (currentViewId && shouldExecuteQuery(stateKey, showChanges)) {
        const queryArgs = {
          variables: {
            teamId: currentViewId,
            ...getCacheBuster(pitDate, stateKey),
          },
          ...getQueryOptions(pitDate, stateKey),
        };

        const result = await getNextTeamDetail(queryArgs);
        const currentViewTeam = result?.data?.team;

        return currentViewTeam;
      }

      return null;
    },
    [pitDate, getNextTeamDetail, stateKey, showChanges]
  );

  const loadChildTeamsByParentId = useCallback(
    async (parent) => {
      if (!parent) {
        return { childTeams: [], members: [] };
      }

      if (!shouldExecuteQuery(stateKey, showChanges)) {
        return { childTeams: [], members: [] };
      }

      if (parent.id) {
        const result = await getDirectMembersAndChildTeams({
          variables: {
            groupId: parent.id,
            ...getCacheBuster(pitDate, stateKey),
          },
          ...getQueryOptions(pitDate, stateKey),
        });

        return result?.data?.team;
      }
      return { childTeams: [], members: [] };
    },
    [pitDate, getDirectMembersAndChildTeams, stateKey, showChanges]
  );

  const loadMissingTeamsForDiff = useCallback(
    async (groupIds) => {
      const result = await getMissingTeamsForDiff({
        variables: {
          groupIds,
          ...getCacheBuster(pitDate, stateKey),
        },
        ...getQueryOptions(pitDate, stateKey),
      });

      return result?.data?.getGroups;
    },
    [getMissingTeamsForDiff, pitDate, stateKey]
  );

  const loadMissingMembersForDiff = useCallback(
    async (ids) => {
      const result = await getMissingMembersForDiff({
        variables: {
          ids,
          ...getCacheBuster(pitDate, stateKey),
        },
        ...getQueryOptions(pitDate, stateKey),
      });

      return result?.data?.people;
    },
    [getMissingMembersForDiff, pitDate, stateKey]
  );

  const loadSpecificTeamDataById = useCallback(
    async (id) => {
      if (!shouldExecuteQuery(stateKey, showChanges)) {
        return null;
      }

      const parent = await loadCurrentViewTeamById(id);

      if (!parent) {
        return { teams: {}, members: {} };
      }

      const { childTeams, members } = await loadChildTeamsByParentId(parent);

      return {
        teams: keyBy([...childTeams, parent], "id"),
        members: keyBy(members, "aggregateId"),
      };
    },
    [loadCurrentViewTeamById, loadChildTeamsByParentId, stateKey, showChanges]
  );

  return {
    loadCurrentViewTeamById,
    loadChildTeamsByParentId,
    loadSpecificTeamDataById,
    loadMissingTeamsForDiff,
    loadMissingMembersForDiff,
  };
};

export default useGetTeamsForOrgChart;
