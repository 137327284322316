import { Loading, Select } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { Suspense } from "react";

import { PersonFormatOptionLabel } from "src/components/EntitySearchDropdowns";
import AllocationPersonSearch from "src/components/AllocationPersonSearch";

import { copywriting } from "src/pages/PersonDetailPage/PersonDetailPage.config";
import CustomSingleValue from "./CustomSingleValue";

export default function FulfillVacantRoleSearchInput({
  targetGroupId,
  autoSearch,
  searchFilter,
  onPersonSelected,
  groupTypes,
  disableCache,
}) {
  const allocatedOptionLabel = copywriting.Allocated;
  const allocateOptionLabel = copywriting.Allocate;
  return (
    <Suspense fallback={<Loading small ml="r" />}>
      <AllocationPersonSearch
        disableCache={disableCache}
        targetGroupId={targetGroupId}
        autoSearch={autoSearch}
        filter={searchFilter}
        onPersonSelected={onPersonSelected}
        groupTypes={groupTypes}
        excludeMembersInGroup
        allocatedOptionLabel={allocatedOptionLabel}
        allocateOptionLabel={allocateOptionLabel}
      >
        {({ loadOptions, onSelectEntity, options }) => {
          return (
            <Select
              placeholder="Search..."
              backspaceRemovesValue={true}
              ariaLabel="Type to search and select person to fulfil this vacant role"
              loadOptions={loadOptions}
              onChange={onSelectEntity}
              formatOptionLabel={(params, { context }) =>
                PersonFormatOptionLabel(
                  params,
                  groupTypes,
                  allocatedOptionLabel,
                  allocateOptionLabel,
                  true,
                  true,
                  context
                )
              }
              menuPlacement="auto"
              menuPosition="fixed"
              selectType="async"
              defaultOptions={options}
              components={{ SingleValue: CustomSingleValue }}
              isSearchable={true}
              height="54px"
            />
          );
        }}
      </AllocationPersonSearch>
    </Suspense>
  );
}

FulfillVacantRoleSearchInput.propTypes = {
  targetGroupId: PropTypes.string,
  autoSearch: PropTypes.bool,
  searchFilter: PropTypes.object,
  onPersonSelected: PropTypes.func,
  groupTypes: PropTypes.object,
  disableCache: PropTypes.bool,
};
