import { size } from "lodash";
import { H3, Modal } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { getMemberCountDisplay } from "src/util/search";

import FooterContent from "../ModalFooter/index";

const BulkMoveMembersModal = ({
  team,
  onHideModal,
  isVisible,
  isProcessing,
  handleBulkMove,
  selectedMembers,
  children,
}) => {
  return (
    <Modal
      data-testid="cp-link-objectives-modal"
      visible={isVisible}
      height="80vh"
      width="80vw"
      maxHeight="90vh"
      maxWidth="90vw"
      onClose={onHideModal}
      headerContent={
        <H3>
          Move {getMemberCountDisplay(size(selectedMembers))} from {team.name}
        </H3>
      }
      footerContent={
        <FooterContent
          onHideModal={onHideModal}
          isBusy={isProcessing}
          handleBulkMove={handleBulkMove}
        />
      }
    >
      {children}
    </Modal>
  );
};

BulkMoveMembersModal.propTypes = {
  team: PropTypes.object,
  onHideModal: PropTypes.func,
  isVisible: PropTypes.bool,
  isProcessing: PropTypes.bool,
  handleBulkMove: PropTypes.func,
  selectedMembers: PropTypes.object,
  children: PropTypes.node,
};

export default BulkMoveMembersModal;
