import { intersection, size } from "lodash";
import { isInternalSupportUser } from "src/services/auth";

// eslint-disable-next-line import/prefer-default-export
const canPerform = (user, userRoles, allowedRoles) => {
  if (user && isInternalSupportUser(user)) {
    return true;
  }
  return size(intersection(userRoles, allowedRoles)) > 0;
};

export default canPerform;
