import { Box, Button, Badge, Avatar, Flex, FlexItem } from "orcs-design-system";
import React from "react";

import PropTypes from "prop-types";
import { getMemberCountDisplay } from "src/util/search";
import { AvatarWithGradient, getTeamGradient } from "src/util/gradient";
import { OptionValuePropType } from "src/custom-prop-types/search";
import { getGroupBgImg } from "src/util/group";

const TeamFormatOptionLabel = ({
  value,
  alreadyInGroup,
  group,
  globalConfig,
}) => {
  if (value.message) {
    return (
      <Box p="s" bg="transparent" width="100%">
        {value.message}
      </Box>
    );
  }

  const team = group;
  const bgImg = getGroupBgImg(team);
  const bgGradient = getTeamGradient(globalConfig);
  const memberCount = getMemberCountDisplay(team.memberCount);

  const { name } = team;
  const AvatarComponent = bgImg ? Avatar : AvatarWithGradient;

  const badge = alreadyInGroup ? (
    <Badge variant="success">Assigned</Badge>
  ) : (
    <Button small>Assign</Button>
  );
  return (
    <Box bg="transparent" width="100%">
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <FlexItem flex="1 1 auto">
          <AvatarComponent
            shape="square"
            sizing="small"
            title={name}
            subtitleContent={memberCount}
            image={bgImg}
            bgGradient={bgImg ? undefined : bgGradient}
          />
        </FlexItem>
        <FlexItem flex="0 0 auto" ml="s">
          {badge}
        </FlexItem>
      </Flex>
    </Box>
  );
};

TeamFormatOptionLabel.propTypes = {
  value: OptionValuePropType,
  alreadyInGroup: OptionValuePropType,
  group: OptionValuePropType,
  globalConfig: PropTypes.object,
};

export default TeamFormatOptionLabel;
