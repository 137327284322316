import { useCallback } from "react";
import { useOrgChartContext } from "src/components/OrgChart/context/OrgChartContext";
import { ACTION_TYPES } from "src/components/OrgChart/context/actionTypes";

export const useUpdatePersonTagsCache = (aggregateId) => {
  const { dispatch } = useOrgChartContext() || {};

  const updateTagsCache = useCallback(
    (tags) => {
      if (!aggregateId) {
        return;
      }

      dispatch({
        type: ACTION_TYPES.UPDATE_PERSON_PARTIAL,
        payload: {
          aggregateId,
          tags,
        },
      });
    },
    [aggregateId, dispatch]
  );

  return { updateTagsCache };
};
