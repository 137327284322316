import React from "react";
import { get, map } from "lodash";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  Flex,
  FlexItem,
  H2,
  Icon,
  P,
  Spacer,
} from "orcs-design-system";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import CardLogo from "../DatasourceListPage/CardLogo";
import phrasesAreIncluded from "../DatasourceListPage/phrasesAreIncluded";
import {
  JIRA_TEAMS_EXPORT_PHRASES,
  JIRA_CSV_LINK_PHRASES,
} from "../../consts/jira";

const IntegrationTypeSelector = ({ integrationTypes, onSelect }) => {
  const workspace = useWorkspace();
  const workspaceFeatureFlags = get(workspace, "config.featureFlags", {});

  return (
    <Spacer mb="r">
      {map(
        integrationTypes,
        (
          { logoSrc, logoAlt, heading, para, hide, experimental, hidden },
          key
        ) => {
          if (experimental) {
            return null;
          }
          if (hidden) {
            return null;
          }

          if (
            !workspaceFeatureFlags?.enableJiraTeamsExport &&
            phrasesAreIncluded(heading, JIRA_TEAMS_EXPORT_PHRASES)
          ) {
            return null;
          }

          if (
            !workspaceFeatureFlags?.enableJiraCSVLink &&
            phrasesAreIncluded(heading, JIRA_CSV_LINK_PHRASES)
          ) {
            return null;
          }

          if (hide) {
            return null;
          }
          return (
            <Card key={key}>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                flexWrap="wrap"
              >
                <Flex alignItems="center">
                  {logoSrc && <CardLogo src={logoSrc} alt={logoAlt} />}
                  <FlexItem ml="r">
                    <H2 sizing="small">{heading}</H2>
                    <P color="greyDark">{para}</P>
                  </FlexItem>
                </Flex>
                <Button
                  iconLeft
                  id={`${key}-datasource-button`}
                  onClick={() => {
                    onSelect(key);
                  }}
                >
                  <Icon icon={["fas", "plug"]} />
                  Connect
                </Button>
              </Flex>
            </Card>
          );
        }
      )}
    </Spacer>
  );
};

IntegrationTypeSelector.propTypes = {
  integrationTypes: PropTypes.object,
  onSelect: PropTypes.func,
};

export default IntegrationTypeSelector;
