import React from "react";
import PropTypes from "prop-types";
import { get, map } from "lodash";
import {
  Box,
  Button,
  Card,
  Flex,
  FlexItem,
  H2,
  Icon,
  P,
  Spacer,
} from "orcs-design-system";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import integrationTypes from "../../integrationTypes";
import { JIRA_TEAMS_EXPORT_PHRASES } from "../../consts/jira";
import phrasesAreIncluded from "./phrasesAreIncluded";
import CardLogo from "./CardLogo";

const Datasources = ({ data, loading, onCreate, onOpen }) => {
  const workspace = useWorkspace();
  const workspaceFeatureFlags = get(workspace, "config.featureFlags", {});

  const { datasources } = data || {};

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      width="100%"
      height="100%"
      overflow="hidden"
    >
      {!loading && (
        <FlexItem width="100%" height="100%" flex="1 1 auto" overflow="hidden">
          <Box height="100%" overflow="auto">
            <Spacer mb="r">
              <Card key="create-new-ds">
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  flexWrap="wrap"
                >
                  <Flex alignItems="center">
                    <Icon icon={["far", "link"]} size="3x" color="greyLight" />
                    <FlexItem ml="r">
                      <H2 sizing="small">New integration</H2>
                      <P color="greyDark">Connect to third party systems</P>
                    </FlexItem>
                  </Flex>
                  <Button variant="ghost" onClick={onCreate}>
                    Create
                  </Button>
                </Flex>
              </Card>
              {map(datasources, ({ id, name, description, type }) => {
                const { logoSrc, logoAlt, hidden, heading } =
                  get(integrationTypes, type) || {};

                if (
                  !workspaceFeatureFlags?.enableJiraTeamsExport &&
                  phrasesAreIncluded(heading, JIRA_TEAMS_EXPORT_PHRASES)
                ) {
                  return null;
                }

                return hidden ? null : (
                  <Card key={id}>
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      flexWrap="nowrap"
                    >
                      <Flex alignItems="center">
                        {logoSrc && <CardLogo src={logoSrc} alt={logoAlt} />}
                        <FlexItem ml="r">
                          <H2 sizing="small">{name}</H2>
                          <P color="greyDark">{description}</P>
                        </FlexItem>
                      </Flex>
                      <FlexItem flex="0 0 auto">
                        <Button
                          variant="ghost"
                          onClick={() => {
                            onOpen(id);
                          }}
                        >
                          Open
                        </Button>
                      </FlexItem>
                    </Flex>
                  </Card>
                );
              })}
            </Spacer>
          </Box>
        </FlexItem>
      )}
    </Flex>
  );
};

Datasources.propTypes = {
  data: PropTypes.shape({
    datasources: PropTypes.arrayOf(PropTypes.object),
  }),
  loading: PropTypes.bool,
  onCreate: PropTypes.func,
  onOpen: PropTypes.func,
};

export default Datasources;
