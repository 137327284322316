import PropTypes from "prop-types";
import React from "react";
import { H4, Box, Flex } from "orcs-design-system";

const formatDate = (date) => {
  return date && date.format("DD/MM/YYYY");
};

const ImportDateError = ({ fromDate, toDate }) => {
  return (
    <Box bg="white" boxBorder="default" p="r">
      <Flex alignItems="center" justifyContent="space-between">
        <H4>
          {formatDate(fromDate) === formatDate(toDate)
            ? `Unable to find data for ${formatDate(fromDate)}`
            : `Unable to find data for ${formatDate(fromDate)} or ${formatDate(
                toDate
              )}`}
        </H4>
      </Flex>
    </Box>
  );
};

// TODO: check, is this Moment object?
ImportDateError.propTypes = {
  fromDate: PropTypes.object,
  toDate: PropTypes.object,
};

export default ImportDateError;
