import React, { Fragment, useState, useCallback } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { get, sumBy, includes, map, find, round } from "lodash";
import {
  Box,
  Spacer,
  Card,
  Flex,
  Small,
  Icon,
  FlexItem,
  Divider,
} from "orcs-design-system";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import GroupTypeBadge from "src/components/GroupTypeBadge";
import {
  useFilters,
  useApplyFilters,
} from "src/contexts/filterAndFte/FilterAndFteContext";
import { numberToLocaleString } from "src/util/toLocaleString";
import FeedbackEmoji from "src/components/FeedbackEmoji/FeedbackEmoji";
import ReactCirclePack from "../../../Visualisations/D3/ReactCirclePack";
import DragIcon from "../DragIcon";
import HelpIcon from "../HelpIcon";
import TeamHierarchyBreadcrumb from "../../../TeamHierarchyBreadcrumb";
import ClickableStyleLink from "./ClickableStyledLink";
import ShowMore from "./ShowMore";

const InsightStyle = styled.div`
  height: 100%;
  .root {
    fill: ${themeGet("colors.primary10")};
    stroke: ${themeGet("colors.primary20")};
  }
  .company {
    fill: ${themeGet("colors.primary20")};
    stroke: ${themeGet("colors.primary30")};
    cursor: pointer;
  }
  .location {
    fill: ${themeGet("colors.primary20")};
    stroke: ${themeGet("colors.primary30")};
    cursor: pointer;
  }
  .division {
    fill: ${themeGet("colors.primary20")};
    stroke: ${themeGet("colors.primary30")};
    cursor: pointer;
  }
  .country {
    fill: ${themeGet("colors.primary20")};
    stroke: ${themeGet("colors.primary30")};
    cursor: pointer;
  }
  .team {
    fill: ${themeGet("colors.primary20")};
    stroke: ${themeGet("colors.primary30")};
    cursor: pointer;
  }
  .teamFilterMatch {
    fill: ${themeGet("colors.primary20")};
    stroke: ${themeGet("colors.primary30")};
    cursor: pointer;
  }
  .role {
    fill: ${themeGet("colors.primary20")};
    stroke: ${themeGet("colors.primary30")};
    cursor: pointer;
  }
  .bubblelabel {
    fill: ${themeGet("colors.primaryDarker")};
    font-weight: 400;
    font-size: 1.2rem;
    user-select: none;
    cursor: pointer;
  }
  .bubbleselected {
    fill: ${themeGet("colors.primary50")};
    stroke: ${themeGet("colors.primary60")};
    transition: all 200ms ease-in-out;
  }
  .bubble-chart-container {
    position: relative;
    width: 300px;
    margin: 0 auto;
  }
  .tooltip {
    //position: absolute;
    display: block;
    width: auto;
    color: ${themeGet("colors.white")};
    background: ${themeGet("colors.black90")};
    padding: 3px 7px;
    border-radius: ${themeGet("radii.1")};
    font-size: 1.2rem;
    font-weight: 400;
    word-break: normal;
  }
`;

const CustomCard = styled(Card)`
  overflow: hidden;
`;

const PAGINATION_COUNT = 20;

const normaliseForCirclePackChart = (type, data) => {
  const rootId = -1;

  return [{ id: rootId, parent: "", type: "root" }].concat(
    data.map(({ key, label, value }) => ({
      id: key,
      parent: rootId,
      value: round(value, 1),
      type,
      name: label,
    }))
  );
};

const CirclePackCard = (props) => {
  const {
    icon,
    title,
    insights: { valueLists = {} } = {},
    loading,
    filterKey,
    dataKey,
    layoutKey,
    updateCardSize = () => {},
    labelAsPercentage,
    compare,
    helpContent,
  } = props;

  const filters = useFilters();
  const applyFilters = useApplyFilters();

  const [itemCount, updateItemCount] = useState(10);
  const [bubblesSelected, updateBubblesSelected] = useState([]);

  const onFilterByItem = useCallback(
    (itemFilterKey, itemId, label) => {
      // for the division & country filter, the click overwrites existing division filters.
      const currentValues = get(filters, itemFilterKey);
      const selection = [{ value: itemId, label }];
      if (itemFilterKey === "teams") {
        applyFilters({
          teams: {
            ...currentValues,
            teams: selection,
          },
        });
      } else if (itemFilterKey === "divisions") {
        applyFilters({
          divisions: {
            ...currentValues,
            divisions: selection,
            includeSubDivisions: true,
          },
        });
      } else {
        const values = map(currentValues, "value");
        if (includes(values, itemId)) {
          return;
        }
        applyFilters({ [itemFilterKey]: selection });
      }
    },
    [filters, applyFilters]
  );

  const insightOfInterest = find(valueLists, { key: dataKey });
  const insights = get(insightOfInterest, "items", []);

  const cardInsights = map(insights, (insight) => {
    const { label } = insight;
    return {
      ...insight,
      label: label === "_null_" ? "Unknown" : label,
    };
  });

  const displayChange = (comparisonValue, value) => {
    const diff = Math.abs(comparisonValue - value);
    if (diff === 0) {
      return "No change";
    }
    const percentage = (value / comparisonValue) * 100 - 100;
    const res =
      isNaN(percentage) || percentage === Infinity
        ? `${numberToLocaleString(diff, { decimalplaces: 1 })}`
        : `${numberToLocaleString(diff, { decimalplaces: 1 })}${" "}(${round(
            percentage,
            1
          )}%)`;
    return res;
  };

  const cardTotalCount = get(insightOfInterest, "value", 0);
  const comparisonTotalCount = get(
    insightOfInterest,
    "comparisonValue",
    cardTotalCount
  );

  const changeIcon =
    cardTotalCount > comparisonTotalCount
      ? "arrowUp"
      : cardTotalCount < comparisonTotalCount
      ? "arrowDown"
      : null;

  const nextItemCount =
    itemCount + PAGINATION_COUNT < cardInsights.length
      ? itemCount + PAGINATION_COUNT
      : cardInsights.length;

  const subtitle = (
    <Flex alignItems="center">
      <HelpIcon content={helpContent} />
      <DragIcon />
    </Flex>
  );

  if (loading) {
    return (
      <InsightStyle className="insights-card">
        <CustomCard
          fluid
          alternate
          icon={icon}
          title={title}
          subtitle={subtitle}
        />
      </InsightStyle>
    );
  }
  return (
    <InsightStyle className="insights-card">
      <CustomCard
        fluid
        alternate
        icon={icon}
        title={`${numberToLocaleString(cardTotalCount)} ${title}`}
        changeValue={
          compare ? displayChange(comparisonTotalCount, cardTotalCount) : ""
        }
        changeIcon={compare && changeIcon ? changeIcon : null}
        subtitle={subtitle}
      >
        <Box my="r">
          <ReactCirclePack
            data={normaliseForCirclePackChart(dataKey, cardInsights)}
            onBubbleClick={(d) => onFilterByItem(filterKey, d.id, d.name)}
            selectedBubbles={bubblesSelected}
            labelAsPercentage={labelAsPercentage}
          />
        </Box>
        {cardInsights.slice(0, itemCount).map((item) => {
          return (
            <Fragment key={item.key}>
              <Flex
                key={item.key}
                alignItems="center"
                justifyContent="space-between"
                py="xs"
              >
                <FlexItem>
                  {item.group && (
                    <TeamHierarchyBreadcrumb
                      muted
                      team={item.group}
                      key={item.group.id}
                      id={item.group.id}
                      filterKey={filterKey}
                      onFilterByItem={onFilterByItem}
                    />
                  )}
                  <ClickableStyleLink
                    onClick={() => {
                      onFilterByItem(filterKey, item.key, item.label);
                    }}
                    onMouseEnter={() => {
                      updateBubblesSelected([item.key, item.key]);
                    }}
                    onMouseLeave={() => {
                      updateBubblesSelected([]);
                    }}
                  >
                    <Small>{item.label}</Small>
                  </ClickableStyleLink>
                </FlexItem>
                <Flex alignItems="center" justifyContent="space-between">
                  <Spacer ml="s">
                    {item.group && <GroupTypeBadge group={item.group} />}
                    <Flex alignItems="center">
                      <Spacer my="xs">
                        <Small>
                          {numberToLocaleString(item.value, {
                            decimalplaces: 1,
                          })}
                        </Small>
                        {compare ? (
                          item.value !== item.comparisonValue ? (
                            <Small color="greyDark">
                              {item.value > item.comparisonValue ? (
                                <Icon icon={["fal", "arrow-up"]} />
                              ) : item.value < item.comparisonValue ? (
                                <Icon icon={["fal", "arrow-down"]} />
                              ) : null}{" "}
                              {displayChange(item.comparisonValue, item.value)}
                            </Small>
                          ) : (
                            <Small color="greyDark">
                              {displayChange(item.comparisonValue, item.value)}
                            </Small>
                          )
                        ) : null}
                      </Spacer>
                    </Flex>
                  </Spacer>
                </Flex>
              </Flex>
              <Divider light />
            </Fragment>
          );
        })}
        <ShowMore
          cardTotalCount={cardTotalCount}
          countItems={nextItemCount - itemCount}
          countSum={round(sumBy(cardInsights.slice(itemCount), "value"), 1)}
          onShowMore={() => {
            updateItemCount(nextItemCount);
            updateCardSize(layoutKey, nextItemCount);
          }}
        />
        <FeedbackEmoji contextId={`SummaryPage-${title}-${dataKey}`} />
      </CustomCard>
    </InsightStyle>
  );
};

CirclePackCard.propTypes = {
  insights: PropTypes.object.isRequired,
  compare: PropTypes.bool,
  icon: PropTypes.array.isRequired,
  updateCardSize: PropTypes.func,
  title: PropTypes.string.isRequired,
  filterKey: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  layoutKey: PropTypes.string.isRequired,
  labelAsPercentage: PropTypes.bool.isRequired,
  helpContent: PropTypes.node,
  loading: PropTypes.bool.isRequired,
};

export default withRouter(CirclePackCard);
