import React from "react";
import PropTypes from "prop-types";
import { Flex, FlexItem, Button, Small } from "orcs-design-system";
import { numberToLocaleString } from "src/util/toLocaleString";

const ShowMore = (props) => {
  const { cardTotalCount, countItems, countSum, onShowMore } = props;
  if (countItems > 0) {
    return (
      <Flex justifyContent="space-between" alignItems="center" mt="r">
        <Button variant="ghost" small onClick={onShowMore}>
          + Show next {countItems}
        </Button>
        <FlexItem>{numberToLocaleString(countSum)}</FlexItem>
      </Flex>
    );
  }
  if (cardTotalCount > 100 /* Need to reference this somehow */) {
    return (
      <Small fontWeight="bold" py="s">
        NOTE: {numberToLocaleString(cardTotalCount - 100)} more results are
        available. Please use filters to narrow down your search.
      </Small>
    );
  }
  return <Flex />;
};

ShowMore.propTypes = {
  cardTotalCount: PropTypes.number.isRequired,
  countItems: PropTypes.number.isRequired,
  countSum: PropTypes.number.isRequired,
  onShowMore: PropTypes.func.isRequired,
};

export default ShowMore;
