import { Flex, Button, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const ConfirmAndCancelBtns = ({
  isEditMode,
  onSave,
  saveDisabled,
  onCancel,
  canCancel,
  isLoading,
}) => {
  if (!isEditMode) {
    return null;
  }

  return (
    <Flex alignItems="center" my="s">
      <Button
        dataTestId="save-btn"
        iconOnly
        variant={saveDisabled || isLoading ? "disabled" : "success"}
        onClick={onSave}
        height="40px"
        px="11px"
        disabled={saveDisabled || isLoading}
        isLoading={isLoading}
      >
        Change
      </Button>
      {canCancel && !isLoading && (
        <Button
          dataTestId="cancel-btn"
          small
          iconOnly
          variant="danger"
          onClick={onCancel}
          height="40px"
          px="14px"
          ml="xs"
        >
          <Icon icon={["fas", "times"]} />
        </Button>
      )}
    </Flex>
  );
};

ConfirmAndCancelBtns.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  canCancel: PropTypes.bool.isRequired,
  saveDisabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default React.memo(ConfirmAndCancelBtns);
