import { keyBy } from "lodash";
import { useEffect } from "react";
import eventEmitter, { EVENTS } from "src/services/eventEmitter";

const useUpdateMemberListener = ({ mergePartialData }) => {
  useEffect(() => {
    const handler = ({ person, directGroups }) => {
      mergePartialData({
        members: { [person.aggregateId]: person },
        teams: keyBy(directGroups, "id"),
      });
    };

    eventEmitter.on(EVENTS.UPDATE_PERSON_AND_GROUPS, handler);

    return () => eventEmitter.off(EVENTS.UPDATE_PERSON_AND_GROUPS, handler);
  }, [mergePartialData]);
};

export default useUpdateMemberListener;
