import themeGet from "@styled-system/theme-get";
import styled from "styled-components";

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border: 1px solid ${themeGet("colors.greyLight")};
  border-radius: ${themeGet("radii.2")};

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th {
    background: ${themeGet("colors.greyLightest")};
  }

  th,
  td {
    margin: 0;
    padding: ${themeGet("space.s")};
    border-bottom: 1px solid ${themeGet("colors.greyLight")};
    border-right: 1px solid ${themeGet("colors.greyLight")};
    text-align: left;

    :last-child {
      border-right: 0;
    }
  }
`;

export const Wrapper = styled.div``;
