import React from "react";
import PropTypes from "prop-types";
import { Avatar, Flex, H3, Small } from "orcs-design-system";
import PersonContactDetails from "src/components/PersonContactDetails";
import { getName } from "src/util/person";

export const Header = ({ person }) => {
  return (
    <Flex flexDirection="column" alignItems="center">
      <Avatar mb="r" sizing="large" image={person.avatar} />
      <H3 mb="xs">{getName(person)}</H3>
      <Small fontSize="11px" mb="r" color="greyDark" fontWeight="600">
        {person.jobTitle}
      </Small>
      <PersonContactDetails person={person} />
    </Flex>
  );
};

Header.propTypes = {
  person: PropTypes.object.isRequired,
};
