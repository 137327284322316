import React from "react";
import { FloatingPanels } from "orcs-design-system";
import { useTheme } from "styled-components";
import themeGet from "@styled-system/theme-get";
import { replace } from "lodash";
import { useFeatureFlag } from "src/contexts/global/FeatureFlagsContext";
import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";
import { useReactFlowContext } from "src/contexts/reactFlowContext";

const COSMOS_UI_SIDE_NAV_OFFSET = 4;

const FloatingControl = () => {
  const featureFlags = useFeatureFlag();
  const workspaceFeatureFlags = useWorkspaceFeatureFlags();
  const isCosmosUi =
    !!workspaceFeatureFlags?.enableCosmosUI || !!featureFlags?.["cosmos-ui"];
  const themeContext = useTheme();
  const { panels, onClickIcon } = useReactFlowContext();

  const themeSpacingGap = Number(
    Number(replace(themeGet("space.r")({ theme: themeContext }), "px", ""))
  );

  const navBarSize = Number(
    replace(
      themeGet(isCosmosUi ? "appScale.newNavBarSize" : "appScale.navBarSize")({
        theme: themeContext,
      }),
      "px",
      ""
    )
  );

  const windowWidth = window.innerWidth;
  const bottomPosition = windowWidth < 900 ? navBarSize : themeSpacingGap;

  const leftPosition =
    navBarSize / 2 - (isCosmosUi ? COSMOS_UI_SIDE_NAV_OFFSET : 0);

  return (
    <FloatingPanels
      panels={panels}
      onClick={onClickIcon}
      position={{
        bottom: bottomPosition,
        left: leftPosition,
      }}
      centered={true}
      alignPanels="center"
      zIndex={22}
    />
  );
};

export default FloatingControl;
