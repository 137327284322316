import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "orcs-design-system";
import { get } from "lodash";

import { useTenantId } from "src/contexts/global/ApolloSettingsContext";
import { useAuth0 } from "src/contexts/auth0Provider";

import integrationTypes from "../../integrationTypes";
import Heading from "./Heading";
import Imports from "./Imports";
import CreateImport from "./CreateImport";
import DatasourceNotFound from "./DatasourceNotFound";

const DatasourceDetailPage = ({
  workspace,
  data,
  onCreateImport,
  createImportLoading,
  onDeleteDatasource,
  onLinkDatasource,
  defaultImportFields,
  updateDatasource,
  isSavingDatasource,
  savingDatasourceError,
  showRemoveIntegration,
  loadImportWarnings,
  importWarnings,
}) => {
  const { datasource } = data;
  const tenantId = useTenantId();
  const { getTokenSilently } = useAuth0();
  const integrationType = get(integrationTypes, datasource?.type, {});
  const currentFieldsMap =
    datasource?.fieldsMap || integrationType.defaultFieldsMap;
  const showLinkIntegration =
    get(integrationType, "linkFlow") && !datasource.linked;

  const [selectedFieldsMap, setSelectedFieldsMap] = useState(currentFieldsMap);

  if (!data || !data.datasource) {
    return <DatasourceNotFound />;
  }

  return (
    <>
      <Heading
        datasource={datasource}
        onDeleteDatasource={onDeleteDatasource}
        onLinkDatasource={onLinkDatasource}
        showRemoveIntegration={showRemoveIntegration}
        showLinkIntegration={showLinkIntegration}
      />
      <Grid
        width="100%"
        gridTemplateColumns={["none", "none", "none", "none", "1.5fr 1fr"]}
        gridGap="16px"
      >
        <CreateImport
          workspace={workspace}
          datasource={datasource}
          tenantId={tenantId}
          getTokenSilently={getTokenSilently}
          onCreateImport={onCreateImport}
          createImportLoading={createImportLoading}
          defaultImportFields={defaultImportFields}
          updateDatasource={updateDatasource}
          isSavingDatasource={isSavingDatasource}
          savingDatasourceError={savingDatasourceError}
          currentFieldsMap={currentFieldsMap}
          selectedFieldsMap={selectedFieldsMap}
          updateSelectedFieldsMap={setSelectedFieldsMap}
        />
        <Imports
          datasource={datasource}
          loadImportWarnings={loadImportWarnings}
          importWarnings={importWarnings}
          onUseImportFieldsMap={setSelectedFieldsMap}
        />
      </Grid>
    </>
  );
};

DatasourceDetailPage.propTypes = {
  data: PropTypes.shape({
    datasource: PropTypes.shape({
      type: PropTypes.string.isRequired,
      fieldsMap: PropTypes.object.isRequired,
      linked: PropTypes.bool,
    }),
  }).isRequired,
  workspace: PropTypes.object,
  onCreateImport: PropTypes.func.isRequired,
  onDeleteDatasource: PropTypes.func.isRequired,
  onLinkDatasource: PropTypes.func,
  defaultImportFields: PropTypes.object,
  updateDatasource: PropTypes.func,
  isSavingDatasource: PropTypes.bool,
  savingDatasourceError: PropTypes.object,
  showRemoveIntegration: PropTypes.bool,
  loadImportWarnings: PropTypes.func,
  importWarnings: PropTypes.object,
  createImportLoading: PropTypes.bool,
};

export default DatasourceDetailPage;
