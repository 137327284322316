import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useGroupTypes } from "src/contexts/global/WorkspaceContext";

import LoadingPage from "../LoadingPage";
import Heading from "./Heading";
import TeamList from "./TeamList";

const TeamListPage = ({
  types,
  typeFilters,
  onTeamFilterClick,
  onTeamSearchInputChanged,
  teams = [],
  loading,
  loadMoreTeamsHandler,
  hasMoreTeams,
  totalCount,
  importDate,
}) => {
  const history = useHistory();
  const groupTypes = useGroupTypes();

  return (
    <>
      <Heading
        totalCount={totalCount}
        types={types}
        groupTypes={groupTypes}
        typeFilters={typeFilters}
        onChange={onTeamSearchInputChanged}
        onTeamFilterClick={onTeamFilterClick}
        latestImportDate={importDate}
        loading={loading}
      />
      {teams.length > 0 && (
        <TeamList
          teams={teams}
          history={history}
          loadMoreTeamsHandler={loadMoreTeamsHandler}
          hasMoreTeams={hasMoreTeams}
        />
      )}
      {teams.length === 0 && loading && <LoadingPage waitingFor="teamList" />}
    </>
  );
};

TeamListPage.propTypes = {
  teams: PropTypes.array.isRequired,
  onTeamSearchInputChanged: PropTypes.func,
  loading: PropTypes.bool,
  loadMoreTeamsHandler: PropTypes.func,
  hasMoreTeams: PropTypes.bool,
  totalCount: PropTypes.number,
  importDate: PropTypes.string,
  types: PropTypes.array,
  onTeamFilterClick: PropTypes.func,
  typeFilters: PropTypes.array,
};

export default TeamListPage;
