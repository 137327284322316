import { useMemo } from "react";

import { useTheme } from "styled-components";
import { some } from "lodash";
import {
  useGroupTypes,
  useWorkspace,
} from "src/contexts/global/WorkspaceContext";
import { useAllocationProject } from "src/contexts/global/GlobalContext";
import { useAuth0 } from "src/contexts/auth0Provider";
import {
  getNodesAndEdgesGroupMembersByGroupType,
  initializeNodesAndEdges,
  extractGroupNodesAndEdges,
} from "./utils/index";

export const useOrgChartNodesAndEdges = ({ state }) => {
  const theme = useTheme();
  const groupTypes = useGroupTypes();
  const allocationProject = useAllocationProject();
  const workspace = useWorkspace();
  const { user } = useAuth0();

  const contextualData = useMemo(
    () => ({
      state,
      groupTypes,
      theme,
      workspace,
      user,
      allocationProject,
    }),
    [state, groupTypes, theme, workspace, user, allocationProject]
  );

  return useMemo(() => {
    let membersNodesAndEdges = { edges: [], nodes: [] };

    const { currentViewTeam } = state;

    const groupType = groupTypes[currentViewTeam?.type];

    const expandedTeams = state.expandedNodes.map(
      (nodeId) => state.teams[nodeId]
    );

    const hasMembersOnScreen = some(
      expandedTeams,
      (t) => t?.directMemberCount > 0
    );

    if (
      state?.viewOptions?.groupMembersBySupplyOrDemand &&
      state?.viewOptions?.showPeople &&
      hasMembersOnScreen
    ) {
      membersNodesAndEdges = getNodesAndEdgesGroupMembersByGroupType({
        type: groupType?.isSupply ? "demand" : "supply",
        contextualData,
        state,
      });
    } else if (state?.viewOptions?.showPeople) {
      membersNodesAndEdges = initializeNodesAndEdges({
        contextualData,
        state,
      });
    }

    const groupNodesAndEdges = extractGroupNodesAndEdges(state);

    if (state.currentViewTeam?.isLoading) {
      return {
        nodes: groupNodesAndEdges.nodes.filter(
          (n) => n.id === state.currentViewTeam.id
        ),
        edges: groupNodesAndEdges.edges,
      };
    }
    const newNodes = [
      ...membersNodesAndEdges.nodes,
      ...groupNodesAndEdges.nodes,
    ];
    const newEdges = [
      ...membersNodesAndEdges.edges,
      ...groupNodesAndEdges.edges,
    ];

    return {
      nodes: newNodes,
      edges: newEdges,
    };
  }, [state, groupTypes, contextualData]);
};
