import React from "react";
import PropTypes from "prop-types";
import { Divider, Icon, Grid, Box, Typography } from "orcs-design-system";
import styled from "styled-components";

import Auth0UserProps from "src/custom-prop-types/auth0user";

const CustomGrid = styled(Grid)`
  align-items: center;
  svg {
    margin: 0 auto;
  }
`;

const DetailsSection = ({ icon, value, label }) => {
  return (
    <Box p={4}>
      <CustomGrid gridGap="10px" gridTemplateColumns="20px 80px 1fr">
        <Icon icon={["far", icon]} />
        <Typography.Small bold>{label}</Typography.Small>
        <Typography.P weight="light" fontSize="14px">
          {value}
        </Typography.P>
      </CustomGrid>
    </Box>
  );
};

DetailsSection.propTypes = {
  icon: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const Details = ({ user, auth }) => {
  const { groups, roles } = auth;

  return (
    <Box bg="white" boxBorder="default" borderRadius={2}>
      <Box p={4}>
        <Typography.H3>Details</Typography.H3>
      </Box>
      <Divider light />
      <DetailsSection icon="user" value={user.sub} label="User ID" />
      <Divider light />
      <DetailsSection icon="database" value={roles.join(", ")} label="roles" />
      <Divider light />
      <DetailsSection
        icon="chart-network"
        value={groups.join(", ")}
        label="groups"
      />
    </Box>
  );
};

Details.propTypes = {
  user: Auth0UserProps,
  auth: PropTypes.shape({
    groups: PropTypes.array,
    roles: PropTypes.array,
  }),
};

export default Details;
