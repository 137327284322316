/* eslint-disable react/no-array-index-key */
import themeGet from "@styled-system/theme-get";
import { get, size, isEmpty, map, includes } from "lodash";
import { Flex, Icon, StyledLink, Small } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { Fragment, useMemo } from "react";
import styled from "styled-components";

import { isLastBreadcrumbLink } from "src/components/Badge/GroupBadge.util";
import icon from "src/config/icons";
import GroupPropType from "src/custom-prop-types/group";
import { getGroupNamesArrayFromHierarchy } from "src/util/group";
import { getTeamsPath } from "src/util/paths";

import TopLink from "./TopLink";

const CustomLink = styled(StyledLink)`
  color: ${(props) =>
    props.isDarkTheme
      ? themeGet("colors.greyLight")
      : themeGet("colors.greyDark")};
`;

const GroupHierarchyBreadcrumb = ({ group, workspace, isDarkTheme }) => {
  const hierarchyTeams = useMemo(
    () => getGroupNamesArrayFromHierarchy(get(group, "hierarchy")),
    [group]
  );

  const rootGroupIds = get(workspace, "rootGroupIds");

  if (
    isEmpty(group) ||
    (size(rootGroupIds) === 1 && includes(rootGroupIds, group.id))
  ) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      aria-label="Breadcrumb for heading below"
      role="list"
      data-testid="cp-group-breadcrumb"
      flexWrap="wrap"
    >
      {size(rootGroupIds) > 1 && <TopLink isDarkTheme={isDarkTheme} />}
      {map(group.hierarchyIds, (id, index) => {
        const isLastItem = get(group, "hierarchyIds.length") === index + 1;

        return (
          <Fragment key={id}>
            {!isLastItem && (
              <Small fontSize="0">
                <CustomLink
                  isDarkTheme={isDarkTheme}
                  role="listitem"
                  to={getTeamsPath(id)}
                  data-testid="cp-group-breadcrumb-link"
                  fontSize="0"
                >
                  {hierarchyTeams[index]}
                </CustomLink>
              </Small>
            )}
            {!isLastBreadcrumbLink(id, group) && (
              <Icon
                icon={icon.breadcrumbAngle}
                role="presentation"
                color="grey"
                mx="xs"
              />
            )}
          </Fragment>
        );
      })}
    </Flex>
  );
};

GroupHierarchyBreadcrumb.propTypes = {
  group: GroupPropType.isRequired,
  workspace: PropTypes.object,
  isDarkTheme: PropTypes.bool,
};

export default GroupHierarchyBreadcrumb;
