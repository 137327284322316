import { get, map } from "lodash";

import { omitTypename } from "src/util/graphql";
import { getImageAttribute } from "src/util/group";

import { searchConfig } from "./Unsplash.config";

export const unsplashSearch = async (unsplash, query, page = 1) => {
  const result = await unsplash.search.getPhotos({
    query,
    page,
    perPage: searchConfig.LIMIT,
    orientation: "landscape",
    content_filter: "low",
    featured: true,
  });
  if (result.errors) {
    return { error: result.errors[0] };
  }
  return {
    data: get(result, "response.results", []),
  };
};

export const createOrAddImageAttribute = (
  attributes = [],
  bgImgUrl,
  altDescription
) => {
  const imageToInsert = {
    id: "bgImg",
    label: altDescription,
    value: bgImgUrl,
    attributeType: "image",
  };
  const existingImageAttribute = getImageAttribute(attributes);

  if (!existingImageAttribute) {
    return omitTypename([...attributes, imageToInsert]);
  }

  return omitTypename(
    map(attributes, (attribute) =>
      attribute.attributeType === existingImageAttribute.attributeType
        ? imageToInsert
        : attribute
    )
  );
};
