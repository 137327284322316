import React from "react";
import PropTypes from "prop-types";
import { H4, Small, Spacer, P } from "orcs-design-system";
import { get } from "lodash";
import ImportWarnings from "./sub-components/ImportWarnings";
import ImportMetrics from "./sub-components/ImportMetrics";
import ImportValidationErrors from "./sub-components/ImportValidationErrors";
import DeleteImportButton from "./sub-components/DeleteImportButton";

const niceErrorMessages = {
  AUTH: {
    title: "An authorization error has occurred",
    advice: (datasourceType) =>
      `This error can occur when ${datasourceType} api key is expired or the application has been removed from ${datasourceType}. To fix, try to create a new integration.`,
  },
  SUBMIT_JOB_ERROR: {
    title: "An error occurred submitting import",
    advice: () => null,
  },
  DEPENDANT_JOB_FAILED: {
    title: "A dependant import error has occurred",
    advice: () =>
      "This import has not been processed because an earlier import failed. To import this data independently, please create a new import once all imports have completed processing.",
  },
  VALIDATION: {
    title: "Validation failed",
    advice: () =>
      "Review the additional warning messages for more information.",
  },
  UNKNOWN: {
    title: "An error occurred",
    advice: () => null,
  },
};

const ImportFailed = ({
  datasource,
  dataImport,
  datasourceType,
  warnings,
  canDeleteImport,
}) => {
  const { title, advice } =
    niceErrorMessages[dataImport.error.code] || niceErrorMessages.UNKNOWN;
  const adviceMessage = advice(datasourceType, dataImport);
  const metrics = get(dataImport, "metrics");
  const id = get(dataImport, "id");
  return (
    <Spacer mb="r">
      <H4 color="danger">{title}</H4>
      <Small>{dataImport.error.message}</Small>
      {!!adviceMessage && <Small>{adviceMessage}</Small>}
      {warnings && warnings.length > 0 && (
        <ImportWarnings
          warnings={warnings}
          headingText="Warnings"
          headingColor="danger"
        />
      )}
      <ImportValidationErrors showAsErrors={true} metrics={metrics} />

      {metrics && (
        <ImportMetrics metrics={metrics} datasourceType={datasourceType} />
      )}

      <P>{`import id: ${id}`}</P>

      {canDeleteImport && (
        <DeleteImportButton datasource={datasource} dataImport={dataImport} />
      )}
    </Spacer>
  );
};

ImportFailed.propTypes = {
  datasource: PropTypes.object.isRequired,
  datasourceType: PropTypes.string.isRequired,
  dataImport: PropTypes.object.isRequired,
  warnings: PropTypes.array,
  canDeleteImport: PropTypes.bool,
};

export default ImportFailed;
