// @ts-check

import { get, set, sum } from "lodash";
import { Position } from "reactflow";

const VERTICAL_SPACING = 50; // Spacing between ranks
const HORIZONTAL_SPACING = 100; // Base spacing between siblings

/** @typedef {string} NodeId */

/** @type {Record<string, import("./getDefaultLayout.types").AnchorPosition>} */
let anchorPositions = {};
let currentViewId = null;

/**
 * Reset the global anchor positions if we are on a different page (determined by node.id found in window.location.pathname)
 */
const resetAnchorPositionsAndCurrentViewId = (
  /** @type {import("./getDefaultLayout.types").NodeUsedInLayout[]} */ nodes,
  /** @type {string} */ pathname
) => {
  const nextViewId = nodes.find((node) => pathname.includes(node.id))?.id;

  if (currentViewId !== nextViewId) {
    anchorPositions = {};
    currentViewId = nextViewId;
  }
};

/**
 * Function to get node rank by traversing up through incoming edges
 */
export const getNodeRank =
  (
    /** @type {{ [x: NodeId]: import("./getDefaultLayout.types").EdgeUsedInLayout[]; }} */ incomingEdges
  ) =>
  (/** @type {string} */ nodeId, visited = new Set()) => {
    if (visited.has(nodeId)) {
      return 0;
    }
    visited.add(nodeId);

    // If no incoming edges, this is a root node (rank 0)
    if (!incomingEdges[nodeId]?.length) {
      return 0;
    }

    // Get the maximum rank of all parent nodes + 1
    const parentRanks = incomingEdges[nodeId].map((edge) =>
      getNodeRank(incomingEdges)(edge.source, visited)
    );

    return Math.max(...parentRanks, 0) + 1;
  };

/**
 * Calculate ranks and organize nodes by rank and parent
 *
 * @param {object} params
 * @param {import("./getDefaultLayout.types").NodeUsedInLayout[]} params.nodes
 * @param {{ [x: NodeId]: import("./getDefaultLayout.types").EdgeUsedInLayout[]; }} params.incomingEdges
 */
export const groupNodesByRankAndParent = ({ nodes, incomingEdges }) => {
  /** @type {import("./getDefaultLayout.types").RankAndParentGroup} */
  const groupedByRankAndParent = {};

  nodes.forEach((node) => {
    const rank = getNodeRank(incomingEdges)(node.id);
    const sourceId = incomingEdges[node.id]?.[0]?.source || "root";

    set(
      groupedByRankAndParent,
      [rank, sourceId],
      [...get(groupedByRankAndParent, [rank, sourceId], []), node]
    );
  });

  return groupedByRankAndParent;
};

const getVisibleNodesAndEdges = (
  /** @type {import("./getDefaultLayout.types").NodeUsedInLayout[]} */ nodes,
  /** @type {import("./getDefaultLayout.types").EdgeUsedInLayout[]} */ edges
) => {
  const visibleNodes = nodes.filter((node) => !node.data?.hidden);
  const visibleEdges = edges.filter((edge) => {
    const sourceVisible = visibleNodes.some((node) => node.id === edge.source);
    const targetVisible = visibleNodes.some((node) => node.id === edge.target);
    return sourceVisible && targetVisible;
  });

  return { visibleEdges, visibleNodes };
};

const getAllChildrenWidths =
  ({ groupedByRankAndParent }) =>
  (rank, node, includePadding = false) => {
    const childRank = rank + 1;
    const childrenWidths = get(
      groupedByRankAndParent,
      `${childRank}.${node.id}`,
      []
    ).filter((n) => n.type !== "spacer-node");

    let childrenNodesWidth = sum(
      childrenWidths.map((n) => {
        return n.width || 0;
      })
    );

    let padding = 0;

    if (includePadding) {
      padding = (childrenWidths.length - 1) * HORIZONTAL_SPACING;
    }

    childrenNodesWidth += padding;

    if (childrenNodesWidth && childrenNodesWidth < node.width) {
      return node.width;
    }

    return (
      childrenNodesWidth +
      sum(
        childrenWidths.map((n) => {
          const subChildrenTotalWidth = getAllChildrenWidths({
            groupedByRankAndParent,
          })(childRank, n, includePadding);

          return subChildrenTotalWidth
            ? subChildrenTotalWidth - n.width
            : subChildrenTotalWidth;
        })
      )
    );
  };

/**
 * @param {object} params
 * @param {import("./getDefaultLayout.types").RankAndParentGroup} params.groupedByRankAndParent
 */
export const populatePositionedNodesArray = ({ groupedByRankAndParent }) => {
  /** @type {import("./getDefaultLayout.types").NodeUsedInLayout[]} */
  const positionedNodes = [];

  Object.entries(groupedByRankAndParent).forEach(
    ([rankString, parentGroups]) => {
      const rank = Number(rankString);

      // Each rank starts with 0 X position
      let currentX = 0;
      Object.entries(parentGroups).forEach(([sourceId, noods]) => {
        const sourceNode = positionedNodes.find((n) => n.id === sourceId);
        const sourceBottom =
          (sourceNode?.position.y || 0) + (sourceNode?.height || 0);

        if (sourceNode) {
          const sourceChildrenWidthTotal = getAllChildrenWidths({
            groupedByRankAndParent,
          })(rank - 1, sourceNode, true);

          const sourceNodeMiddlePoint =
            sourceNode.position.x + sourceNode.width / 2;

          // Start current X at half of the total width of all children + the middle point of the source node
          currentX = -(sourceChildrenWidthTotal / 2) + sourceNodeMiddlePoint;

          const spacerNode = noods.find((node) => node.type === "spacer-node");
          if (spacerNode) {
            const spacerNodeIndex = noods.findIndex(
              (node) => node.id === spacerNode.id
            );

            const allNodesBefore = noods.slice(0, spacerNodeIndex);

            const beforeTotal = sum(
              allNodesBefore.map((node) =>
                getAllChildrenWidths({ groupedByRankAndParent })(
                  rank - 2,
                  node,
                  true
                )
              )
            );

            currentX =
              sourceNodeMiddlePoint - spacerNode.width / 2 - beforeTotal;
          }

          if (noods.length === 1) {
            // if there is only one child, center it under the parent
            currentX = sourceNodeMiddlePoint - noods[0].width / 2;
          }
        }

        noods.forEach((node, index) => {
          // Get anchored position if it exists
          const anchoredPosition = get(anchorPositions, node.id);

          const prevNode = noods[index - 1];

          if (prevNode) {
            const prevChildrenTotalWidth = getAllChildrenWidths({
              groupedByRankAndParent,
            })(rank, prevNode, true);
            const nodeChildWidth = getAllChildrenWidths({
              groupedByRankAndParent,
            })(rank, node, true);
            const currentTotalWidth = nodeChildWidth || node.width;

            const prevNodeActualX = positionedNodes.find(
              (n) => n.id === prevNode.id
            ).position.x;

            // If the total width of all children is greater than the parent node width, use that as the total width
            const prevTotalWidth =
              prevChildrenTotalWidth > prevNode.width
                ? prevChildrenTotalWidth
                : prevNode.width;
            const prevMiddlePoint = prevNodeActualX + prevNode.width / 2;

            // Previous end point is the middle point of the previous node + half of the total width of the previous node
            const prevEndPoint = prevMiddlePoint + prevTotalWidth / 2;

            currentX = prevEndPoint;

            if (nodeChildWidth) {
              // If Current node has children, center it above it's children
              currentX += currentTotalWidth / 2;
              currentX -= node.width / 2;
            }

            // Add horizontal spacing
            currentX += HORIZONTAL_SPACING;

            if (prevNode.type === "spacer-node") {
              currentX -= prevNode.width;
              currentX += HORIZONTAL_SPACING;
            }
          }

          // Get Y position for this rank (from previously calculated Y positions)
          const y = sourceBottom + VERTICAL_SPACING * 2;

          // Calculate X position based on current X and index
          let x = currentX;

          // Increment current X by node width to determine next starting X

          // If anchored position exists, use it and update current X
          if (anchoredPosition) {
            x = anchoredPosition.x;
            currentX = x + node.width + HORIZONTAL_SPACING;
          }

          positionedNodes.push({
            ...node,
            rank: rankString,
            targetPosition: Position.Top,
            sourcePosition: Position.Bottom,
            position: { y, x },
          });
        });
      });
    }
  );

  return positionedNodes;
};

/**
 *
 * @param {import("./getDefaultLayout.types").NodeUsedInLayout[]} nodes
 * @param {import("./getDefaultLayout.types").EdgeUsedInLayout[]} edges
 * @returns
 */
export const getLayout = (nodes, edges) => {
  resetAnchorPositionsAndCurrentViewId(nodes, window.location.pathname);

  // Filter out hidden nodes
  const { visibleNodes, visibleEdges } = getVisibleNodesAndEdges(nodes, edges);

  // Create maps for incoming and outgoing edges for each node
  /** @type {{ [x: NodeId]: import("./getDefaultLayout.types").EdgeUsedInLayout[]; }} */
  const incomingEdges = {};
  const outgoingEdges = {};

  // Initialize edge maps
  visibleNodes.forEach((node) => {
    incomingEdges[node.id] = [];
    outgoingEdges[node.id] = [];
  });

  // Populate edge maps
  visibleEdges.forEach((edge) => {
    incomingEdges[edge.target].push(edge);
    outgoingEdges[edge.source].push(edge);
  });

  const groupedByRankAndParent = groupNodesByRankAndParent({
    nodes: visibleNodes,
    incomingEdges,
  });

  const positionedNodes = populatePositionedNodesArray({
    groupedByRankAndParent,
  });

  // Track positions if they are anchor nodes
  positionedNodes.forEach((node) => {
    if (node.isAnchor) {
      set(anchorPositions, node.id, node.position);
    }
  });

  return [
    positionedNodes.filter((node) => node.type !== "spacer-node"),
    edges,
    currentViewId,
    anchorPositions,
  ];
};
