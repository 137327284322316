import React from "react";
import { Badge, Box, Flex, Small } from "orcs-design-system";
import { map } from "lodash";
import PropTypes from "prop-types";
import pluralize from "pluralize";

const GroupTypeBadges = ({ label, teams, mb = "0" }) => {
  return (
    <Box mb={mb}>
      <Small
        fontSize="0"
        fontWeight="600"
        display="block"
        color="greyDark"
        mb="s"
      >
        {pluralize(`${label}`)}
      </Small>
      <Flex flexWrap="wrap" gap="xs">
        {map(teams, (team) => {
          return (
            <Badge variant={team.variant} key={team.label}>
              {team.label}
            </Badge>
          );
        })}
      </Flex>
    </Box>
  );
};

GroupTypeBadges.propTypes = {
  label: PropTypes.string,
  teams: PropTypes.array,
  mb: PropTypes.string,
};

export default React.memo(GroupTypeBadges);
