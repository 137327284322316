import PropTypes from "prop-types";
import React, { useCallback } from "react";

import { allocationPeopleSearch } from "src/queries/person.graphql";
import ErrorNotification from "src/components/ErrorNotification";

import useLoadOptions from "src/hooks/useLoadOptions";
import EntityDropdown from "../EntityDropdown/index";

import PersonFormatOptionLabel from "./PersonFormatOptionLabel";

const RESULT_SIZE = 20;

const getErrorMessage = (addEntityError) => {
  if (addEntityError) {
    return "Sorry, an error occurred, please try adding the person again";
  }
  return "Sorry, an error occurred, please try searching again.";
};

const PersonSearchDropdown = ({
  filter,
  onPersonSelected,
  getPeopleResult,
  placeholder,
  addEntityError,
  disabled,
  groupTypes,
  disableCache,
  ariaLabel,
}) => {
  const {
    searchString,
    setSearchString,
    queryError,
    isSearching,
    loadOptions,
  } = useLoadOptions(
    allocationPeopleSearch,
    filter,
    RESULT_SIZE,
    getPeopleResult,
    disableCache
  );

  const onSelectPerson = useCallback(
    (option) => {
      onPersonSelected(option ? option.value : null);
      setSearchString("");
    },
    [onPersonSelected, setSearchString]
  );

  const error = queryError || addEntityError;

  return (
    <>
      <EntityDropdown
        id="person-search-dropdown"
        ariaLabel={ariaLabel}
        placeholder={placeholder}
        isSearchable={true}
        isLoading={isSearching}
        loadOptions={loadOptions}
        onSelectEntity={onSelectPerson}
        value={searchString}
        formatOptionLabel={({ value }) =>
          PersonFormatOptionLabel({ value }, groupTypes)
        }
        disabled={disabled}
      />

      {error && (
        <ErrorNotification
          message={getErrorMessage(addEntityError)}
          error={error}
        />
      )}
    </>
  );
};

PersonSearchDropdown.propTypes = {
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  onPersonSelected: PropTypes.func,
  getPeopleResult: PropTypes.func,
  filter: PropTypes.object,
  addEntityError: PropTypes.object,
  disabled: PropTypes.bool,
  groupTypes: PropTypes.object,
  disableCache: PropTypes.bool,
};

export default PersonSearchDropdown;
