import { Box, Flex, H2, H5 } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import * as placeholder from "src/components/Placeholder/PlaceholderStyles";

const PanelContainer = ({
  actions = null,
  className,
  children,
  "data-testid": dataTestId,
  loading = false,
  title,
  subtitle,
  wrap,
  mt,
  height,
}) => {
  const isStringTitle = typeof title === "string";
  const titleDisplay = isStringTitle ? (
    <H2 sizing="small" weight="light">
      {title}
    </H2>
  ) : (
    title
  );

  return (
    <Box
      borderRadius={2}
      boxBorder="default"
      className={className}
      data-testid={dataTestId}
      pl="r"
      pr="r"
      pb="r"
      pt={["0", "0", "s"]}
      bg="white"
      width="100%"
      mt={mt}
      height={height}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        flexWrap={wrap}
        width="100%"
      >
        {loading && (
          <placeholder.Line
            data-testid="cp-panel-title-placeholder"
            width={160}
            height={24}
          />
        )}
        {title && (
          <Box my={mt || "r"} mr="r">
            {!loading && title && titleDisplay}
          </Box>
        )}
        {actions}
      </Flex>
      {!loading && subtitle && <H5>{subtitle}</H5>}
      {children}
    </Box>
  );
};

PanelContainer.propTypes = {
  actions: PropTypes.node,
  className: PropTypes.string,
  "data-testid": PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
  wrap: PropTypes.string,
  subtitle: PropTypes.string,
  mt: PropTypes.string,
  height: PropTypes.string,
};

export default PanelContainer;
