import React from "react";
import PropTypes from "prop-types";
import { Flex, Box, Select, Button, P, Icon } from "orcs-design-system";
import { map } from "lodash";

import icons from "src/config/icons";
import formatFieldNameLabel from "./formatFieldNameLabel";

const TargetFieldsSelector = ({
  targets,
  disabled,
  fieldOptions,
  onTargetFieldSelected,
}) => {
  const onSelectTarget = ({ value }) => {
    onTargetFieldSelected(value, null);
  };

  const onRemove = (t) => () => {
    onTargetFieldSelected(undefined, t);
  };

  return (
    <>
      {map(targets, (v, k) => {
        const label = v && v.label ? `${k}:${v.label}` : k;
        return (
          <Flex
            width="100%"
            alignItems="center"
            justifyContent="space-between"
            mb="s"
            key={k}
          >
            <P weight="bold">{label}</P>
            {!disabled && (
              <Button
                variant="danger"
                ml="r"
                small
                onClick={onRemove(k)}
                ariaLabel="Remove this mapping"
              >
                <Icon icon={icons.cancel} />
              </Button>
            )}
          </Flex>
        );
      })}
      {!disabled && (
        <Box width="100%">
          <Select
            ariaLabel="Select target field"
            data-testid="cp-select-target"
            placeholder="Select target field"
            options={fieldOptions}
            value={null}
            onChange={onSelectTarget}
            formatOptionLabel={formatFieldNameLabel}
          />
        </Box>
      )}
    </>
  );
};

TargetFieldsSelector.propTypes = {
  targets: PropTypes.array,
  disabled: PropTypes.bool,
  fieldOptions: PropTypes.array,
  onTargetFieldSelected: PropTypes.func,
};

export default TargetFieldsSelector;
