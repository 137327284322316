import { sortBy, uniqBy } from "lodash";
import { NODE_TYPES } from "src/components/NodeVisualizer/consts";
import { createCollectionNode, createEdge, createNode } from "./shared";

export const getDirectTeamCollectionId = (id) => `${id}_direct_teams`;

const MORE_THAN_GROUP_COUNT = 3;

export const extractGroupNodesAndEdges = (state = {}) => {
  const teams = Object.values(state.teams);
  const allTeams = [...teams];

  const { expandedNodes, currentViewTeam, viewOptions } = state;

  if (!teams.length || !currentViewTeam) {
    return { nodes: [], edges: [] };
  }

  const getChildTeams = (team) => {
    return team.childTeams;
  };

  const shouldGroupChildren = (team) => {
    return getChildTeams(team)?.length > MORE_THAN_GROUP_COUNT;
  };

  const nodes = [];
  const edges = [];
  const collectionNodes = {};

  // First pass collect the grouped teams
  teams.forEach((team) => {
    const isExpanded = expandedNodes.includes(team.id);

    if (shouldGroupChildren(team) && isExpanded) {
      collectionNodes[team.id] = createCollectionNode({
        id: getDirectTeamCollectionId(team.id),
        items: [],
        label: "Teams",
        prefixItemCountToLabel: true,
      });

      edges.push(createEdge(team.id, collectionNodes[team.id].id));
    }
  });

  const processChildTeamNodes = (team) => {
    if (!team) {
      return;
    }

    let childTeamsToAdd = team.childTeams;

    if (viewOptions?.showChanges) {
      childTeamsToAdd = team.childTeams;
    }

    childTeamsToAdd?.forEach((childTeam) => {
      const fullChildTeam = {
        ...allTeams.find((t) => t.id === childTeam.id),
        ...childTeam,
      };

      const node = createNode(
        childTeam.id,
        fullChildTeam,
        NODE_TYPES.GROUP_NODE
      );

      const isExpanded = expandedNodes.includes(node.id);

      if (isExpanded) {
        return;
      }

      if (collectionNodes[team.id] && shouldGroupChildren(team)) {
        collectionNodes[team.id].data.items.push(node);
      } else {
        nodes.push(node);
        edges.push(createEdge(team.id, node.id));
      }
    });
  };

  // Second pass create the nodes and edges
  teams.forEach((team) => {
    const isExpanded = expandedNodes.includes(team.id);
    const isRootNode = currentViewTeam.id === team.id;
    // If a team has no children and it's parent has a collection node, add it to the collection

    const node = createNode(team.id, team, NODE_TYPES.GROUP_NODE);

    if (isRootNode || isExpanded) {
      nodes.push(node);

      processChildTeamNodes(team);
    }

    const parentIsPresent = teams.find((t) => t.id === team.directParentId);

    // Only create an edge if the parent is present and the parent is not a collection node
    if (
      (team.directParentId &&
        !collectionNodes[team.directParentId] &&
        parentIsPresent) ||
      (isExpanded && parentIsPresent)
    ) {
      edges.push(createEdge(team.directParentId, node.id));
    }
  });

  const finalNodes = [
    ...nodes,
    ...Object.values(collectionNodes)
      .map((collection) => ({
        ...collection,
        data: {
          ...collection.data,
          items: sortBy(collection.data.items, "data.name").filter((item) => {
            return !expandedNodes.includes(item.id);
          }),
        },
      }))
      .filter((group) => group.data.items.length),
  ];

  const nodesAndEdges = {
    // Ensure nodes are spread after collection nodes for layout purposes
    nodes: finalNodes,
    edges: uniqBy(edges, "id"),
  };

  return nodesAndEdges;
};
