import { Box, TextInput } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { MAX_TEAM_NAME_LENGTH } from "src/consts/global";

const TeamNameInput = ({
  isEditMode,
  value,
  onChange,
  onSave,
  onCancel,
  isLoading,
  label,
}) => {
  const handleFocus = (e) => {
    // auto select text
    e.target.select();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSave();
    }
    if (e.key === "Escape") {
      onCancel();
    }
  };

  if (!isEditMode) {
    return null;
  }

  return (
    <Box mr="s">
      <TextInput
        id="team-name-edit"
        label={label}
        autoFocus
        fullWidth
        data-testid="team-name-editor-input"
        className="guided-tour-team-details-team-name"
        disabled={isLoading}
        maxLength={MAX_TEAM_NAME_LENGTH}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
      />
    </Box>
  );
};

TeamNameInput.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  label: PropTypes.string,
};

export default TeamNameInput;
