/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import PropTypes from "prop-types";
import {
  Spacer,
  H4,
  Icon,
  Flex,
  FlexItem,
  Expandable,
} from "orcs-design-system";
import moment from "moment";
import { map, get } from "lodash";

import ImportWarnings from "./sub-components/ImportWarnings";
import ImportValidationErrors from "./sub-components/ImportValidationErrors";
import ImportMetrics from "./sub-components/ImportMetrics";
import ImportTransactionDetails from "./sub-components/ImportTransactionDetails";
import DeleteImportButton from "./sub-components/DeleteImportButton";
import RevertImportButton from "./sub-components/RevertImportButton";

const ImportCompleted = ({
  datasource,
  dataImport,
  datasourceType,
  warnings,
  canDeleteImport,
  canRevertImport,
}) => {
  const started = moment(dataImport.created);
  const completed = moment(dataImport.processed);
  const duration = moment.duration(completed.diff(started));
  const durationString = duration.format();
  const { metrics, id } = dataImport;

  const workspaceAccessMetrics = metrics && metrics.workspaceAccess;
  const isDatasourceTypeWorkspaceAccess = datasourceType === "workspaceAccess";
  return (
    <Spacer my="r">
      <Flex justifyContent="space-between" alignItems="center">
        <H4 color="success">Successfully completed import</H4>
        <FlexItem>
          <Icon icon={["far", "stopwatch"]} /> {durationString}
        </FlexItem>
      </Flex>
      <ImportMetrics metrics={metrics} datasourceType={datasourceType} />
      {!["associations", "personTags", "entityTags"].includes(
        datasourceType
      ) && <ImportTransactionDetails importId={id} />}
      <ImportValidationErrors showAsErrors={false} metrics={metrics} />
      {workspaceAccessMetrics && isDatasourceTypeWorkspaceAccess && (
        <>
          <Expandable
            title={`Added (${get(workspaceAccessMetrics, "added", []).length})`}
            key={`added-expandable-${id}`}
          >
            <ul>
              {map(get(workspaceAccessMetrics, "added", []), (added, index) => (
                <li key={`${added}-${index}`}>{added}</li>
              ))}
            </ul>
          </Expandable>
          <Expandable
            title={`Pending (${
              get(workspaceAccessMetrics, "failed", []).length
            })`}
            key={`failed-expandable-${id}`}
          >
            <ul>
              {map(
                get(workspaceAccessMetrics, "failed", []),
                (failed, index) => (
                  <li key={`${failed}-${index}`}>{failed}</li>
                )
              )}
            </ul>
          </Expandable>
        </>
      )}

      {warnings && warnings.length > 0 && (
        <ImportWarnings
          warnings={warnings}
          headingText="Warnings &amp; result"
        />
      )}
      {canRevertImport && (
        <RevertImportButton
          datasource={datasource}
          dataImport={dataImport}
          confirmationRequired
          buttonText="Revert and remove import"
        />
      )}
      {canDeleteImport && (
        <DeleteImportButton datasource={datasource} dataImport={dataImport} />
      )}
    </Spacer>
  );
};

ImportCompleted.propTypes = {
  datasource: PropTypes.object.isRequired,
  datasourceType: PropTypes.string.isRequired,
  dataImport: PropTypes.object.isRequired,
  warnings: PropTypes.array,
  canDeleteImport: PropTypes.bool,
  canRevertImport: PropTypes.bool,
};

export default ImportCompleted;
