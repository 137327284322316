import React from "react";
import { Box, Flex, FlexItem, H1, Icon, P } from "orcs-design-system";

import WorkspacePropType from "src/custom-prop-types/workspace";

const Heading = ({ workspace = {} }) => {
  return (
    <Box bg="white" boxBorder="default" borderRadius={2} p="r" mb="r">
      <Flex alignItems="center">
        <Icon icon={["far", "database"]} size="2x" color="greyLight" mr="r" />
        <FlexItem>
          <H1>{workspace.name}</H1>
          <P color="greyDark">{workspace.description}</P>
        </FlexItem>
      </Flex>
    </Box>
  );
};

Heading.propTypes = {
  workspace: WorkspacePropType,
};

export default Heading;
