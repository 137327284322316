import { useMutation } from "@apollo/client";
import { map } from "lodash";
import { H3, P, Modal, Flex, Button, Spacer, Box } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";

import GroupPropType from "src/custom-prop-types/group";
import { removeAssociation as removeAssociationMutation } from "src/queries/tags.graphql";
import { EventBus, EVENTS } from "src/services/eventEmitter";
import ErrorNotification from "src/components/ErrorNotification";

const ModalHeader = ({ associatedTeam, tags }) => {
  const tagType = tags[0].tagTypeMetadata.name;
  const tagValue = tags[0].displayValue;
  return (
    <H3 mr="s">
      <span>{`Remove association ${tagType}:${tagValue} to ${associatedTeam.name}`}</span>
    </H3>
  );
};

ModalHeader.propTypes = {
  associatedTeam: GroupPropType,
  tags: PropTypes.array,
};

const RemoveAssociationModal = ({
  subjectTeam,
  associatedTeam,
  tags,
  isVisible,
  onHideModal,
  eventEmitter = EventBus,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [removeAssociation, { error }] = useMutation(removeAssociationMutation);

  const onRemoveAssociation = useCallback(async () => {
    setIsProcessing(true);
    await Promise.all(
      map(tags, (tag) =>
        removeAssociation({
          variables: {
            input: {
              entityAId: subjectTeam.id,
              entityBId: associatedTeam.id,
              type: tag && tag.type,
            },
          },
        })
      )
    );
    setIsProcessing(false);
    onHideModal();

    eventEmitter.emit(EVENTS.ASSOCIATION_REMOVED, subjectTeam);
    eventEmitter.emit(EVENTS.ASSOCIATION_REMOVED, associatedTeam);
  }, [
    removeAssociation,
    subjectTeam,
    associatedTeam,
    tags,
    onHideModal,
    eventEmitter,
  ]);

  const modalFooter = (
    <Flex>
      <Spacer mr="s">
        <Button
          onClick={onRemoveAssociation}
          disabled={isProcessing}
          isLoading={isProcessing}
          data-testid="cp-remove-association-btn"
          px="l"
        >
          Remove
        </Button>
        <Button variant="ghost" onClick={onHideModal}>
          Cancel
        </Button>
      </Spacer>
    </Flex>
  );

  return (
    <Modal
      visible={isVisible}
      onClose={onHideModal}
      maxWidth="90vw"
      maxHeight="90vh"
      width={["90vw", "90vw", "80vw", "70vw", "60vw", "50vw"]}
      footerContent={modalFooter}
      headerContent={
        <ModalHeader associatedTeam={associatedTeam} tags={tags} />
      }
    >
      <>
        <P my="r">Are you sure you&apos;d like to remove?</P>{" "}
        {error && (
          <Box m="s">
            <ErrorNotification
              message="Sorry, an error occurred, please refresh then try to remove association again."
              error={error}
              closable
              floating
            />
          </Box>
        )}
      </>
    </Modal>
  );
};

RemoveAssociationModal.propTypes = {
  subjectTeam: GroupPropType,
  associatedTeam: GroupPropType,
  tags: PropTypes.array,
  onHideModal: PropTypes.func,
  isVisible: PropTypes.bool.isRequired,
  eventEmitter: PropTypes.object,
};

export default React.memo(RemoveAssociationModal);
