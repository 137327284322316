import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Flex,
  H4,
  Icon,
  Loading,
  Popover,
  Spacer,
  Badge,
} from "orcs-design-system";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import themeGet from "@styled-system/theme-get";

import { get, cloneDeep } from "lodash";
import GroupMemberCountBadge from "src/components/GroupMemberCountBadge";
import GroupPropType from "src/custom-prop-types/group";
import { teamDetailsPath, visualisationTeamPath } from "src/util/routing";
import urlPaths, { url } from "src/consts/urlPaths";
import isSupplyDemandGroupType from "src/util/isSupplyDemandGroupType";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import {
  useApplyFilters,
  DEFAULT_FILTERS,
} from "src/contexts/filterAndFte/FilterAndFteContext";

import { FTE_SUM_TYPE, FTE_SUM_TARGET } from "src/consts/team";
import GroupTypeBadge from "src/components/GroupTypeBadge";
import FteQueryBadge from "src/components/FteQueryBadge";
import { getGroupExternalId } from "src/util/groupAttributes";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";
import InfiniteScroll from "../InfiniteScroll";
import TeamHierarchyBreadcrumb from "../TeamHierarchyBreadcrumb";
import PrincipleStatus from "../PrincipleStatus";

const StyledListLink = styled(Link)`
  display: block;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  border-bottom: solid 1px ${themeGet("colors.greyLightest")};
  transition: ${(props) => themeGet("transition.transitionDefault")(props)};
  &:hover,
  &:focus {
    outline: 0;
    background-color: rgba(156, 224, 248, 0.2);
  }
  &:last-of-type {
    border-bottom: none;
  }
  h3 {
    color: ${(props) => themeGet("colors.primary")(props)};
  }
`;

const TeamRow = ({ team, history, exploreInSummaryPage }) => {
  const workspace = useWorkspace();
  const [isSupply, isDemand] = isSupplyDemandGroupType(team, workspace);
  const workspaceFeatureFlags = get(workspace, "config.featureFlags", {});
  const externalId = getGroupExternalId(team);
  const showExternalIdBadge =
    workspaceFeatureFlags?.showTeamExternalIdAsBadge && externalId;

  return (
    <StyledListLink
      data-testid="cp-teamlist-row"
      to={teamDetailsPath(team, null)}
    >
      <Flex alignItems="center" justifyContent="space-between" p="r">
        <Box mr="s">
          <TeamHierarchyBreadcrumb
            muted
            team={team}
            key={team.id}
            id={team.id}
          />
          <H4 color="primary" fontSize="2">
            {team.name}
          </H4>
        </Box>
        <PrincipleStatus team={team} textDirection="right" />
        <Flex
          data-testid="cp-teamlist-row-details"
          alignItems="center"
          justifyContent="space-between"
        >
          <Spacer ml="s">
            <GroupTypeBadge group={team} showToolTip toolTipPosition="left" />
            {showExternalIdBadge && <Badge role="listitem">{externalId}</Badge>}
            <GroupMemberCountBadge
              count={team.memberCount}
              isTotalMemberCount
              showToolTip
              toolTipPosition="topLeft"
            />
            {(isSupply || isDemand) && (
              <FteQueryBadge
                groupId={team.id}
                fteSumTarget={FTE_SUM_TARGET.ALL_MEMBERS}
                fteSumType={
                  isSupply
                    ? FTE_SUM_TYPE.CAPACITY_FTE
                    : FTE_SUM_TYPE.ALLOCATED_FTE
                }
                showToolTip
                toolTipPosition="topLeft"
              />
            )}
            <Popover
              direction="topLeft"
              width="130px"
              textAlign="center"
              text="View in Insights"
            >
              <Button
                small
                iconOnly
                height="26px"
                width="26px"
                ariaLabel="View in Insights"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  trackEvent(EVENT_TRACKING.TEAM_CLICKED, {
                    page: "team_list",
                    menu_item: "view_in_summary",
                  });
                  exploreInSummaryPage(team);
                }}
              >
                <Icon icon={["fas", "chart-pie-alt"]} />
              </Button>
            </Popover>
            <Popover
              direction="topLeft"
              width="154px"
              textAlign="center"
              text="Explore in Organisation"
            >
              <Button
                small
                iconOnly
                height="26px"
                width="26px"
                ariaLabel="Explore in Organisation"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  trackEvent(EVENT_TRACKING.TEAM_CLICKED, {
                    page: "team_list",
                    menu_item: "explore_in_org",
                  });
                  history.push(visualisationTeamPath(team));
                }}
              >
                <Icon icon={["far", "chart-network"]} />
              </Button>
            </Popover>
          </Spacer>
        </Flex>
      </Flex>
    </StyledListLink>
  );
};

// TODO: bool naming, object shape
TeamRow.propTypes = {
  team: PropTypes.object,
  history: PropTypes.object,
  exploreInSummaryPage: PropTypes.func,
};

const TeamList = ({ teams, loadMoreTeamsHandler, hasMoreTeams }) => {
  const history = useHistory();
  const applyFilters = useApplyFilters();

  const exploreInSummaryPage = (team) => {
    applyFilters({
      teams: {
        ...cloneDeep(DEFAULT_FILTERS.teams),
        teams: [{ value: team.id, label: team.name }],
      },
    });
    history.push({
      pathname: url(urlPaths.INSIGHTS),
      state: { sidebar: { action: "openSidebar", selectedTab: "filter" } },
    });
  };

  const loader = (
    <Box p="l" m="l" key={0}>
      <Loading data-testid="page-teamlist-loader" large centered />
    </Box>
  );

  return (
    <Box
      data-testid="cp-teamlist-container"
      id="teamlistpage-teamlist-container"
      bg="white"
      borderRadius="2"
      mt="r"
      boxBorder="default"
    >
      <InfiniteScroll
        loadMore={loadMoreTeamsHandler}
        hasMore={hasMoreTeams}
        loader={loader}
      >
        {teams.map((team) => {
          return (
            <TeamRow
              key={team.id}
              team={team}
              history={history}
              exploreInSummaryPage={exploreInSummaryPage}
            />
          );
        })}
      </InfiniteScroll>
    </Box>
  );
};

// TODO: bool naming convention
TeamList.propTypes = {
  teams: PropTypes.arrayOf(GroupPropType).isRequired,
  loadMoreTeamsHandler: PropTypes.func,
  hasMoreTeams: PropTypes.bool,
};

export default TeamList;
