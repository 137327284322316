import React, { PureComponent } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { PieChart, Pie, Cell } from "recharts";
import { Box } from "orcs-design-system";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CustomBox = styled(Box)`
  position: relative;
  &:before {
    content: "";
    background-color: rgba(0, 123, 199, 0.1);
    border-radius: 50%;
    border: solid 1px rgba(0, 123, 199, 0.2);
    position: absolute;
    top: 10px;
    left: 10px;
    width: 280px;
    height: 280px;
  }
  .recharts-surface {
    overflow: visible;
  }
`;

export default class PieInsight extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <CustomBox>
        <PieChart width={300} height={300} fill="#007bc71a" stroke="#007bc71a">
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={true}
            label={renderCustomizedLabel}
            outerRadius={130}
            innerRadius={75}
            dataKey="value"
          >
            {data.map((entry) => {
              return <Cell key={`cell-${entry.label}`} fill={entry.colour} />;
            })}
          </Pie>
        </PieChart>
      </CustomBox>
    );
  }
}

PieInsight.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
};
