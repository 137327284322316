/* istanbul ignore file */
import { get } from "lodash";
import { Flex, Grid } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { ModalProvider } from "src/contexts/withModal";
import PersonPropType from "src/custom-prop-types/person";
import useUpdateTeamDetails from "src/hooks/useUpdateTeamDetails";
import TeamDetailsPanel from "src/pages/TeamPage/sub-components/TeamDetailsPanel";
import TeamExplorePanel from "src/pages/TeamPage/sub-components/TeamExplorePanel";
import TeamMembersPanel from "src/pages/TeamPage/sub-components/TeamMembersPanel";
import { canAddNewTeam } from "src/util/allocations";
import { isGroupActive } from "src/util/group";
import useTagsVisibleInFilter from "src/app/Restricted/useTagsVisibleInFilter";
import { useReactFlowContext } from "src/contexts/reactFlowContext";
import OrgChart from "../OrgChart";
import { FLOATING_CONTROL_ID } from "../TeamDetailPage/const";
import FloatingControl from "./components/FloatingControl";

const LayoutWrapper = ({ team, children }) => {
  // handle shifting of components
  const directMemberCount = get(team, "directMemberCount");

  const gridColumns =
    directMemberCount === 0 ? "1fr" : "repeat(auto-fit,minmax(520px,1fr))";

  if (directMemberCount === 0) {
    return (
      <Flex flexDirection="column" height="100%" gap="r">
        {children}
      </Flex>
    );
  }
  return (
    <Grid
      flex="1 1 auto"
      gridGap="r"
      gridTemplateColumns={["1fr", "1fr", gridColumns]}
    >
      {children}
    </Grid>
  );
};

LayoutWrapper.propTypes = {
  team: PropTypes.object,
  children: PropTypes.node,
};

export const TeamsPanel = ({
  loading,
  teamId,
  team,
  groupTypes,
  user,
  userPerson,
  workspace,
  allocationProject,
  renderTeamFteBadge,
  renderMemberFteBadge,
  renderPrincipleStatus,
  refetch,
}) => {
  const { viewMode } = useReactFlowContext();
  const tagsVisibleIn = useTagsVisibleInFilter();
  const {
    handleUpdateTeamDetails,
    canUpdateGroupAttributes,
    editDetailsMode,
    setEditDetailsMode,
  } = useUpdateTeamDetails({ team, refetch });

  const showAddNewTeam =
    canAddNewTeam(user, groupTypes, team) && isGroupActive(team);

  return (
    <>
      {viewMode === FLOATING_CONTROL_ID.LIST_VIEW && (
        <TeamDetailsPanel
          key="team-details-panel"
          loading={loading}
          team={team}
          groupTypes={groupTypes}
          user={user}
          handleUpdateTeamDetails={handleUpdateTeamDetails}
          canUpdateGroupAttributes={canUpdateGroupAttributes}
          editDetailsMode={editDetailsMode}
          setEditDetailsMode={setEditDetailsMode}
          workspace={workspace}
          tagsVisibleIn={tagsVisibleIn}
        />
      )}
      {viewMode === FLOATING_CONTROL_ID.CHART_VIEW ? (
        <OrgChart teamId={teamId} groupTypes={groupTypes} />
      ) : (
        <LayoutWrapper team={team}>
          <TeamMembersPanel
            key="team-members-panel"
            teamId={teamId}
            team={team}
            workspace={workspace}
            allocationProject={allocationProject}
            user={user}
            userPerson={userPerson}
            renderFteBadge={renderMemberFteBadge}
          />
          <TeamExplorePanel
            key="team-explore"
            teamId={teamId}
            team={team}
            groupTypes={groupTypes}
            showAddNewTeam={showAddNewTeam}
            renderFteBadge={renderTeamFteBadge}
            renderPrincipleStatus={renderPrincipleStatus}
            workspace={workspace}
          />
        </LayoutWrapper>
      )}
      <FloatingControl />
    </>
  );
};

TeamsPanel.propTypes = {
  loading: PropTypes.bool,
  teamId: PropTypes.string,
  team: PropTypes.object,
  groupTypes: PropTypes.object,
  user: PersonPropType,
  workspace: PropTypes.object,
  allocationProject: PropTypes.func,
  userPerson: PersonPropType,
  refetch: PropTypes.func,
  renderTeamFteBadge: PropTypes.func,
  renderMemberFteBadge: PropTypes.func,
  renderPrincipleStatus: PropTypes.func,
};

const TeamInfo = (props) => {
  return (
    <ModalProvider>
      <TeamsPanel {...props} />
    </ModalProvider>
  );
};

export default TeamInfo;
