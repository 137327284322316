import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Divider,
  Spacer,
  P,
  Box,
  Small,
  Toggle,
  Flex,
} from "orcs-design-system";
import { useMutation } from "@apollo/client";

import { get, isEqual, map, set } from "lodash";
import {
  useGlobalConfig,
  useGlobalStateDispatch,
} from "src/contexts/global/GlobalContext";
import { GLOBAL_STATE_ACTIONS } from "src/contexts/global/GlobalContext/GlobalContextProvider";
import FancyCard, {
  FancyCardBody,
  FancyCardFooter,
} from "src/components/FancyCard/FancyCard";

import { updateGlobalConfig } from "../../pages/AdminConfiguration/globalConfig.graphql";
import SlackConfigInput from "./SlackConfigInput";

const LABELS = {
  individualContactOptions: "Individual contact config",
  phone: "Phone",
  email: "Email",
  slack: "Slack",
  microsoftTeams: "Microsoft Teams",
  googleChat: "Google Chat",
};

const CUSTOM_COMPONENTS = {
  slack: SlackConfigInput,
};

const getindividualContactOptions = (individualContactOptions) => {
  return {
    phone: get(individualContactOptions, "phone", true),
    email: get(individualContactOptions, "email", true),
    microsoftTeams: get(individualContactOptions, "microsoftTeams", false),
  };
};

const getInitialData = (featureFlags) => {
  return {
    individualContactOptions: getindividualContactOptions(
      featureFlags?.individualContactOptions
    ),
  };
};

const TenantFeatureFlags = ({ globalConfigId }) => {
  const globalStateDispatch = useGlobalStateDispatch();
  const globalConfig = useGlobalConfig();

  const flags = getInitialData(get(globalConfig, "config.featureFlags", {}));
  const [featureFlags, setFeatureFlags] = useState(flags);

  const [saveFeatureFlags, { loading: isSaving }] =
    useMutation(updateGlobalConfig);

  const onFlagChange = useCallback(
    (key, value) => setFeatureFlags({ ...set(featureFlags, key, value) }),
    [featureFlags]
  );

  const onFlagToggle = useCallback(
    (key) => (evt) => onFlagChange(key, evt.target.checked),
    [onFlagChange]
  );

  const handleOnSave = useCallback(async () => {
    await saveFeatureFlags({
      variables: {
        input: { id: globalConfigId, featureFlags },
      },
    });

    globalStateDispatch({
      type: GLOBAL_STATE_ACTIONS.UPDATE_FEATURE_FLAGS,
      data: JSON.parse(JSON.stringify(featureFlags)),
    });
  }, [featureFlags, globalConfigId, saveFeatureFlags, globalStateDispatch]);

  const handleOnReset = useCallback(() => {
    setFeatureFlags(flags);
  }, [flags]);

  const isChanged = !isEqual(featureFlags, flags);

  return (
    <FancyCard height="100%">
      <FancyCardBody>
        <Box p="xs">
          <Spacer mb="r">
            <P>Here you can set the tenant feature flags</P>
            <Small>
              Note: After making changes and saving, refresh the page to see
              changes in action.
            </Small>
            <Divider />
            <Box>
              <Spacer mb="r">
                {map(featureFlags, (value, key) => {
                  if (typeof value === "object") {
                    return (
                      <Box boxBorder="default" borderRadius={2} p="r">
                        <Spacer mb="r">
                          <P>{LABELS[key]}</P>

                          {map(value, (nestedValue, nestedKey) => {
                            const flagKey = `${key}.${nestedKey}`;
                            const CustomComponent =
                              CUSTOM_COMPONENTS[nestedKey];

                            if (CustomComponent) {
                              return (
                                <CustomComponent
                                  value={nestedValue}
                                  onChange={(v) => onFlagChange(flagKey, v)}
                                />
                              );
                            }

                            return (
                              <Toggle
                                id={`${key}-${nestedKey}-Toggle`}
                                label={LABELS[nestedKey]}
                                checked={nestedValue}
                                onChange={onFlagToggle(flagKey)}
                              />
                            );
                          })}
                        </Spacer>
                      </Box>
                    );
                  }

                  return (
                    <Toggle
                      id={`${key}-Toggle`}
                      label={LABELS[key]}
                      checked={value}
                      onChange={onFlagToggle(key)}
                    />
                  );
                })}
              </Spacer>
            </Box>
          </Spacer>
        </Box>
      </FancyCardBody>
      <FancyCardFooter>
        <Flex>
          <Spacer mr="s">
            <Button
              onClick={handleOnSave}
              isLoading={isSaving}
              disabled={isSaving || !isChanged}
              variant={isSaving ? "disabled" : "success"}
            >
              Save changes
            </Button>
            <Button
              onClick={handleOnReset}
              isLoading={isSaving}
              disabled={!isChanged}
              variant="ghost"
            >
              Reset changes
            </Button>
          </Spacer>
        </Flex>
      </FancyCardFooter>
    </FancyCard>
  );
};

TenantFeatureFlags.propTypes = {
  globalConfigId: PropTypes.string,
};

export default TenantFeatureFlags;
