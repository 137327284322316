import React from "react";
import { withRouter } from "react-router";

import GroupPropType from "src/custom-prop-types/group";
import TeamHeading from "../TeamHeading";

const VisualisationTeamHeading = ({ team }) => {
  return <TeamHeading team={team} />;
};

VisualisationTeamHeading.propTypes = {
  team: GroupPropType.isRequired,
};

export default withRouter(VisualisationTeamHeading);
