import { useUserPerson } from "src/contexts/global/GlobalContext";
import { isLocalTimeSame } from "src/util/getLocalTime";

export const useShouldDisplayLocalTime = ({ showLocalTime, member }) => {
  const userPerson = useUserPerson();
  if (!showLocalTime) {
    return false;
  }
  if (!member || !userPerson) {
    return false;
  }
  return !isLocalTimeSame(member, userPerson);
};
