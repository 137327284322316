import { StyledLink } from "orcs-design-system";
import themeGet from "@styled-system/theme-get";
import PropTypes from "prop-types";
import styled from "styled-components";

const ClickableStyledLink = styled(StyledLink)`
  color: ${(props) =>
    props.muted ? themeGet("colors.grey") : themeGet("colors.primary")};
`;

ClickableStyledLink.propTypes = {
  muted: PropTypes.bool,
};

export default ClickableStyledLink;
