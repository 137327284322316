import { useEffect, useState, useRef } from "react";
import { getUpdatedAttributes } from "src/components/TagsEditor/utils/getUpdatedAttributes";

export const useAttributesUpdater = ({
  entityId,
  initialAttributes,
  selectedTag,
}) => {
  const previousSelectedTag = useRef(selectedTag);
  const [updatedAttributes, setUpdatedAttributes] = useState(
    initialAttributes || {}
  );
  useEffect(() => {
    if (selectedTag && previousSelectedTag?.current?.id !== selectedTag?.id) {
      previousSelectedTag.current = selectedTag;
      setUpdatedAttributes(
        getUpdatedAttributes({
          attributes: updatedAttributes,
          tagType: selectedTag,
          entityId,
        })
      );
    }
  }, [selectedTag, entityId, previousSelectedTag, updatedAttributes]);

  return {
    updatedAttributes,
    setUpdatedAttributes,
  };
};
