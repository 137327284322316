import React, { useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import integrationTypes from "../../integrationTypes";
import FieldsMapping from "./sub-components/FieldsMapping";
import FieldsMapJsonUploader from "./sub-components/FieldsMapJsonUploader";

const CreateImport = ({
  workspace,
  datasource,
  tenantId,
  getTokenSilently,
  onCreateImport,
  createImportLoading,
  defaultImportFields,
  updateDatasource,
  isSavingDatasource,
  savingDatasourceError,
  currentFieldsMap,
  selectedFieldsMap,
  updateSelectedFieldsMap,
}) => {
  const integrationType = get(integrationTypes, datasource.type, {});
  const CreateImportComponent = get(integrationType, "uiFlow.createImport");
  const CustomFieldMapEditor = get(integrationType, "uiFlow.fieldMapsEditor");
  const [targetFile, setTargetFile] = useState(null);
  const [targetFileContent, setTargetFileContent] = useState(null);
  const [isNewMapping, setIsNewMapping] = useState(false);

  if (!CreateImportComponent) {
    return null;
  }

  const onFileLoaded = (file, fileContent) => {
    setTargetFile(file);
    setTargetFileContent(fileContent);
  };

  const { withFieldsMapEditor, withJsonFieldsMapUploader, importFlags } =
    integrationType;

  const FieldMapEditorComponent = CustomFieldMapEditor || FieldsMapping;

  const fieldsMapEditor = withFieldsMapEditor && (
    <FieldMapEditorComponent
      datasource={datasource}
      file={targetFile}
      fileContent={targetFileContent}
      defaultImportFields={defaultImportFields}
      updateDatasource={updateDatasource}
      isSavingDatasource={isSavingDatasource}
      savingDatasourceError={savingDatasourceError}
      setIsNewMapping={setIsNewMapping}
      importFlags={importFlags}
    />
  );

  const fieldsMapUploader = withJsonFieldsMapUploader && (
    <FieldsMapJsonUploader
      workspace={workspace}
      datasource={datasource}
      updateDatasource={updateDatasource}
      integrationType={integrationType}
      currentFieldsMap={currentFieldsMap}
      selectedFieldsMap={selectedFieldsMap}
      updateSelectedFieldsMap={updateSelectedFieldsMap}
    />
  );

  return (
    <CreateImportComponent
      workspace={workspace}
      datasource={datasource}
      integrationType={integrationType}
      tenantId={tenantId}
      getTokenSilently={getTokenSilently}
      onCreateImport={onCreateImport}
      createImportLoading={createImportLoading}
      onFileLoaded={withFieldsMapEditor ? onFileLoaded : undefined}
      isNewMapping={isNewMapping}
      fieldsMapEditor={fieldsMapEditor}
      fieldsMapUploader={fieldsMapUploader}
    />
  );
};

CreateImport.propTypes = {
  workspace: PropTypes.object,
  tenantId: PropTypes.string,
  getTokenSilently: PropTypes.func,
  datasource: PropTypes.object,
  onCreateImport: PropTypes.func,
  defaultImportFields: PropTypes.object,
  updateDatasource: PropTypes.func,
  isSavingDatasource: PropTypes.bool,
  savingDatasourceError: PropTypes.object,
  currentFieldsMap: PropTypes.object,
  selectedFieldsMap: PropTypes.object,
  updateSelectedFieldsMap: PropTypes.func,
  createImportLoading: PropTypes.bool,
};

export default CreateImport;
