import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Flex } from "orcs-design-system";
import { isEqual } from "lodash";

import { isReadOnly } from "src/components/OrgChart/context/utils/getCalculatedState";
import { PanelSection } from "../../shared/PanelSection";
import { useUpdateTeamAttributes } from "./hooks/useUpdateTeamAttributes";
import { Header } from "./sections/Header";
import { Description } from "./sections/Description";
import { Links } from "./sections/Links";
import { Tags } from "./sections/Tags";

export const TeamDetails = ({ selectedTeam, refreshTeams }) => {
  const [selectedTeamId, setSelectedTeamId] = useState(selectedTeam.id);
  // whether a secon is being edited
  const [isEditingDesc, setIsEditingDesc] = useState(false);
  const [isEditingLinks, setIsEditingLinks] = useState(false);
  // holds new attrs from currently editing section
  const [newAttributes, setNewAttributes] = useState(null);
  // whether we are waiting for a data refresh after saving
  const [isSaving, setIsSaving] = useState(false);

  // how we update team attributes
  const { updateTeamAttributes, canUpdateGroupAttributes: canEdit } =
    useUpdateTeamAttributes({
      team: selectedTeam,
    });

  const resetState = useCallback(async () => {
    setIsEditingDesc(false);
    setIsEditingLinks(false);
    setNewAttributes(null);
  }, []);

  // reset state on team change
  useEffect(() => {
    if (selectedTeamId !== selectedTeam.id) {
      resetState();
      setSelectedTeamId(selectedTeam.id);
    }
  }, [resetState, selectedTeam.id, selectedTeamId]);

  const onSave = useCallback(async () => {
    resetState();

    // update attributes
    if (!isEqual(newAttributes, selectedTeam.attributes)) {
      // KNOWN ISSUE: if user quickly changes selected team while saving, you can get a
      // "Maximum update depth exceeded." error. This is not a big deal, but fixing it will
      // require adjusting orcs/FloatingPanels to allow for preventing new selections.
      setIsSaving(true);
      await updateTeamAttributes(newAttributes);
      await refreshTeams?.();
      setIsSaving(false);
    }
  }, [
    resetState,
    newAttributes,
    selectedTeam.attributes,
    updateTeamAttributes,
    refreshTeams,
  ]);

  const readOnly = isReadOnly(selectedTeam);

  return (
    <Flex flexDirection="column" gap="r">
      <Header team={selectedTeam} />
      <PanelSection
        name="DESCRIPTION"
        canEdit={canEdit && !readOnly}
        isEditing={isEditingDesc}
        setEditing={setIsEditingDesc}
        isSaving={isSaving}
        onSave={onSave}
        onEdit={resetState}
      />
      <Description
        team={selectedTeam}
        isEditing={isEditingDesc}
        setNewAttributes={setNewAttributes}
      />
      <PanelSection
        name="LINKS"
        canEdit={canEdit && !readOnly}
        isEditing={isEditingLinks}
        setEditing={setIsEditingLinks}
        isSaving={isSaving}
        onSave={onSave}
        onEdit={resetState}
      />
      <Links
        team={selectedTeam}
        isEditing={isEditingLinks}
        setNewAttributes={setNewAttributes}
      />
      <PanelSection name="TAGS" />
      <Tags team={selectedTeam} readOnly={readOnly} />
    </Flex>
  );
};

TeamDetails.propTypes = {
  selectedTeam: PropTypes.object.isRequired,
  refreshTeams: PropTypes.func.isRequired,
};
