import { map } from "lodash";
import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";

import CollapsedList from "src/components/CollapsedList";
import CollapseBtn from "src/components/CollapsedList/CollapseButton";
import ExpandButton from "src/components/CollapsedList/ExpandButton";
import FluidGrid from "src/components/FluidGrid/";
import AvatarPlaceholder from "src/components/Placeholder/AvatarPlaceholder";
import { Circle } from "src/components/Placeholder/PlaceholderStyles";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import useShortList from "src/hooks/useShortList";
import RelatedPerson from "src/pages/PersonDetailPage/sub-components/PersonWorksWithPanel/sub-components/RelatedPerson";
import { getPeoplePath } from "src/util/paths";
import { getInitials, sortMembersByName } from "src/util/person";

const FullList = ({
  loading,
  peopleList,
  groupTypes,
  cardView,
  showLocalTime = false,
  user,
  userPerson,
  featureFlags,
  workspace,
  allocationProject,
}) => {
  if (loading) {
    return <AvatarPlaceholder rows={6} />;
  }
  const peopleLength = peopleList.length - 1;

  return (
    <>
      {map(peopleList, (workmate, index) => {
        return (
          <RelatedPerson
            data-testid="cp-person-workswithinternal-panel"
            key={`unique-workmate-${index}`}
            person={workmate}
            showLocalTime={showLocalTime}
            groupTypes={groupTypes}
            isLastItem={index === peopleLength}
            cardView={cardView}
            user={user}
            userPerson={userPerson}
            featureFlags={featureFlags}
            workspace={workspace}
            allocationProject={allocationProject}
          />
        );
      })}
    </>
  );
};

FullList.propTypes = {
  loading: PropTypes.bool,
  cardView: PropTypes.bool,
  peopleList: PropTypes.array,
  groupTypes: GroupTypesPropType,
  showLocalTime: PropTypes.bool,
  user: PropTypes.object,
  featureFlags: PropTypes.object,
  allocationProject: PropTypes.object,
  userPerson: PropTypes.object,
  workspace: PropTypes.object,
};

const MembersList = ({
  loading,
  peopleList,
  groupTypes,
  cardView,
  testListLength = 0,
  person = null,
  showLocalTime = false,
  user,
  userPerson,
  featureFlags,
  workspace,
  allocationProject,
}) => {
  const [fullListMode, setFullListMode] = useState(false);
  const [listLength, containerRef] = useShortList(fullListMode, testListLength);

  const toggleList = useCallback(() => {
    setFullListMode((mode) => !mode);
  }, []);

  let list = null;

  if (!fullListMode && listLength > 0) {
    list = (
      <CollapsedList
        loading={loading}
        sortItems={sortMembersByName}
        getItemInitials={getInitials}
        getLinkPath={getPeoplePath}
        items={peopleList}
        person={person}
        showLocalTime={showLocalTime}
        listLength={listLength}
        onListClick={toggleList}
        placeholder={
          <Circle
            width={48}
            height={48}
            data-testid="short-works-with-list-placeholder"
          />
        }
      />
    );
  } else {
    list = (
      <FullList
        person={person}
        showLocalTime={showLocalTime}
        loading={loading}
        peopleList={peopleList}
        groupTypes={groupTypes}
        cardView={cardView}
        user={user}
        userPerson={userPerson}
        featureFlags={featureFlags}
        workspace={workspace}
        allocationProject={allocationProject}
      />
    );
    if (cardView) {
      list = <FluidGrid>{list}</FluidGrid>;
    }
  }

  return (
    <div ref={containerRef}>
      {fullListMode && listLength > 0 && <CollapseBtn onClick={toggleList} />}
      {list}
      {!fullListMode && listLength > 0 && <ExpandButton onClick={toggleList} />}
    </div>
  );
};

MembersList.propTypes = {
  loading: PropTypes.bool,
  cardView: PropTypes.bool,
  peopleList: PropTypes.array,
  groupTypes: GroupTypesPropType,
  testListLength: PropTypes.number,
  person: PropTypes.object,
  showLocalTime: PropTypes.bool,
  user: PropTypes.object,
  featureFlags: PropTypes.object,
  allocationProject: PropTypes.object,
  userPerson: PropTypes.object,
  workspace: PropTypes.object,
};

export default MembersList;
