import React from "react";
import PropTypes from "prop-types";
import {
  Flex,
  Box,
  Spacer,
  TextInput,
  H5,
  P,
  StyledLink,
} from "orcs-design-system";
import moment from "moment";
import { url } from "src/consts/urlPaths";
import DownloadButton from "src/dataExport/components/CsvDownloadButton";
import { EVENT_LOCATIONS } from "src/consts/eventTracking";

const Heading = ({
  totalCount,
  formValues,
  onChange,
  latestImportDate,
  loading,
}) => {
  const labelText = formValues.peopleSearch
    ? `${totalCount} results for:`
    : `${totalCount} results`;
  const importLink = (
    <StyledLink
      bold
      to={url("/:workspaceId?/configuration/datasources/select")}
    >
      here
    </StyledLink>
  );

  return (
    <Box bg="white" p="r" boxBorder="default" borderRadius={2}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="baseline">
          {(!latestImportDate && !loading && (
            <H5>
              No data available to view. Click {importLink} to import data.
            </H5>
          )) ||
            (latestImportDate &&
              moment(latestImportDate) &&
              moment(latestImportDate).isValid() && (
                <Spacer mr="s">
                  <H5>
                    Viewing data as of {moment(latestImportDate).fromNow()}
                  </H5>
                  <P color="greyDark">
                    ({moment(latestImportDate).format("hh:mma DD/MM/YYYY")})
                  </P>
                </Spacer>
              ))}
        </Flex>
        <DownloadButton
          loading={loading}
          totalCount={totalCount}
          location={EVENT_LOCATIONS.PEOPLE_PAGE}
        />
      </Flex>
      <Box mt="s">
        <TextInput
          id="peopleSearch"
          data-testid="page-people-search"
          type="text"
          label={labelText}
          fullWidth
          floating
          iconRight={["far", "search"]}
          onChange={(e) =>
            onChange({ ...formValues, [e.target.id]: e.target.value })
          }
          value={formValues.peopleSearch}
          placeholder="All People"
          autoFocus
        />
      </Box>
    </Box>
  );
};

// TODO: Shape object
Heading.propTypes = {
  totalCount: PropTypes.number.isRequired,
  formValues: PropTypes.object.isRequired,
  latestImportDate: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Heading;
