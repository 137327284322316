import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Flex,
  H5,
  P,
  Spacer,
  StyledLink,
  Tag,
  TextInput,
} from "orcs-design-system";
import { map, orderBy } from "lodash";
import { useDebounce } from "use-lodash-debounce";
import moment from "moment";
import { numberToLocaleString } from "src/util/toLocaleString";
import { url } from "src/consts/urlPaths";
import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";

const TypeFilter = ({
  types,
  groupTypes,
  typeFilters = [],
  onTeamFilterClick,
}) => {
  if (types && types.length > 0) {
    return (
      <Flex
        data-testid="cp-teamlist-heading-filter"
        flexWrap="wrap"
        alignItems="center"
      >
        {map(
          orderBy(
            types,
            [
              (type) =>
                (groupTypes?.[type.key]?.name || type.key) === "ALL"
                  ? "0"
                  : "1",
              (type) =>
                (groupTypes?.[type.key]?.name || type.key || "").toLowerCase(),
            ],
            ["asc", "asc"]
          ),
          (type) => {
            const groupType = groupTypes?.[type.key];
            const name = groupType?.name || type.key;
            return (
              <Tag
                mr="4px"
                mb="4px"
                key={type.key}
                selected={typeFilters.includes(type.key)}
                onClick={() => onTeamFilterClick(type.key)}
              >
                {name} ({numberToLocaleString(type.count)})
              </Tag>
            );
          }
        )}
      </Flex>
    );
  }
  return null;
};

TypeFilter.propTypes = {
  types: PropTypes.array,
  groupTypes: PropTypes.object,
  typeFilters: PropTypes.array,
  onTeamFilterClick: PropTypes.func,
};

const Heading = ({
  totalCount,
  types,
  groupTypes,
  typeFilters = [],
  onChange,
  onTeamFilterClick,
  latestImportDate,
  loading,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  // hook to update search term event on debounce
  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_SEARCH_TIME);

  useEffect(() => {
    onChange(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]); // we only want search to run if the search term changes

  const onSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const labelText = `${totalCount} results`;

  const importDatalink = (
    <StyledLink
      bold
      to={url("/:workspaceId?/configuration/datasources/select")}
    >
      here
    </StyledLink>
  );

  return (
    <Box bg="white" p="r" boxBorder="default" borderRadius={2}>
      <Flex alignItems="baseline">
        {!latestImportDate && loading && <H5>&nbsp;</H5>}
        {!latestImportDate && !loading && (
          <H5>
            No data available to view. Click {importDatalink} to import data.
          </H5>
        )}
        {latestImportDate &&
          moment(latestImportDate) &&
          moment(latestImportDate).isValid() && (
            <Spacer mr="s">
              <H5>Viewing data as of {moment(latestImportDate).fromNow()}</H5>
              <P color="greyDark">
                ({moment(latestImportDate).format("hh:mma DD/MM/YYYY")})
              </P>
            </Spacer>
          )}
      </Flex>
      <Box my="s">
        <TextInput
          id="teamSearch"
          data-testid="page-team-search"
          type="text"
          label={labelText}
          fullWidth
          floating
          value={searchTerm}
          iconRight={["far", "search"]}
          onChange={onSearchInputChange}
          placeholder="All Teams"
          autoFocus
        />
      </Box>
      <TypeFilter
        types={types}
        groupTypes={groupTypes}
        typeFilters={typeFilters}
        onTeamFilterClick={onTeamFilterClick}
      />
    </Box>
  );
};

Heading.propTypes = {
  totalCount: PropTypes.number.isRequired,
  types: PropTypes.arrayOf(String),
  groupTypes: PropTypes.object,
  typeFilters: PropTypes.arrayOf(Object),
  onChange: PropTypes.func.isRequired,
  onTeamFilterClick: PropTypes.func.isRequired,
  latestImportDate: PropTypes.string,
  loading: PropTypes.bool,
};

export default Heading;
