import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Grid, GridItem, Small } from "orcs-design-system";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import themeGet from "@styled-system/theme-get";
import { map, times } from "lodash";
import { NODE_TYPES } from "src/components/NodeVisualizer/consts";
import { collectionNodeDataPropType } from "src/components/NodeVisualizer/Nodes/propTypes";
import {
  EDGE_COLOR,
  getVisibilityStyles,
  HIGHLIGHTED_ANIMATION,
  TargetHandle,
} from "../node.styled";
import PersonNode from "../PersonNode";
import GroupNode from "../GroupNode";
import CreatePersonNode from "../CreatePersonNode";
import { RoundIconButton } from "../RoundIconButton";

export const COLLECTION_NODE_DISPLAY_MODE = {
  SINGLE_COLUMN: "single-column",
  SUB_COLLECTIONS: "sub-collections",
};

const PositionedPlusButton = styled(RoundIconButton)`
  position: absolute;
  bottom: -15px;
  left: calc(50% - 10.5px);
`;

const StyledLabel = styled(Small)((props) => {
  const { $labelColor = EDGE_COLOR, $labelRects = {} } = props;
  const { height } = $labelRects;

  return css`
    position: absolute;
    z-index: 1;
    top: -${height / 2 + 5}px;
    background: white;
    color: ${$labelColor};
    text-align: center;
    max-width: 80%;
  `;
});

const StyledBox = styled(Box)((props) => {
  const { $borderStyle = `2px solid ${EDGE_COLOR}`, isSubCollection } = props;

  return css`
    border: ${$borderStyle};
    border-radius: calc(${themeGet("radii.2")} * 2) !important;
    ${getVisibilityStyles(props)}

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: ${isSubCollection ? "flex-start" : "center"};
    pointer-events: none;
  `;
});

const CREATE_NODE_TYPE_COMPONENTS = {
  [NODE_TYPES.CREATE_PERSON_NODE]: CreatePersonNode,
};

const CollectionNode = ({ data, isSubCollection, ...props }) => {
  const COLLECTION_TYPES = useMemo(
    () => ({
      [NODE_TYPES.PERSON_NODE]: PersonNode,
      [NODE_TYPES.GROUP_NODE]: GroupNode,
      [NODE_TYPES.COLLECTION_NODE]: CollectionNode,
    }),
    []
  );

  const {
    label,
    prefixItemCountToLabel,
    displayMode,
    creatableNodeTypes = [],
    contextForCreation = {},
  } = data;

  const items = useMemo(() => {
    // use loaded items if available
    if (data?.items?.length > 0) {
      return data.items;
    }

    // expecting items to load?
    if (data.itemCount > 0) {
      // show loading cards until they do
      return times(data.itemCount, (i) => ({
        id: `loading-item_${i}`,
        groupId: data.groupId,
        type: data.loadingType,
        data: { name: "Loading", isLoading: true },
      }));
    }

    // no items
    return [];
  }, [data?.items, data.itemCount, data?.groupId, data?.loadingType]);

  const [showCreateNodeType, setShowCreateNodeType] = useState();
  const [labelRects, setLabelRects] = useState();
  const [highlightedNodeId, setHighlightedNodeId] = useState();
  const ref = useRef();

  const [colCount, rowCount] = useMemo(() => {
    const itemCount = items?.length ?? 0;
    let cols = Math.ceil(Math.sqrt(itemCount));
    const rows = Math.ceil(itemCount / cols);

    if (cols > 6) {
      cols = Math.round(cols / 2);
    } else {
      cols = cols > 2 ? cols - 1 : cols;
    }

    if (
      [
        COLLECTION_NODE_DISPLAY_MODE.SINGLE_COLUMN,
        COLLECTION_NODE_DISPLAY_MODE.SUB_COLLECTIONS,
      ].includes(displayMode)
    ) {
      cols = 1;
    }

    // If there are more than 6 items, we want to have one less column. It just looks better.
    const finalCols = itemCount > 12 ? cols - 1 : cols;

    if (finalCols === 1 && isSubCollection) {
      return [finalCols + 1, rows];
    }

    return [finalCols, rows];
  }, [displayMode, items.length, isSubCollection]);

  useEffect(() => {
    if (highlightedNodeId) {
      const timeout = setTimeout(() => {
        setHighlightedNodeId(null);
      }, HIGHLIGHTED_ANIMATION.DURATION * HIGHLIGHTED_ANIMATION.REPETITIONS);

      return () => clearTimeout(timeout);
    }

    return () => null;
  });

  const nodeElements = useMemo(() => {
    if (items.length > 0) {
      return map(items, (item) => {
        const NodeComponent = COLLECTION_TYPES[item.type];

        return (
          <GridItem key={item.id} minWidth="fit-content">
            <NodeComponent
              className={item.id === highlightedNodeId && "highlighted"}
              data={item.data}
              overrideHidden
              collectionGroupId={data.groupId}
              pinnable
              isSubCollection={
                data.displayMode ===
                COLLECTION_NODE_DISPLAY_MODE.SUB_COLLECTIONS
              }
            />
          </GridItem>
        );
      });
    }

    return (
      <GridItem gridColumn="1 / -1">{label || "No items"} to display</GridItem>
    );
  }, [
    items,
    highlightedNodeId,
    label,
    data.groupId,
    COLLECTION_TYPES,
    data.displayMode,
  ]);

  const createNodeElement = useMemo(() => {
    return creatableNodeTypes.map((createableNodeType) => {
      const { type } = createableNodeType;
      const Component = CREATE_NODE_TYPE_COMPONENTS[type];

      return (
        showCreateNodeType === type && (
          <GridItem key={type} minWidth="fit-content">
            <Component
              contextForCreation={contextForCreation}
              items={items}
              onCreate={(nodeIdToHighlight) => {
                setShowCreateNodeType(null);
                setHighlightedNodeId(nodeIdToHighlight);
              }}
              onCancel={() => setShowCreateNodeType(null)}
            />
          </GridItem>
        )
      );
    });
  }, [creatableNodeTypes, contextForCreation, items, showCreateNodeType]);

  const plusOnClick = useCallback(
    () => setShowCreateNodeType(creatableNodeTypes[0].type),
    [creatableNodeTypes]
  );

  useEffect(() => {
    if (!ref.current) {
      return () => null;
    }
    const updateRectsAfterTimeout = (timeout = 500) => {
      setTimeout(() => {
        setLabelRects(
          (prevRects) => ref.current?.getBoundingClientRect?.() ?? prevRects
        );
      }, timeout);
    };

    const resizeObserver = new ResizeObserver(() => {
      // HACK: This ensures the label is positioned correctly after initial layout and size is properly calculated.
      [50, 250, 500, 1000].forEach((timeout) => {
        updateRectsAfterTimeout(timeout);
      });
    });

    resizeObserver.observe(ref.current);

    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <StyledBox
      {...props}
      padding="l"
      $hidden={data?.hidden}
      $labelRects={labelRects}
      $borderStyle={data.borderStyle}
      paddingBottom="xl"
      isSubCollection={isSubCollection}
    >
      {label && (
        <StyledLabel
          ref={ref}
          $labelRects={labelRects}
          px="s"
          className="label"
          $labelColor={data.labelColor}
          fontSize="1.4rem"
        >
          {prefixItemCountToLabel && <>{items.length} </>}
          {label}
        </StyledLabel>
      )}
      <TargetHandle $hidden type="target" position="top" />

      <Grid
        gridGap={
          data.displayMode === COLLECTION_NODE_DISPLAY_MODE.SUB_COLLECTIONS
            ? "xxl"
            : "l"
        }
        gridTemplateColumns={`repeat(${colCount}, 1fr)`}
        gridTemplateRows={`repeat(${rowCount}, auto, 1fr)`}
        columns={colCount}
        rows={rowCount}
      >
        {nodeElements}
        {createNodeElement}
      </Grid>

      {/* Left it open to create multiple node types in one collection  */}
      {!!creatableNodeTypes?.length && creatableNodeTypes[0]?.canCreate && (
        <PositionedPlusButton
          disabled={showCreateNodeType}
          onClick={plusOnClick}
          variant="primary"
          large
          icon={["fas", "plus"]}
        />
      )}
    </StyledBox>
  );
};

CollectionNode.propTypes = {
  data: collectionNodeDataPropType,
  isSubCollection: PropTypes.bool,
};

export default CollectionNode;
