import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Loading, P, Box } from "orcs-design-system";

import {
  deleteImport as deleteImportMutation,
  getDatasource as getDatasourceQuery,
} from "src/pages/Datasource/datasource.gql";
import Dialogue from "src/components/Dialogue";

const DeleteImportButton = ({ datasource, dataImport }) => {
  const [deleteImport, { loading: isDeleting }] = useMutation(
    deleteImportMutation,
    {
      refetchQueries: () => [
        {
          query: getDatasourceQuery,
          variables: { id: datasource.id },
        },
      ],
    }
  );

  const onDelete = async () => {
    await deleteImport({
      variables: {
        datasourceId: datasource.id,
        importId: dataImport.id,
        force: true,
      },
    });

    return true;
  };

  let dialogBody;

  if (isDeleting) {
    dialogBody = <Loading centered large />;
  } else {
    dialogBody = (
      <Box data-testid="confirm-dialog-body">
        <P mb="r" weight="bold">
          This will delete the import history from datasource.
        </P>
        <P>Do you wish to continue?</P>
      </Box>
    );
  }

  return (
    <Dialogue
      width="600px"
      height="300px"
      variant={isDeleting ? "disabled" : "danger"}
      buttonText="Delete"
      confirmAction={onDelete}
      confirmText="OK"
      cancelText="Cancel"
      small
      icon={["far", "trash-alt"]}
      iconLeft
      disabled={isDeleting}
      data-testid="dialog-btn"
    >
      {dialogBody}
    </Dialogue>
  );
};

DeleteImportButton.propTypes = {
  datasource: PropTypes.object.isRequired,
  dataImport: PropTypes.object.isRequired,
};

export default DeleteImportButton;
