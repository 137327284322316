import PropTypes from "prop-types";
import GroupPropType from "src/custom-prop-types/group";
import PersonPropType from "src/custom-prop-types/person";

export const objectiveNodeHeaderDataPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  depth: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  hierarchyParentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  nodeType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  typeCount: PropTypes.number.isRequired,
});

export const objectiveNodeDataPropType = PropTypes.shape({
  // required
  hasChildren: PropTypes.bool.isRequired,
  nodeType: PropTypes.string.isRequired,
  hierarchyDepth: PropTypes.number,
  hierarchyParentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  measureIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  teamIds: PropTypes.arrayOf(PropTypes.string).isRequired,

  // optional
  progress: PropTypes.number,
  datasource: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
  }),
  measures: PropTypes.arrayOf(PropTypes.object),
  teams: PropTypes.arrayOf(PropTypes.object),
  childTeams: PropTypes.arrayOf(PropTypes.object),
  avatarUrl: PropTypes.string,
  externalUrl: PropTypes.string,
  status: PropTypes.string,
  statusColor: PropTypes.string,
  hidden: PropTypes.bool,
});

export const collapsedNodesPropType = PropTypes.arrayOf(
  objectiveNodeDataPropType
);

export const moreObjectivesNodePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  hierarchyParentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  collapsedNodes: collapsedNodesPropType.isRequired,
});

export const contextForCreationPropType = PropTypes.shape({
  team: GroupPropType,
  filter: PropTypes.object,
  targetGroup: GroupPropType,
  filterGroup: GroupPropType,
});

export const collectionNodeItemsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.oneOfType([PersonPropType, GroupPropType]),
    type: PropTypes.string,
    position: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  })
);

export const collectionNodeDataPropType = PropTypes.shape({
  id: PropTypes.string,
  groupId: PropTypes.string,
  label: PropTypes.string,
  prefixItemCountOnLabel: PropTypes.bool,
  borderStyle: PropTypes.string,
  labelColor: PropTypes.string,
  items: collectionNodeItemsPropType,
  hidden: PropTypes.bool,
  displayMode: PropTypes.string,
  variant: PropTypes.string,
  creatableNodeTypes: PropTypes.arrayOf(PropTypes.string),
  contextForCreation: contextForCreationPropType,
});
