import React from "react";
import PropTypes from "prop-types";
import { isNil, isEmpty } from "lodash";
import moment from "moment";
import {
  P,
  H2,
  Spacer,
  Card,
  Icon,
  Flex,
  FlexItem,
  Checkbox,
  Popover,
  Text,
} from "orcs-design-system";

import Dialogue from "src/components/Dialogue";
import { DEFAULT_DATE_TIME_FORMAT } from "src/consts/global";

const ReportingStatus = ({ lastReportingTime, lastTransactionTime }) => {
  let color = "success";
  let message = "";
  if (isEmpty(lastReportingTime)) {
    color = "danger";
    message = "Reporting data has never been refreshed.";
  } else {
    const dataChangeTime = moment(lastTransactionTime);
    const diff = dataChangeTime.diff(moment(lastReportingTime), "seconds");
    const reportingDelayed = diff - 3600 > 5;

    const reportingTimeStr = moment(lastReportingTime)
      .local()
      .format(DEFAULT_DATE_TIME_FORMAT);

    const dataChangeTimeStr = moment(dataChangeTime)
      .local()
      .format(DEFAULT_DATE_TIME_FORMAT);

    if (reportingDelayed) {
      color = "warning";
      message = `Reporting delayed. Last reporting refreshed at ${reportingTimeStr}. Last transaction happened at ${dataChangeTimeStr}.`;
    } else {
      message = `Reporting up to date. Last reporting refreshed at ${reportingTimeStr}. Last transaction happened at ${dataChangeTimeStr}.`;
    }
  }

  return (
    <Popover
      data-testid="reporting-enabled-workspace-icon"
      direction="right"
      text={message}
      textAlign="left"
      width="270px"
      inlineBlock
    >
      <Icon icon={["fas", "poll"]} size="lg" color={color} />
    </Popover>
  );
};

ReportingStatus.propTypes = {
  lastReportingTime: PropTypes.string.isRequired,
  lastTransactionTime: PropTypes.string.isRequired,
};

const Workspace = ({
  workspace,
  selectedWorkspaceId,
  currentActiveWorkspace,
  onSelect,
  onUnSelect,
  onMarkAsActive,
  onDeleteWorkspace,
  onCloneWorkspace,
}) => {
  const {
    name,
    description,
    id,
    isReportingEnabled,
    lastReportingTime,
    lastTransactionTime,
  } = workspace;
  const isWorkspaceSelected = selectedWorkspaceId === id;
  const isUsingCurrentActiveWorkspace =
    !isNil(currentActiveWorkspace) && currentActiveWorkspace.id === id;
  const noWorkspaceSelected = isNil(selectedWorkspaceId);
  const isViewing =
    isWorkspaceSelected ||
    (isUsingCurrentActiveWorkspace && noWorkspaceSelected);

  const onSelectClick = () => {
    if (isWorkspaceSelected) {
      onUnSelect(workspace);
    } else {
      onSelect(workspace);
    }
  };

  const canDelete = !(isWorkspaceSelected || isUsingCurrentActiveWorkspace);

  const DeleteWorkspace = (
    <Dialogue
      width="480px"
      height="220px"
      variant={canDelete ? "danger" : "disabled"}
      buttonText="Delete"
      confirmAction={() => onDeleteWorkspace(workspace)}
      confirmText="Yes, delete workspace"
      confirmVariant="danger"
      confirmIcon={["far", "trash-alt"]}
      cancelText="Cancel"
      small
      icon={["far", "trash-alt"]}
      iconLeft
      disabled={!canDelete}
    >
      <P mb="r">
        This will remove <Text weight="bold">{workspace.name}</Text> and all
        it&apos;s data.
      </P>
      <P>Do you wish to continue?</P>
    </Dialogue>
  );

  // Quickly hide the clone workspace button in workspaces page
  // Use the dev tool to clone workspace.
  // This may be used in the future, so keep the code here for some time
  // eslint-disable-next-line no-constant-condition
  const CloneWorkspace = true ? null : (
    <Dialogue
      width="320px"
      height="220px"
      buttonText="Clone"
      confirmAction={() => onCloneWorkspace(workspace)}
      confirmText="OK"
      cancelText="Cancel"
      small
      icon={["far", "clone"]}
      iconLeft
    >
      <P mb="r" weight="bold">
        Not working now. - This will clone all data from this workspace to a new
        workspace.
      </P>
      <P>Do you wish to continue?</P>
    </Dialogue>
  );

  return (
    <Card
      alternate
      key={id}
      boxBorder="default"
      colour={isViewing && "success"}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        flexWrap="nowrap"
      >
        <Flex alignItems="center">
          <Icon icon={["far", "database"]} size="2x" color="greyLight" mr="r" />
          <FlexItem>
            <Flex alignItems="center">
              <Spacer mx="xs">
                <H2 sizing="small">{name}</H2>
                {isReportingEnabled && (
                  <ReportingStatus
                    lastReportingTime={lastReportingTime}
                    lastTransactionTime={lastTransactionTime}
                  />
                )}
              </Spacer>
            </Flex>
            <P color="greyDark">{description}</P>
          </FlexItem>
        </Flex>

        <Flex alignItems="center" flex="0 0 auto">
          <Spacer ml="r">
            <Checkbox
              data-testid={`${id}-select`}
              label="Select"
              colour="success"
              checked={isWorkspaceSelected}
              onChange={onSelectClick}
            />
            <Checkbox
              label="Active"
              colour="warning"
              checked={isUsingCurrentActiveWorkspace}
              disabled={isUsingCurrentActiveWorkspace}
              onChange={() => {
                onMarkAsActive(workspace);
              }}
            />
            {DeleteWorkspace}
            {CloneWorkspace}
          </Spacer>
        </Flex>
      </Flex>
    </Card>
  );
};

Workspace.propTypes = {
  selectedWorkspaceId: PropTypes.string,
  workspace: PropTypes.object,
  currentActiveWorkspace: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onUnSelect: PropTypes.func.isRequired,
  onMarkAsActive: PropTypes.func.isRequired,
  onDeleteWorkspace: PropTypes.func.isRequired,
  onCloneWorkspace: PropTypes.func.isRequired,
};

Workspace.propTypes = {
  selectedWorkspaceId: PropTypes.string,
  workspace: PropTypes.object,
  currentActiveWorkspace: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onUnSelect: PropTypes.func.isRequired,
  onMarkAsActive: PropTypes.func.isRequired,
  onDeleteWorkspace: PropTypes.func.isRequired,
  onCloneWorkspace: PropTypes.func.isRequired,
};

export default Workspace;
