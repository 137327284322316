import React from "react";
import { Box, Notification, P, StyledLink } from "orcs-design-system";

import urlPaths, { url } from "src/consts/urlPaths";

const DatasourceNotFound = () => {
  const link = (
    <StyledLink
      white
      bold
      href={url(urlPaths.WORKSPACE_CONFIGURATION, { tabType: "datasources" })}
    >
      here
    </StyledLink>
  );

  return (
    <Box p="l">
      <Notification
        top="20px"
        closable={false}
        colour="warning"
        icon={["far", "exclamation-triangle"]}
      >
        <P color="white">
          Sorry, this integration does not exist. Click {link} to list all
          integrations and select another.
        </P>
      </Notification>
    </Box>
  );
};

export default DatasourceNotFound;
