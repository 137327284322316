import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Modal, Spacer, Flex } from "orcs-design-system";

class Dialogue extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
    this.showDialogue = this.showDialogue.bind(this);
    this.closeDialogue = this.closeDialogue.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.triggerRef = React.createRef();
  }

  handleOk() {
    const { confirmAction, closeOnConfirm } = this.props;
    const confirmationResponse = confirmAction();

    if (closeOnConfirm) {
      this.closeDialogue();
    }

    const isPromise = confirmationResponse && confirmationResponse.then;
    if (isPromise) {
      confirmationResponse.then((result) => {
        if (result) {
          this.closeDialogue();
        }
      });
    } else if (confirmationResponse) {
      this.closeDialogue();
    }
  }

  handleCancel() {
    const { cancelAction } = this.props;
    if (cancelAction) {
      cancelAction();
    }
    this.closeDialogue();
  }

  showDialogue() {
    this.setState({
      visible: true,
    });
  }

  closeDialogue() {
    this.setState({
      visible: false,
    });
  }

  render() {
    const {
      children,
      buttonText,
      variant,
      width,
      height,
      icon,
      confirmText,
      confirmVariant,
      confirmIcon,
      cancelText,
      disabled,
      ...props
    } = this.props;

    const { visible } = this.state;

    const modalFooter = (
      <Flex>
        <Spacer mr="s">
          <Button
            iconLeft={Boolean(confirmIcon)}
            variant={confirmVariant}
            onClick={this.handleOk}
          >
            {confirmIcon ? <Icon icon={confirmIcon} /> : null}
            {confirmText}
          </Button>
          <Button variant="ghost" onClick={this.handleCancel}>
            {cancelText}
          </Button>
        </Spacer>
      </Flex>
    );

    const buttonVariant = disabled ? "disabled" : variant;

    return (
      <>
        <Button
          {...props}
          onClick={this.showDialogue}
          iconLeft={Boolean(icon)}
          variant={buttonVariant}
          ref={this.triggerRef}
        >
          {icon ? <Icon icon={icon} /> : null}
          {buttonText}
        </Button>
        <Modal
          visible={visible}
          width={width}
          height={height}
          handleOnConfirm={this.handleOk}
          onClose={this.handleCancel}
          footerContent={modalFooter}
          triggerRef={this.triggerRef}
        >
          {children}
        </Modal>
      </>
    );
  }
}

Dialogue.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  /** Specifies the width of the Dialogue in pixels */
  width: PropTypes.string,
  /** Specifies the height of the Dialogue in pixels */
  height: PropTypes.string,
  /** Specifies the text for the button that triggers the dialogue */
  buttonText: PropTypes.string,
  /** Specifies button disabled or not */
  disabled: PropTypes.bool,
  /** Specifies an icon for the button if required */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(String)]),
  /** Specifies the text to use for the confirm button. Recommend using words like OK, Confirm, Yes, Proceed, Add, Save. */
  confirmText: PropTypes.string,
  /** Specifies the variant for the confirm button */
  confirmVariant: PropTypes.string,
  /** Specifies the icon for the confirm button */
  confirmIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(String),
  ]),
  variant: PropTypes.oneOf([
    "success",
    "successAlternate",
    "danger",
    "dangerAlternate",
    "ghost",
    "disabled",
    "default",
  ]),
  /** Specifies the function to run on clicking confirm button. Function must return a truthy value or a promise that resolves to a truthy value in order to close the dialogue (see example code) */
  confirmAction: PropTypes.func,
  /** Specifies the text to use for the cancel button. Recommend using words like Cancel, Close, No. */
  cancelText: PropTypes.string,
  /** Specifies the function to run on clicking cancel button. (Note, dialogue is closed automatically) */
  cancelAction: PropTypes.func,
  /** Specifies the function to decide should show the dialogue or not, executing confirmAction immediately if falsy value returned */
  closeOnConfirm: PropTypes.bool,
  /** Specifies whether the dialog should be closed when confirm action triggered */
};

/** @component */
export default Dialogue;
