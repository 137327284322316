import { slice, map, isEmpty, get } from "lodash";
import { Flex, Button, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";

import { Tag } from "src/custom-prop-types/tag";
import TagBadge from "src/components/Badge/TagBadge";

import { TAG_OPERATIONS } from "src/consts/tags";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { shouldHighlightTag } from "../utils/shouldHighlightTag";
import { getCombinedSortedTags } from "./ExpandableBadgeList.utils";

// TODO: See if we can consolidate, refactor this with src/components/BadgeList/ExpandableBadgeList.js

const ToggleShowButton = ({ showAll, onClick }) => {
  const icon = showAll ? ["fas", "plus"] : ["fas", "minus"];
  const buttonText = showAll ? "Show all tags" : "Show less tags";
  return (
    <Button
      onClick={onClick}
      ariaLabel={buttonText}
      iconLeft
      mt="s"
      small
      variant="ghost"
    >
      <Icon icon={icon} /> {buttonText}
    </Button>
  );
};

ToggleShowButton.propTypes = {
  showAll: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ExpandableBadgeList = ({
  tags,
  defaultLimit = 8,
  initiallyCollapsed = true,
  onEntityTagClick,
  withAppliedCount,
  small,
  highlighted,
  inline = false,
  onClickTagInfo,
  entityType,
}) => {
  const workspace = useWorkspace();

  const enableAllTagsToBeVisible = get(
    workspace,
    "config.featureFlags.enableAllTagsToBeVisible"
  );
  const [isCollapsed, setCollapsed] = useState(
    enableAllTagsToBeVisible === true ? false : initiallyCollapsed
  );

  const onInfo = useCallback(
    (tag, config) => {
      onClickTagInfo(tag, config);
    },
    [onClickTagInfo]
  );

  if (!tags || !tags.length) {
    return null;
  }

  const shouldShowBtn = tags.length > defaultLimit;
  const sortedTags = getCombinedSortedTags(tags, entityType);

  const visibleTags = isCollapsed
    ? slice(sortedTags, 0, defaultLimit)
    : sortedTags;

  const onClick = () => {
    setCollapsed((currentState) => !currentState);
  };

  const onSelect = (tagValue) => () => {
    if (onEntityTagClick) {
      onEntityTagClick(tagValue);
    }
  };

  if (inline) {
    return map(visibleTags, (tag) => (
      <TagBadge
        mr="xs"
        mt="xs"
        data-testid="cp-exp-badge-item"
        key={
          tag.status === TAG_OPERATIONS.REMOVED ? `${tag.id}_removed` : tag.id
        }
        value={tag}
        withType={true}
        role="listitem"
        onSelect={onSelect(tag)}
        isRemoved={tag.status === TAG_OPERATIONS.REMOVED}
        selected={!!onEntityTagClick}
        disabled={!onEntityTagClick}
        withAppliedCount={withAppliedCount}
        small={small}
        highlighted={highlighted}
        showInfo={
          !tag?.tagTypeConfig?.hideShowInfoIconInTagDisplay &&
          !isEmpty(tag?.tagTypeConfig?.attributes)
        }
        onInfo={(e) => {
          e.stopPropagation();
          onInfo(tag, tag?.tagTypeConfig);
        }}
      />
    ));
  }

  return (
    <>
      <Flex gap="xs" flexWrap="wrap" role="list" aria-label="Tags">
        {map(visibleTags, (tag) => (
          <TagBadge
            data-testid="cp-exp-badge-item"
            key={
              tag.status === TAG_OPERATIONS.REMOVED
                ? `${tag.id}_removed`
                : tag.id
            }
            value={tag}
            withType={true}
            role="listitem"
            onSelect={onSelect(tag)}
            isRemoved={tag.status === TAG_OPERATIONS.REMOVED}
            selected={!!onEntityTagClick}
            disabled={!onEntityTagClick}
            withAppliedCount={withAppliedCount}
            small={small}
            highlighted={shouldHighlightTag({
              tag,
              entityType,
            })}
            showInfo={
              !tag?.tagTypeConfig?.hideShowInfoIconInTagDisplay &&
              !isEmpty(tag?.tagTypeConfig?.attributes)
            }
            onInfo={(e) => {
              e.stopPropagation();
              onInfo(tag, tag?.tagTypeConfig);
            }}
          />
        ))}
      </Flex>
      {shouldShowBtn && (
        <ToggleShowButton showAll={isCollapsed} onClick={onClick} />
      )}
    </>
  );
};

ExpandableBadgeList.propTypes = {
  tags: PropTypes.arrayOf(Tag),
  defaultLimit: PropTypes.number,
  initiallyCollapsed: PropTypes.bool,
  onEntityTagClick: PropTypes.func,
  withAppliedCount: PropTypes.bool,
  small: PropTypes.bool,
  inline: PropTypes.bool,
  highlighted: PropTypes.bool,
  onClickTagInfo: PropTypes.func,
  entityType: PropTypes.string,
};

// todo: Copied the memo thing from an earlier componet that wrapped this - is it helpign us?
export default React.memo(ExpandableBadgeList);
