import React, { useCallback, useMemo } from "react";
import { Box } from "orcs-design-system";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useNodes } from "reactflow";
import { TargetHandle, SourceHandle, StyledCard } from "../node.styled";
import {
  DEFAULT_COLLAPSE_COUNT,
  useObjectiveState,
} from "../../contexts/ObjectiveProviderContext";
import { objectiveNodeDataPropType } from "../propTypes";
import ObjectiveHeaderNode from "../ObjectiveHeaderNode";
import NodeTitleSection from "./NodeTitleSection";
import NodeExpandables from "./NodeExpandables";

const StyledBox = styled(Box)`
  padding-right: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ObjectiveNode = ({ data, id }) => {
  const nodeContainerId = `node-container-${id}`;
  const nodeCardId = `node-card-${id}`;
  const { setHoveredObjective, onPinObjective, pinnedObjectives } =
    useObjectiveState();

  // Note: using this hook means that this/all nodes will rerender anytime any other node changes (inc when moved)
  const nodes = useNodes();

  const nodeHeader = useMemo(() => {
    return nodes.find(
      (n) => n.depth === data.hierarchyDepth && n.position.y === 0
    );
  }, [nodes, data.hierarchyDepth]);

  const bottomNodeInColumn = useMemo(() => {
    return nodes
      .filter((n) => n.depth === data.hierarchyDepth)
      .sort((a, b) => a.position.y - b.position.y)
      .pop();
  }, [nodes, data.hierarchyDepth]);

  const isPinned = useMemo(() => {
    return pinnedObjectives?.find(
      (pinnedObjective) => pinnedObjective.id === data.id
    );
  }, [pinnedObjectives, data.id]);

  const onMouseEnter = useCallback(() => {
    setHoveredObjective(data);
  }, [setHoveredObjective, data]);

  const onMouseLeave = useCallback(() => {
    setHoveredObjective(null);
  }, [setHoveredObjective]);

  const onClick = useCallback(() => {
    onPinObjective(data);
  }, [onPinObjective, data]);

  return (
    <StyledBox id={nodeContainerId}>
      <StyledCard
        id={nodeCardId}
        nodeId={id}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        active={isPinned}
      >
        {data.hierarchyParentIds?.length > 0 && (
          <TargetHandle
            id="left-handle"
            type="target"
            position="left"
            isConnectable={false}
            $yPosition="40px"
            className="triangle"
          />
        )}

        <NodeTitleSection data={data} />

        {data.hasChildren && (
          <SourceHandle
            type="source"
            position="right"
            isConnectable={false}
            $yPosition="40px"
          />
        )}

        <NodeExpandables
          nodeId={id}
          nodeCardId={nodeCardId}
          nodeContainerId={nodeContainerId}
          data={data}
        />
      </StyledCard>

      {bottomNodeInColumn?.id === id &&
        nodeHeader?.data?.typeCount >= DEFAULT_COLLAPSE_COUNT && (
          <Box mt="200px">
            <ObjectiveHeaderNode data={nodeHeader?.data} isFooter />
          </Box>
        )}
    </StyledBox>
  );
};

ObjectiveNode.propTypes = {
  id: PropTypes.string,
  data: objectiveNodeDataPropType,
};

export default ObjectiveNode;
