import { debounce } from "lodash";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import useOnclickOutside from "react-cool-onclickoutside";
import styled from "styled-components";
import { Loading, Box, TextInput, Flex } from "orcs-design-system";

import GroupsNavigation from "src/components/GroupsNavigation/GroupsNavigation";
import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";
import useLoadGroupsTree from "./useLoadGroupsTree";
import useSearchGroups from "./useSearchGroups";
import CustomTreeHeader from "./CustomTreeHeader";
import { getTypesWithParents } from "./index.logic";
import { useGroupsSearchContext } from "./GroupsSearchContext";

const SearchBoxWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const TreeWrapper = styled(Box)`
  position: absolute;
  top: 3rem;
  left: 0;
  width: 100%;
  min-height: 20rem;
  max-height: 30rem;
  overflow: hidden;
  overflow-y: auto;
  z-index: 20;
`;

const GroupsSearch = ({
  existingGroupIds,
  actionLabel,
  groupTypes,
  targetGroupTypes,
  value = "",
  placeholder = "Search",
  onGroupSearch,
  onGroupSelected,
  loadChildNodes,
  withIndependentTreeView = false,
  customizeTreeHeader: customizeTreeHeaderOverride,
  createTreeNodeImpl,
  convertChildrenToArrayImpl,
  addGroupsToRootsImpl,
  shouldIgnoreOnToggle,
  hierarchyIds = [],
  rootGroupVariables = {},
}) => {
  const { additionalSearchVariables = {} } = useGroupsSearchContext();

  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState(value);

  // Add parent group types into the targets, so that we can display the full tree
  // To handle cases like parent is hybrid but has child in targetGroupTypes
  const fullTargetTypes = useMemo(() => {
    return getTypesWithParents(targetGroupTypes, groupTypes);
  }, [targetGroupTypes, groupTypes]);

  const additionalVariables = useMemo(
    () => ({ ...additionalSearchVariables, ...rootGroupVariables }),
    [additionalSearchVariables, rootGroupVariables]
  );

  const { searchGroups, filteredGroups, searchInProgress, clearSearch } =
    useSearchGroups({
      targetGroupTypes,
      additionalVariables,
    });

  const { rootGroupsLoading, ...othersTreeProps } = useLoadGroupsTree({
    filteredGroups,
    targetGroupTypes: fullTargetTypes,
    additionalVariables,
    createTreeNodeImpl,
    convertChildrenToArrayImpl,
    addGroupsToRootsImpl,
  });

  const doSearch = useMemo(
    () =>
      debounce((term) => {
        searchGroups(term);
      }, DEBOUNCE_SEARCH_TIME),
    [searchGroups]
  );

  useEffect(() => {
    if (onGroupSearch) {
      onGroupSearch(isSearching);
    }
  }, [isSearching, onGroupSearch]);

  const stopSearch = useCallback(() => {
    setIsSearching(false);
    setSearchTerm("");
    clearSearch();
  }, [clearSearch]);

  const wrapperRef = useOnclickOutside(stopSearch, {
    disabled: !isSearching,
  });

  const onSearchChange = (e) => {
    const term = e.target.value;

    setSearchTerm(term);
    doSearch(term);
  };

  const onFocused = () => {
    setIsSearching(true);
  };

  const handleGroupSelected = useCallback(
    (group) => {
      onGroupSelected(group);
      setIsSearching(false);

      // This is to show the selected group in the search box, but does cause
      // and extra search query which will in turn limit the list to chosen group.
      // May need a searchTerm and a displayTerm to handle this better.
      setSearchTerm(group.name);
    },
    [onGroupSelected, setIsSearching]
  );

  // callback for custom tree headers
  const customizeTreeHeader = useCallback(
    (props) =>
      // eslint-disable-next-line react/prop-types
      ({ node }) => {
        return (
          <CustomTreeHeader
            {...props}
            node={node}
            targetGroupTypes={targetGroupTypes}
            existingGroupIds={existingGroupIds}
            onSelectGroup={handleGroupSelected}
            actionLabel={actionLabel}
          />
        );
      },
    [handleGroupSelected, targetGroupTypes, existingGroupIds, actionLabel]
  );

  let groupsNav = null;
  if (withIndependentTreeView || isSearching) {
    groupsNav = (
      <GroupsNavigation
        {...othersTreeProps}
        groupTypes={groupTypes}
        targetGroupTypes={fullTargetTypes}
        customizeTreeHeader={customizeTreeHeaderOverride || customizeTreeHeader}
        loadChildNodes={loadChildNodes}
        createTreeNodeImpl={createTreeNodeImpl}
        convertChildrenToArrayImpl={convertChildrenToArrayImpl}
        shouldIgnoreOnToggle={shouldIgnoreOnToggle}
        hierarchyIds={hierarchyIds}
        addGroupsToRootsImpl={addGroupsToRootsImpl}
        additionalSearchVariables={additionalSearchVariables}
      />
    );
  }

  return (
    <SearchBoxWrapper ref={wrapperRef}>
      <Flex>
        <TextInput
          id="groups-search"
          data-testid="groups-search"
          height="38px"
          type="text"
          placeholder={placeholder}
          fullWidth
          iconRight={["far", "search"]}
          value={searchTerm}
          onChange={onSearchChange}
          onFocus={onFocused}
          autoComplete="off"
        />
        {(rootGroupsLoading || searchInProgress) && <Loading ml="r" />}
      </Flex>
      {withIndependentTreeView && (
        <Box mt="r">
          {rootGroupsLoading ? (
            <Flex justifyContent="center">
              <Loading large />
            </Flex>
          ) : (
            groupsNav
          )}
        </Box>
      )}
      {!withIndependentTreeView && isSearching && (
        <TreeWrapper
          borderRadius={2}
          bg="white"
          boxBorder="default"
          shadow="default"
          mt="r"
          p="r"
        >
          {groupsNav}
        </TreeWrapper>
      )}
    </SearchBoxWrapper>
  );
};

GroupsSearch.propTypes = {
  existingGroupIds: PropTypes.array,
  actionLabel: PropTypes.string,
  groupTypes: PropTypes.object,
  targetGroupTypes: PropTypes.object,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onGroupSearch: PropTypes.func,
  onGroupSelected: PropTypes.func,
  withIndependentTreeView: PropTypes.bool,
  customizeTreeHeader: PropTypes.func,
  loadChildNodes: PropTypes.func,
  createTreeNodeImpl: PropTypes.func,
  convertChildrenToArrayImpl: PropTypes.func,
  addGroupsToRootsImpl: PropTypes.func,
  shouldIgnoreOnToggle: PropTypes.func,
  hierarchyIds: PropTypes.array,
  rootGroupVariables: PropTypes.object,
};

export default React.memo(GroupsSearch);
