import React from "react";
import PropTypes from "prop-types";
import { Flex } from "orcs-design-system";
import { copywriting } from "src/pages/PersonDetailPage/PersonDetailPage.config";

import { PanelSection } from "../../shared/PanelSection";
import { Header } from "./sections/Header";
import { Details } from "./sections/Details";
import { Reports } from "./sections/Reports";
import { Tags } from "./sections/Tags";

export const PersonDetails = ({ selectedPerson }) => {
  return (
    <Flex flexDirection="column" gap="r">
      <Header person={selectedPerson} />
      <PanelSection name={copywriting.DETAILS} />
      <Details person={selectedPerson} />
      <PanelSection name={copywriting.TAGS} />
      <Tags person={selectedPerson} />
      <PanelSection name={copywriting.REPORTS_TO} />
      <Reports person={selectedPerson} />
    </Flex>
  );
};

PersonDetails.propTypes = {
  selectedPerson: PropTypes.object,
};
