import { get, size } from "lodash";
import { Spacer, Box, Divider } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";

import RichTextEditor from "src/components/RichTextEditor";
import { GroupAttribute } from "src/custom-prop-types/groupAttribute";
import { attributeTypes } from "src/util/group";
import { reportError } from "src/services/errorReporting";
import { isValidUrl } from "src/util/url";

import EditableInputRows from "./sub-components/EditableInputRows";
import TeamEditFooter from "./sub-components/TeamEditFooter";
import {
  changeTypes,
  copywriting,
  formFieldsLabel,
  linksLabelAndPlaceholder,
} from "./TeamEdit.config";
import * as teamEditUtil from "./TeamEdit.util";

const TeamEdit = ({
  attributes,
  defaultDescription,
  onHandleTeamUpdate,
  onToggleEditMode,
}) => {
  const [formAttributes, setFormAttributes] = useState({});
  const editorRef = useRef();

  useEffect(() => {
    // Set initial values from attributes
    const initialFormValues = teamEditUtil.getInitialFormAttributes(attributes);
    setFormAttributes(initialFormValues);

    return () => {
      // Reset attributes when unload
      setFormAttributes({});
    };
  }, [attributes]);

  useEffect(() => {
    if (editorRef.current) {
      setTimeout(() => {
        if (editorRef.current) {
          editorRef.current.focus();
        }
      }, 150);
    }
  }, []);

  const [onSaveError, setOnSaveError] = useState();
  const [error, setError] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const clearError = (attributeFormId) => {
    const { [attributeFormId]: currentError, ...remainingErrors } = error;
    setError(remainingErrors);
    if (Object.keys(remainingErrors).length === 0) {
      setOnSaveError(null);
    }
  };

  const validateField = (attributeFormId) => (event) => {
    const value = get(event, "target.value");

    if (!isValidUrl(value)) {
      setError({
        ...error,
        [attributeFormId]: copywriting.FIELD_URL_ERROR,
      });
    } else {
      clearError(attributeFormId);
    }
  };

  const hasError = (attributeFormId) => {
    return Boolean(error[attributeFormId]);
  };

  const onFormValueChange =
    (attributeFormId, changeType, attributeType = attributeTypes.LINK) =>
    (event) => {
      const isDescription = attributeType === attributeTypes.DESCRIPTION;
      // rich text editor has a different onChange contract
      const changeValue = isDescription ? event : get(event, "target.value");
      const newFormAttribute = teamEditUtil.applyFormAttributeChange({
        formAttributes,
        attributeFormId,
        attributeType,
        changeType,
        changeValue,
      });
      if (changeType === changeTypes.DELETE) {
        clearError(attributeFormId);
      }
      setFormAttributes({
        ...formAttributes,
        [attributeFormId]: newFormAttribute,
      });
    };

  const onConfirm = async () => {
    if (size(error)) {
      setOnSaveError(copywriting.FORM_VALIDATION_ERROR);
    } else {
      setOnSaveError(null);
      setIsSaving(true);

      try {
        const newAttributes = teamEditUtil.getAttributes(formAttributes);
        await onHandleTeamUpdate(newAttributes);
        onToggleEditMode(false);
      } catch (mutationError) {
        reportError(mutationError);
        setOnSaveError(copywriting.FORM_SAVE_ERROR);
      }
      setIsSaving(false);
    }
  };

  const onCancel = async () => {
    setError({});
    setOnSaveError(null);
    onToggleEditMode(false);
    setIsSaving(false);
  };

  return (
    <Box>
      <Spacer mb="r">
        <RichTextEditor
          defaultValue={defaultDescription}
          height={200}
          placeholder={formFieldsLabel.DESCRIPTION}
          onTextChange={onFormValueChange(
            attributeTypes.DESCRIPTION,
            changeTypes.VALUE,
            attributeTypes.DESCRIPTION
          )}
          isWithMentions={true}
          ref={editorRef}
        />
        <EditableInputRows
          formAttributes={teamEditUtil.getLinksFormAttributes(formAttributes)}
          attributeType={attributeTypes.LINK}
          onChange={onFormValueChange}
          title={formFieldsLabel.LINK}
          placeholder={linksLabelAndPlaceholder}
          hasError={hasError}
          validateField={validateField}
        />
      </Spacer>
      <TeamEditFooter
        onConfirm={onConfirm}
        onCancel={onCancel}
        isSaving={isSaving}
        onSaveError={onSaveError}
      />
      <Divider mt="r" mb="s" />
    </Box>
  );
};

TeamEdit.propTypes = {
  attributes: PropTypes.arrayOf(GroupAttribute),
  defaultDescription: PropTypes.object,
  onHandleTeamUpdate: PropTypes.func,
  onToggleEditMode: PropTypes.func,
};

export default React.memo(TeamEdit);
