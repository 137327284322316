import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Loading, P, Box, Button, Icon, Spacer } from "orcs-design-system";

import {
  revertImport as revertImportMutation,
  getDatasource as getDatasourceQuery,
} from "src/pages/Datasource/datasource.gql";
import Dialogue from "src/components/Dialogue";

const RevertImportButton = ({
  buttonText,
  datasource,
  dataImport,
  confirmationRequired,
}) => {
  const [revertImport, { loading: isReverting }] = useMutation(
    revertImportMutation,
    {
      refetchQueries: () => [
        {
          query: getDatasourceQuery,
          variables: { id: datasource.id },
        },
      ],
    }
  );

  const onRevert = async () => {
    await revertImport({
      variables: {
        datasourceId: datasource.id,
        importId: dataImport.id,
      },
    });

    return true;
  };

  let dialogBody;

  if (isReverting) {
    dialogBody = <Loading centered large />;
  } else {
    dialogBody = (
      <Box data-testid="confirm-dialog-body">
        <P mb="r" weight="bold">
          Continuing with this action will revert this import to the previous
          state.
        </P>
        <P mb="r">
          This will revert changes made through the import unless imported tag
          information has been applied or modified. See documentation for more
          details.
        </P>
        <P mb="r">Are you sure you&apos;d like to do this?</P>
      </Box>
    );
  }

  if (confirmationRequired) {
    return (
      <Dialogue
        width="600px"
        height="300px"
        variant={isReverting ? "disabled" : "danger"}
        buttonText={buttonText}
        confirmAction={onRevert}
        confirmText="OK"
        cancelText="Cancel"
        confirmVariant="danger"
        small
        icon={["fas", "exclamation-triangle"]}
        iconLeft
        disabled={isReverting}
        data-testid="dialog-btn"
      >
        {dialogBody}
      </Dialogue>
    );
  }
  return (
    <Spacer my="3">
      <Button
        variant={isReverting ? "disabled" : "warning"}
        disabled={isReverting}
        data-testid="dialog-btn"
        onClick={onRevert}
      >
        <Icon icon={["fas", "exclamation-triangle"]} />
        {buttonText}
      </Button>
    </Spacer>
  );
};

RevertImportButton.propTypes = {
  confirmationRequired: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  datasource: PropTypes.object.isRequired,
  dataImport: PropTypes.object.isRequired,
};

export default RevertImportButton;
