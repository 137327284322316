import React from "react";
import { Box, Flex, Icon, StyledLink, Text } from "orcs-design-system";
import themeGet from "@styled-system/theme-get";
import styled, { css } from "styled-components";
import { teamDetailsPath } from "src/util/routing";
import GroupHierarchyBreadcrumb from "src/components/GroupHierarchyBreadcrumb";

const StyledStyledLink = styled(StyledLink)((props) => {
  return css`
    color: ${themeGet(`colors.${props.color}`)};
    &:visited {
      color: ${themeGet(`colors.${props.color}`)};
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
});

const StyledText = styled(Text)(() => {
  // "pre" prevents wrapping without stripping trailing whitespace
  return css`
    white-space: pre;
  `;
});

const getMembersCollectionLabel = ({
  group,
  groupType,
  itemCount,
  variant,
  isGroupedBySupplyOrDemand,
}) => {
  // results in "x Unallocated"
  if (group.id === "unallocated") {
    return <span>{itemCount} Unallocated</span>;
  }

  /**
   * results in one of:
   *    "x from SupplyTeamName 𓁹"
   *    "x in DemandTeamName 𓁹"
   *
   * or a truncated version for long team names:
   *    "x from LongSupplyTeamNa... 𓁹"
   *    "x in LongDemandTeamNa... 𓁹"
   *
   * and with a team-name popover on hover
   */
  if (isGroupedBySupplyOrDemand) {
    const fromSupplyOrInDemand = groupType?.isSupply ? "from" : "in";
    const teamUrl = teamDetailsPath(group.id);

    return (
      <Flex maxWidth="300px" justifyContent="center">
        <Box style={{ cursor: "pointer" }}>
          <GroupHierarchyBreadcrumb
            group={group}
            showFullHierarchyInBreadcrumb={true}
            useChildren={true}
          >
            <Icon icon={["far", "layer-group"]} />
          </GroupHierarchyBreadcrumb>
        </Box>
        <StyledText>
          <span>
            {" "}
            {itemCount} {fromSupplyOrInDemand}{" "}
          </span>
        </StyledText>
        <StyledStyledLink to={teamUrl} color={variant}>
          {group.name}
        </StyledStyledLink>
        <StyledText>
          {" "}
          <Icon icon={["far", "eye"]} />
        </StyledText>
      </Flex>
    );
  }

  // defaults to "x Members"
  return <>{itemCount} Members</>;
};

export default getMembersCollectionLabel;
