import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Divider, Flex, H6 } from "orcs-design-system";
import { PanelIconButton } from "./PanelIconButton";

export const PanelSection = ({
  name,
  canEdit,
  isEditing,
  setEditing,
  isSaving,
  onSave,
  onEdit,
}) => {
  const onEditClick = useCallback(() => {
    onEdit?.();
    if (canEdit) {
      setEditing(true);
    }
  }, [canEdit, setEditing, onEdit]);

  const onCancelClick = useCallback(() => {
    setEditing(false);
  }, [setEditing]);

  const onSaveClick = useCallback(() => {
    setEditing(false);
    onSave();
  }, [onSave, setEditing]);

  const renderButtons = () => {
    if (!canEdit) {
      return null;
    }

    if (isSaving) {
      return <PanelIconButton type="loading" onClick={() => {}} />;
    }

    if (isEditing) {
      return (
        <Flex flexDirection="row" gap="s">
          <PanelIconButton type="approve" onClick={onSaveClick} />
          <PanelIconButton type="cancel" onClick={onCancelClick} />
        </Flex>
      );
    }

    return <PanelIconButton type="edit" onClick={onEditClick} />;
  };

  return (
    <>
      <Divider dash />
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <H6 weight="bold" color="greyDark">
          {name}
        </H6>
        {renderButtons()}
      </Flex>
    </>
  );
};

PanelSection.propTypes = {
  name: PropTypes.string.isRequired,
  canEdit: PropTypes.bool,
  isEditing: PropTypes.bool,
  setEditing: PropTypes.func,
  isSaving: PropTypes.bool,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
};
