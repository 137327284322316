import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Avatar } from "orcs-design-system";
import { fullDisplayName, displayInitials } from "../../util/personName";

const PersonHeading = ({ person }) => {
  return (
    <Avatar
      image={person.avatar}
      title={fullDisplayName(person)}
      subtitle={person.jobTitle}
      initials={displayInitials(person)}
    />
  );
};

PersonHeading.propTypes = {
  person: PropTypes.object.isRequired,
};

export default withRouter(PersonHeading);
