import React from "react";
import PropTypes from "prop-types";
import { Box, H4, Icon, Flex, FlexItem } from "orcs-design-system";
import moment from "moment";

import ImportWarnings from "./sub-components/ImportWarnings";
import ImportMetrics from "./sub-components/ImportMetrics";
import ImportTransactionDetails from "./sub-components/ImportTransactionDetails";
import RevertImportButton from "./sub-components/RevertImportButton";

const ImportPreviewChanges = ({
  datasource,
  dataImport,
  datasourceType,
  warnings,
}) => {
  const started = moment(dataImport.created);
  const completed = moment(dataImport.processed);
  const duration = moment.duration(completed.diff(started));
  const durationString = duration.format();
  const { metrics, id } = dataImport;

  return (
    <Box my="r">
      <Flex justifyContent="space-between" alignItems="center" my="r">
        <H4 color="success">Successfully completed task</H4>
        <FlexItem>
          <Icon icon={["far", "stopwatch"]} /> {durationString}
        </FlexItem>
      </Flex>
      <ImportMetrics metrics={metrics} datasourceType={datasourceType} />
      <ImportTransactionDetails importId={id} />
      {warnings && warnings.length > 0 && (
        <ImportWarnings
          warnings={warnings}
          headingText="Warnings &amp; result"
        />
      )}
      <RevertImportButton
        buttonText="Remove import preview"
        datasource={datasource}
        dataImport={dataImport}
        confirmationRequired={false}
      />
    </Box>
  );
};

ImportPreviewChanges.propTypes = {
  datasource: PropTypes.object.isRequired,
  dataImport: PropTypes.object.isRequired,
  datasourceType: PropTypes.string.isRequired,
  warnings: PropTypes.object,
};

export default ImportPreviewChanges;
