import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Divider, Flex } from "orcs-design-system";

import GroupHierarchyBreadcrumb from "src/components/GroupHierarchyBreadcrumb";
import getAllocationVisibility from "src/allocation/util/getAllocationVisibility";

import ConfiguredActionMenu from "src/components/ConfiguredActionMenu";
import { TEAM_TAB, url } from "src/consts/urlPaths";

const QuickLinkRow = ({ team, config, activeAllocationProject }) => {
  const allocationVisibility = getAllocationVisibility({
    config,
    group: team,
    activeAllocationProject,
  });
  const actions = useMemo(
    () => [
      {
        label: "Open in planner",
        isHidden:
          !allocationVisibility.approveNumbers2 &&
          !allocationVisibility.requestNumbers2,
        href: url(
          allocationVisibility.approveNumbers2
            ? `/:workspaceId?/teams/${team.id}/${TEAM_TAB.APPROVE_ALLOCATION_NUMBERS}`
            : `/:workspaceId?/teams/${team.id}/${TEAM_TAB.REQUEST_ALLOCATION_NUMBERS}`
        ),
      },
      {
        label: "Open in forecast",
        isHidden: !allocationVisibility.forecastNumbers,
        href: url(`/:workspaceId?/teams/${team.id}/${TEAM_TAB.FORECAST}`),
      },
      {
        label: "Open in team builder",
        isHidden:
          !allocationVisibility.requestPeople &&
          !allocationVisibility.individualAllocations,
        href: url(
          `/:workspaceId?/teams/${team.id}/${TEAM_TAB.INDIVIDUAL_ALLOCATIONS}`
        ),
      },
    ],
    [allocationVisibility, team.id]
  );

  const showActionsMenu = actions.some((action) => !action.isHidden);

  return (
    <>
      <Box p="r">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexWrap="nowrap"
        >
          <GroupHierarchyBreadcrumb
            group={team}
            showGroupTypeBadge
            showGroupTypeBadgeShowToolTip
            groupTypeBadgeToolTipPosition="bottomRight"
          />
          {showActionsMenu && (
            <ConfiguredActionMenu
              ariaLabel="links-menu"
              direction="left-start"
              actions={actions}
            />
          )}
        </Flex>
      </Box>
      <Divider light />
    </>
  );
};

QuickLinkRow.propTypes = {
  team: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  activeAllocationProject: PropTypes.object,
};

export default QuickLinkRow;
