import { get, isEqual } from "lodash";
import { useMemo, useRef, useCallback } from "react";

import useRootGroupsQuery from "src/hooks/useRootGroupsQuery";
import {
  buildTreeStructure,
  updateTreeObject,
} from "src/shared/TreeView/TreeView.util";

import {
  filterOutNonVisibleGroups,
  convertSortTeamsByGroupTypeFlag,
} from "src/components/GroupsNavigation/index.util";

const useGroupsTreeData = ({
  workspace,
  featureFlags,
  isSelected,
  targetGroupTypes,
  additionalVariables,
  createTreeNodeImpl,
  convertChildrenToArrayImpl,
  addGroupsToRootsImpl,
}) => {
  const { data, loading: rootGroupsLoading } = useRootGroupsQuery({
    workspace,
    featureFlags,
    isSelected,
    additionalVariables,
  });

  const prevResult = useRef(null);

  const groupTypes = get(workspace, "config.groupTypes");

  const sortTeamsByGroupType = get(
    workspace,
    "config.featureFlags.sortTeamsByGroupType"
  );
  const sortOption = convertSortTeamsByGroupTypeFlag(sortTeamsByGroupType);

  const { root, treeData } = useMemo(() => {
    const filteredRootGroups = filterOutNonVisibleGroups(
      get(data, "pagedGroups.groups")
    );

    // Found an issue, the data returned from useRootGroupsQuery is changing.
    // Maybe because of some kind of cache updates... I couldn't find out why.
    // So add a check here to make sure we don't build the tree again,
    // if the groups used to build the tree are the same
    if (
      prevResult.current &&
      isEqual(filteredRootGroups, prevResult.current.groups)
    ) {
      return prevResult.current;
    }

    const result = buildTreeStructure(
      filteredRootGroups,
      groupTypes,
      undefined,
      sortOption,
      targetGroupTypes,
      createTreeNodeImpl,
      convertChildrenToArrayImpl,
      addGroupsToRootsImpl
    );
    prevResult.current = result;

    return result;
  }, [
    addGroupsToRootsImpl,
    convertChildrenToArrayImpl,
    createTreeNodeImpl,
    data,
    groupTypes,
    sortOption,
    targetGroupTypes,
  ]);

  const refreshTree = useCallback(() => {
    prevResult.current = updateTreeObject(treeData);
  }, [treeData]);

  return {
    root,
    treeData,
    rootGroupsLoading,
    groupTypes,
    refreshTree,
    sortOption,
  };
};

export default useGroupsTreeData;
