import { filter, includes, isEmpty, map } from "lodash";
import { ENTITY_TYPES } from "src/consts/comments";
import { TAGS_VISIBLE_IN_TYPE } from "src/pages/Workspaces/consts";
import {
  addLocalStorageItem,
  getLocalStorageItem,
} from "src/services/localStorage";
import { createPropertyOption } from "./createPropertyOption";

export const ORG_CHART_TEAMS_PROPERTIES = "org_chart_teams_properties";
export const ORG_CHART_PEOPLE_PROPERTIES = "org_chart_people_properties";

export const ORG_CHART_DEFAULT_PANEL_ID = "org_chart_default_panel_id";

export const storeOrgChartProperties = ({ entityType, value }) => {
  const storageKey =
    entityType === ENTITY_TYPES.PERSON
      ? ORG_CHART_PEOPLE_PROPERTIES
      : ORG_CHART_TEAMS_PROPERTIES;

  addLocalStorageItem(storageKey, value);
};

const filterIt = (tagConfig, entityType) => (option) => {
  const allowedToHaveTag =
    entityType === ENTITY_TYPES.PERSON
      ? "allowPersonToHaveTag"
      : "allowGroupToHaveTag";
  return (
    includes(
      tagConfig?.[option.value]?.visibleIn,
      TAGS_VISIBLE_IN_TYPE.OrgChart
    ) && tagConfig?.[option.value]?.[allowedToHaveTag]
  );
};

const getInitialOptions = (tagConfig, entityType) => {
  const isHighlighted =
    entityType === ENTITY_TYPES.PERSON ? "isPersonBadge" : "isGroupBadge";
  return map(
    filter(tagConfig, (tagType) => {
      return (
        includes(tagType?.visibleIn, TAGS_VISIBLE_IN_TYPE.OrgChart) &&
        tagType?.[isHighlighted]
      );
    }),
    createPropertyOption()
  );
};

export const getOrgChartPropertiesFromLocalStorage = ({ tagConfig }) => {
  const peopleProperties =
    getLocalStorageItem(ORG_CHART_PEOPLE_PROPERTIES) || [];
  const teamsProperties = getLocalStorageItem(ORG_CHART_TEAMS_PROPERTIES) || [];

  const peopleData = !isEmpty(peopleProperties)
    ? peopleProperties
    : getInitialOptions(tagConfig, ENTITY_TYPES.PERSON);

  const teamData = !isEmpty(teamsProperties)
    ? teamsProperties
    : getInitialOptions(tagConfig, ENTITY_TYPES.GROUP);

  return {
    properties: {
      peopleData: filter(peopleData, filterIt(tagConfig, ENTITY_TYPES.PERSON)),
      teamsData: filter(teamData, filterIt(tagConfig, ENTITY_TYPES.GROUP)),
    },
  };
};
