import { FloatingPanels } from "orcs-design-system";
import React, { useEffect, useMemo } from "react";
import { isEmpty } from "lodash";
import { useOrgChartContext } from "src/components/OrgChart/context/OrgChartContext";
import { EventBus } from "src/services/eventEmitter";
import {
  addLocalStorageItem,
  getLocalStorageItem,
} from "src/services/localStorage";
import { ORG_CHART_DEFAULT_PANEL_ID } from "src/components/OrgChart/utils/storeInLocalStorage";
import ViewOptions from "./panels/ViewOptions";
import Properties from "./panels/Properties";
import Legend from "./panels/Legend";
import { PANEL_ID } from "./const";
import { Details } from "./panels/Details/Details";

const BOTTOM_PADDING = "30px";

const LegendPanel = {
  id: PANEL_ID.legend,
  iconName: "palette",
  title: "Legend",
  content: <Legend />,
};

const ViewOptionsPanel = {
  id: PANEL_ID.viewOptions,
  iconName: "cog",
  title: "View Options",
  content: <ViewOptions />,
};

const PropertiesPanel = {
  id: PANEL_ID.properties,
  iconName: "filter",
  title: "Properties",
  content: <Properties />,
};

const DetailsPanel = {
  id: PANEL_ID.details,
  iconName: "info-circle",
  title: "Details",
  content: <Details />,
};

const OrgChartPanel = () => {
  const { state } = useOrgChartContext();

  // listen to panel change events so we can retain panel state across page loads
  useEffect(() => {
    const StoreSelectedPanel = (paneId) => {
      // store selected panel in local storage
      addLocalStorageItem(ORG_CHART_DEFAULT_PANEL_ID, paneId);
    };

    // register event listeners
    EventBus.on("FloatingPanels_SelectedPanelChanged", StoreSelectedPanel);

    // on unmount, deregister event listeners
    return () => {
      EventBus.off("FloatingPanels_SelectedPanelChanged", StoreSelectedPanel);
    };
  }, []);

  // calc panels to display based on state
  const panelsToDisplay = useMemo(() => {
    const panels = [];

    // legend panel (always show)
    panels.push(LegendPanel);

    // view options panel
    panels.push(ViewOptionsPanel);

    // only show properties panel if there are loaded teams or members
    if (!isEmpty(state?.teams) || !isEmpty(state?.members)) {
      panels.push(PropertiesPanel);
    }

    // legend panel (always show)
    panels.push(DetailsPanel);

    return panels;
  }, [state]);

  const defaultPanelId = useMemo(() => {
    // get default selected panel from local storage
    const storedPanelId = getLocalStorageItem(ORG_CHART_DEFAULT_PANEL_ID);

    // have default panel? (null = closed)
    if (storedPanelId !== undefined) {
      return storedPanelId;
    }

    // fallback to details panel
    return PANEL_ID.details;
  }, []);

  return (
    <FloatingPanels
      panels={panelsToDisplay}
      defaultSelectedPanelId={defaultPanelId}
      containerHeight={`calc(100% - ${BOTTOM_PADDING})`}
      position={{
        right: 15,
        top: 15,
      }}
      alignPanels="right"
      eventBus={EventBus}
    />
  );
};

export default OrgChartPanel;
