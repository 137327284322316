import { useMutation } from "@apollo/client";
import { get } from "lodash";
import { Box, H4, Modal } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";

import TeamNameEditor from "src/components/TeamNameEditor";
import ErrorNotification from "src/components/ErrorNotification";
import { updateGroupName } from "src/queries/mutations/group.graphql";
import eventEmitter, { EVENTS } from "src/services/eventEmitter";

const TeamRenameModal = ({ team, isVisible, onHideModal }) => {
  const { id: groupId } = team;
  const [error, setError] = useState(null);

  const [onRenameTeamMutation, { loading }] = useMutation(updateGroupName);

  const handleRenameTeam = useCallback(
    async (newName) => {
      setError(null);
      try {
        await onRenameTeamMutation({
          variables: {
            groupId,
            name: newName,
          },
          update: (proxy, result) => {
            eventEmitter.emit(EVENTS.GROUP_RENAMED, get(result, "data.result"));
          },
        });
        setTimeout(onHideModal, 50);
      } catch (e) {
        setError(e);
      }
    },
    [onRenameTeamMutation, groupId, onHideModal]
  );

  return (
    <Modal
      visible={isVisible}
      maxHeight="90vh"
      width={["90vw", "80vw", "70vw", "60vw", "40vw"]}
      maxWidth="900vw"
      onClose={onHideModal}
      headerContent={<H4>Change team name</H4>}
    >
      <Box px="xs" pt="r">
        <TeamNameEditor
          team={team}
          label="Please enter new name"
          updateName={handleRenameTeam}
          canCancel={false}
          autoExitEditMode={false}
          isEditMode
          isLoading={loading}
        />
        {error && (
          <Box mt="r">
            <ErrorNotification message={error.message} error={error} />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

TeamRenameModal.propTypes = {
  team: PropTypes.object.isRequired,
  isVisible: PropTypes.bool,
  onHideModal: PropTypes.func.isRequired,
};

export default TeamRenameModal;
