import getLocalTime from "src/util/getLocalTime";

// eslint-disable-next-line import/prefer-default-export
export const displayLocalTime = (person) => {
  const localTime = getLocalTime(
    person?.city,
    person?.address,
    person?.country
  );
  if (!localTime) {
    return null;
  }
  return `${localTime.replace(" ", "")} local time`;
};
