import { isEmpty, map } from "lodash";
import { Flex, Avatar, Badge, Spacer } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import FteBadge from "src/components/Badge/FteBadge";
import GroupBadge from "src/components/Badge/GroupBadge";
import { getSupplyBadgeGroups } from "src/util/group";
import { findFteToDisplay, getName, getInitials } from "src/util/person";
import { getTagsOfType } from "src/util/tags";
import { TAG_SPECIALIST } from "src/consts/tags";

const PersonRow = ({ person, group, groupTypes }) => {
  const badgeGroups = getSupplyBadgeGroups({
    person,
    groupTypes,
  });
  const specialistRoles = getTagsOfType(person.tags, TAG_SPECIALIST);
  const fte = group && findFteToDisplay(person, group, groupTypes);
  const showFte = isFinite(fte);
  const hasNoBadges =
    isEmpty(specialistRoles) && isEmpty(badgeGroups) && !showFte;

  return (
    <Avatar
      title={
        <Flex justifyContent="flex-start" alignItems="center">
          {getName(person)}
        </Flex>
      }
      imageAlt={`Avatar for ${getName(person)}`}
      initials={getInitials(person)}
      image={person.avatar}
      sizing="small"
      subtitleContent={
        <Flex flexWrap="wrap" alignItems="center">
          {person.jobTitle && (
            <Badge mr="xs" mt="xs">
              {person.jobTitle}
            </Badge>
          )}
          {!hasNoBadges && (
            <Spacer mr="xs" mt="xs">
              {!isEmpty(specialistRoles) &&
                map(specialistRoles, (specialistRole) => (
                  <Badge variant="warning" display="block">
                    {specialistRole.displayValue}
                  </Badge>
                ))}
              {!isEmpty(badgeGroups) &&
                map(badgeGroups, (badgeGroup) => (
                  <GroupBadge
                    group={badgeGroup}
                    groupTypes={groupTypes}
                    key={badgeGroup.id}
                  />
                ))}
              {showFte && !isEmpty(fte) && <FteBadge fte={fte} />}
            </Spacer>
          )}
        </Flex>
      }
    />
  );
};

PersonRow.propTypes = {
  person: PropTypes.object,
  group: PropTypes.object,
  groupTypes: PropTypes.object,
};

export default PersonRow;
