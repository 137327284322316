import { isMobile } from "react-device-detect";
import { get, isString } from "lodash";
import { getLocalStorageItem } from "src/services/localStorage";
import { FLOATING_CONTROL_ID } from "src/components/TeamDetailPage/const";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { useTenantId } from "src/contexts/global/ApolloSettingsContext";

export const useGetViewMode = ({ defaultView, viewModeStorageKey }) => {
  const workspace = useWorkspace();
  const tenantId = useTenantId();
  const localStorageData = getLocalStorageItem(viewModeStorageKey);

  const viewMode = get(localStorageData, `${tenantId}.${workspace.id}`, null);

  if (viewMode) {
    return viewMode;
  }

  if (
    (isString(localStorageData) &&
      localStorageData === FLOATING_CONTROL_ID.CHART_VIEW) ||
    localStorageData === FLOATING_CONTROL_ID.LIST_VIEW
  ) {
    return localStorageData;
  }

  if (isMobile) {
    return FLOATING_CONTROL_ID.LIST_VIEW;
  }

  return defaultView || FLOATING_CONTROL_ID.CHART_VIEW;
};
