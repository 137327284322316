import { size, isEmpty } from "lodash";
import { Box, H4, Small, Notification } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import ErrorNotification from "src/components/ErrorNotification";
import TeamSearch from "src/components/TeamSearch";
import { getMemberCountDisplay } from "src/util/search";

const TeamSearchSection = ({
  team,
  setTargetGroupOption,
  isProcessing,
  typeFilters,
  error,
  selectedMembers,
  groupTypes,
}) => {
  return (
    <>
      <H4 mb="r" sizing="small">
        Where would you like to move to?
      </H4>
      <Box
        borderRadius={2}
        boxBorder="default"
        p="r"
        height="calc(100% - 48px)"
        maxHeight="calc(100% - 48px)"
        overflowY="auto"
      >
        <TeamSearch
          existingGroupIds={[team.id]}
          onTeamSelected={setTargetGroupOption}
          isProcessing={isProcessing}
          showButton={false}
          typeFilters={typeFilters}
          buttonLabel="Move"
          groupTypes={groupTypes}
        />
        <Box mt="r">
          <Small display="block" mb="r" color="greyDark">
            Note: This will only move members currently allocated FTE from this
            team to selected team. If members have partial FTE allocated to
            another team, that will not be changed.
          </Small>
          {isProcessing && !error && (
            <Notification loading closable={false}>
              Moving {getMemberCountDisplay(size(selectedMembers))} in
              progress...
            </Notification>
          )}
          {error && (
            <ErrorNotification
              message={`Sorry, an error occurred, please try moving again ${
                !isEmpty(error?.message) ? `: ${error?.message}` : ""
              } `}
              error={error}
              closable={false}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

TeamSearchSection.propTypes = {
  setTargetGroupOption: PropTypes.func,
  isProcessing: PropTypes.bool,
  typeFilters: PropTypes.object,
  error: PropTypes.object,
  selectedMembers: PropTypes.object,
  team: PropTypes.object,
  groupTypes: PropTypes.object,
};

export default TeamSearchSection;
