import {
  filter,
  startsWith,
  reduce,
  pick,
  some,
  values,
  keys,
  forEach,
  join,
  difference,
  isEmpty,
} from "lodash";

export const ATTRIBUTES_TYPE_COLUMN = "Attributes";
export const TEAMS_TYPE_COLUMN = "Teams";
export const BUDGETS_TYPE_COLUMN = "Budgets";
export const DISABLED_COLUMN = "Disabled";

export const ReservedFields = [
  ATTRIBUTES_TYPE_COLUMN,
  BUDGETS_TYPE_COLUMN,
  TEAMS_TYPE_COLUMN,
  DISABLED_COLUMN,
];

export const ReservedMultipleFields = [
  ATTRIBUTES_TYPE_COLUMN,
  BUDGETS_TYPE_COLUMN,
  TEAMS_TYPE_COLUMN,
];

export const updateFieldMapping = (
  currentMap,
  targetField,
  fieldsDefinition
) => {
  const next = { ...currentMap };

  let newFieldMap = {
    [targetField]: true,
  };

  const definition = fieldsDefinition[targetField];
  const alias = definition?.as;
  if (alias) {
    newFieldMap = {
      [alias]: definition[alias],
    };
  }

  if (definition?.exclusive) {
    forEach(definition.exclusive, (e) => {
      if (next[e]) {
        delete next[e];
      }
    });
  }

  return {
    ...next,
    ...newFieldMap,
  };
};

export const isReservedFields = (fieldName) =>
  ReservedFields.indexOf(fieldName) > -1;

export const hasAllHeaderFields = (headerFields, fieldsMap) => {
  const savedHeaders = keys(fieldsMap);

  const diff = difference(headerFields, savedHeaders);

  return isEmpty(diff);
};

export const getTargetFields = (headerFields, defaultFieldsMap) => {
  const targets = pick(defaultFieldsMap, headerFields);
  const reserved = pick(defaultFieldsMap, ReservedFields);

  return {
    ...targets,
    ...reserved,
  };
};

export const isTargetField = (header, columnName) =>
  startsWith(header, columnName);

export const isDisabledField = (header) =>
  startsWith(header, TEAMS_TYPE_COLUMN) ||
  header === DISABLED_COLUMN ||
  startsWith(header, BUDGETS_TYPE_COLUMN);

export const fillReservedFields = (headers, fieldsMap, fieldName) => {
  const fieldKey = fieldsMap[fieldName];

  if (!fieldKey) {
    return {};
  }

  const columns = filter(headers, (h) => isTargetField(h, fieldName));

  const map = reduce(
    columns,
    (prev, h) => {
      return {
        ...prev,
        [h]: fieldKey,
      };
    },
    {}
  );

  return map;
};

const validateRequiredFields = (fieldsDefinition, currentMap) => {
  const requiredFields = filter(fieldsDefinition, (f) => f.required);
  const currentMappedFields = values(currentMap);

  for (let i = 0, j = requiredFields.length; i < j; i += 1) {
    const fieldKey = requiredFields[i].key;
    const isKeyMapped = some(currentMappedFields, (c) => c && !!c[fieldKey]);

    if (!isKeyMapped) {
      return {
        hasError: true,
        message: `${fieldKey} is required. Please map a field to ${fieldKey}.`,
      };
    }
  }

  return {
    hasError: false,
  };
};

const validateDuplicatedFields = (fieldsDefinition, currentMap) => {
  const reverseMap = reduce(
    currentMap,
    (prev, v, k) => {
      const mapped = keys(v);
      forEach(mapped, (mappedKey) => {
        if (prev[mappedKey]) {
          prev[mappedKey].push(k);
        } else {
          // eslint-disable-next-line no-param-reassign
          prev[mappedKey] = [k];
        }
      });

      return prev;
    },
    {}
  );

  const filtered = filter(keys(reverseMap), (k) => reverseMap[k].length >= 2);

  for (let i = 0, j = filtered.length; i < j; i += 1) {
    const mappedName = filtered[i];
    if (!fieldsDefinition[mappedName]?.allowMultiple) {
      return {
        hasError: true,
        message: `Field ${mappedName} can only be mapped once. Currently mapped to ${join(
          reverseMap[mappedName]
        )}`,
      };
    }
  }

  return {
    hasError: false,
  };
};

export const validateFieldsMap = (
  headerFields,
  fieldsMap,
  fieldsDefinition
) => {
  const currentMap = pick(fieldsMap, headerFields);

  const requiredResult = validateRequiredFields(fieldsDefinition, currentMap);

  if (requiredResult.hasError) {
    return requiredResult;
  }

  const duplicatedResult = validateDuplicatedFields(
    fieldsDefinition,
    currentMap
  );

  if (duplicatedResult.hasError) {
    return duplicatedResult;
  }

  return {
    hasError: false,
  };
};
