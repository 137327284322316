/* eslint-disable react/no-array-index-key */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { split, map, initial, filter } from "lodash";
import { Box, Flex, Icon, Spacer } from "orcs-design-system";
import TeamLink from "../TeamLink";

const HIERARCHY_SEPERATOR = "\\";

export const pairTeamHierarchyAndParentIds = (team) => {
  if (!team) {
    return [];
  }

  const { hierarchy, parentIds = [] } = team;
  const names = split(hierarchy, HIERARCHY_SEPERATOR);

  // filter out names that is empty after split
  const parentNames = filter(initial(names), (n) => !!n);

  // Map parentIds with name, ids should be the source of truth.
  // In case the team name is end with `\\` <= this is an illegal team name, but appears due to some imports
  const groups = map(parentIds, (id, i) => ({
    id,
    name: parentNames[i],
    hierarchy,
  }));

  return groups;
};

const TeamHierarchyBreadcrumb = ({
  className,
  team,
  filterKey,
  muted,
  onFilterByItem,
  parentGroups,
}) => {
  const groups = parentGroups || pairTeamHierarchyAndParentIds(team);

  if (groups.length === 0) {
    return null;
  }

  const onLinkClick = (selectedTeam) => {
    if (!onFilterByItem) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return (e) => {
      e.preventDefault();
      onFilterByItem(
        filterKey,
        selectedTeam.id,
        selectedTeam.name,
        selectedTeam.hierarchy
      );
    };
  };

  return (
    <Box
      data-testid="cp-teamhierarchy-link"
      className="guided-tour-team-details-hierarchy"
    >
      <Flex alignItems="center" flexWrap="wrap">
        {groups.map((t) => {
          return (
            <Fragment key={t.id}>
              <Spacer mr="xs">
                <TeamLink
                  className={className}
                  muted={muted}
                  small={true}
                  team={t}
                  onLinkClick={onLinkClick(t)}
                />
                <Icon icon={["far", "angle-right"]} color="grey" size="xs" />
              </Spacer>
            </Fragment>
          );
        })}
      </Flex>
    </Box>
  );
};

TeamHierarchyBreadcrumb.propTypes = {
  className: PropTypes.string,
  team: PropTypes.object.isRequired,
  muted: PropTypes.bool,
  filterKey: PropTypes.string,
  onFilterByItem: PropTypes.func,
  parentGroups: PropTypes.array,
};

export default TeamHierarchyBreadcrumb;
