import { Box, Flex } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import {
  getTeamHeaderHeight,
  TeamHeaderBox,
} from "src/util/DetailsHeaderUtils";

const CoverImagePanel = ({
  loading,
  bgImg,
  bgGradient,
  heading,
  showActions,
  actions,
}) => {
  return (
    <TeamHeaderBox
      bgImg={bgImg}
      bgGradient={bgGradient}
      loading={loading}
      borderRadius={2}
      boxBorder="default"
      p="r"
      mb="r"
      bg="white"
      width="100%"
      minHeight={getTeamHeaderHeight(bgImg)}
    >
      <Flex justifyContent="space-between" alignItems="flex-start">
        <Box p="r" bg="black70" data-testid="cp-team-header-container">
          {heading}
        </Box>
        {showActions && actions}
      </Flex>
    </TeamHeaderBox>
  );
};

CoverImagePanel.propTypes = {
  loading: PropTypes.any,
  bgGradient: PropTypes.any,
  bgImg: PropTypes.any,
  heading: PropTypes.any,
  showActions: PropTypes.any,
  actions: PropTypes.any,
};

export default CoverImagePanel;
