import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";

import GlobalNotificationSettings from "src/components/GlobalNotificationSettings";
import { updateGlobalConfig } from "../../pages/AdminConfiguration/globalConfig.graphql";

const GlobalNotifications = ({ globalConfig, globalConfigId }) => {
  const [saveNotification, { loading: isSaving }] =
    useMutation(updateGlobalConfig);

  const onSave = ({ mapperData, directoryData }) => {
    saveNotification({
      variables: {
        input: {
          id: globalConfigId,
          mapperNotification: mapperData,
          directoryNotification: directoryData,
        },
      },
    });
  };

  return (
    <GlobalNotificationSettings
      notifications={globalConfig}
      onSave={onSave}
      isSaving={isSaving}
    />
  );
};

GlobalNotifications.propTypes = {
  globalConfig: PropTypes.object,
  globalConfigId: PropTypes.string,
};

export default GlobalNotifications;
