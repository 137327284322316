import { size } from "lodash";
import {
  Spacer,
  Checkbox,
  Divider,
  Flex,
  FlexItem,
  Box,
  Badge,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { getName } from "src/util/person";

import { MAX_MOVE_LIMIT } from "../../consts";
import PersonRow from "../PersonRow";

const MembersListSection = ({
  shouldSelectAllMembers = false,
  isProcessing,
  peopleList = [],
  selectedMembers = {},
  setSelectedMembers,
  group,
  groupTypes,
  onSelect,
}) => {
  const [allSelectMembers, setAllSelectMembers] = useState(
    shouldSelectAllMembers
  );

  const handleAllSelectMembersClick = () => {
    const prev = allSelectMembers;
    setAllSelectMembers(!prev);
    if (prev) {
      setSelectedMembers({});
    } else {
      setSelectedMembers(
        peopleList.reduce((acc, next) => {
          acc[next.aggregateId] = next;
          return acc;
        }, {})
      );
    }
  };

  return (
    <FlexItem flex="1 1 50%">
      <Flex alignItems="center" justifyContent="space-between" mb="r" px="r">
        <Checkbox
          disabled={isProcessing}
          checked={allSelectMembers}
          onChange={handleAllSelectMembersClick}
          label="Select all members"
          colour="primary"
          mr="s"
        />
        <Badge variant="primary">{`Total ${size(
          selectedMembers
        )} selected. Max ${MAX_MOVE_LIMIT}.`}</Badge>
      </Flex>
      <Box
        borderRadius={2}
        boxBorder="default"
        p="r"
        height="calc(100% - 48px)"
        maxHeight="calc(100% - 48px)"
        overflowY="auto"
      >
        {peopleList.map((person) => {
          return (
            <Spacer mb="r" key={person.aggregateId}>
              <Flex>
                <Flex alignItems="center" justifyContent="center">
                  <Checkbox
                    disabled={isProcessing}
                    checked={!!selectedMembers[person.aggregateId]}
                    onChange={() => onSelect(person)}
                    colour="primary"
                    ariaLabel={`Select ${getName(person)}`}
                  />
                </Flex>
                <PersonRow
                  person={person}
                  group={group}
                  groupTypes={groupTypes}
                />
              </Flex>
              <Divider light />
            </Spacer>
          );
        })}
      </Box>
    </FlexItem>
  );
};

MembersListSection.propTypes = {
  peopleList: PropTypes.array,
  selectedMembers: PropTypes.object,
  group: PropTypes.object,
  groupTypes: PropTypes.object,
  onSelect: PropTypes.func,
  setSelectedMembers: PropTypes.func,
  isProcessing: PropTypes.bool,
  shouldSelectAllMembers: PropTypes.bool,
};

export default MembersListSection;
