import React from "react";
import PropTypes from "prop-types";
import { Box, H2, Spacer } from "orcs-design-system";
import LoadingPage from "../../LoadingPage";
import PrincipleSetSelector from "./PrincipleSetSelector";

const NoPrincipleSet = ({
  mutating,
  team,
  principleSets = [],
  setPrincipleSetId,
}) => (
  <Box
    overflow="visible"
    bg="white"
    boxBorder="default"
    borderRadius="2"
    p="r"
    height="100%"
  >
    <Spacer mt="s">
      <H2 sizing="small" weight="light" mb="r">
        Principles
      </H2>
      <PrincipleSetSelector
        mutating={mutating}
        team={team}
        principleSets={principleSets}
        setPrincipleSetId={setPrincipleSetId}
      />
      {mutating && <LoadingPage waitingFor="selectingPrincipleSet" />}
    </Spacer>
  </Box>
);

NoPrincipleSet.propTypes = {
  team: PropTypes.object,
  principleSets: PropTypes.array,
  mutating: PropTypes.bool,
  setPrincipleSetId: PropTypes.func.isRequired,
};

export default NoPrincipleSet;
