import moment from "moment";
import { forEach, map } from "lodash";

import { MEMBERSHIP_DATE_FORMAT } from "src/util/memberships";

export const futureDate = (date) => {
  return date || moment(moment().add(1, "day").format(MEMBERSHIP_DATE_FORMAT));
};

export const toFloat = (value, decimals = 2) => {
  return parseFloat(value.toFixed(decimals));
};

const byDateAscending = (a, b) => {
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }
  return a - b;
};

const toUniqDates = (acc, date) => {
  if (!date) {
    return acc;
  }

  const values = acc.map((d) => d?.format());

  if (!date || values.indexOf(date.format()) === -1) {
    acc.push(date);
  }

  return acc;
};

const toDateRange = (startDate, index, dates) => {
  let endDate = null;
  if (dates[index + 1]) {
    endDate = moment(
      dates[index + 1].clone().subtract(1, "day").format(MEMBERSHIP_DATE_FORMAT)
    );
  }
  return { startDate, endDate };
};

const withAvailableFte =
  (memberships, capacityFte, fteConfig) => (dateRange) => {
    const allocated = memberships.filter(({ startDate, endDate }) => {
      return (
        dateRange.startDate >= startDate &&
        (!endDate || dateRange.startDate <= endDate)
      );
    });

    const totalFte = allocated.reduce(
      (sum, { fte }) => toFloat(sum + fte, fteConfig.decimal),
      0
    );
    const availableFte = toFloat(capacityFte - totalFte, fteConfig.decimal);

    return { ...dateRange, availableFte };
  };

export const getFteAllocations = (
  memberships = [],
  capacityFte,
  fteConfig = {}
) => {
  const startDates = memberships.map((m) => m.startDate);
  // convert end dates to exclusive dates by adding 1 day to make period calculations easier
  const endDates = memberships.map((m) => {
    if (m.endDate) {
      const endDatePlusOneDay = moment(
        m.endDate.clone().add(1, "day").format(MEMBERSHIP_DATE_FORMAT)
      );
      return endDatePlusOneDay;
    }
    return null;
  });

  // now that end dates are exclusive, we can treat all dates the same way to calculate the important points in time
  const allDates = [...startDates, ...endDates];
  const sortedDates = allDates.sort(byDateAscending);
  const uniqedDates = sortedDates.reduce(toUniqDates, []);
  // dateRanges are converted back to inclusive ranges
  const dateRanges = uniqedDates.map(toDateRange);
  const allocations = dateRanges.map(
    withAvailableFte(memberships, capacityFte, fteConfig)
  );

  // If the the last allocation is not infinite then insert one
  const lastAllocation = allocations[allocations.length - 1];

  // Always end with an infinite endDate
  if (!lastAllocation || !!lastAllocation.endDate) {
    let startDate;
    if (lastAllocation) {
      startDate = moment(
        lastAllocation.endDate
          .clone()
          .add(1, "day")
          .format(MEMBERSHIP_DATE_FORMAT)
      );
    } else {
      startDate = moment(moment().format(MEMBERSHIP_DATE_FORMAT));
    }
    allocations.push({
      startDate,
      endDate: null,
      availableFte: capacityFte,
    });
  }

  return allocations;
};

export const getCurrentFuturePastMemberships = (memberships) => {
  const currentMemberships = [];
  const futureMemberships = [];
  const pastMemberships = [];

  const startOfToday = moment().startOf("day");
  const endOfToday = moment().endOf("day");

  forEach(memberships, (membership) => {
    const { startDate, endDate } = membership;
    if (!!endDate && endDate < startOfToday) {
      pastMemberships.push(membership);
    } else if (startDate > endOfToday) {
      futureMemberships.push(membership);
    } else {
      currentMemberships.push(membership);
    }
  });

  return [currentMemberships, futureMemberships, pastMemberships];
};

export const getLatestUnallocated = ({
  memberships = [],
  capacityFte,
  fromDate = moment(),
  fteConfig,
}) => {
  const allocations = getFteAllocations(memberships, capacityFte, fteConfig);

  const withCapacity = ({ availableFte, endDate }) => {
    return (
      (!endDate && availableFte === capacityFte) ||
      (availableFte > 0 && availableFte <= capacityFte)
    );
  };

  const firstBeforeEndDate = ({ endDate }) => {
    return memberships.length > 0 ? fromDate <= futureDate(endDate) : true;
  };

  const latestUnallocated = allocations
    .filter(withCapacity)
    .find(firstBeforeEndDate);
  if (!latestUnallocated) {
    return {
      startDate: fromDate,
      endDate: null,
      availableFte: 0,
    };
  }
  if (latestUnallocated.startDate < fromDate) {
    return {
      startDate: fromDate,
      endDate: latestUnallocated.endDate,
      availableFte: latestUnallocated.availableFte,
    };
  }
  return latestUnallocated;
};

export const getEndDateFromStartDate = (startDate) => {
  const today = moment().startOf("day");

  const endDate = startDate.isBefore(today)
    ? moment().subtract(1, "days").startOf("day")
    : moment(startDate).startOf("day");

  return endDate;
};

export const formatDate = (date) => {
  return date ? date.format(MEMBERSHIP_DATE_FORMAT) : null;
};

export const buildNewMembership = (newMembership) => {
  if (!newMembership) {
    return undefined;
  }

  const { personId, groupId, startDate, endDate, fte } = newMembership;

  return [
    {
      personId,
      groupId,
      fte,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    },
  ];
};

export const convertMembershipToRemove = (membershipToRemove) => {
  if (!membershipToRemove) {
    return undefined;
  }

  const { currentCompositeId } = membershipToRemove;

  return [{ currentCompositeId }];
};

const getEndDateFromExpectedDate = (expectedDate, startDate) => {
  // If expected date is before startDate, use the same day to end membership
  if (startDate && expectedDate.isBefore(startDate)) {
    return moment(startDate).startOf("day");
  }

  return expectedDate;
};

export const buildMembershipsToUpdate = (targetMemberships, params) => {
  const expectedEndDate = moment(params.startDate)
    .subtract(1, "days")
    .startOf("day");

  return map(targetMemberships, (m) => {
    const { currentCompositeId, startDate, fte } = m;

    // End membership until yesterday
    return {
      currentCompositeId,
      fte,
      startDate: formatDate(startDate),
      endDate: formatDate(
        getEndDateFromExpectedDate(expectedEndDate, startDate)
      ),
    };
  });
};

export const convertMembershipToUpdate = (membershipToUpdate) => {
  if (!membershipToUpdate) {
    return undefined;
  }

  const { currentCompositeId, startDate, endDate, fte } = membershipToUpdate;

  return [
    {
      currentCompositeId,
      fte,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    },
  ];
};
