import PropTypes from "prop-types";
import React from "react";

import { showGroup } from "src/components/Explore/Explore.util";
import GroupPropType from "src/custom-prop-types/group";
import { sortGroupsByOptions } from "src/util/group";

import ExplorePanel from "./ExplorePanel";

const ExplorePanelList = ({
  loading,
  childGroups,
  selectedSort,
  newChildId = null,
  workspace,
}) => {
  const sortedGroups = loading
    ? childGroups
    : sortGroupsByOptions(childGroups, selectedSort, newChildId);

  if (!loading && !showGroup(sortedGroups)) {
    return null;
  }

  return (
    <ExplorePanel
      loading={loading}
      groups={sortedGroups}
      newChildId={newChildId}
      workspace={workspace}
    />
  );
};

ExplorePanelList.propTypes = {
  childGroups: PropTypes.oneOfType([
    PropTypes.arrayOf(GroupPropType),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  loading: PropTypes.bool,
  selectedSort: PropTypes.object,
  newChildId: PropTypes.string,
  workspace: PropTypes.object,
};

export default ExplorePanelList;
