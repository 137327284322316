import { stratify, pack } from "d3-hierarchy";
import { flatMap } from "lodash";

export const calcCirclePositions = (data = {}) => {
  const nodeHierarchy = stratify()
    .id((d) => d.id)
    .parentId((d) => d.parent)(data);

  nodeHierarchy.sum((d) => d.value);

  const circlePackLayout = pack().size([300, 300]).padding(5);
  const bubbleValSum = nodeHierarchy.value;
  return { bubbleValSum, bubbles: circlePackLayout(nodeHierarchy) };
};

export const toPercentageString = (count, total) => {
  const percentage = Math.floor((count / total) * 100);
  return percentage > 2 ? `${percentage}%` : "";
};

export const toCountString = (count, total) => {
  const percentage = Math.floor((count / total) * 100);
  return percentage > 2 ? count : "";
};

export const getBubbleValSumAndBubbles = (data, maxBubbles) =>
  calcCirclePositions(data.slice(0, maxBubbles));

export const getRootBubble = (bubbles) => ({
  id: bubbles.data.id,
  x: bubbles.x,
  y: bubbles.y,
  r: bubbles.r,
  type: bubbles.data.type,
});

export const getChildBubbles = (bubbles) => flatMap(bubbles);
