import { Box, Small } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

// custom react-select Group heading component
const GroupHeading = ({ label }) => {
  if (!label) {
    return null;
  }

  return (
    <Box
      bg="greyLightest"
      borderTop="solid 1px"
      borderTopColor="black10"
      role="heading"
      aria-level="2"
      p="s"
      m="0"
    >
      <Small uppercase color="greyDarker" fontWeight="600">
        {label}
      </Small>
    </Box>
  );
};

GroupHeading.propTypes = {
  label: PropTypes.string,
};

export default GroupHeading;
