import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { Avatar, Flex, StyledLink } from "orcs-design-system";
import PATHS, { url, TEAM_TAB } from "src/consts/urlPaths";
import GroupTypeBadge from "src/components/GroupTypeBadge/index";
import { objectiveNodeDataPropType } from "../propTypes";

const NodeTeamInfo = ({ team }) => {
  const history = useHistory();

  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const teamUrl = url(PATHS.TEAM_DETAILS, {
        id: team.id,
        tabType: TEAM_TAB.STRATEGY,
      });
      history.push(teamUrl);
    },
    [history, team.id]
  );

  return (
    <Flex alignItems="center" gap="s">
      <Avatar
        sizing="small"
        title={
          <StyledLink onClick={onClick} type="button">
            {team.name}
          </StyledLink>
        }
        shape="square"
        image={team.avatarUrl}
        imageAlt={`Avatar of ${team.name}`}
      />
      <GroupTypeBadge group={team} />
    </Flex>
  );
};

NodeTeamInfo.propTypes = {
  team: objectiveNodeDataPropType,
};

export default NodeTeamInfo;
